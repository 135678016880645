import React from 'react';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { Button } from '@material-ui/core';

import active_lang from 'src/langs/jp_en';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';

export default function AddCategoryButton({ onClick }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Button
        id="add-category-button"
        className={classes.AddCategoryButton}
        startIcon={<AddCircleOutlineIcon />}
        onClick={onClick}
      >
        <span className={classes.button_label}>
          {t('COMMON_ADD_CATEGORY')}
        </span>
      </Button>
    </>
  );
}
