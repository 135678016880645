import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Box, Grid } from '@material-ui/core';

import { AuthContext } from 'src/Firebase/context';
import { GlobalContext } from 'src/contexts/Global';
import HelpRequestedShortList from 'src/components/HelpRequestedShortList';
import ReservationRequestedShortList from 'src/components/ReservationRequestedShortList';
import NavBar from 'src/components/NavBar';
import { checkLgUp } from 'src/utils/checkClientWidth';
import { SERVICE_MENY, SERVICE_MENY_LIGHT } from 'src/consts/MENY_SERVICE';
import active_lang from 'src/langs/jp_en';

import { useStyles } from './styles';

import SubscribeToSeeReservationRequestedShortList from './SubscribeToSeeReservationRequestedShortList';
import Topbar from './Topbar';
import { useTranslation } from 'react-i18next';

const LOG_PREFIX = 'ShowAppLayout';
export default function ShowAppLayout() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { t } = useTranslation();

  let { rest_id, meny_service_class, restaurant_config } =
    React.useContext(GlobalContext);
  const { user } = React.useContext(AuthContext);

  const [open_nav_bar, setOpenNavBar] = React.useState(null);
  let [checking_credentials, setCheckingCredentials] = React.useState(true);

  let [confirm_loaded, setConfirmLoaded] = React.useState(false);

  let [
    show_reservation_request_short_list,
    setShowReservationRequestShortList,
  ] = React.useState(false);

  let [show_opening_hour_toggle_button, setShowOpeningHourToggleButton] =
    React.useState(false);

  React.useEffect(() => {
    if (checkLgUp()) {
      setOpenNavBar(true);
    } else {
      setOpenNavBar(false);
    }
  }, []);

  React.useEffect(() => {
    if (confirm_loaded || !checking_credentials) {
      console.log(LOG_PREFIX, 'confirm_loaded');

      if (user && restaurant_config) {
      } else {
        navigate('/login');
      }
    }
  }, [confirm_loaded, checking_credentials]);

  React.useEffect(() => {
    switch (meny_service_class?.id) {
      case SERVICE_MENY:
        setShowReservationRequestShortList(true);
        setShowOpeningHourToggleButton(true);
        break;

      case SERVICE_MENY_LIGHT:
        setShowReservationRequestShortList(false);
        setShowOpeningHourToggleButton(true);
        break;

      default:
        setShowReservationRequestShortList(false);
        setShowOpeningHourToggleButton(false);
        break;
    }
  }, [meny_service_class]);

  React.useEffect(() => {
    console.log(LOG_PREFIX, 'starting');
    setTimeout(() => {
      setConfirmLoaded(true);
    }, 3000);
  }, []);

  React.useEffect(() => {
    const isCredentialReady = () => rest_id && restaurant_config;

    setCheckingCredentials(!isCredentialReady());
  }, [rest_id, restaurant_config]);

  return (
    <>
      {checking_credentials ? (
        <>​{t('LOGIN_CHECKING_USER')}</>
      ) : (
        <>
          <Box className={classes.DashboardContainer}>
            <Topbar
              open_nav_bar={open_nav_bar}
              setOpenNavBar={setOpenNavBar}
              show_opening_hour_toggle_button={show_opening_hour_toggle_button}
            />

            <Box
              className={classes.DashboardBody}
              style={{
                display: 'flex',
                flexFlow: 'row',
                flexGrow: 1,
                height: '93vh',
              }}
            >
              <NavBar
                className={classes.Navbar}
                open={open_nav_bar}
                setOpen={setOpenNavBar}
              />
              <Box className={classes.NavBody}>
                <Box
                  style={{
                    display: 'flex',
                    flexFlow: 'column',
                    height: '100%',
                    borderLeft: '1px solid #E0E0E0',
                  }}
                >
                  <Box
                    className={classes.NavBodyHead}
                    style={{
                      minHeight: '180px',
                      borderBottom: '1px solid #E0E0E0',
                    }}
                  >
                    <Grid
                      container
                      style={{ height: '100%', minHeight: '180px' }}
                    >
                      {restaurant_config.isEnableBellRing && (
                        <Grid
                          item
                          xs={6}
                          lg={6}
                          className={classes.top_left_list_window}
                          style={{
                            height: '100%',
                            minHeight: '180px',
                            borderRight: '1px solid #E0E0E0',
                          }}
                        >
                          <HelpRequestedShortList />
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={restaurant_config.isEnableBellRing ? 6 : 12}
                        lg={restaurant_config.isEnableBellRing ? 6 : 12}
                        className={classes.top_left_list_window}
                      >
                        {show_reservation_request_short_list ? (
                          <ReservationRequestedShortList />
                        ) : (
                          <SubscribeToSeeReservationRequestedShortList />
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                  <Box style={{ flexGrow: 1, overflowY: 'auto' }}>
                    <Outlet />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
