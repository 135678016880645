import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { ShareContext } from './context/Share';
import Task from './Task';

// import DragHandleOutlinedIcon from '@material-ui/icons/DragHandleOutlined';
import { ReactComponent as DragHandleOutlinedIcon } from 'src/assets/icons/moveable.svg';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { ReactComponent as AddCircleIcon } from 'src/assets/icons/plus.svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { makeStyles, IconButton, Grid } from '@material-ui/core';

import { LYNKED_DEEP_GREY, LYNKED_LIGHT_GREY_HOVER } from 'src/consts/colors';

import { isUndefined } from 'lodash';
import ShowTextFoodHideStatus from 'src/components/ShowTextFoodHideStatus';

// const container = {
//   margin: '8px',
//   border: '1px solid lightgrey',
//   borderRadius: '2px',
//   width: '420px',

//   display: 'flex',
//   flexDirection: 'column',
// };

const useStyles = makeStyles(() => ({
  helloworld: {},
  container: {
    // margin: '8px',
    border: '1px solid lightgrey',
    backgroundColor: 'white',
    borderRadius: '2px',
    width: '100%',

    display: 'flex',
    flexDirection: 'column',
  },
  tasklist: () => ({
    transition: 'background-color 0.2s ease',
    // backgroundColor: props.isDraggingOver ? "skyblue" : "inherit",
    flexGrow: '1',
    // minHeight: '100px',
  }),
  tasklist_is_dragging_over: {
    backgroundColor: LYNKED_LIGHT_GREY_HOVER,
  },
  tasklist_not_dragging_over: {
    backgroundColor: 'white',
  },
  title_row_container: {
    margin: '0px',
  },
  title_row: {
    display: 'inline-flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#E0E0E0',
    width: '100%',
    height: '100%',
    padding: '10px',
  },
  title_row_left: {
    display: 'inline-flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
  },
  title_handler: {
    display: 'inline-flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  category_title: {
    // backgroundColor: 'tomato',
    marginLeft: '1rem',
    minWidth: '10rem',

    display: 'inline-flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  title_buttons_group: {
    marginLeft: '2rem',
  },
  category_bottom_row: {
    width: '100%',
    display: 'inline-flex',
    flexFlow: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title_buttons: {
    marginLeft: '1rem',
  },
}));

function Column({ column, tasks, index, search }) {
  const classes = useStyles();

  let {
    handleColumnEditClick,
    handleColumnDeleteClick,
    setPreSelectCategory,
    setOpenAddFoodDetailDialog,
    categories_available,
  } = React.useContext(ShareContext);

  const handleExpandButtonClick = () => {
    setListExpanded(!list_expanded);
  };
  const [list_expanded, setListExpanded] = React.useState(index === 0);

  return (
    <Draggable
      draggableId={column.id}
      index={index}
      isDragDisabled={Boolean(search)}
    >
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <div className={classes.container}>
            <div
              className={classes.title_row_container}
              {...provided.dragHandleProps}
            >
              <div className={classes.title_row}>
                <Grid container>
                  <Grid
                    item
                    xs={1}
                    style={{
                      display: 'flex',
                      flexFlow: 'row',
                      alignItems: 'center',
                      maxWidth: '65px',
                    }}
                  >
                    <DragHandleOutlinedIcon />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{
                      display: 'flex',
                      flexFlow: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      fontWeight: 'bold',
                    }}
                  >
                    {column.title}

                    <ShowTextFoodHideStatus
                      food_show_status={column.category_show}
                    />
                    <IconButton
                      id={`edit-category-button-${index}`}
                      className={classes.title_buttons}
                      onClick={(e) => handleColumnEditClick(e, column.id)}
                      size="small"
                    >
                      <EditOutlinedIcon />
                    </IconButton>
                    <IconButton
                      id={`delete-category-button-${index}`}
                      className={classes.title_buttons}
                      onClick={(e) => handleColumnDeleteClick(e, column.id)}
                      size="small"
                    >
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={2} style={{ textAlign: 'right' }}></Grid>
                  <Grid item xs={1} style={{ textAlign: 'right' }}>
                    <IconButton
                      className={classes.title_buttons}
                      onClick={(e) => handleExpandButtonClick(e, column.id)}
                      size="small"
                    >
                      {list_expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
          <div style={{ display: list_expanded ? 'block' : 'none' }}>
            <Droppable droppableId={column.id} isDropDisabled={Boolean(search)}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{ minHeight: '150px', border: '1px solid lightgrey' }}
                  className={`${classes.tasklist} ${
                    snapshot.isDraggingOver
                      ? classes.tasklist_is_dragging_over
                      : classes.tasklist_not_dragging_over
                  }`}
                  isDraggingOver={snapshot.isDraggingOver}
                >
                  {tasks.map((task, index) => {
                    if (isUndefined(task)) {
                      return <></>;
                    } else {
                      return (
                        <>
                          <Task
                            key={task.id}
                            task={task}
                            index={index}
                            search={search}
                          ></Task>
                        </>
                      );
                    }
                  })}
                  {provided.placeholder}
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexFlow: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
                      <IconButton
                        style={{ width: 37, padding: 0 }}
                        onClick={() => {
                          setPreSelectCategory(
                            categories_available.filter(
                              (x) => x.id === column.id
                            )[0]
                          );
                          setOpenAddFoodDetailDialog(true);
                        }}
                      >
                        <AddCircleIcon style={{ color: LYNKED_DEEP_GREY }} />{' '}
                      </IconButton>
                    </div>
                  </div>
                </div>
              )}
            </Droppable>
          </div>
        </div>
      )}
    </Draggable>
  );
}

export default React.memo(Column);
