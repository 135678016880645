import { isUndefined } from 'lodash';
import React from 'react';
import app from 'src/Firebase/config';

import AdvertisementControl from 'src/components/RestaurantNavButtonColumn/NavButtons/AdvertisementControl';
import NavMenuBillingManagement from 'src/components/RestaurantNavButtonColumn/NavButtons/NavMenuBillingManagement';
import NavMenuHelpRequests from 'src/components/RestaurantNavButtonColumn/NavButtons/NavMenuHelpRequests';
import NavMenuMenuManagement from 'src/components/RestaurantNavButtonColumn/NavButtons/NavMenuMenuManagement';
import NavMenuOrders from 'src/components/RestaurantNavButtonColumn/NavButtons/NavMenuOrders';
import NavMenuOverview from 'src/components/RestaurantNavButtonColumn/NavButtons/NavMenuOverview';
import NavMenuQrGenerator from 'src/components/RestaurantNavButtonColumn/NavButtons/NavMenuQrGenerator';
import NavMenuReceptionList from 'src/components/RestaurantNavButtonColumn/NavButtons/NavMenuReceptionList';
import ShowDebug from 'src/components/ShowDebug';

import { SERVICE_MENY, SERVICE_MENY_LIGHT } from 'src/consts/MENY_SERVICE';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from 'src/contexts/Global';

// const seconds_to_redirect = 3;

function MenyLightRestaurantNavButtonColumn({
  handleCloseNavBar,
  restaurant_config,
}) {
  // const { restaurant_config } = React.useContext(
  //   MonitorRestaurantConfigContext
  // );

  return (
    <>
      <NavMenuOverview handleCloseNavBar={handleCloseNavBar} />
      {restaurant_config?.isEnableBellRing && (
        <NavMenuHelpRequests handleCloseNavBar={handleCloseNavBar} />
      )}
      <NavMenuMenuManagement handleCloseNavBar={handleCloseNavBar} />
      <NavMenuQrGenerator handleCloseNavBar={handleCloseNavBar} />

      {restaurant_config && restaurant_config.enable_rest_ad_control ? (
        <>
          <AdvertisementControl handleCloseNavBar={handleCloseNavBar} />
        </>
      ) : (
        <></>
      )}

      <ShowDebug>
        <pre>MenyLightRestaurantNavButtonColumn</pre>
      </ShowDebug>
    </>
  );
}

function MenyRestaurantNavButtonColumn({
  handleCloseNavBar,
  restaurant_config,
}) {
  // const { restaurant_config } = React.useContext(
  //   MonitorRestaurantConfigContext
  // );
  return (
    <>
      <NavMenuOverview handleCloseNavBar={handleCloseNavBar} />
      <NavMenuReceptionList handleCloseNavBar={handleCloseNavBar} />
      {restaurant_config?.isEnableBellRing && (
        <NavMenuHelpRequests handleCloseNavBar={handleCloseNavBar} />
      )}
      <NavMenuOrders handleCloseNavBar={handleCloseNavBar} />
      <NavMenuBillingManagement handleCloseNavBar={handleCloseNavBar} />
      <NavMenuMenuManagement handleCloseNavBar={handleCloseNavBar} />
      <NavMenuQrGenerator handleCloseNavBar={handleCloseNavBar} />

      {restaurant_config && restaurant_config.enable_rest_ad_control ? (
        <>
          <AdvertisementControl handleCloseNavBar={handleCloseNavBar} />
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default function NavButtonsByMenyServiceClass({
  meny_service_class,
  handleCloseNavBar,
  restaurant_config,
}) {
  const navigate = useNavigate();
  const { resetApplicationState } = React.useContext(GlobalContext);

  if (isUndefined(meny_service_class)) {
    app
      .auth()
      .signOut()
      .then(() => resetApplicationState())
      .then(() => navigate('/login'));
  } else {
    switch (meny_service_class.id) {
      case SERVICE_MENY_LIGHT:
        return (
          <MenyLightRestaurantNavButtonColumn
            handleCloseNavBar={handleCloseNavBar}
            restaurant_config={restaurant_config}
          />
        );
      case SERVICE_MENY:
        return (
          <MenyRestaurantNavButtonColumn
            handleCloseNavBar={handleCloseNavBar}
            restaurant_config={restaurant_config}
          />
        );
      default:
        return (
          <MenyRestaurantNavButtonColumn
            handleCloseNavBar={handleCloseNavBar}
            restaurant_config={restaurant_config}
          />
        );
    }
  }
  return <></>;
}
