import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { GlobalContext } from 'src/contexts/Global';

function ShowYen({ value_yen }) {

  const {restaurant_config} = React.useContext(GlobalContext)

  return (
    <>
      <CurrencyFormat
        value={value_yen}
        displayType={'text'}
        thousandSeparator={true}
        prefix={ restaurant_config && restaurant_config.currencySymbol ? restaurant_config.currencySymbol : '¥'}
      />
    </>
  );
}

export default ShowYen;
