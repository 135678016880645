import React from 'react';
import clsx from 'clsx';

import ShowDebug from 'src/components/ShowDebug';

import {
  Box,
  Card,
  Divider,
  useTheme,
  makeStyles,
  Typography,
} from '@material-ui/core';

import { LYNKED_GREEN, LYNKED_RED } from 'src/consts/colors';

import { GlobalContext } from 'src/contexts/Global';
import active_lang from 'src/langs/jp_en';

import { getBillsTodayArray } from 'src/modals/orders';

import { STATUS_DELIVERED, STATUS_PREPARING } from 'src/consts/ORDER_STATUS';
import { useTranslation } from 'react-i18next';

function ShowCompareUp({ value }) {
  return (
    <Box
      style={{
        font: 'normal normal normal 14px/18px Helvetica',
        color: LYNKED_GREEN,
      }}
    >
      &#x2197; {value}
    </Box>
  );
}

function ShowCompareDown({ value }) {
  return (
    <Box
      style={{
        font: 'normal normal normal 14px/18px Helvetica',
        color: LYNKED_RED,
      }}
    >
      &#x2198; {value}
    </Box>
  );
}

const checkUpDown = (today, yesterday) => {
  return today > yesterday;
};

const useStyles = makeStyles(() => ({
  root: {},
}));

const ShowAdImpressionsTiles = ({ value, debug }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [counts_loading, setCountsLoading] = React.useState(false);
  const [today_count, setTodayCount] = React.useState(2);
  const [yesterday_count, setYesterdayCount] = React.useState(1);

  // TODO: update this

  return (
    <>
      <Card className={clsx(classes.root)} style={{ margin: '1rem' }}>
        <Box style={{ margin: '1rem' }}>
          <Box mb={'0.5rem'}>
            <Typography
              variant="h4"
              color="textPrimary"
              style={{
                fontSize: '12px',
                fontWeight: '700',
                marginBottom: '12px',
                marginTop: '12px',
              }}
            >
              {t('DASHBOARD_AVG_NUMBER_ORDERS')}
            </Typography>
          </Box>
          <Divider />

          <Box
            mt={'0.5rem'}
            height={'80px'}
            position="relative"
            style={{ font: 'normal normal bold 31px/40px Roboto' }}
          >
            <Box
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box>
                <span style={{ font: 'normal normal bold 46px/60px Roboto' }}>
                  {Math.ceil(value || 0)}
                </span>
                <span style={{ paddingLeft: '1rem' }}>{t('COMMON_POINTS')}</span>
              </Box>
            </Box>
          </Box>

          <Box
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            {/* {counts_loading ? (
              <>{active_lang.TILES_LOADING}</>
            ) : (
              <ShowCompare today={today_count} yesterday={yesterday_count} />
            )} */}

            {/* <Box
              style={{
                paddingLeft: '0.5rem',
                font: 'normal normal normal 14px/18px Helvetica',
              }}
            >
              昨日から
            </Box> */}
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default function AdImpressionTiles() {
  let [loading, setLoading] = React.useState(true);
  const { t } = useTranslation();
  const { rest_id } = React.useContext(GlobalContext);

  const [counting, setCounting] = React.useState(0);
  const [dates_from_db, setDatesFromDb] = React.useState();
  const [counts_from_db, setCountsFromDb] = React.useState();
  const [total_customer_today, setTotalCustomerToday] = React.useState(0);

  let [debug, setDebug] = React.useState(null);

  React.useEffect(() => {
    if (rest_id != null) {
      getBillsTodayArray(rest_id).then((a_d) => {
        let total_number_of_item = 0;
        let total_number_of_order = 0;

        a_d.forEach((d) => {
          let orders = d.orders;

          orders.forEach((o) => {
            if ([STATUS_PREPARING, STATUS_DELIVERED].includes(o.status)) {
              total_number_of_order = total_number_of_order + 1;
              total_number_of_item =
                total_number_of_item + parseInt(o.item_quantity);
            }
          });
        });

        // **(Sum of all bill)/ number of table assigned in one day**
        setTotalCustomerToday(total_number_of_item / total_number_of_order);
        setDebug({
          total_number_of_order: total_number_of_order,
          total_number_of_item: total_number_of_item,
        });

        setLoading(false);
      });
    }
  }, [rest_id]);

  return (
    <>
      {loading ? (
        <>{t('COMMON_LOADING')}</>
      ) : (
        <ShowAdImpressionsTiles value={total_customer_today} debug={debug} />
      )}
    </>
  );
}
