import React from 'react';
import { IconButton } from '@material-ui/core';
import { LYNKED_RED, LYNKED_WHITE } from 'src/consts/colors';
import DeleteIcon from '@material-ui/icons/Delete';

export default function RubbishBinButton({ handleOnClick }) {
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          border: '3px solid #707070',
          width: '40px',
          height: '40px',
          borderRadius: '20px',
          backgroundColor: LYNKED_RED,
        }}
      >
        <IconButton style={{ color: LYNKED_WHITE }} onClick={handleOnClick}>
          <DeleteIcon />
        </IconButton>
      </div>
    </>
  );
}
