// meny-210, Add/Edit category dialog text field does not handle "Enter" keypress event on windows and iPad

import React from 'react';
import 'firebase/storage';

import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';

import Dialog from '@material-ui/core/Dialog';

import {
  Typography,
  Box,
  TextField,
  Button,
  Grid,
  FormControl,
} from '@material-ui/core';

import active_lang from 'src/langs/jp_en';

import { ENQUEUE_SUCCESS_CONFIG } from 'src/consts/ENQUEUE_CONFIG';

import * as Yup from 'yup';

// MENY-191, restaurant finish added new category, but try first time add new food, does not able to find the new created category in category list field

import { useStyles, Transition } from './styles';
import WarningIcon from '@material-ui/icons/Warning';
import { dialog_transition_duration } from 'src/consts/TRANSITION';
import ShowCategorySelect from 'src/components/ShowCategorySelect';
import ShowDebug from 'src/components/ShowDebug';
import { STATUS_CATEGORY_SHOW } from 'src/modals/food_details';
import { useTranslation } from 'react-i18next';

// MENY-191, restaurant finish added new category, but try first time add new food, does not able to find the new created category in category list field

export default function EditCategoryTitleDialog({
  open,
  setOpen,
  category_id_to_edit,
  processUpdateCategoryTitle,
  getCurrentCategoryTitle,
  getCurrentCategoryShow,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleClose = () => {
    formik.handleReset();
    setOpen(false);
  };

  const handleCancelClick = () => {
    handleClose();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      category_title: '',
      category_show: STATUS_CATEGORY_SHOW,
    },
    validationSchema: Yup.object({
      category_title: Yup.string().required(
        t('MENU_REQUIRED_CATEGORY')
      ),
    }),
    onSubmit: (values) => {
      return processUpdateCategoryTitle({
        category_id: category_id_to_edit,
        category_title: values.category_title,
        category_show: values.category_show,
      })
        .then(() => {
          enqueueSnackbar(
            t('COMMON_UPDATED_COMPLETE'),
            ENQUEUE_SUCCESS_CONFIG
          );

          formik.resetForm();
          handleClose();
        })
        .catch((err) => {
          // NOTE: remove as unused

          // enqueueSnackbar(
          //   active_lang.MENU_MANAGEMENT_EDIT_FOOD_CATEGORY_ERROR,
          //   ENQUEUE_WARNING_CONFIG
          // );
          console.error('processUpdateCategoryTitle', 'err', err);
        });
    },
  });

  React.useEffect(() => {
    formik.setFieldValue(
      'category_title',
      getCurrentCategoryTitle(category_id_to_edit)
    );
    formik.setFieldValue(
      'category_show',
      getCurrentCategoryShow(category_id_to_edit) || STATUS_CATEGORY_SHOW
    );
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        transitionDuration={dialog_transition_duration}
        keepMounted
        maxWidth={'sm'}
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="edit-category-title-dialog"
        aria-describedby="edit-category-title-dialog"
      >
        <form
          onSubmit={formik.handleSubmit}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              document.activeElement.blur();
              formik.handleSubmit();
              e.stopPropagation();
            }
          }}
        >
          <Box className={classes.AddCategoryDialogWrapper}>
            <Box className={classes.DialogTitle}>
              <Box>
                <Typography
                  variant="h3"
                  color="textPrimary"
                  style={{ fontSize: '1rem', fontWeight: '700' }}
                >
                  {t('MENU_CATEGORY_EDIT')}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.DialogBody}>
              <Grid container>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Box className={classes.TextInputGroup}>
                      <Box className={classes.TextInputTitle}>
                        {t('MENU_CATEGORY_EDIT')}
                      </Box>
                      <Box className={classes.TextInputWrapper} mt={'1rem'}>
                        <TextField
                          id="category_title"
                          {...formik.getFieldProps('category_title')}
                          className={classes.TextInput}
                          fullWidth
                          InputProps={{ disableUnderline: true }}
                          inputProps={{ style: { padding: '1rem' } }}
                          disabled={formik.isSubmitting}
                        />
                        {formik.touched.category_title &&
                        formik.errors.category_title ? (
                          <Box className={classes.user_input_error}>
                            <Box pr="1rem">
                              <WarningIcon fontSize="small" />
                            </Box>
                            <Box>{formik.errors.category_title}</Box>
                          </Box>
                        ) : null}
                      </Box>
                    </Box>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <ShowCategorySelect formik={formik} />
                </Grid>
              </Grid>
            </Box>
            <Box className={classes.DialogFooter}>
              <Box className={classes.Buttons}>
                <Button
                  className={classes.ButtonCancel}
                  onClick={handleCancelClick}
                >
                  {t('COMMON_CANCEL')}
                </Button>
                <Button
                  disabled={!formik.isValid || formik.isSubmitting}
                  onClick={formik.handleSubmit}
                  className={classes.ButtonOK}
                >
                  {t('SETTINGS_SAVE')}
                </Button>
              </Box>
            </Box>
            <ShowDebug>{JSON.stringify(formik.values, null, 2)}</ShowDebug>
          </Box>
        </form>
      </Dialog>
    </>
  );
}
