import React from 'react';

import NavButton from 'src/components/NavButton';

import active_lang from 'src/langs/jp_en';

import QR_CODE_PNG from './qr-code-48.png';
import { useTranslation } from 'react-i18next';

const QR_CODE_ICON = () => {
  return (
    <>
      <div
        style={{
          width: '24px',
          height: '24px',
          backgroundImage: `url(${QR_CODE_PNG})`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      ></div>
    </>
  );
};

export default function NavMenuQrGenerator({ handleCloseNavBar }) {
  const { t } = useTranslation();

  return (
    <>
      <NavButton
        text={t('SIDEMENU_QR_CODE')}
        href={`/app/qr_gen`}
        icon={<QR_CODE_ICON />}
        handleCloseNavBar={handleCloseNavBar}
        id="nav-qr-gen"
      />
    </>
  );
}
