import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import App from 'src/App';
import { AuthProvider } from 'src/Firebase/context';
import { GlobalContextProvider } from 'src/contexts/Global';
import { UtilProvider } from 'src/contexts/Util';
import { SharedContextProvider } from 'src/contexts/SharedContext';
import BUILD_INFO from 'src/consts/URLS/data.json';
import { RestaurantActiveProvider } from 'src/contexts/RestaurantActiveContext';

import reportWebVitals from './reportWebVitals';

import './index.css';

console.log('hello from menymeny.com', BUILD_INFO.GIT_COMMIT);

if (process.env.NODE_ENV !== 'development') {
  // console.log = () => {};
  // console.assert = () => {};
  // console.warn = () => {};

  if (window.location.href.search(/web.app/) > 0) {
    // console.debug = () => {};
    // console.error = () => {};
  }

  alert = () => {};
}

ReactDOM.render(
  <>
    <React.StrictMode>
      <UtilProvider>
        <BrowserRouter>
          <AuthProvider>
            <SharedContextProvider>
              <GlobalContextProvider>
                <RestaurantActiveProvider>
                  <CssBaseline />
                  <App />
                </RestaurantActiveProvider>
              </GlobalContextProvider>
            </SharedContextProvider>
          </AuthProvider>
        </BrowserRouter>
      </UtilProvider>
    </React.StrictMode>
  </>,
  document.getElementById('root')
);

reportWebVitals(console.log);
