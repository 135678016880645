import React from 'react';

import { LYNKED_DEEP_GREY, LYNKED_LIGHT_GREY } from 'src/consts/colors';

const starPath =
  'M22 5.72l-4.6-3.86-1.29 1.53 4.6 3.86L22 5.72zM7.88 3.39L6.6 1.86 2 5.71l1.29 1.53 4.59-3.85zM12.5 8H11v6l4.75 2.85.75-1.23-4-2.37V8zM12 4c-4.97 0-9 4.03-9 9s4.02 9 9 9c4.97 0 9-4.03 9-9s-4.03-9-9-9zm0 16c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z';

const iconStyle = {
  position: 'absolute',
  width: '36px',
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: LYNKED_DEEP_GREY,
};

const getIcon = (_path, style = {}) => (
  <i
    style={{
      fontSize: '12px',
      fontStyle: 'normal',
      color: LYNKED_DEEP_GREY,
      display: 'inline-block',
      lineHeight: '1',
      width: '20px',
      transition: 'color 0.3s ease',
      paddingRight: '1rem',
      ...style,
    }}
  >
    <svg height="24" width="24" viewBox="0 0 24 24" aria-hidden="true">
      <path
        d="M22 5.72l-4.6-3.86-1.29 1.53 4.6 3.86L22 5.72zM7.88 3.39L6.6 1.86 2 5.71l1.29 1.53 4.59-3.85zM12.5 8H11v6l4.75 2.85.75-1.23-4-2.37V8zM12 4c-4.97 0-9 4.03-9 9s4.02 9 9 9c4.97 0 9-4.03 9-9s-4.03-9-9-9zm0 16c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"
        fill={LYNKED_LIGHT_GREY}
      ></path>
    </svg>
  </i>
);

const clock_icon_svg = getIcon(starPath, iconStyle);

export { clock_icon_svg };
