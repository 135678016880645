import firebase from 'firebase/app';
import 'firebase/storage';
import { useMutation } from 'react-query';

const useFirestoreUpload = () => {
  const mutation = useMutation(({ file_path, file_base64 }) => {
    var storage = firebase.storage();
    var storageRef = storage.ref();
    var spaceRef = storageRef.child(file_path);
    return spaceRef
      .putString(file_base64, 'data_url')
      .then((snapshot) => {
        return snapshot.ref.getDownloadURL();
      })
      .then((download_url) => {
        return { download_url: download_url };
      });
  });

  return mutation;
};

export default useFirestoreUpload;
