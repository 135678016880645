import React from 'react';

import { GlobalContext } from 'src/contexts/Global';
import { MonitorOrderContext } from 'src/contexts/MonitorOrder';

import active_lang from 'src/langs/jp_en';

import ShowBillingManagementList from './ShowBillingManagementList';
import { useTranslation } from 'react-i18next';

const LOG_PREFIX = 'BillingManagementList';

export default function BillingManagementList({ filter_words }) {
  const [is_loading, setIsLoading] = React.useState(true);
  const { rest_id } = React.useContext(GlobalContext);
  const { order_list } = React.useContext(MonitorOrderContext);
  const { t } = useTranslation();

  React.useEffect(() => {
    console.log(LOG_PREFIX, 'order_list', order_list);
    console.log(LOG_PREFIX, 'rest_id', rest_id);
    if (rest_id && order_list) {
      setIsLoading(false);
    }
  }, [rest_id, order_list]);

  if (is_loading) return <>{t('COMMON_LOADING')}</>;

  return <ShowBillingManagementList filter_words={filter_words} />;
}
