import React from 'react';

import ShowCompareUp from './ShowCompareUp';
import ShowCompareSame from './ShowCompareSame';
import ShowCompareDown from './ShowCompareDown';

import checkUpDown from './checkUpDown';
import { isUndefined } from 'lodash';

export default function ShowCompare({ today, yesterday }) {
  if (isUndefined(yesterday) || isNaN(yesterday)) {
    if (isUndefined(today) || isNaN(today)) {
      return <ShowCompareSame value={0 - 0} />;
    }
    return <ShowCompareUp value={today - 0} />;
  } else {
    if (today === yesterday) {
      // return <ShowCompareSame value={0 - 0} />;
      return <ShowCompareSame value={0 - 0} />;
    } else {
      if (checkUpDown(today, yesterday)) {
        return <ShowCompareUp value={today - yesterday} />;
      } else {
        return <ShowCompareDown value={yesterday - today} />;
      }
    }
  }
}
