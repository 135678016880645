import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  boxWrapper: {
    height: '76px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 1px 2px #00000029',
    borderRadius: '2px',
    opacity: 1,

    width: '250px',
  },
  topContent: {
    height: '33%',
    width: '100%',
    padding: '0px 10px',

    display: 'inline-flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    // fontSize: '8px',
    // lineHeight: '24px',
    // font: 'normal normal normal 8px/24px Hiragino Sans',

    fontSize: '8.5px',
    lineHeight: '24px',

    borderBottom: '1px solid #E0E0E0',
  },
  dayString: {
    textAlign: 'left',
    // fontSize: '8px',
    // lineHeight: '24px',
    // font: 'normal normal normal 8px/24px Hiragino Sans',

    fontSize: '8.5px',
    lineHeight: '24px',

    letterSpacing: '0px',
    color: '#525252',
    opacity: 1,
  },
  seatString: {
    textAlign: 'left',
    fontSize: '8px',
    lineHeight: '24px',
    // font: 'normal normal normal 8px/24px Hiragino Sans',
    letterSpacing: '0px',
    color: '#525252',

    opacity: 1,
  },
  bottomContent: {
    height: '67%',

    padding: '0px 10px',
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: '0.8rem',
    lineHeight: '18px',
    // font: 'normal normal normal 12px/18px Hiragino Sans',
  },
}));

export { useStyles };
