import React from 'react';
import { Button } from '@material-ui/core';

import { checkUserRestId } from 'src/modals/user_info';

export default function ExampleQueries() {
  const handleCheckUserRestIdClick = (e) => {
    checkUserRestId('OvoVubp76JaSJdLyz2eioKOecEW2').then((json) => {
      console.log('json', json);
    });
  };
  return (
    <>
      <Button onClick={handleCheckUserRestIdClick}>checkUserRestId</Button>
    </>
  );
}
