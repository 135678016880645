import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { Box } from '@material-ui/core';

import ShowDebug from 'src/components/ShowDebug';

import { GlobalContext } from 'src/contexts/Global';

import NavButtonsByMenyServiceClass from './NavButtonsByMenyServiceClass';

import { useStyles } from './styles';
import useMutateRestaurantConfigByRestId from 'src/hooks/useMutateRestaurantConfigByRestId';
import { isNull, isUndefined } from 'lodash';
import useMutateSubscribeRestaurantConfig from 'src/hooks/useMutateSubscribeRestaurantConfig';

export default function RestaurantNavButtonColumn({ handleCloseNavBar }) {
  const classes = useStyles();
  const { restaurant_config } = React.useContext(GlobalContext);
  let [meny_service_class, setMenyServiceClass] = React.useState(null);

  return (
    <>
      <Box style={{ display: 'none' }}>
        <ShowDebug>
          <pre>{JSON.stringify(meny_service_class, null, 2)}</pre>
        </ShowDebug>
      </Box>
      <Box className={classes.TopNavBody} id={'restaurant-nav-button-column'}>
        <Box className={classes.NavButtonGroup}>
          {isNull(restaurant_config) ? (
            <Skeleton
              height={'250px'}
              width={document.documentElement.clientWidth * 0.16}
            />
          ) : (
            <>
              <NavButtonsByMenyServiceClass
                meny_service_class={restaurant_config.meny_service_class}
                handleCloseNavBar={handleCloseNavBar}
                restaurant_config={restaurant_config}
              />
            </>
          )}
        </Box>
      </Box>
    </>
  );
}
