import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import IconButton from '@material-ui/core/IconButton';
import { Box, TextField, Button, makeStyles, Input } from '@material-ui/core';
import DragHandleOutlinedIcon from '@material-ui/icons/DragHandleOutlined';
// import { ReactComponent as DragHandleOutlinedIcon } from 'src/assets/icons/moveable.svg';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

import ShowDebug from 'src/components/ShowDebug';

import EditFoodDetailDialog from 'src/components/EditFoodDetailDialog';
import ConfirmRemoveFoodDetailDialog from 'src/components/ConfirmRemoveFoodDetailDialog';

import {
  LYNKED_WHITE,
  LYNKED_RED_DARKEN,
  LYNKED_RED_HOVER,
  LYNKED_RED_SUPER_LIGHT,
  LYNKED_LIGHT_GREY,
  LYNKED_LIGHT_GREY_HOVER,
} from 'src/consts/colors';

const useStyles = makeStyles((theme) => ({
  helloworld: {},
  container: (props) => ({
    borderBottom: '1px solid lightgrey',
    borderRadius: '2px',
    padding: '8px',
    // marginBottom: '8px',
    '&:hover': {
      backgroundColor: LYNKED_LIGHT_GREY_HOVER,
    },
  }),
  container_is_dragging: {
    backgroundColor: LYNKED_RED_DARKEN,
    color: LYNKED_WHITE,
  },
  container_is_stop: {
    backgroundColor: 'white',
  },
  container_is_collapsed: {
    height: '0px',
    display: 'none',
  },

  handle: {
    width: '20px',
    height: '20px',
    // backgroundColor: "orange",
    borderRadius: '4px',
    marginRight: '8px',
  },
  food_detail_block: {
    display: 'flex',
    flexFlow: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',

    margin: '1rem 2rem',
  },
  food_detail_left: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  food_detail_buttons: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    margin: '0',
    marginLeft: '2rem',
  },
  food_image_container: {
    margin: '0',
    marginLeft: '2rem',
  },
  food_image: {
    width: '100px',
    height: '100px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  food_title_container: {
    margin: '0',
    marginLeft: '1rem',
  },
  food_title: {
    font: 'normal normal normal 12px/18px Hiragino Sans',
  },
  food_price: {
    font: 'normal normal normal 12px/18px Hiragino Sans',
  },

  food_description: {
    width: '100%',
    // padding: '0 4rem',
    margin: '0rem',
    marginLeft: '4rem',
    font: 'normal normal normal 12px/18px Hiragino Sans',
    color: LYNKED_LIGHT_GREY,
  },
  fd_buttons: {
    margin: '0 0.5rem',
  },
}));

function Task({
  task,
  category_id,
  food_detail_id,
  index,
  food_detail,
  list_expanded,
  loadFoodDetail,
  processDeleteFoodDetail,
  columnOrder,
  columns,
  handleFoodCategoriesChange,
  food_categories,
  setColumns,
}) {
  const classes = useStyles();
  const [
    open_edit_food_detail_dialog,
    setOpenEditFoodDetailDialog,
  ] = React.useState(false);

  const [
    open_confirm_remove_food_detail_dialog,
    setOpenConfirmRemoveFoodDetailDialog,
  ] = React.useState(false);
  const [food_id_to_delete, setFoodIdToDelete] = React.useState(null);

  const handleFoodEditOnClick = (e, task) => {
    // alert(JSON.stringify(food_detail, null, 2));
    setOpenEditFoodDetailDialog(true);
  };

  const handleFoodDeleteOnClick = (e, task) => {
    setOpenConfirmRemoveFoodDetailDialog(true);
  };

  return (
    <>
      {/* <EditFoodDetailDialog
        open={open_edit_food_detail_dialog}
        setOpen={setOpenEditFoodDetailDialog}
        food_detail={food_detail}
        loadFoodDetail={loadFoodDetail}
        columnOrder={columnOrder}
        columns={columns}
        handleFoodCategoriesChange={handleFoodCategoriesChange}
        food_categories={food_categories}
        setColumns={setColumns}
      /> */}

      <ConfirmRemoveFoodDetailDialog
        open={open_confirm_remove_food_detail_dialog}
        setOpen={setOpenConfirmRemoveFoodDetailDialog}
        food_detail={food_detail}
        loadFoodDetail={loadFoodDetail}
        processDeleteFoodDetail={processDeleteFoodDetail}
      />

      <Draggable draggableId={`${category_id}_${food_detail_id}`} index={index}>
        {(provided, snapshot) => (
          <Box
            className={`${classes.container} ${
              snapshot.isDragging
                ? classes.container_is_dragging
                : classes.container_is_stop
            } ${list_expanded ? '' : classes.container_is_collapsed}`}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            isDragging={snapshot.isDragging}
          >
            <Box
              style={{
                display: 'flex',
                flexFlow: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <Box className={classes.handle} {...provided.dragHandleProps}>
                <DragHandleOutlinedIcon />
              </Box>
              <Box className={classes.food_image_container}>
                <div
                  className={classes.food_image}
                  style={{
                    backgroundImage: `url(${food_detail.image_url})`,
                  }}
                />
              </Box>

              <Box className={classes.food_detail_block}>
                <Box className={classes.food_detail_left}>
                  <Box className={classes.food_title_container}>
                    <Box className={classes.food_title}>{food_detail.name}</Box>
                    <Box className={classes.food_price}>
                      ¥ {food_detail.unit_price}
                    </Box>
                  </Box>
                </Box>
                <Box
                  className={classes.food_description}
                  dangerouslySetInnerHTML={{
                    __html: food_detail.description.replace(/\n/g, '<br />'),
                  }}
                >
                  {/* {food_detail.description} */}
                </Box>
                <Box>
                  <Box className={classes.food_detail_buttons}>
                    <Box
                      className={`${classes.fd_buttons} ${classes.EditButton}`}
                    >
                      <IconButton
                        onClick={(e) => {
                          handleFoodEditOnClick(e, task);
                        }}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    </Box>
                    <Box
                      className={`${classes.fd_buttons} ${classes.DeleteButton}`}
                    >
                      <IconButton
                        onClick={(e) => {
                          handleFoodDeleteOnClick(e, task);
                        }}
                      >
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
                <Box></Box>
                <ShowDebug>
                  <pre style={{ width: '100px' }}>
                    {JSON.stringify(food_detail, null, 2)}
                  </pre>
                </ShowDebug>
              </Box>
            </Box>
          </Box>
        )}
      </Draggable>
    </>
  );
}
export default React.memo(Task);
