import React from 'react';

import { PreCaches } from '../PreCaches';
import PreCacheSharedVar from '../PreCaches/shared_var';

export const SharedContext = React.createContext();

export function SharedContextProvider({ children }) {
  let [hello_share, setHelloShare] = React.useState('world');

  let shared_varirables = {
    hello_share,
    setHelloShare,

    ...PreCacheSharedVar(),
  };

  return (
    <>
      <SharedContext.Provider value={shared_varirables}>
        <PreCaches />
        {children}
      </SharedContext.Provider>
    </>
  );
}
