import React from 'react';
import moment from 'moment-timezone';

import {
  LYNKED_BLACK,
  LYNKED_DEEP_GREY,
  LYNKED_DEEP_GREY_HOVER,
  LYNKED_GREEN,
  LYNKED_GREEN_HOVER,
  LYNKED_LIGHT_GREY,
  LYNKED_RED,
  LYNKED_WHITE,
} from 'src/consts/colors';

import ShowYen from 'src/components/ShowYen';

import { Box, Grid, makeStyles } from '@material-ui/core';

import active_lang from 'src/langs/jp_en';
import BillStatusIndicator from 'src/components/BillStatusIndicator';
import getAdjustedItemPrice from 'src/utils/getAdjustedItemPrice';
import { useTranslation } from 'react-i18next';

const FOOD_CUSTOMIZE_SEPERATOR = '、';

const useStyles = makeStyles((theme) => ({
  helloworld: {},
  BillingCardContainer: {
    //
    borderRadius: '2px',
    boxShadow: '0px 1px 2px #00000029',
  },
  CardHeader: {
    backgroundColor: LYNKED_LIGHT_GREY,
    padding: '10px 20px',

    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
  },
  BillToTable: {
    font: 'normal normal normal 15px/20px Noto Sans JP',
  },
  BillToPerson: {
    font: 'normal normal normal 15px/20px Noto Sans JP',
  },
  BillEntryWrapper: {
    border: '1px solid #E0E0E0',
    padding: '5px 20px',
  },
  BillPlaceholderWrapper: {
    padding: '5px 20px',
    maxHeight: '58px',
    minHeight: '58px',
  },
  BillEntryRowBottom: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  BillTime: {
    font: 'normal normal normal 9px/18px Noto Sans JP',
    color: LYNKED_LIGHT_GREY,
  },
  BillItemName: {
    padding: '5px 0px',
    font: 'normal normal normal 12px/18px Noto Sans JP',
    textDecoration: 'line-through',

    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
  },
  BillItemCustomization: {
    paddingLeft: '10px',
    font: 'normal normal normal 12px/18px Noto Sans JP',
    textDecoration: 'line-through',
  },
  ItemSubTotal: {
    color: LYNKED_RED,
    textAlign: 'right',
    textDecoration: 'line-through',
  },
  CardFooter: {
    display: 'flex',
    flexFlow: 'column',
    padding: '0px 20px',
    paddingBottom: '20px',
    backgroundColor: '#FAFAFA',
  },
  BillTotal: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#FAFAFA',
  },
  FooterBody: {
    padding: '20px 2px',
    width: '45%',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    font: 'normal normal normal 15px/28px Noto Sans JP',
    color: LYNKED_GREEN,
  },
  FooterTotalPrice: {
    padding: '20px 0px',
    flexGrow: 1,
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    font: 'normal normal normal 12px/18px Noto Sans JP',
  },
  FooterItem: {},
  TotalPrice: {
    padding: '0px 10px',
    textAlign: 'right',
    font: 'normal normal normal 15px/28px Noto Sans JP',
    color: LYNKED_GREEN,
  },
  TotalExplain: {
    font: 'normal normal normal 10px/15px Noto Sans JP',
  },
  FooterButtonsGroup: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  FooterButtons: {
    width: '48%',
  },
  FooterButtonViewAllL: {
    width: '100%',
    backgroundColor: LYNKED_DEEP_GREY,
    color: LYNKED_WHITE,
    borderRadius: '1px',
    '&:hover': {
      backgroundColor: LYNKED_DEEP_GREY_HOVER,
    },
  },
  FooterButtonFinishBill: {
    width: '100%',
    backgroundColor: LYNKED_GREEN,
    color: LYNKED_WHITE,
    borderRadius: '1px',
    '&:hover': {
      backgroundColor: LYNKED_GREEN_HOVER,
    },
  },
}));

export default function BillCancelled({ entry }) {
  const classes = useStyles();
  const { t } = useTranslation();
  let [adjusted_price, setAdjustedPrice] = React.useState(0);

  React.useEffect(() => {
    if (entry) {
      if (entry.item_customize_settings) {
        setAdjustedPrice(
          getAdjustedItemPrice(entry.item_price, entry.item_customize_settings)
        );
      } else {
        setAdjustedPrice(entry.item_price);
      }
    }
  }, [entry]);

  return (
    <>
      <Box className={classes.BillEntryWrapper}>
        <Grid container>
          <Grid item xs={6} sm={4}>
            <Box className={classes.BillTime}>
              {t('BILL_ORDER_TIME')}:
              {entry && entry.created ? (
                <>
                  {moment
                    .unix(entry.created.seconds)
                    .format(t('COMMON_TIME_FORMAT'))}
                </>
              ) : (
                <></>
              )}
            </Box>
          </Grid>
          <Grid item xs={6} sm={8}>
            <Box>
              <BillStatusIndicator color={LYNKED_BLACK} />
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            xs={8}
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Box className={classes.BillItemName}>
              {entry && entry.item_name ? (
                <>
                  {' '}
                  {entry.item_name} {'    '}
                  {entry.item_customize_settings &&
                  entry.item_customize_settings.length > 0
                    ? `(${entry.item_customize_settings
                        .map((c) => c.name)
                        .join(FOOD_CUSTOMIZE_SEPERATOR)})`
                    : // MENY-165, no text for food without modifier group
                      ``}
                </>
              ) : (
                <></>
              )}
            </Box>
          </Grid>

          <Grid item xs={4} className={classes.ItemSubTotal}>
            <Box>
              <ShowYen value_yen={adjusted_price} />
              {entry && entry.item_quantity ? (
                <>{` x ${entry.item_quantity}${t('COMMON_POINTS')}`}</>
              ) : (
                <></>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
