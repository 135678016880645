import React from 'react';

import { Box, Grid } from '@material-ui/core';

import ShowDebug from 'src/components/ShowDebug';

import ShowAdvertisementTable from './ShowAdvertisementTable';

import { MonitorOrderContext } from 'src/contexts/MonitorOrder';

import { LYNKED_DEEP_GREY } from 'src/consts/colors';

import active_lang from 'src/langs/jp_en';
import { isNull, isUndefined } from 'lodash';

import useHelloworld from 'src/hooks/useHelloworld';
import useListAdvertisement from 'src/hooks/useListAdvertisement';
import { useTranslation } from 'react-i18next';

export default function AdvertisementTable({
  filter_by_words,
  advertisements,
  refetch,
  food_details,
}) {
  const { t } = useTranslation();

  let [show_content, setShowContent] = React.useState(false);
  // let {
  //   order_list,
  //   flattened_order_list,
  //   setFlattenedOrderList,
  // } = React.useContext(MonitorOrderContext);

  React.useEffect(() => {
    console.log('filter_by_words', filter_by_words);
  }, [filter_by_words]);

  React.useEffect(() => {
    if (isUndefined(advertisements) || isNull(advertisements)) {
    } else {
      setShowContent(true);
    }
  }, [advertisements]);

  return (
    <>
      {show_content ? (
        advertisements.length > 0 ? (
          <>
            <ShowAdvertisementTable
              advertisements={advertisements}
              filter_by_words={filter_by_words}
              refetch={refetch}
              food_details={food_details}
            />
          </>
        ) : (
          <>
            <Box
              style={{
                width: '100%',
                margin: '2rem',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                color: LYNKED_DEEP_GREY,
              }}
            >
              {/* TODO: update translation */}
              {t('AD_NO_AD')}
            </Box>
            {/* <ShowDebug>
                <pre>{JSON.stringify(flattened_order_list, null, 2)}</pre>
              </ShowDebug> */}
          </>
        )
      ) : (
        <>
          <Box>
            <Grid container>
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  flexFlow: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  width: '100%',
                }}
              >
                {t('AD_LOADING_BANNER')}
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
}
