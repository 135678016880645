import React from 'react';
import { Box } from '@material-ui/core';

import { LYNKED_RED } from 'src/consts/colors';

export default function ShowCompareDown({ value }) {
  return (
    <Box
      style={{
        font: 'normal normal normal 14px/18px Helvetica',
        color: LYNKED_RED,
      }}
    >
      &#x2198; {isNaN(value) ? 0 : value}
    </Box>
  );
}
