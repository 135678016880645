import React from 'react';
import { styled, Box } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';

import { useStyles } from './styles';
import LoginForm from './LoginForm';

import active_lang from 'src/langs/jp_en';
import { LYNKED_WHITE } from 'src/consts/colors';

import LYNKED_LOGO_BLACK_SVG from 'src/assets/tabi-life-black.svg';
import { useTranslation } from 'react-i18next';
import LanguageDropdown from 'src/components/LanguageDropdown';

const TextBox = styled(Box)({
  textAlign: 'center',
  letterSpacing: '0px',
  color: '#FFFFFF',
  opacity: '1',
});

export default function ShowLoginScreen() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <div className={classes.loginRoot}>
        <div className={classes.languageDropdown}>
          <LanguageDropdown paperClass={classes.listItemDropdown}/>
        </div>
        <div className={classes.loginInput}>
          <Box>
            <Box className={classes.logoWrapper}>
              <div
                id="login-page-lynked-logo"
                className={classes.lynkedLogo}
                style={{
                  backgroundImage: `url(${LYNKED_LOGO_BLACK_SVG})`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}
              ></div>
            </Box>

            <TextBox className={classes.textMessages}>
              <span style={{color: '#AD7E35'}}>MENY </span>
              <span style={{color: 'black'}}>PORTAL</span>
            </TextBox>
            <LoginForm />

            {/* <a
              className={clsx([
                classes.terms_and_conds_link,
                classes.textMessages,
              ])}
              href="https://www.lynked.co.jp/privacy-policy"
              style={{ color: LYNKED_WHITE }}
            >
              {t('LOGIN_TERMS_CONDITIONS')}
            </a> */}
            <p className={classes.footerText}>{t('LOGIN_HELPER_HINT')}</p>
          </Box>
        </div>
      </div>
    </>
  );
}
