import React from 'react';
import ShowDebug from 'src/components/ShowDebug';
import { AuthContext } from 'src/Firebase/context';
import AlertNewOrder from 'src/components/dialogs/AlertNewOrder';
import { ServiceRequestSoundContext } from 'src/contexts/AudioNotification/ServiceRequestSound';

let LOG_PREFIX = 'NewOrderAlerts';

export const NewOrderAlertsContext = React.createContext();
export const NewOrderAlertsContextProvider = ({ children }) => {
  const { user, isAdminUpdating, setIsAdminUpdating } =
    React.useContext(AuthContext);
  const ServiceRequestSound = React.useContext(ServiceRequestSoundContext);
  const hwNewOrderAlertsContext = () => {
    // alert('hello service request alerts context');
  };

  const [open_alert_dialog, setOpenAlertDialog] = React.useState(false);
  const showNewOrderAlert = () => {
    console.log(LOG_PREFIX, 'showNewOrderAlert', user);
    if (user != null) {
      if (isAdminUpdating) {
        setIsAdminUpdating(false);
      } else {
        setOpenAlertDialog(true);
        ServiceRequestSound.handlePlaySound();
        setIsAdminUpdating(false);
      }
    }
  };

  const handleCloseAlert = () => {
    ServiceRequestSound.handleStopSound();
    setOpenAlertDialog(false);
  };

  return (
    <>
      <NewOrderAlertsContext.Provider
        value={{ hwNewOrderAlertsContext, showNewOrderAlert }}
      >
        <AlertNewOrder
          open={open_alert_dialog}
          setOpen={setOpenAlertDialog}
          onClose={handleCloseAlert}
        />
        {/* <ShowDebug>
          <button onClick={showNewOrderAlert}>show new order alert</button>
        </ShowDebug> */}
        {children}
      </NewOrderAlertsContext.Provider>
    </>
  );
};
