import React from 'react';
import { isNull, isUndefined } from 'lodash';
import { Box, makeStyles } from '@material-ui/core';

import { GlobalContext } from 'src/contexts/Global';
import ShowAdvertisementPage from './ShowAdvertisementPage';
import AdvertisementPageLoading from './AdvertisementPageLoading';

import Page from 'src/components/Page';

const useStyles = makeStyles(() => ({
  root: { width: '100%', padding: '3rem' },
}));

export default function AdvertisementPage() {
  const classes = useStyles();

  let [show_content, setShowContent] = React.useState(false);
  const { rest_id } = React.useContext(GlobalContext);

  React.useEffect(() => {
    if (isUndefined(rest_id) || isNull(rest_id)) {
    } else {
      setShowContent(true);
    }
  }, [rest_id]);

  return (
    <>
      <Page className={classes.root}>
        <Box
          style={{
            width: '100%',
            paddingBottom: '5rem',
          }}
        >
          {show_content ? (
            <ShowAdvertisementPage rest_id={rest_id} />
          ) : (
            <>
              <AdvertisementPageLoading />
            </>
          )}
        </Box>
      </Page>
    </>
  );
}
