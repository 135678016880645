import React from 'react';


import { MonitorOrderContext } from 'src/contexts/MonitorOrder';

import BillListEmpty from './BillListEmpty';
import BillList from './BillList';

export default function ShowBillingManagementList(props) {
  const [is_bill_empty, setIsBillEmpty] = React.useState(false);
  const { show_order_list } = React.useContext(MonitorOrderContext);

  React.useEffect(() => {
    setIsBillEmpty(show_order_list.orders.length < 1);
  }, [show_order_list]);

  // return <ShowDebug>{JSON.stringify(show_order_list)}</ShowDebug>;

  if (is_bill_empty) return <BillListEmpty />;

  return <BillList {...props} />;
}
