import React from 'react';
import ShowDebug from 'src/components/ShowDebug';

import { AuthContext } from 'src/Firebase/context';
import AlertNewServiceRequest from 'src/components/dialogs/AlertNewServiceRequest';
import { ServiceRequestSoundContext } from 'src/contexts/AudioNotification/ServiceRequestSound';
import { GlobalContext } from './Global';

export const ServiceRequestsAlertsContext = React.createContext();
export const ServiceRequestsAlertsContextProvider = ({ children }) => {
  const { user } = React.useContext(AuthContext);
  const { restaurant_config } = React.useContext(GlobalContext);
  const ServiceRequestSound = React.useContext(ServiceRequestSoundContext);
  const hwServiceRequestsAlertsContext = () => {
    // alert('hello service request alerts context');
  };

  const [open_alert_dialog, setOpenAlertDialog] = React.useState(false);
  const showServiceAlert = () => {
    if (user != null) {
      setOpenAlertDialog(true);
      console.log('ServiceRequestSound', ServiceRequestSound);
      ServiceRequestSound.handlePlaySound();
    }
  };

  const handleCloseAlert = () => {
    ServiceRequestSound.handleStopSound();
    setOpenAlertDialog(false);
  };

  return (
    <>
      <ServiceRequestsAlertsContext.Provider
        value={{ hwServiceRequestsAlertsContext, showServiceAlert }}
      >
        <AlertNewServiceRequest
          open={open_alert_dialog}
          setOpen={setOpenAlertDialog}
          onClose={handleCloseAlert}
        />
        {/* <ShowDebug>
          <button onClick={(e) => setOpenAlertDialog(true)}>show alert</button>
        </ShowDebug> */}
        {children}
      </ServiceRequestsAlertsContext.Provider>
    </>
  );
};
