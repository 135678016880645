import { useMutation } from 'react-query';
import { updateRestaurantConfig } from 'src/modals/restaurant_config';

export default function useMutateUpdateRestaurantConfig() {
  const mutation = useMutation(({ rest_id, rest_config }) => {
    console.log('useMutateUpdateRestaurantConfig', 'rest_id', rest_id);
    console.log('useMutateUpdateRestaurantConfig', 'rest_config', rest_config);
    return updateRestaurantConfig(rest_id, rest_config);
  });

  return mutation;
}
