import React from 'react';

import './AssignTableDialog_style.css';
import ShowAssignTableDialog from './ShowAssignTableDialog';

export default function AssignTableDialog({
  open,
  setOpen,
  assign_waiting_entry,
}) {
  return (
    <>
      {assign_waiting_entry ? (
        <ShowAssignTableDialog
          open={open}
          setOpen={setOpen}
          assign_waiting_entry={assign_waiting_entry}
        />
      ) : (
        <></>
      )}
    </>
  );
}
