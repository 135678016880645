import { isNull } from 'lodash';

const testImageUrlValid = (url_to_test) => {
  try {
    let img_url_is_null = isNull(url_to_test);
    let img_url_is_empty = url_to_test.trim() == '';

    return !(img_url_is_null || img_url_is_empty);
  } catch (error) {
    console.log('testImageUrlValid', 'url_to_test', url_to_test);
    return false;
  }
};

export default testImageUrlValid;
