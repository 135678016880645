import { makeStyles } from '@material-ui/core';

import { LYNKED_RED, LYNKED_WHITE, LYNKED_RED_HOVER } from 'src/consts/colors';

const useStyles = makeStyles((_theme) => ({
  helloworld: {},
  AddCategoryButton: {
    height: '45px',
    padding: '1rem 1rem',
    backgroundColor: LYNKED_RED,
    color: LYNKED_WHITE,
    boxShadow: '0px 1px 2px #00000029',
    '&:hover': {
      backgroundColor: LYNKED_RED_HOVER,
    },
  },
}));

export { useStyles };
