import React from 'react';

import Slide from '@material-ui/core/Slide';

import { makeStyles } from '@material-ui/core';

import {
  LYNKED_RED,
  LYNKED_WHITE,
  LYNKED_GREEN,
  LYNKED_GREEN_HOVER,
  LYNKED_LIGHT_GREY,
  LYNKED_DEEP_GREY,
  LYNKED_DEEP_GREY_HOVER,
} from 'src/consts/colors';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
  helloworld: {},
  BillingCardContainer: {
    //
    borderRadius: '2px',
    boxShadow: '0px 1px 2px #00000029',
  },
  CardHeader: {
    backgroundColor: LYNKED_LIGHT_GREY,
    padding: '10px 20px',

    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    color: 'black'
  },
  BillToTable: {
    font: 'bold normal normal 15px/20px Noto Sans JP',
  },
  BillToPerson: {
    font: 'bold normal normal 15px/20px Noto Sans JP',
  },
  BillEntryWrapper: {
    border: '1px solid #E0E0E0',
    padding: '5px 20px',
  },
  BillEntryRowTop: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  BillEntryRowBottom: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  BillTime: {
    font: 'normal normal normal 9px/18px Noto Sans JP',
    color: LYNKED_LIGHT_GREY,
  },
  BillItemName: {
    padding: '5px 0px',
    font: 'normal normal normal 12px/18px Noto Sans JP',
  },
  ItemSubTotal: {
    color: LYNKED_RED,
  },
  CardFooter: {
    display: 'flex',
    flexFlow: 'column',
    padding: '0px 20px',
    paddingBottom: '20px',
  },
  BillTotal: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#FAFAFA',
  },
  FooterBody: {
    padding: '20px 2px',
    width: '45%',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    font: 'bold normal normal 15px/28px Noto Sans JP',
    color: LYNKED_GREEN,
  },
  FooterTotalPrice: {
    padding: '20px 20px',
    width: '55%',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    font: 'bold normal normal 12px/18px Noto Sans JP',
  },
  FooterItem: {},
  TotalPrice: {
    padding: '0px 10px',
    textAlign: 'right',
    font: 'bold normal normal 15px/28px Noto Sans JP',
    color: LYNKED_GREEN,
  },
  TotalExplain: {
    font: 'bold normal normal 10px/15px Noto Sans JP',
  },
  FooterButtonsGroup: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  FooterButtons: {
    width: '48%',
  },
  FooterButtonViewAllL: {
    width: '100%',
    backgroundColor: LYNKED_DEEP_GREY,
    color: LYNKED_WHITE,
    borderRadius: '1px',
    '&:hover': {
      backgroundColor: LYNKED_DEEP_GREY_HOVER,
    },
  },
  FooterButtonFinishBill: {
    width: '100%',
    backgroundColor: LYNKED_GREEN,
    color: LYNKED_WHITE,
    borderRadius: '1px',
    '&:hover': {
      backgroundColor: LYNKED_GREEN_HOVER,
    },
  },
}));

export { useStyles, Transition };
