import db_config from 'src/configs/db_config';
import db from 'src/Firebase/db';

import { getNearestCutOffTime } from 'src/modals/getNearestCutOffTime';

const MENY_ALL_ORDERS_REF = db.collection(db_config.DB_TABLE_ALL_ORDERS);

function updateBillByOrderId(rest_id, order_id, payload) {
  let DocRef = db
    .collection(db_config.DB_TABLE_ALL_ORDERS)
    .doc(rest_id)
    .collection('orders')
    .doc(order_id);

  return db.runTransaction((transaction) => {
    return transaction.get(DocRef).then((sfDoc) => {
      transaction.set(DocRef, payload, { merge: true });
    });
  });
}

function getBillByOrderId(rest_id, order_id) {
  return db
    .collection(db_config.DB_TABLE_ALL_ORDERS)
    .doc(rest_id)
    .collection('orders')
    .doc(order_id)
    .get();
}

function subscribeToOrderChange(rest_id) {
  return db
    .collection(db_config.DB_TABLE_ALL_ORDERS)
    .doc(rest_id)
    .collection('orders');
}

function getAllOrders(rest_id) {
  console.log('🚀 ~ file: orders.js ~ line 39 ~ getAllOrders ~ rest_id', rest_id)
  return db
    .collection(db_config.DB_TABLE_ALL_ORDERS)
    .doc(rest_id)
    .collection('orders');
}

function getOrdersByOrderId(rest_id, order_id) {
  return db
    .collection(db_config.DB_TABLE_ALL_ORDERS)
    .doc(rest_id)
    .collection('orders')
    .doc(order_id)
    .get();
}

function updateOrderStatus(rest_id, order_id, payload) {
  let DocRef = db
    .collection(db_config.DB_TABLE_ALL_ORDERS)
    .doc(rest_id)
    .collection('orders')
    .doc(order_id);

  return db
    .runTransaction((transaction) => {
      return transaction.get(DocRef).then((sfDoc) => {
        transaction.set(DocRef, payload, { merge: true });
      });
    })
    .then(() => {
      console.log('Transaction successfully committed!');
    })
    .catch((error) => {
      console.log('Transaction failed: ', error);
    });
}

function helloworld() {
  console.log('helloworld orders modal');
}

// metrics
function getBillsToday(rest_id) {
  return MENY_ALL_ORDERS_REF.doc(rest_id)
    .collection('orders')
    .where('order_create_time', '>', getNearestCutOffTime().toDate())
    .get();
}

// TODO: better naming
function getBillsTodayArray(rest_id) {
  return getBillsToday(rest_id).then((ss) => {
    let a_d = [];
    ss.forEach((doc) => {
      let doc_data = doc.data();
      let orders_length = doc_data.orders.length;
      if (orders_length > 0) {
        a_d.push(doc.data());
      }
    });

    return a_d;
  });
}

export {
  getBillsTodayArray,
  getBillsToday,
  helloworld,
  subscribeToOrderChange,
  updateOrderStatus,
  getAllOrders,
  getOrdersByOrderId,
  getBillByOrderId,
  updateBillByOrderId,
};
