import React from 'react';
import {
  Box,
  makeStyles,
  FormControl,
  MenuItem,
  Select,
  withStyles,
  InputBase,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import active_lang from 'src/langs/jp_en';
import WarningIcon from '@material-ui/icons/Warning';

import {
  LYNKED_RED,
  LYNKED_WHITE,
  LYNKED_GREEN,
  LYNKED_GREEN_HOVER,
  LYNKED_LIGHT_GREY,
  LYNKED_DEEP_GREY,
  LYNKED_DEEP_GREY_HOVER,
} from 'src/consts/colors';
import { GlobalContext } from 'src/contexts/Global';

import useGetFoodDetails from 'src/hooks/useGetFoodDetails';
import ShowDebug from 'src/components/ShowDebug';
import { useTranslation } from 'react-i18next';
import { useStyles, Transition } from './styles';

// TODO: remove me after MENY-222
// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.background.dark,
//     minHeight: '100%',
//     paddingBottom: theme.spacing(3),
//     paddingTop: theme.spacing(3),
//   },
//   button_yes: {
//     color: theme.palette.success.main,
//   },
//   button_no: {
//     color: theme.palette.error.main,
//   },
//   TextInput: {
//     border: '1px solid #E0E0E0',
//     font: 'normal normal bold 12px/16px Roboto',
//     padding: '3px 3px',
//     '& ::placeholder': {
//       color: LYNKED_LIGHT_GREY,
//     },
//   },
//   food_availability: {
//     border: '1px solid #e2e2e2',
//     padding: '0.5rem',
//   },
//   icon: {
//     color: '#707070',
//     marginRight: '1rem',
//     marginTop: '0.25rem',
//   },
//   DialogBottomButtons: {
//     display: 'flex',
//     flexFlow: 'row',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//   },
//   Buttons: {
//     width: '48%',
//     padding: '10px',
//     color: 'white',
//     font: 'normal normal normal 11px/15px Noto Sans JP',

//     letterSpacing: '0.12px',
//     borderRadius: '1px',
//   },
//   ButtonCancel: {
//     backgroundColor: LYNKED_DEEP_GREY,
//     '&:hover': {
//       backgroundColor: LYNKED_DEEP_GREY_HOVER,
//     },
//   },
//   ButtonOK: {
//     backgroundColor: LYNKED_GREEN,
//     '&:hover': {
//       backgroundColor: LYNKED_GREEN_HOVER,
//     },
//   },
//   AddImageButton: {
//     color: LYNKED_RED,
//   },
//   AddImagePlaceHolder: {
//     background: `${LYNKED_WHITE} 0% 0% no-repeat padding-box`,
//     border: `1px dashed ${LYNKED_RED}`,
//     borderRadius: '9px',

//     width: '100%',
//     minHeight: '300px',

//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//   },
//   test_icon_style: {
//     color: LYNKED_RED,
//   },
// }));

const AdWindowInput = withStyles(() => ({}))(InputBase);

export default function SelectFoodDetail({ formik, food_details }) {
  let classes = useStyles();
  const { t } = useTranslation();
  let { data, isLoading } = food_details;

  // TODO: remove me after MENY-222
  // let [default_value, setDefaultValue] = React.useState();
  // React.useEffect(() => {
  //   if (isLoading) {
  //   } else {
  //     console.log('data.food_details', data.food_details);
  //     let first_food_detail = Object.keys(data.food_details)[0];
  //     setDefaultValue('4ku180zwv2');
  //     console.log('formik.values', formik.values);
  //   }
  // }, []);

  return (
    <>
      <Box mt={'0.5rem'}>
        <FormControl fullWidth>
          <Box>
            {t('COMMON_MENU_DETAILS')}
            <ShowDebug>{'AD_FOOD_DETAIL_NAME'}</ShowDebug>
          </Box>
          {isLoading ? (
            <>{active_lang.AD_FOOD_DETAIL_LOADING}</>
          ) : (
            <>
              <Select
                id="form_ad_destination"
                {...formik.getFieldProps('form_ad_destination')}
                value={formik.values.form_ad_destination}
                classes={{
                  root: classes.food_availability,
                  icon: classes.icon,
                }}
                input={<AdWindowInput />}
                IconComponent={KeyboardArrowDownIcon}
                style={{ paddingTop: '0.5rem' }}
                defaultValue={'please_select'}
              >
                <MenuItem value={'please_select'} disabled>
                  {t('AD_SELECT')}
                </MenuItem>
                {Object.keys(data.food_details)
                  .sort()
                  .map((k, idx) => {
                    let food_detail = data.food_details[k];

                    return (
                      <MenuItem value={food_detail.id} key={idx}>
                        {food_detail.name}
                      </MenuItem>
                    );
                  })}
              </Select>
              {formik.touched.form_ad_destination &&
              formik.errors.form_ad_destination ? (
                <Box className={classes.user_input_error}>
                  <Box pr="1rem">
                    <WarningIcon fontSize="small" />
                  </Box>
                  <Box>
                    {active_lang.AD_INVALID_FOOD_DETAILS}
                    <ShowDebug>{'AD_INVALID_FOOD_DETAILS'}</ShowDebug>
                  </Box>
                </Box>
              ) : null}
            </>
          )}
        </FormControl>
      </Box>
    </>
  );
}
