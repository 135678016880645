import React from 'react';
import { Box, makeStyles, Grid, Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import active_lang from 'src/langs/jp_en';

const useStyles = makeStyles(() => ({ root: { flexGrow: 1 } }));

const LoadingGridQR = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Grid item>
        <Paper className={classes.paper} style={{ padding: '0.5rem' }}>
          <Box style={{ width: '300px', height: '300px' }}>
            {t('QR_LOADING')}
          </Box>
        </Paper>
      </Grid>
    </>
  );
};
export default React.memo(LoadingGridQR);
