const performDeleteCategory = (_e, category_id, food_menu) => {
  let column_id = category_id;

  // cleanup food_id inside category_id
  let temp_food_details = food_menu.food_details;
  let new_food_details = {};

  let food_detail_ids_to_delete = food_menu.columns[column_id].taskIds;
  console.log('food_detail_ids_to_delete', food_detail_ids_to_delete);

  // check if category is empty
  if (food_detail_ids_to_delete.length > 0) {
    food_detail_ids_to_delete.forEach((id_to_delete) => {
      console.log('id_to_delete', id_to_delete);
      Object.keys(temp_food_details).forEach((k) => {
        if (food_detail_ids_to_delete.includes(k)) {
        } else {
          new_food_details[k] = temp_food_details[k];
        }
      });
    });
  } else {
    // in case the category to delete is empty, skip it
    new_food_details = temp_food_details;
  }

  console.log('processDeleteCategory', 'new_food_details', new_food_details);

  let new_columns = {};
  Object.keys(food_menu.columns).forEach((k) => {
    if (k != column_id) {
      new_columns[k] = food_menu.columns[k];
    }
  });
  let new_column_order = food_menu.columnOrder.filter((co) => co != column_id);

  return {
    ...food_menu,
    columnOrder: new_column_order,
    columns: new_columns,
    food_details: new_food_details,
  };
};

export default performDeleteCategory;
