import React from 'react';
import 'firebase/storage';
import { isNull } from 'lodash';

import Dialog from '@material-ui/core/Dialog';
import { Box } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import active_lang from 'src/langs/jp_en';
import { GlobalContext } from 'src/contexts/Global';
import NewFoodEditForm from 'src/components/EditFoodDetailDialog/NewFoodEditForm';

import { useStyles, Transition } from './styles';
import { dialog_transition_duration } from 'src/consts/TRANSITION';
import { useTranslation } from 'react-i18next';

export default function EditFoodDetailDialog({
  open,
  setOpen,
  food_detail,
  loadFoodDetail,
  setEditFoodDetail,
  columns,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { rest_id } = React.useContext(GlobalContext);

  const resetForm = () => {
    setTest(null);
    setLoadingForm(true);
  };
  const handleClose = () => {
    resetForm();
    // loadFoodDetail(rest_id);
    setOpen(false);
    setEditFoodDetail(null);
  };

  const [test, setTest] = React.useState({
    image_url: '',
    customize_group_settings: [],
  });
  const [loading_form, setLoadingForm] = React.useState(true);

  React.useEffect(() => {
    if (!isNull(food_detail)) {
      setLoadingForm(false);
      setTest(food_detail);
    }
  }, [food_detail]);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        transitionDuration={dialog_transition_duration}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        keepMounted
        maxWidth={'md'}
      >
        {/* <pre>{JSON.stringify(food_detail, null, 2)}</pre> */}

        <Box className={classes.DialogWrapper} style={{ overflowX: 'hidden' }}>
          <Box className={classes.DialogHead}>
            <Box className={classes.EditFormTitle}>
              {/* 商品編集 */}
              {t('MENU_EDIT_ITEM')}
            </Box>
            <Box
              className={classes.DialogHeadButtons}
              style={{ display: 'none' }}
            >
              <Box>
                <FileCopyIcon />
              </Box>
              <Box>
                <DeleteIcon />
              </Box>
            </Box>
          </Box>
          <Box className={classes.DialogBody}>
            {loading_form ? (
              <>{active_lang.FOOD_DETAIL_IS_LOADING}</>
            ) : (
              <>
                <NewFoodEditForm
                  rest_id={rest_id}
                  initial_value={test}
                  handleClose={handleClose}
                  columns={columns}
                />
              </>
            )}
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}
