import React from 'react';
import { Box } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import { LYNKED_RED, LYNKED_RED_BACKGROUND } from 'src/consts/colors';

export default function NoAdImage() {
  return (
    <>
      <Box
        style={{
          backgroundColor: LYNKED_RED_BACKGROUND,
          color: LYNKED_RED,
          height: '100%',
          minHeight: '50px',
          width: '100%',

          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'center',
          alignItems: 'center',

          borderRadius: '5px',
        }}
      >
        <Box style={{ width: '24px', height: '24px' }}>
          <ImageIcon />
        </Box>
        <Box>画像無し</Box>
      </Box>
    </>
  );
}
