import React from 'react';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
} from '@material-ui/core';

import WarningIcon from '@material-ui/icons/Warning';
import ShowDebugJson from 'src/components/ShowDebugJson';

import ShowDebug from 'src/components/ShowDebug';
import { LYNKED_RED } from 'src/consts/colors';
import {
  ENQUEUE_SUCCESS_CONFIG,
  ENQUEUE_WARNING_CONFIG,
  ENQUEUE_ERROR_CONFIG,
} from 'src/consts/ENQUEUE_CONFIG';

import { GlobalContext } from 'src/contexts/Global';
import useFirestoreUpload from 'src/hooks/useFirestoreUpload';
import MutateAddAdvertisement from 'src/hooks/MutateAddAdvertisement';

import active_lang from 'src/langs/jp_en';

import { getAdActionObjByName } from 'src/utils/AdAction';
import { getAdDestination } from 'src/utils/AdDestination';
import { getAdWindowObjByName } from 'src/utils/AdWindow';

import isBase64Image from 'src/utils/isBase64Image';
import isHttpLink from 'src/utils/isHttpLink';

import ImageHandler from './ImageHandler';
import SelectAdAction from './SelectAdAction';
import SelectAdConfigureSlot from './SelectAdConfigureSlot';
import SelectAdDestination from './SelectAdDestination';
import MutateNewAdvertisementId from 'src/hooks/MutateNewAdvertisementId';
import useGetFoodDetails from 'src/hooks/useGetFoodDetails';

import { select_values } from 'src/modals/advertisement_config';
import { useStyles, Transition } from './styles';

import { SERVICE_MENY, SERVICE_MENY_LIGHT } from 'src/consts/MENY_SERVICE';
import { dialog_transition_duration } from 'src/consts/TRANSITION';
import { useTranslation } from 'react-i18next';

function AdWindowSubForm({ ad_window, service_class, formik, food_details }) {
  switch (ad_window) {
    case select_values.ad_window.FOOD_MENU.name:
      return (
        <>
          <SelectAdAction service_class={service_class} formik={formik} />

          <SelectAdDestination
            service_class={service_class}
            formik={formik}
            food_details={food_details}
          />
        </>
      );
    case select_values.ad_window.FOOD_DETAIL.name:
      return <></>;

    case select_values.ad_window.ORDER_LIST.name:
      return <></>;

    default:
      return <></>;
  }
}

export default function AddAdvertisementDialog({
  open,
  setOpen,
  advertisement_id,
  refetch,
  advertisement_result,
}) {
  const classes = useStyles();
  let { rest_id, meny_service_class } = React.useContext(GlobalContext);
  const { t } = useTranslation();

  let mutationNewAdvertisementId = MutateNewAdvertisementId();
  let mutationAddAdvertisement = MutateAddAdvertisement();
  let mutateFirestoreUpload = useFirestoreUpload();

  let food_details = useGetFoodDetails(rest_id);

  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    document.activeElement.blur();

    setOpen(false);

    setTimeout(() => {
      formik.resetForm();
    }, 100);

    refetch();
  };

  React.useEffect(() => {
    switch (meny_service_class.id) {
      case SERVICE_MENY:
        if (advertisement_result.data.length === 1) {
          if (
            advertisement_result.data[0].data.ad_window.name ===
            select_values.ad_window.FOOD_MENU.name
          ) {
            formik.setFieldValue(
              'form_ad_window',
              select_values.ad_window.ORDER_LIST.name
            );

            formik.setFieldValue('form_ad_destination', 'order_list');
          }

          if (
            advertisement_result.data[0].data.ad_window.name ===
            select_values.ad_window.ORDER_LIST.name
          ) {
            formik.setFieldValue(
              'form_ad_window',
              select_values.ad_window.FOOD_MENU.name
            );
            formik.setFieldValue('form_ad_destination', 'please_select');
          }
        }
        break;
      case SERVICE_MENY_LIGHT:
        if (advertisement_result.data.length === 1) {
          if (
            advertisement_result.data[0].data.ad_window.name ===
            select_values.ad_window.FOOD_MENU.name
          ) {
            formik.setFieldValue('form_ad_window', 'food_detail');
            formik.setFieldValue('form_ad_destination', 'food_detail');
          }

          if (
            advertisement_result.data[0].data.ad_window.name ===
            select_values.ad_window.FOOD_DETAIL.name
          ) {
            formik.setFieldValue('form_ad_window', 'food_menu');
            formik.setFieldValue('form_ad_destination', 'please_select');
          }
        }
        break;

      default:
        break;
    }

    console.log('advertisement_result', advertisement_result);
  }, [advertisement_result, meny_service_class]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: '',
      ad_type: select_values.ad_type.AD_TYPE_MENY_REST_ADVERTISEMENT,
      image_urls: ['', '', ''],
      restaurant_service_tags: [],
      target: [],

      form_ad_window: select_values.ad_window.FOOD_MENU.name,
      form_ad_action: select_values.ad_action.FOOD_MENU.name,
      form_ad_destination: 'please_select',
    },
    validationSchema: Yup.object({
      title: Yup.string().required(t('AD_REQUIRED_BANNER')),
      image_urls: Yup.array()
        .test(
          'AD_IMAGE_CANNOT_BE_EMPTY',
          t('AD_REQUIRED_IMAGE'),
          (value, context) => {
            if (value.length !== 3) return false;
            return context;
          }
        )
        .test(
          'not a valid image',
          t('AD_REQUIRED_IMAGE'),
          (value, context) => {
            if (/^data:.+/.test(value[0]) || /^http:.+/.test(value[0]))
              return context;
            return false;
          }
        ),
      form_ad_destination: Yup.mixed().notOneOf(
        ['please_select'],
        active_lang.AD_INVALID_AD_DESTINATION
      ),
    }),

    onSubmit: (values) => {
      let sanitized_ad_window = getAdWindowObjByName(values.form_ad_window);
      let sanitized_ad_action = {
        ...values.ad_action,
        ...getAdActionObjByName(values.form_ad_action),
      };
      console.log('sanitized_ad_action', sanitized_ad_action);

      let sanitized_ad_destination = getAdDestination(
        food_details.data,
        values.form_ad_action,
        values.form_ad_destination
      );
      console.log('sanitized_ad_destination', sanitized_ad_destination);

      let sanitized_value = {
        is_enabled: select_values.is_enabled.ENABLED,
        image_urls: values.image_urls,
        title: values.title,
        target: values.target,
        ad_type: values.ad_type,
        restaurant_service_tags: values.restaurant_service_tags,
        ad_action: sanitized_ad_action,
        ad_window: sanitized_ad_window,
        ad_destination: sanitized_ad_destination,
      };
      console.log('sanitized_value', sanitized_value);

      enqueueSnackbar(
        t('COMMON_UPDATING'),
        ENQUEUE_WARNING_CONFIG
      );

      mutationNewAdvertisementId
        .mutateAsync()
        .then(async (new_advertisement_id) => {
          console.log('new_advertisement_id', new_advertisement_id);
          let file_path = `rest_advertisement/${rest_id}/${new_advertisement_id}/image_0`;

          let image_base64 = sanitized_value.image_urls[0];
          console.log(
            'AddAdvertisementDialog base64 test result',
            isBase64Image(image_base64)
          );

          if (isBase64Image(image_base64)) {
            console.log(
              'AddAdvertisementDialog update Advertisement with image'
            );
            return await mutateFirestoreUpload
              .mutateAsync({
                file_path,
                file_base64: image_base64,
              })
              .then((data) => {
                return mutationAddAdvertisement.mutateAsync({
                  advertisement_id: new_advertisement_id,
                  payload: {
                    ...sanitized_value,
                    rest_id: rest_id,
                    image_urls: [data.download_url, '', ''],
                  },
                });
              })
              .then(() => {
                enqueueSnackbar(
                  t('COMMON_UPDATED'),
                  ENQUEUE_SUCCESS_CONFIG
                );

                refetch();
                handleClose();
              })
              .catch((err) => {
                enqueueSnackbar(
                  active_lang.SORRY_BUT_I_CANNOT_UPDATE_ADVERTISEMENT_DATA,
                  ENQUEUE_ERROR_CONFIG
                );
                console.log('AddAdvertisementDialog', 'err', err);
              });
          } else if (isHttpLink(sanitized_value.image_urls[0])) {
            console.log('user have not touch ad image');
            delete sanitized_value.image_urls;

            return await mutationAddAdvertisement
              .mutateAsync({
                advertisement_id: new_advertisement_id,
                payload: { ...sanitized_value, rest_id: rest_id },
              })
              .then(() => {
                enqueueSnackbar(
                  t('COMMON_UPDATED'),
                  ENQUEUE_SUCCESS_CONFIG
                );

                refetch();
                handleClose();
              })
              .catch((err) => {
                enqueueSnackbar(
                  active_lang.SORRY_BUT_I_CANNOT_UPDATE_ADVERTISEMENT_DATA,
                  ENQUEUE_ERROR_CONFIG
                );
                console.log('AddAdvertisementDialog', 'err', err);
              });
          } else {
            console.log(
              'AddAdvertisementDialog update Advertisement without image'
            );

            return await mutationAddAdvertisement
              .mutateAsync({
                advertisement_id: new_advertisement_id,
                payload: {
                  ...sanitized_value,
                  rest_id: rest_id,
                  image_urls: ['', '', ''],
                },
              })
              .then(() => {
                enqueueSnackbar(
                  t('COMMON_UPDATED'),
                  ENQUEUE_SUCCESS_CONFIG
                );

                refetch();
                handleClose();
              })
              .catch((err) => {
                enqueueSnackbar(
                  active_lang.SORRY_BUT_I_CANNOT_UPDATE_ADVERTISEMENT_DATA,
                  ENQUEUE_ERROR_CONFIG
                );
                console.log('AddAdvertisementDialog', 'err', err);
              });
          }
        })

        .catch((err) => {
          console.log('AddAdvertisementDialog', 'err', err);
        })
        .finally(() => {
          formik.resetForm();
          formik.setSubmitting(false);
        });
    },
  });

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        transitionDuration={dialog_transition_duration}
        keepMounted
        fullWidth
        maxWidth={'md'}
        onClose={handleClose}
        aria-labelledby="add-advertisement-dialog"
        aria-describedby="add-advertisement-dialog"
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.setSubmitting(true);
            formik.handleSubmit();
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              formik.setSubmitting(true);
              formik.handleSubmit();
              // e.stopPropagation();
            }
          }}
        >
          <DialogTitle id="add-advertisement-dialog" style={{fontWeight: 'bold'}}>
            {t('AD_NEW_AD')}
            {/* <ShowDebug>{JSON.stringify(advertisement_id)}</ShowDebug> */}
          </DialogTitle>

          <DialogContent style={{ overflowX: 'hidden' }}>
            <Box mt={'0.5rem'}>
              <FormControl fullWidth>
                <Box style={{fontWeight: 'bold'}}>{t('COMMON_BANNER_NAME')}</Box>
                <TextField
                  id="title"
                  {...formik.getFieldProps('title')}
                  className={classes.TextInput}
                  fullWidth
                  placeholder={t('COMMON_BANNER_NAME')}
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ style: { padding: '1rem' } }}
                  style={{ marginTop: '0.5rem' }}
                />

                {formik.touched.title && formik.errors.title ? (
                  <Box
                    style={{
                      color: LYNKED_RED,
                      display: 'flex',
                      flexFlow: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      paddingLeft: '0.25rem',
                      paddingTop: '0.25rem',
                      paddingBottom: '0.25rem',
                    }}
                  >
                    <Box pr="1rem">
                      <WarningIcon fontSize="small" />
                    </Box>
                    <Box>{formik.errors.title}</Box>
                  </Box>
                ) : null}
              </FormControl>
            </Box>
            <Box mt={'0.5rem'}>
              <FormControl fullWidth>
                <ImageHandler
                  id="image_urls"
                  {...formik.getFieldProps('image_urls')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  image_url={
                    formik.values.image_urls && formik.values.image_urls[0]
                  }
                  edit_show_detail={formik.values}
                  setEditShowDetail={`setEditShowDetail`}
                />
                {formik.touched.image_urls && formik.errors.image_urls ? (
                  <Box className={classes.user_input_error}>
                    <Box pr="1rem">
                      <WarningIcon fontSize="small" />
                    </Box>
                    <Box>{formik.errors.image_urls}</Box>
                  </Box>
                ) : null}
              </FormControl>
            </Box>

            <SelectAdConfigureSlot
              service_class={meny_service_class}
              formik={formik}
              advertisement_result={advertisement_result}
            />

            <AdWindowSubForm
              ad_window={formik.values['form_ad_window']}
              formik={formik}
              service_class={meny_service_class}
              food_details={food_details}
            />
          </DialogContent>
          <DialogActions>
            <Grid item xs={12}>
              <Grid container justify="center">
                <Grid item xs={6}>
                  <div className={classes.DialogBottomButtons}>
                    <Button
                      className={clsx(classes.Buttons, classes.ButtonCancel)}
                      onClick={handleClose}
                      type="button"
                    >
                      {t('COMMON_CANCEL')}
                    </Button>
                    <Button
                      type="button"
                      className={clsx(classes.Buttons, classes.ButtonOK)}
                      onClick={formik.handleSubmit}
                    >
                      {t('COMMON_CONFIRM')}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
          {/* <ShowDebugJson>
            {JSON.stringify(formik.isSubmitting, null, 2)}
          </ShowDebugJson>
          <ShowDebugJson>
            {JSON.stringify(formik.errors, null, 2)}
          </ShowDebugJson>
          <ShowDebugJson>
            {JSON.stringify(formik.touched, null, 2)}
          </ShowDebugJson> */}
        </form>
      </Dialog>
    </>
  );
}
