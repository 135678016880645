let AD_IMPRESSIONS = '広告表示回数';
let TILES_AD_IMPRESSIONS = '広告表示回数';
let TILES_MENY_USAGE = 'meny 利用率';
let TILES_HELP_REQUEST = '呼び出し数';

let TILES_VIEWS = 'PV';
let TILES_TOTAL_CUSTOMER_TODAY = '（日報）総客数';

let TILES_TOTAL_REVENUE_TODAY = '（日報）総売上額';
let TILES_TOTAL_ORDERS_TODAY = '（日報）合計注文数';
let TILES_PER_TABLE_CUSTOMER = 'テーブルごとの客数';
let TILES_PER_CUSTOEMR_SPENT = '平均売上額';
let TILES_PER_ORDER_SPENT = '平均注文数';

let OVERVIEW_SEE_ALL = '全て見る';

let PER_ORDER_ITEMS = '平均注文数';
let PER_TABLE_SPENT = '平均売上額';

let SINCE_YESTERDAY = '昨日から';

export default {
  AD_IMPRESSIONS,
  TILES_AD_IMPRESSIONS,
  TILES_MENY_USAGE,
  TILES_HELP_REQUEST,
  TILES_VIEWS,
  TILES_TOTAL_CUSTOMER_TODAY,

  TILES_TOTAL_REVENUE_TODAY,
  TILES_TOTAL_ORDERS_TODAY,
  TILES_PER_TABLE_CUSTOMER,
  TILES_PER_CUSTOEMR_SPENT,
  TILES_PER_ORDER_SPENT,
  OVERVIEW_SEE_ALL,
  PER_ORDER_ITEMS,
  PER_TABLE_SPENT,
  SINCE_YESTERDAY,
};
