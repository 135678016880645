import { LYNKED_RED } from 'src/consts/colors';
import { STATUS_FOOD_HIDE } from 'src/modals/food_details';
import active_lang from 'src/langs/jp_en';
import { useTranslation } from 'react-i18next';

export default function ShowTextFoodHideStatus({ food_show_status }) {
  const { t } = useTranslation();

  if (STATUS_FOOD_HIDE === food_show_status) {
    return (
      <div style={{ paddingLeft: '1rem', color: LYNKED_RED }}>
        {`(  ${t('COMMON_NOT_PULIC')}  )`}
      </div>
    );
  }
  return <></>;
}
