import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

import { Box, Button, makeStyles } from '@material-ui/core';

import {
  LYNKED_RED,
  LYNKED_RED_HOVER,
  LYNKED_WHITE,
  LYNKED_DEEP_GREY,
  LYNKED_DEEP_GREY_HOVER,
} from 'src/consts/colors';

import active_lang from 'src/langs/jp_en';
import { dialog_transition_duration } from 'src/consts/TRANSITION';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((_theme) => ({
  helloworld: {},
  DialogBottom: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',

    width: '100%',
    justifyContent: 'space-between',
  },
  ConfirmDeleteDialogWrapper: {
    margin: '30px',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  DialogTitle: {
    paddingBottom: '2rem',
    fontWeight: 'bold'
  },
  QuestionsWrapper: {
    textAlign: 'center',
    paddingBottom: '3rem',
    fontWeight: 'bold'
  },
  FooterButtonsGroup: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  FooterButtons: {
    width: '48%',
  },
  ButtonCancel: {
    width: '48%',
    backgroundColor: LYNKED_DEEP_GREY,
    '&:hover': {
      backgroundColor: LYNKED_DEEP_GREY_HOVER,
    },
    color: LYNKED_WHITE,
    borderRadius: '1px',
  },
  ButtonAlertOK: {
    width: '48%',
    backgroundColor: LYNKED_RED,
    '&:hover': {
      backgroundColor: LYNKED_RED_HOVER,
    },
    color: LYNKED_WHITE,
    borderRadius: '1px',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmRemoveFoodDetailDialog({
  open,
  setOpen,
  delete_food_detail,
  setDeleteFoodDetail,
  _loadFoodDetail,
  processDeleteFoodDetail,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOkClick = (e) => {
    processDeleteFoodDetail(e, delete_food_detail.id);
    setDeleteFoodDetail({ id: null, name: null });
    handleClose();
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        transitionDuration={dialog_transition_duration}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        fullWidth
      >
        <Box className={classes.ConfirmDeleteDialogWrapper}>
          <Box className={classes.DialogTitle}>
            <Box>{t('MENU_DELETE_ITEM')}</Box>
          </Box>
          <Box className={classes.QuestionsWrapper}>
            <Box>{t('MENU_CONFIRM_DELETE_ITEM')}</Box>
            {/* <Box>"{delete_food_detail.name}"</Box> */}
            {/* <pre>{JSON.stringify(delete_food_detail, null, 2)}</pre> */}
          </Box>

          <Box className={classes.DialogBottom}>
            <Button
              onClick={handleClose}
              className={`${classes.Buttons} ${classes.ButtonCancel}`}
            >
              {t('COMMON_CANCEL')}
            </Button>
            <Button
              onClick={handleOkClick}
              className={`${classes.Buttons} ${classes.ButtonAlertOK}`}
            >
              {t('COMMON_DELETE')}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
