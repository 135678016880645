//1.
import React, { useEffect, useState } from 'react';

import app from './config';
import firebase_app from 'src/Firebase/config';
import { isNull, isUndefined } from 'lodash';

//2.
export const AuthContext = React.createContext();

//3.
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAdminUpdating, setIsAdminUpdating] = useState(false);
  const getUserUid = () => {
    if (isNull(user)) {
      return null;
    } else {
      if (isUndefined(user) || isUndefined(user.uid)) {
        return null;
      } else {
        console.log('getUserUid', user);
        console.log('getUserUid', user.uid);
        return user.uid;
      }
    }
  };

  const updateUser = (user) => {
    console.log('updateUser', 'user', user);
    setUser(user);
  };

  const getUserEmail = (user) => {
    console.log('getUserEmail', 'user', user);
    if (user != null && user.user != null && user.user.email != null) {
      console.log('getUserEmail', 'user.user.email', user.user.email);
      return user.user.email;
    } else {
      console.log('getUserEmail', 'user email is null', 'user', user);
      return '';
    }
  };

  const checkUserLoggedIn = () => {
    console.log('context.js', 'checkUserLoggedIn', 'user', user);
    return user != null;
  };

  const logoutUser = () => {
    firebase_app.auth().signOut();
    clearUserLoginState();
  };

  const clearUserLoginState = () => {
    setUser(null);
  };

  const checkValidUidFound = () => {
    return user != null;
  };

  const getDecryptedUserUid = (encrypted_uid) => {
    let a = encrypted_uid.split('');
    let b = a.reverse();
    let c = b.join('');
    return c;
  };

  const getEncryptedUserUid = () => {
    if (checkValidUidFound()) {
      let a = getUserUid().split('');
      let b = a.reverse();
      let c = b.join('');
      return c;
    }
  };

  useEffect(() => {
    console.log('context.js', 'onAuthStateChanged');
    app.auth().onAuthStateChanged(updateUser);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        updateUser,
        getUserEmail,
        checkUserLoggedIn,
        clearUserLoginState,
        getUserUid,
        // setUserToRest1,
        logoutUser,
        checkValidUidFound,
        getEncryptedUserUid,
        getDecryptedUserUid,
        isAdminUpdating,
        setIsAdminUpdating,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
