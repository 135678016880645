import { useMutation } from 'react-query';
import { deleteAdvertisementById } from 'src/modals/advertisement_config';

import firebase from 'firebase/app';
import 'firebase/storage';

var storage = firebase.storage();

export default function useMutateDeleteAdvertisement() {
  const mutation = useMutation(
    ({ advertisement_id, rest_id }) => {
      let file_path = `rest_advertisement/${rest_id}/${advertisement_id}/image_0`;
      var desertRef = storage.ref(file_path);

      return deleteAdvertisementById(advertisement_id).then(() =>
        desertRef.delete()
      );
    },
    { retry: 10 }
  );

  return mutation;
}
