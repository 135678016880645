import React from 'react';
import Sound from 'react-sound';

import DongSoundFile from 'src/assets/swiftly-610.wav';

export const ReceptionSoundContext = React.createContext();
export const ReceptionSoundContextProvider = ({ children }) => {
  let [play_sound, setPlaySound] = React.useState(false);

  const handlePlaySound = () => {
    // alert('helloworld');
    var audio1 = document.getElementById('audio-reception-sound');
    audio1
      .play()
      .then(() => {
        console.log('play successfully');
      })
      .catch((err) => {
        console.log('play failed');
      });
  };

  React.useEffect(() => {
    if (play_sound) {
      handlePlaySound();
    }
  }, [play_sound]);

  const hwReceptionSoundContext = () => {
    alert('hello audio context');
  };

  React.useEffect(() => {
    console.log('ReceptionSound', 'play_sound', play_sound);
  }, [play_sound]);

  return (
    <>
      <ReceptionSoundContext.Provider
        value={{ handlePlaySound, hwReceptionSoundContext }}
      >
        <audio id="audio-reception-sound">
          <source src={DongSoundFile} type="audio/wav" />
        </audio>

        {children}
      </ReceptionSoundContext.Provider>
    </>
  );
};
