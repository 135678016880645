import React from 'react';

import { GlobalContext } from 'src/contexts/Global';
import { NewLineupRequestAlertsContext } from 'src/contexts/NewLineupRequestAlerts';

import { RESTAURANT_OPEN_STATE_OPENED } from 'src/consts/RESTAURANT_OPEN_STATE';
import { SERVICE_MENY, SERVICE_MENY_LIGHT } from 'src/consts/MENY_SERVICE';

import {
  subscribeLineupChangeWithUnassignedTableWithOpenId,
  subscribeLineupChangeWithAssignedTableWithOpenId,
} from 'src/modals/lineup';

const LOG_PREFIX = 'MonitorLineup';

export const MonitorLineupContext = React.createContext();

export function MonitorLineupContextProvider({ children }) {
  const { rest_id, restaurant_config } = React.useContext(GlobalContext);
  const [line_up_list, setLineUpList] = React.useState([]);
  const [last_lineup_alert_num, setLastLineupAlertNum] = React.useState(0);
  const { showNewLineupAlert } = React.useContext(
    NewLineupRequestAlertsContext
  );
  const [number_of_active_line_up, setNumberOfActiveLineUp] = React.useState(0);

  const [assigned_table_list, setAssignedTableList] = React.useState(null);

  const resetLineupList = () => {
    setNumberOfActiveLineUp(0);
    setLineUpList([]);
  };

  React.useEffect(() => {
    if (
      number_of_active_line_up > last_lineup_alert_num &&
      number_of_active_line_up > 0
    ) {
      setLastLineupAlertNum(number_of_active_line_up);
      showNewLineupAlert();
    } else {
      // reset when len_line_up_list is 0
      setLastLineupAlertNum(number_of_active_line_up);
    }
  }, [line_up_list, number_of_active_line_up]);

  function monitorLineupChange({ rest_id, restaurant_open_id }) {
    console.log(LOG_PREFIX, 'start', rest_id, restaurant_open_id);

    return subscribeLineupChangeWithUnassignedTableWithOpenId({
      rest_id,
      restaurant_open_id,
    })
      .orderBy('request-time-utc')
      .onSnapshot((ss) => {
        let a_d = [];
        ss.forEach((doc) => a_d.push({ id: doc.id, data: doc.data() }));
        setLineUpList(a_d);
        setNumberOfActiveLineUp(a_d.length);
      });
  }

  React.useEffect(() => {
    let unsubMonitor;

    if (rest_id && restaurant_config) {
      let { meny_service_class, restaurant_open_state, restaurant_open_id } =
        restaurant_config;

      switch (meny_service_class?.id) {
        case SERVICE_MENY:
          if (restaurant_open_state === RESTAURANT_OPEN_STATE_OPENED) {
            unsubMonitor = monitorLineupChange({ rest_id, restaurant_open_id });
          } else {
            // restaurant closed
            setLineUpList([]);
            if (unsubMonitor) unsubMonitor();
          }

          break;
        case SERVICE_MENY_LIGHT:
          break;

        default:
          console.error(LOG_PREFIX, 'meny_service_class not handled');
          break;
      }
    }

    let componentUnload = () => {
      if (unsubMonitor) unsubMonitor();
    };

    return componentUnload;
  }, [rest_id, restaurant_config?.restaurant_open_state]);

  function monitorAssignedTableChange({ rest_id, restaurant_open_id }) {
    console.log(LOG_PREFIX, 'start', rest_id, restaurant_open_id);
    return subscribeLineupChangeWithAssignedTableWithOpenId(
      rest_id,
      restaurant_open_id
    ).onSnapshot((ss) => {
      let a_d = [];
      ss.forEach((doc) => a_d.push({ id: doc.id, data: doc.data() }));
      setAssignedTableList(a_d);
      console.log(
        LOG_PREFIX,
        'subscribeLineupChangeWithAssignedTableWithOpenId',
        a_d
      );
    });
  }

  React.useEffect(() => {
    let unsubMonitor;

    if (rest_id && restaurant_config) {
      let { meny_service_class, restaurant_open_state, restaurant_open_id } =
        restaurant_config;

      switch (meny_service_class?.id) {
        case SERVICE_MENY:
          if (restaurant_open_state === RESTAURANT_OPEN_STATE_OPENED) {
            unsubMonitor = monitorAssignedTableChange({
              rest_id,
              restaurant_open_id,
            });
          } else {
            // restaurant closed

            setAssignedTableList(null);
            if (unsubMonitor) unsubMonitor();
          }

          break;
        case SERVICE_MENY_LIGHT:
          break;

        default:
          console.error(LOG_PREFIX, 'meny_service_class not handled');
          break;
      }
    }

    let componentUnload = () => {
      console.log(LOG_PREFIX, 'end');
      if (unsubMonitor) unsubMonitor();
    };

    return componentUnload;
  }, [rest_id, restaurant_config?.restaurant_open_id]);

  return (
    <>
      <MonitorLineupContext.Provider
        value={{
          line_up_list,
          assigned_table_list,
          number_of_active_line_up,
          resetLineupList,
        }}
      >
        {children}
      </MonitorLineupContext.Provider>
    </>
  );
}
