import React from 'react';
import { Grid, Paper } from '@material-ui/core';

import GetQR from 'src/utils/GetQR';

const GridQR = ({ link, text, id_of_qr }) => {
  return (
    <>
      <Grid item>
        <Paper style={{ padding: '0.5rem' }}>
          <GetQR
            link={link}
            text={text}
            style={{ padding: '10px' }}
            id_of_qr={id_of_qr}
          />
        </Paper>
      </Grid>
    </>
  );
};

export default React.memo(GridQR);
