import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import clsx from 'clsx';
import active_lang from 'src/langs/jp_en';
import WarningIcon from '@material-ui/icons/Warning';
import { isUndefined } from 'lodash';
import useUpdateAdvertisement from 'src/hooks/useUpdateAdvertisement';

import { select_values } from 'src/modals/advertisement_config';

import { useSnackbar } from 'notistack';
import {
  ENQUEUE_ERROR_CONFIG,
  ENQUEUE_SUCCESS_CONFIG,
  ENQUEUE_WARNING_CONFIG,
} from 'src/consts/ENQUEUE_CONFIG';

import { GlobalContext } from 'src/contexts/Global';

import useFirestoreUpload from 'src/hooks/useFirestoreUpload';
import isBase64Image from 'src/utils/isBase64Image';
import isHttpLink from 'src/utils/isHttpLink';
import useGetFoodDetails from 'src/hooks/useGetFoodDetails';

import SelectAdDestination from './SelectAdDestination';

import ImageHandler from './ImageHandler';
import SelectAdConfigureSlot from './SelectAdConfigureSlot';
import SelectAdAction from './SelectAdAction';

// import EnqueueAlert from 'src/contexts/EnqueueAlertContext';

import { getAdWindowNameByObj, getAdWindowObjByName } from 'src/utils/AdWindow';

import { getAdActionNameByObj, getAdActionObjByName } from 'src/utils/AdAction';

import { preSelectAdDestinationByValue } from 'src/utils/preSelectAdDestinationByValue';
import { useTranslation } from 'react-i18next';

import {
  getAdDestination,
  getAdDestinationValue,
} from 'src/utils/AdDestination';

import ShowDebugJson from 'src/components/ShowDebugJson';
import * as Yup from 'yup';

import { useStyles, Transition } from './styles';
import { dialog_transition_duration } from 'src/consts/TRANSITION';

function AdWindowSubForm({ ad_window, service_class, formik, food_details }) {
  switch (ad_window) {
    case select_values.ad_window.FOOD_MENU.name:
      return (
        <>
          <SelectAdAction service_class={service_class} formik={formik} />

          <SelectAdDestination
            service_class={service_class}
            formik={formik}
            food_details={food_details}
          />
        </>
      );
    case select_values.ad_window.FOOD_DETAIL.name:
      return <></>;

    case select_values.ad_window.ORDER_LIST.name:
      return <></>;

    default:
      return <></>;
  }
}

export default function EditAdvertisementDialog({
  open,
  setOpen,
  initial_data,
  advertisement_id,
  refetch,
  advertisement_table_data,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  let { rest_id, meny_service_class } = React.useContext(GlobalContext);
  // let { showEnqueue } = React.useContext(EnqueueAlert);

  let mutationUpdateAdvertisement = useUpdateAdvertisement();
  let mutateFirestoreUpload = useFirestoreUpload();

  let food_details = useGetFoodDetails(rest_id);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...initial_data,

      form_ad_window: getAdWindowNameByObj(initial_data.ad_window),
      form_ad_action: getAdActionNameByObj(initial_data.ad_action),
      form_ad_destination: getAdDestinationValue(
        getAdActionNameByObj(initial_data.ad_action),
        initial_data.ad_destination
      ),
    },
    validationSchema: Yup.object({
      title: Yup.string().required(t('AD_REQUIRED_BANNER')),
      image_urls: Yup.array()
        .test(
          'AD_IMAGE_CANNOT_BE_EMPTY',
          t('AD_REQUIRED_IMAGE'),
          (value, context) => {
            console.log('image_urls validate', value);
            if (value.length != 3) return false;
            return context;
          }
        )
        .test(
          'not a valid image',
          t('AD_REQUIRED_IMAGE'),
          (value, context) => {
            if (/^data:.+/.test(value[0]) || /^http:.+/) return context;
            return false;
          }
        ),
      form_ad_destination: Yup.mixed().notOneOf(
        ['please_select'],
        'invalid_ad_destination'
      ),
    }),
    onSubmit: (values, { resetForm }) => {
      let sanitized_ad_window = getAdWindowObjByName(values.form_ad_window);
      let sanitized_ad_action = {
        ...values.ad_action,
        ...getAdActionObjByName(values.form_ad_action),
      };

      let sanitized_ad_destination = getAdDestination(
        food_details.data,
        values.form_ad_action,
        values.form_ad_destination
      );

      let sanitized_value = {
        is_enabled: true,
        image_urls: values.image_urls,
        title: values.title,
        target: values.target,
        ad_type: values.ad_type,
        restaurant_service_tags: values.restaurant_service_tags,
        ad_action: sanitized_ad_action,
        ad_window: sanitized_ad_window,
        ad_destination: sanitized_ad_destination,
      };

      console.log(
        'EditAdvertisementDialog',
        'sanitized_value',
        sanitized_value
      );
      enqueueSnackbar(
        t('COMMON_UPDATING'),
        ENQUEUE_WARNING_CONFIG
      );

      let file_path = `rest_advertisement/${rest_id}/${advertisement_id}/image_0`;
      let image_base64 = sanitized_value.image_urls[0];
      console.log(
        'EditAdvertisementDialog base64 test result',
        isBase64Image(image_base64)
      );

      if (isBase64Image(image_base64)) {
        console.log('EditAdvertisementDialog update Advertisement with image');
        return mutateFirestoreUpload
          .mutateAsync({
            file_path,
            file_base64: image_base64,
          })
          .then((data) => {
            return mutationUpdateAdvertisement.mutateAsync({
              advertisement_id,
              payload: {
                ...sanitized_value,
                rest_id: rest_id,
                image_urls: [data.download_url, '', ''],
              },
            });
          })
          .then(() => {
            enqueueSnackbar(
              t('COMMON_UPDATED'),
              ENQUEUE_SUCCESS_CONFIG
            );

            refetch();
            resetForm();
            handleClose();
          })
          .catch((err) => {
            enqueueSnackbar(
              active_lang.SORRY_BUT_I_CANNOT_UPDATE_ADVERTISEMENT_DATA,
              ENQUEUE_ERROR_CONFIG
            );
            console.log('err', err);
          });
      } else if (isHttpLink(sanitized_value.image_urls[0])) {
        console.log('user have not touch ad image');
        delete sanitized_value.image_urls;

        console.log('sanitized_value', sanitized_value);

        mutationUpdateAdvertisement
          .mutateAsync({
            advertisement_id,
            payload: { ...sanitized_value, rest_id: rest_id },
          })
          .then(() => {
            enqueueSnackbar(
              t('COMMON_UPDATED'),
              ENQUEUE_SUCCESS_CONFIG
            );

            refetch();
            resetForm();
            handleClose();
          })
          .catch((err) => {
            enqueueSnackbar(
              active_lang.SORRY_BUT_I_CANNOT_UPDATE_ADVERTISEMENT_DATA,
              ENQUEUE_ERROR_CONFIG
            );
            console.log('err', err);
          });
      } else {
        // NOTE: should not go here as image is considered to be required
        console.log(
          'EditAdvertisementDialog update Advertisement without image'
        );
        mutationUpdateAdvertisement
          .mutateAsync({
            advertisement_id,
            payload: {
              ...sanitized_value,
              rest_id: rest_id,
              image_urls: ['', '', ''],
            },
          })
          .then(() => {
            enqueueSnackbar(
              t('COMMON_UPDATED'),
              ENQUEUE_SUCCESS_CONFIG
            );

            refetch();
            resetForm();
            handleClose();
          })
          .catch((err) => {
            enqueueSnackbar(
              active_lang.SORRY_BUT_I_CANNOT_UPDATE_ADVERTISEMENT_DATA,
              ENQUEUE_ERROR_CONFIG
            );
            console.log('err', err);
          });
      }
    },
  });

  const handleClose = () => {
    document.activeElement.blur();

    setOpen(false);

    setTimeout(() => {
      formik.resetForm();
    }, 100);
  };

  // TODO: MENY-286, remove me after meny-286 done,
  // update to fix image changing back problem,
  // React.useEffect(() => {
  //   formik.setValues({
  //     ...initial_data,
  //     form_ad_window: getAdWindowNameByObj(initial_data.ad_window),
  //     form_ad_action: getAdActionNameByObj(initial_data.ad_action),
  //     form_ad_destination: preSelectAdDestinationByValue(
  //       food_details.data,
  //       getAdActionNameByObj(initial_data.ad_action),
  //       initial_data.ad_destination
  //     ),
  //   });
  // }, [initial_data]);

  React.useEffect(() => {
    if (open) {
      // when form open
      if (isUndefined(formik) || isUndefined(formik.setValues)) {
      } else {
        formik.setValues({
          ...initial_data,

          form_ad_window: getAdWindowNameByObj(initial_data.ad_window),
          form_ad_action: getAdActionNameByObj(initial_data.ad_action),
          form_ad_destination: preSelectAdDestinationByValue(
            food_details.data,
            getAdActionNameByObj(initial_data.ad_action),
            initial_data.ad_destination
          ),
        });
      }
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        transitionDuration={dialog_transition_duration}
        keepMounted
        fullWidth
        maxWidth={'md'}
        onClose={handleClose}
        aria-labelledby="edit-advertisement-dialog"
        aria-describedby="edit-advertisement-dialog"
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            // formik.setSubmitting(true);
            formik.handleSubmit(e);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              // formik.setSubmitting(true);

              formik.handleSubmit(e);

              // e.stopPropagation();
            }
          }}
        >
          <DialogTitle id="edit-advertisement-dialog">
            {t('AD_EDIT_BANNER')}
            <ShowDebugJson>{JSON.stringify(advertisement_id)}</ShowDebugJson>
          </DialogTitle>

          <DialogContent style={{ overflowX: 'hidden' }}>
            <Box mt={'0.5rem'}>
              <FormControl fullWidth>
                <Box>{t('COMMON_BANNER_NAME')}</Box>
                <TextField
                  id="title"
                  {...formik.getFieldProps('title')}
                  className={classes.TextInput}
                  fullWidth
                  placeholder={t('COMMON_BANNER_NAME')}
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ style: { padding: '1rem' } }}
                  style={{ marginTop: '0.5rem' }}
                />

                {formik.touched.title && formik.errors.title ? (
                  <Box className={classes.user_input_error}>
                    <Box pr="1rem">
                      <WarningIcon fontSize="small" />
                    </Box>
                    <Box>{formik.errors.title}</Box>
                  </Box>
                ) : null}
              </FormControl>
            </Box>
            <Box mt={'0.5rem'}>
              <FormControl fullWidth>
                <ImageHandler
                  id="image_urls"
                  {...formik.getFieldProps('image_urls')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  image_url={
                    formik.values.image_urls && formik.values.image_urls[0]
                  }
                  edit_show_detail={formik.values}
                  setEditShowDetail={`setEditShowDetail`}
                />
                {formik.touched.image_urls && formik.errors.image_urls ? (
                  <Box className={classes.user_input_error}>
                    <Box pr="1rem">
                      <WarningIcon fontSize="small" />
                    </Box>
                    <Box>{formik.errors.image_urls}</Box>
                  </Box>
                ) : null}
              </FormControl>
            </Box>

            <SelectAdConfigureSlot
              service_class={meny_service_class}
              formik={formik}
              advertisement_table_data={advertisement_table_data}
            />

            <AdWindowSubForm
              ad_window={formik.values['form_ad_window']}
              formik={formik}
              service_class={meny_service_class}
              food_details={food_details}
            />
          </DialogContent>
          <DialogActions>
            <Grid item xs={12}>
              <Grid container justify="center">
                <Grid item xs={6}>
                  <div className={classes.DialogBottomButtons}>
                    <Button
                      className={clsx(classes.Buttons, classes.ButtonCancel)}
                      onClick={handleClose}
                      type="button"
                    >
                      {t('COMMON_CANCEL')}
                    </Button>
                    <Button
                      id="myBtn"
                      type="button"
                      className={clsx(classes.Buttons, classes.ButtonOK)}
                      // MENY-240, align validation beheaviour
                      disabled={formik.isSubmitting}
                      onClick={(e) => formik.handleSubmit(e)}
                    >
                      {t('COMMON_CONFIRM')}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
          <ShowDebugJson>
            {JSON.stringify(formik.isSubmitting, null, 2)}
          </ShowDebugJson>
          <ShowDebugJson>
            {JSON.stringify(formik.errors, null, 2)}
          </ShowDebugJson>
          <ShowDebugJson>
            {JSON.stringify(formik.touched, null, 2)}
          </ShowDebugJson>
          <ShowDebugJson>
            {JSON.stringify(formik.values, null, 2)}
          </ShowDebugJson>
        </form>
      </Dialog>
    </>
  );
}
