import { isUndefined } from 'lodash';

function getAdActionNameByObj(obj_in) {
  if (!isUndefined(obj_in)) {
    return obj_in.name;
  }
  return '';
}

function getAdActionObjByName(name_in) {
  let sanitized_ad_action = { id: 1, name: 'food_menu' };
  switch (name_in) {
    case 'food_menu':
      sanitized_ad_action = { id: 1, name: 'food_menu' };
      break;
    case 'food_detail':
      sanitized_ad_action = { id: 2, name: 'food_detail' };
      break;
    default:
      break;
  }
  return sanitized_ad_action;
}

export { getAdActionNameByObj, getAdActionObjByName };
