import React from 'react';
import { Box, IconButton, styled } from '@material-ui/core';

import {
  LYNKED_DEEP_GREY,
  LYNKED_DEEP_GREY_HOVER,
  LYNKED_RED,
  LYNKED_TRANSPARENT,
  LYNKED_WHITE,
} from 'src/consts/colors';

const ButtonContent = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  borderRadius: '999px',
  borderWidth: '3px',
  borderStyle: 'solid',
  
  minWidth: '40px',
  height: '40px',
  lineHeight: '100%',

  backgroundColor: props => props.enabled ? LYNKED_RED : LYNKED_TRANSPARENT,
  borderColor: props => props.enabled ? LYNKED_DEEP_GREY : LYNKED_DEEP_GREY_HOVER,
  color: props => props.enabled ? LYNKED_WHITE : LYNKED_DEEP_GREY,
  paddingInline: ({ children }) => typeof children === 'string' && children.length > 1 ? 12 : 0,
});

const ButtonWrapper = styled(IconButton)({
  borderRadius: '999px',
  padding: '8px',
});

export default function OpeningHourEnableButton({
  handleOnClick,
  button_text,
  value,
}) {
  return (
    <ButtonWrapper
      id="opening_hour_enable_button"
      onClick={handleOnClick}
    > 
      <ButtonContent enabled={value}>
        {button_text}
      </ButtonContent>
    </ButtonWrapper>
  );
}
