import React from 'react';

export const BrowserZoomingContext = React.createContext();

export function BrowserZoomingContextProvider({ children }) {
  const alertHelloworld = () => {
    // alert('helloworld');
  };
  React.useEffect(() => {
    window.addEventListener('resize', (event) => alertHelloworld(event));
    return () => {
      window.removeEventListener('resize', alertHelloworld);
    };
  }, []);

  return (
    <>
      <BrowserZoomingContext.Provider>
        {children}
      </BrowserZoomingContext.Provider>
    </>
  );
}
