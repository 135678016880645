import { makeStyles } from '@material-ui/core/styles';

import { LYNKED_LIGHT_GREY } from 'src/consts/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300 + theme.spacing(3) * 2,
  },
  margin: {
    height: theme.spacing(3),
  },

  // TODO: update this
  TextInput: {
    border: '1px solid #E0E0E0',
    font: 'normal normal bold 12px/16px Roboto',
    '& ::placeholder': {
      color: LYNKED_LIGHT_GREY,
    },
    marginBottom: '1.5rem',
  },

  screencapture: {},
}));

export { useStyles };
