import { useMutation } from 'react-query';

import { REST_MNGT_ENDPOINT } from 'src/config';

const useMutateCheckUserRestId = () => {
  return useMutation(
    'check-user-rest-id',
    async (user_id) => {
      const response = await fetch(
        `${REST_MNGT_ENDPOINT}/getRestId?user_id=${user_id}`
      );

      if (!response.ok) {
        // throw new Error('Network response was not ok');
        console.error('error during checking user rest id', user_id);
      }

      return response.json();
    },
    { retry: 5 }
  );
};

export default useMutateCheckUserRestId;
