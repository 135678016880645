import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import ShowDebug from 'src/components/ShowDebug';

import service_request_white_png from 'src/assets/alarm/alarm-svgrepo-com-white.svg';

import active_lang from 'src/langs/jp_en';
import { useTranslation } from 'react-i18next';
import { useStyles, Transition } from './styles';
import { dialog_transition_duration } from 'src/consts/TRANSITION';

export default function AlertNewLineupRequest({ open, onClose }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      transitionDuration={dialog_transition_duration}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert_new_lineup_request"
      aria-describedby="new_lineup_request"
    >
      <DialogTitle
        id="alert_new_lineup_request"
        className={classes.dialog_background}
        style={{ textAlign: 'center' }}
      >
        <Box className={classes.title}>
          {t('POPUP_NEW_RECEIVED_MSG')}
        </Box>
        <ShowDebug>AlertNewLineupRequest</ShowDebug>
      </DialogTitle>
      <DialogContent
        className={classes.dialog_background}
        style={{ textAlign: 'center' }}
      >
        <Box
          className={classes.dialog_background}
          style={{
            display: 'inline-flex',
            flexFlow: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            style={{
              backgroundImage: `url(${service_request_white_png})`,
              backgroundSize: `contain`,
              backgroundRepeat: `no-repeat`,
              width: '50px',
              height: '50px',
              color: 'white',
              path: 'white',
            }}
          ></Box>
        </Box>
      </DialogContent>
      <DialogActions
        className={classes.dialog_background}
        style={{ justifyContent: 'center' }}
      >
        <Box style={{ paddingTop: '2rem' }}>
          <Button
            id="ack-new-lineup-request"
            onClick={onClose}
            className={classes.button_yes}
          >
            {t('COMMON_YES')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
