import React from 'react';

import NavButton from 'src/components/NavButton';

import active_lang from 'src/langs/jp_en';

import WallpaperIcon from '@material-ui/icons/Wallpaper';
import { useTranslation } from 'react-i18next';

export default function AdvertisementControl({ handleCloseNavBar }) {
  const { t } = useTranslation();

  return (
    <>
      <NavButton
        text={t('SIDEMENU_BANNER_MANAGE')}
        href={`/app/edit_advertisement`}
        icon={<WallpaperIcon />}
        handleCloseNavBar={handleCloseNavBar}
        id="nav-edit-advertisement"
      />
    </>
  );
}
