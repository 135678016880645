import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import IconButton from '@material-ui/core/IconButton';

import DragHandleOutlinedIcon from '@material-ui/icons/DragHandleOutlined';
// import { ReactComponent as DragHandleOutlinedIcon } from 'src/assets/icons/moveable.svg';

import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

import { Button, Typography, makeStyles, Box } from '@material-ui/core';

import AddOutlinedIcon from '@material-ui/icons/AddOutlined';

// import FoodDetailDialog from 'src/components/FoodDetailDialog';

import ShowDebug from 'src/components/ShowDebug';

import Task from './task';
import Food from './food';

import { LYNKED_BACKGROUND_LIGHT_GREY, LYNKED_LIGHT_GREY, LYNKED_LIGHT_GREY_HOVER } from 'src/consts/colors';

const useStyles = makeStyles((theme) => ({
  helloworld: {},
  container: {
    // margin: '8px',
    border: '1px solid lightgrey',
    backgroundColor: 'white',
    borderRadius: '2px',
    width: '100%',

    display: 'flex',
    flexDirection: 'column',
  },
  tasklist: (props) => ({
    transition: 'background-color 0.2s ease',
    // backgroundColor: props.isDraggingOver ? "skyblue" : "inherit",
    flexGrow: '1',
    // minHeight: '100px',
  }),
  tasklist_is_dragging_over: {
    backgroundColor: LYNKED_LIGHT_GREY_HOVER,
  },
  tasklist_not_dragging_over: {
    backgroundColor: 'white',
  },
  title_row_container: {
    margin: '0px',
  },
  title_row: {
    display: 'inline-flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#E0E0E0',
    width: '100%',
    height: '100%',
    padding: '10px',
  },
  title_row_left: {
    display: 'inline-flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
  },
  title_handler: {
    display: 'inline-flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  category_title: {
    // backgroundColor: 'tomato',
    marginLeft: '1rem',
    minWidth: '10rem',

    display: 'inline-flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title_buttons_group: {
    marginLeft: '2rem',
  },
  category_bottom_row: {
    width: '100%',
    display: 'inline-flex',
    flexFlow: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title_buttons: {
    marginLeft: '1rem',
  },
}));

export default function Column({
  column,
  tasks,
  index,
  food_details,
  category_id,
  handleRemoveFoodCategory,
  handleUpdateFoodCategoryTitle,
  loadFoodDetail,
  processDeleteFoodDetail,
  addFoodCategory,
  handleOpenAddNewFoodDialogPrefillCategory,
  columnOrder,
  columns,
  handleFoodCategoriesChange,
  food_categories,
  setColumns,
}) {
  const classes = useStyles();
  // always expand the first column by default
  const [list_expanded, setListExpanded] = React.useState(true);

  const handleExpandOnClick = (e) => {
    setListExpanded(!list_expanded);
  };
  return (
    <>
      <Draggable draggableId={column.id} index={index}>
        {(provided) => (
          <Box className={classes.container} {...provided.draggableProps} ref={provided.innerRef}>
            <Box className={classes.title_row_container} {...provided.dragHandleProps}>
              <Box className={classes.title_row}>
                <Box className={classes.title_row_left}>
                  <Box className={classes.title_handler}>
                    <DragHandleOutlinedIcon />
                  </Box>
                  <Box className={classes.category_title}>{column.title}</Box>
                  <Box className={classes.title_buttons_group}>
                    <IconButton
                      className={classes.title_buttons}
                      onClick={(e) => {
                        handleUpdateFoodCategoryTitle(e, column.id);
                      }}
                      size="small"
                    >
                      <EditOutlinedIcon />
                    </IconButton>
                    <IconButton className={classes.title_buttons} onClick={(e) => handleRemoveFoodCategory(e, column.id)} size="small">
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                    <ShowDebug>
                      <pre>{column.id}</pre>
                    </ShowDebug>
                  </Box>
                </Box>
                <Box>
                  <IconButton onClick={handleExpandOnClick} size="small">
                    <ExpandMoreOutlinedIcon />
                  </IconButton>
                </Box>
              </Box>
            </Box>
            <Droppable droppableId={column.id} type="task">
              {(provided, snapshot) => (
                <Box
                  className={`${classes.tasklist} ${snapshot.isDraggingOver ? classes.tasklist_is_dragging_over : classes.tasklist_not_dragging_over}`}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  isDraggingOver={snapshot.isDraggingOver}
                >
                  {tasks.map((task1, index) => {
                    // cat_0_food_detail_0 ==> food_detail_0
                    try {
                      let food_detail_id = task1.id;

                      let task = { id: `${category_id}_${food_detail_id}` };
                      // return <pre>{JSON.stringify(task, null, 2)}</pre>;
                      return (
                        <Task
                          key={task.id}
                          category_id={category_id}
                          food_detail_id={food_detail_id}
                          task={task}
                          index={index}
                          food_detail={food_details[food_detail_id]}
                          list_expanded={list_expanded}
                          loadFoodDetail={loadFoodDetail}
                          processDeleteFoodDetail={processDeleteFoodDetail}
                          columnOrder={columnOrder}
                          columns={columns}
                          handleFoodCategoriesChange={handleFoodCategoriesChange}
                          food_categories={food_categories}
                          setColumns={setColumns}
                        />
                      );
                      // return <Food key={task.id} task={task} index={index} />;
                    } catch (error) {
                      console.error('task1', task1);
                    }
                  })}
                  {provided.placeholder}
                  <Box mt={'0.5rem'} mb={'0.5rem'} className={classes.category_bottom_row}>
                    <IconButton
                      aria-label="add-food-items"
                      onClick={(e) => {
                        handleOpenAddNewFoodDialogPrefillCategory(e, index);
                      }}
                    >
                      <AddOutlinedIcon />
                    </IconButton>
                  </Box>
                </Box>
              )}
            </Droppable>
          </Box>
        )}
      </Draggable>
    </>
  );
}
