import { isMobile, isIPad13 } from 'react-device-detect';

// length to scroll up when ipad keyboard hide
let IPAD_KEYBOARD_DOWN_COMPENSATE = -100;

// to scroll up when iPad virtual keyboard hide
const forceScreenScrollUp = () => {
  if (isMobile) {
  }

  if (isIPad13) {
    // window.scrollTo(0, 0);

    var scrollElm = document.scrollingElement;
    scrollElm.scrollTop = 0;

    console.log('extra step to scroll back to the top');
  }
};

export default { IPAD_KEYBOARD_DOWN_COMPENSATE, forceScreenScrollUp };
