import React, { useContext, useState } from 'react';
import { isNull } from 'lodash';
import { Grid, Box, makeStyles } from '@material-ui/core';

import Page from 'src/components/Page';
import SearchInput from 'src/components/SearchInput';
import ExportButton from 'src/components/ExportButton';
import BillingManagementList from 'src/components/BillingManagementList';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MonitorOrderContext } from 'src/contexts/MonitorOrder';
import active_lang from 'src/langs/jp_en';

import {
  LYNKED_WHITE,
  LYNKED_BLUE,
  LYNKED_BLUE_HOVER,
} from 'src/consts/colors';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: { width: '100%', padding: '3rem' },
  search: { paddingRight: '1rem' },
  dateFilter: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    position: 'relative',
  },
  warning: {
    position: 'absolute',
    top: '100%',
    left: '0%',
    marginTop: 8,
    fontWeight: '500',
    color: 'red',
  },
  clearButton: {
    height: '45px',
    padding: '10px 10px',
    font: 'normal normal bold 12px/16px Roboto',
    borderRadius: '1px',
    color: LYNKED_WHITE,
    backgroundColor: LYNKED_BLUE,
    '&:hover': {
      backgroundColor: LYNKED_BLUE_HOVER,
    },
    '&.Mui-disabled': {
      color: LYNKED_WHITE,
    },
  },
}));

export default function BillingManagementPage() {
  const classes = useStyles();
  const { t } = useTranslation();

  let [filter_words, setFilterWords] = React.useState(null);
  const { startDate, endDate, setStartDate, setEndDate } =
    useContext(MonitorOrderContext);

  const [isOpenStartDate, setIsOpenStartDate] = useState(false);
  const [isOpenEndDate, setIsOpenEndDate] = useState(false);

  const handleStartDateChange = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const startDate =
      Date.parse(date) - hours * 3600 * 1000 - minutes * 60 * 1000;
    setStartDate(startDate);
  };
  const handleEndDateChange = (date) => {
    const hours = 23 - date.getHours();
    const minutes = 59 - date.getMinutes();
    const endDate =
      Date.parse(date) + hours * 3600 * 1000 + minutes * 60 * 1000;
    setEndDate(endDate);
  };

  const handleUpdateFilterWords = (e) => {
    if (isNull(e.target.value) || e.target.value.trim() === '') {
      setFilterWords(null);
    } else {
      setFilterWords(e.target.value.trim());
    }
  };
  return (
    <>
      <Page className={classes.root}>
        <Box
          style={{
            width: '100%',
            paddingBottom: '5rem',
          }}
        >
          <Grid container>
            <Grid item xs={6} className={classes.search}>
              <Box mt={0.25}>
                <SearchInput
                  handleUpdateFilterWords={handleUpdateFilterWords}
                />
              </Box>
            </Grid>
            <Grid item md={6} xs={12} className={classes.dateFilter}>
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                mt={{ xs: 2, md: 0 }}
                gridColumnGap={20}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    label={t('BILL_START_DATE')}
                    value={startDate}
                    onChange={handleStartDateChange}
                    inputVariant="standard"
                    onAccept={() => setIsOpenStartDate(false)}
                    KeyboardButtonProps={{
                      onFocus: (e) => {
                        setIsOpenStartDate(true);
                      },
                    }}
                    PopoverProps={{
                      disableRestoreFocus: true,
                      onClose: () => {
                        setIsOpenStartDate(false);
                      },
                    }}
                    InputProps={{
                      onFocus: () => {
                        setIsOpenStartDate(true);
                      },
                    }}
                    open={isOpenStartDate}
                  />
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    label={t('BILL_END_DATE')}
                    value={endDate}
                    onChange={handleEndDateChange}
                    inputVariant="standard"
                    onAccept={() => setIsOpenEndDate(false)}
                    KeyboardButtonProps={{
                      onFocus: (e) => {
                        setIsOpenEndDate(true);
                      },
                    }}
                    PopoverProps={{
                      disableRestoreFocus: true,
                      onClose: () => {
                        setIsOpenEndDate(false);
                      },
                    }}
                    InputProps={{
                      onFocus: () => {
                        setIsOpenEndDate(true);
                      },
                    }}
                    open={isOpenEndDate}
                  />
                  <ExportButton startDate={startDate} endDate={endDate} />
                </MuiPickersUtilsProvider>
              </Box>
              {startDate && endDate && startDate >= endDate && (
                <Box className={classes.warning}>
                  {t('BILL_START_DATE_ERROR')}
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              <Box style={{ marginTop: '2rem' }}>
                <BillingManagementList filter_words={filter_words} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Page>
    </>
  );
}
