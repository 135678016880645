import React from 'react';
import clsx from 'clsx';
import { Bar } from 'react-chartjs-2';
import {
  Box,
  Card,
  Divider,
  useTheme,
  makeStyles,
  colors,
  Typography,
} from '@material-ui/core';

import { zipArray } from 'src/utils/zipArray';

import { LYNKED_GREEN, LYNKED_RED } from 'src/consts/colors';

import moment from 'moment-timezone';
import ShowCompare from './ShowCompare';

import { GlobalContext } from 'src/contexts/Global';

import active_lang from 'src/langs/jp_en';

import { getAllServiceRequest } from 'src/modals/service_requests';
import { useTranslation } from 'react-i18next';

const SHOW_HISTORY_LENGTH = 7;

const data_default = {
  datasets: [
    {
      backgroundColor: colors.indigo[500],
      data: [0],
      label: 'This year',
    },
  ],
  labels: [''],
};

const useStyles = makeStyles(() => ({
  root: {},
}));

function ShowCompareUp({ value }) {
  return (
    <Box
      style={{
        font: 'normal normal normal 14px/18px Helvetica',
        color: LYNKED_GREEN,
      }}
    >
      &#x2197; {value}
    </Box>
  );
}

export default function ShowHelpRequestTiles({
  chart_data,
  dates,
  counts,
  className,
  ...rest
}) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const [counts_loading, setCountsLoading] = React.useState(false);
  const [today_count, setTodayCount] = React.useState(2);
  const [yesterday_count, setYesterdayCount] = React.useState(1);

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 10,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: theme.palette.text.secondary,
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
            suggestedMin: 1,
            suggestedMax: 10,
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider,
          },
        },
      ],
    },
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary,
    },
  };

  const init_moment_date_range = [
    moment().subtract(1, 'day').startOf('day'),
    moment().startOf('day'),
  ];

  const [date_range, setDateRange] = React.useState({
    startDate: moment().subtract(10, 'day').toDate(),
    endDate: moment().toDate(),
  });

  const [date_range_value, setDateRangeValue] = React.useState(
    init_moment_date_range
  );

  React.useEffect(() => {
    console.log('date_range', date_range);
    console.log('date_range_value', date_range_value);
    if (date_range_value[0] == null || date_range_value[1] == null) {
    } else {
    }
  }, [date_range_value]);

  React.useEffect(() => {
    if (typeof counts != 'undefined') {
      let counts_len = counts.length;
      let idx_today = counts_len - 1;
      let idx_yesterday = idx_today - 1;

      console.log('idx_today', idx_today);
      console.log('idx_yesterday', idx_yesterday);
      setTodayCount(counts[idx_today]);
      setYesterdayCount(counts[idx_yesterday]);
    }
  }, [counts]);

  return (
    <>
      <Card
        className={clsx(classes.root, className)}
        {...rest}
        style={{ margin: '1rem' }}
      >
        <Box style={{ margin: '1rem' }}>
          <Box mb={'0.5rem'}>
            <Typography
              variant="h4"
              color="textPrimary"
              style={{
                fontSize: '12px',
                fontWeight: '700',
                marginBottom: '12px',
                marginTop: '12px',
              }}
            >
              {t('DASHBOARD_CALL_NUMBER')}
            </Typography>
          </Box>
          <Divider />

          <Box mt={'0.5rem'} height={'150px'} position="relative">
            <Bar data={chart_data} options={options} />
          </Box>

          <Box
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            {counts_loading ? (
              <>{t('COMMON_LOADING')}</>
            ) : (
              <ShowCompare today={today_count} yesterday={yesterday_count} />
            )}

            <Box
              style={{
                paddingLeft: '0.5rem',
                font: 'normal normal normal 14px/18px Helvetica',
              }}
            >
              {t('DASHBOARD_SINCE_YESTERDAY')}
            </Box>
          </Box>
        </Box>
      </Card>
    </>
  );
}
