import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    width: '100%',
    padding: '3rem',
  },
}));

export { useStyles };
