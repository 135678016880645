import { useQuery } from 'react-query';
import { getAllFoods } from 'src/modals/food_details';

const useGetFoodDetails = (rest_id) => {
  return useQuery('use-get-food-details', () => {
    return getAllFoods(rest_id).then((result) => {
      return result;
    });
  });
};

export default useGetFoodDetails;
