import React from 'react';
import { Link } from 'react-router-dom';

export default function ApiEndpointPage() {
  const testcheckRestInfo = () => {
    fetch(
      `http://localhost:5001/lynked-demo-tryout/us-central1/api/checkRestInfo?user_id=OvoVubp76JaSJdLyz2eioKOecE1W2`
    )
      .then((res) => res.json())
      .then((res_json) => {
        console.log('res_json', res_json);
        let rest_id = res_json.restaurant_id;
        // let user_id = user.user.uid;
        console.log('rest_id', rest_id);
      });
  };
  return (
    <>
      <button onClick={testcheckRestInfo}>checkRestInfo</button>
      <Link to="/app">back</Link>
    </>
  );
}
