import {
  LYNKED_BLACK_HOVER,
  LYNKED_RED,
  LYNKED_WHITE,
  LYNKED_BLACK,
  LYNKED_DEEP_GREEN,
  LYNKED_DEEP_GREEN_HOVER,
} from 'src/consts/colors';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  EmailInput: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    border: '1px solid #B3B3B3',
    width: '100%',
    height: '45px',
    backgroundColor: LYNKED_WHITE,
  },
  TextInput: {
    // backgroundColor: 'gold',
    margin: '0 1rem',
    textAlign: 'left',
  },
  languageDropdown: {
    position: 'fixed',
    right: '90px',
    color: 'white',
    backgroundColor: LYNKED_DEEP_GREEN,
    width: '150px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '0px 0px 10px 10px',
    padding: '20px 0 10px 0',
    display: 'flex',
    justifyContent: 'center',
  },
  listItemDropdown: {
    '& ul': {
      marginTop: '8px',
    },
    '&': {
      top: '50px !important',
      right: '90px',
      backgroundColor: LYNKED_DEEP_GREEN,
      width: '150px',
      boxShadow:
        '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 13px 14px 2px rgba(0,0,0,0.12)',
    },
  },
  logoWrapper: {},
  lynkedLogo: {
    width: '100%',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: { minHeight: '10vh' },
    [theme.breakpoints.up('md')]: { minHeight: '10vh' },
  },

  loginInputText: {
    margin: '1rem 0px',
    [theme.breakpoints.down('sm')]: { width: '350px' },
    [theme.breakpoints.up('sm')]: { width: '450px' },
  },
  loginInputPasswordText: {
    margin: '1rem 0px',
    [theme.breakpoints.down('sm')]: { width: '350px' },
    [theme.breakpoints.up('sm')]: { width: '450px' },
  },
  textMessages: {
    margin: '20px 0px',
    fontFamily: 'Bayon',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '30px',
  },
  loginInputButton: {
    width: '300px',
    height: '47px',
    background: LYNKED_DEEP_GREEN,
    borderRadius: '4px',
    opacity: '1',
    color: 'white',
    margin: '20px 0px',
    '&:hover': { backgroundColor: LYNKED_DEEP_GREEN_HOVER },
  },
  footerText: {
    position: 'fixed',
    bottom: '10px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: { width: '350px' },
    [theme.breakpoints.up('sm')]: { width: '450px' },
  },
  login_form: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  loginInput: {
    textAlign: 'center',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    order: 3,
    padding: '0px',
    width: '100%',
    height: '100vh',
  },
  loginAd: {
    [theme.breakpoints.up('xs')]: {
      order: 2,
      padding: '0px',
      width: '100%',
      height: '45%',
    },
    [theme.breakpoints.up('md')]: {
      order: 2,
      padding: '0px',
      width: '100%',
      height: '45vh',
    },
    [theme.breakpoints.up('lg')]: {
      order: 2,
      padding: '0px',
      width: '50%',
      height: '100vh',
    },
  },
  loginRoot: {
    [theme.breakpoints.up('xs')]: {
      backgroundColor: LYNKED_WHITE,
      height: '100%',
      width: '100%',
      position: 'fixed',
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.up('md')]: {
      backgroundColor: LYNKED_WHITE,
      justifyContent: 'space-between',
      flexFlow: 'column',
      display: 'flex',
      height: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      backgroundColor: LYNKED_WHITE,
      flexFlow: 'row',
      display: 'flex',
      height: '100%',
    },
  },
  terms_and_conds_link: { color: LYNKED_WHITE },
}));

export { useStyles };
