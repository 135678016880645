import { useMutation } from 'react-query';
import { getAllFoods } from 'src/modals/food_details';

export default function MutateListFoodDetails(rest_id) {
  const mutation = useMutation(
    () => {
      return getAllFoods(rest_id);
    },
    {
      retry: 10,
    }
  );

  return mutation;
}
