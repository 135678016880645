import React from 'react';
import moment from 'moment-timezone';

import { Box, Grid } from '@material-ui/core';
import { UtilContext } from 'src/contexts/Util';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

export default function ReservationRequestCard({ line_up_info }) {
  const classes = useStyles();
  let lineup_info_data = line_up_info.data;
  let { ios_device } = React.useContext(UtilContext);
  const { t } = useTranslation();

  return (
    <>
      <Box
        className={classes.boxWrapper}
        style={{ width: ios_device ? '210px' : '250px' }}
      >
        <Box className={classes.topContent}>
          <Grid container>
            <Grid
              item
              xs={6}
              style={{
                paddingRight: '0.5em',
                paddingLeft: '0.5em',
                paddingTop: '0.5em',
              }}
            >
              {process.env.NODE_ENV === 'test'
                ? t('COMMON_TIME_FORMAT')
                : moment
                    .unix(lineup_info_data['request-time-utc'] / 1000)
                    .format(t('COMMON_TIME_FORMAT'))}
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                textAlign: 'right',
                paddingTop: '0.5em',
              }}
            >
              {t('COMMON_RECEPTION_NUMBER')}{lineup_info_data['current_ticket']}
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.bottomContent}>
          <Grid container style={{ paddingRight: '1em', paddingLeft: '1em' }}>
            <Grid item xs={12}>
              {t('DASHBOARD_NUMBER_PERSON', {
                userName: lineup_info_data['lineup-username'],
                numberOfAdult: lineup_info_data['num-of-adult'],
                numberOfChildren: lineup_info_data['num-of-child'],
              })}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
