let REGEX_TEST_NUMBER_POS_NEG = /^[ \u{3000}\u{2D}\u{FF0D}\u{30FC}]*[ \u{3000}\u{30}-\u{39}\u{FF10}-\u{FF19}]+$/u;
let REGEX_TEST_NUMBER_WITH_DECIMAL_POINT = /.*[\u{30}-\u{39}\u{FF10}-\u{FF19}]*[\.\u{3002}\u{FF0E}][\u{30}-\u{39}\u{FF10}-\u{FF19}]*.*/u;
let REGEX_TEST_NUMBER_WITHOUT_MINUS = /^[ \u{3000}\u{30}-\u{39}\u{FF10}-\u{FF19}]+$/u;
let REGEX_TEST_NUMBER_WITHOUT_MINUS_WITH_DECIMAL_POINT = /.*[ \u{3000}\u{30}-\u{39}\u{FF10}-\u{FF19}]*\.[ \u{3000}\u{30}-\u{39}\u{FF10}-\u{FF19}]*.*/u;

export {
  REGEX_TEST_NUMBER_POS_NEG,
  REGEX_TEST_NUMBER_WITH_DECIMAL_POINT,
  REGEX_TEST_NUMBER_WITHOUT_MINUS,
  REGEX_TEST_NUMBER_WITHOUT_MINUS_WITH_DECIMAL_POINT,
};
