import React from 'react';
import { Button } from '@material-ui/core';

import active_lang from 'src/langs/jp_en';

import { LYNKED_GREEN } from 'src/consts/colors';

import { RESTAURANT_OPEN_STATE_PREPARING } from 'src/consts/RESTAURANT_OPEN_STATE';

import ConfirmChangeToPrepareDialog from './ConfirmChangeToPrepareDialog';
import { useTranslation } from 'react-i18next';

export default function RestaurantOpenButton({
  handleChangeRestaurantOpenState,
}) {
  // open -> preparing
  let [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  const handleChangeStateOnClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmChangeState = () => {
    handleChangeRestaurantOpenState(RESTAURANT_OPEN_STATE_PREPARING);
    setOpen(false);
  };

  return (
    <>
      <ConfirmChangeToPrepareDialog
        open={open}
        handleClose={handleClose}
        handleConfirmChangeState={handleConfirmChangeState}
      />
      <Button
        onClick={handleChangeStateOnClick}
        style={{ backgroundColor: '#417537', border: '2px solid #FFFFFF' }}
        id="toggle-restaurant-open-button"
      >
        {t('OPERATING_TEXT')}
      </Button>
    </>
  );
}
