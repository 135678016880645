import React from 'react';
import { makeStyles, Slide } from '@material-ui/core';

import { LYNKED_GREEN, LYNKED_RED, LYNKED_WHITE } from 'src/consts/colors';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  dialog_background: {
    backgroundColor: LYNKED_RED,
    color: LYNKED_WHITE,
    '& div svg path': {
      fill: LYNKED_WHITE,
    },
  },
  button_yes: {
    color: LYNKED_WHITE,
  },
  button_no: {
    color: theme.palette.error.main,
  },
  title: {
    fontSize: '24px',
    fontWeight: '700',
    fontFamily: 'Noto Sans JP',
  },
}));

export { useStyles, Transition };
