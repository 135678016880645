import { makeStyles } from '@material-ui/core';

import {
  LYNKED_WHITE,
  LYNKED_BLUE,
  LYNKED_BLUE_HOVER,
} from 'src/consts/colors';

const useStyles = makeStyles(() => ({
  helloworld: {},
  AddCategoryButton: {
    height: '45px',
    padding: '1rem 1rem',
    backgroundColor: LYNKED_BLUE,
    color: LYNKED_WHITE,
    boxShadow: '0px 1px 2px #00000029',
    '&:hover': { backgroundColor: LYNKED_BLUE_HOVER },
  },
  button_label: {
    font: 'normal normal bold 12px/16px Roboto',
  },
}));

export { useStyles };
