import React from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export default function RestaurantButtonLoading() {
  const { t } = useTranslation();
  return (
    <>
      <Button>{t('COMMON_LOADING')}</Button>
    </>
  );
}
