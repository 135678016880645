// meny-210, Add/Edit category dialog text field does not handle "Enter" keypress event on windows and iPad

import React from 'react';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';

import Dialog from '@material-ui/core/Dialog';

import { Typography, Box, TextField, Button, Grid } from '@material-ui/core';

import active_lang from 'src/langs/jp_en';
// import useMutateAddFoodCategory from 'src/hooks/useMutateAddFoodCategory';

import { ENQUEUE_SUCCESS_CONFIG } from 'src/consts/ENQUEUE_CONFIG';

import * as Yup from 'yup';

// MENY-191, restaurant finish added new category, but try first time add new food, does not able to find the new created category in category list field

import { useStyles, Transition } from './styles';
import WarningIcon from '@material-ui/icons/Warning';
import { dialog_transition_duration } from 'src/consts/TRANSITION';
import ShowCategorySelect from 'src/components/ShowCategorySelect';
import { STATUS_CATEGORY_SHOW } from 'src/modals/food_details';
import { useTranslation } from 'react-i18next';

// MENY-191, restaurant finish added new category, but try first time add new food, does not able to find the new created category in category list field
export default function AddNewCategoryDialog({
  open,
  setOpen,
  handleAddCategory,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  // MENY-264, After changed category name, add new food will show old category name
  // let mutationAddFoodCategory = useMutateAddFoodCategory();

  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { category_title: '', category_show: STATUS_CATEGORY_SHOW },
    validationSchema: Yup.object({
      category_title: Yup.string().required(
        t('MENU_REQUIRED_CATEGORY')
      ),
    }),
    onSubmit: (values) => {
      handleAddCategory({
        category_title: values.category_title,
        category_show: values.category_show,
      })
        .then(() => {
          enqueueSnackbar(
            t('COMMON_UPDATED_COMPLETE'),
            ENQUEUE_SUCCESS_CONFIG
          );

          formik.resetForm();
          handleClose();
        })
        .catch((err) => {
          // NOTE: remove as unused

          // enqueueSnackbar(
          //   active_lang.MENU_MANAGEMENT_ADD_NEW_FOOD_CATEGORY_ERROR,
          //   ENQUEUE_WARNING_CONFIG
          // );
          console.error('handleAddCategory', 'err', err);
        });
    },
  });

  const handleClose = () => {
    formik.handleReset();
    setOpen(false);
  };

  const handleCancelClick = () => {
    handleClose();
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        transitionDuration={dialog_transition_duration}
        keepMounted
        maxWidth={'sm'}
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="add-new-category-dialog"
        aria-describedby="add-new-category-dialog"
      >
        <form
          onSubmit={formik.handleSubmit}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              document.activeElement.blur();
              formik.handleSubmit();
              e.stopPropagation();
            }
          }}
        >
          <Box className={classes.AddCategoryDialogWrapper}>
            <Box className={classes.DialogTitle}>
              <Box>
                <Typography
                  variant="h3"
                  color="textPrimary"
                  style={{ fontSize: '1rem', fontWeight: '700' }}
                >
                  {t('COMMON_ADD_CATEGORY')}
                </Typography>
              </Box>
              <Box className={classes.DialogBody}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box className={classes.TextInputGroup}>
                      <Box className={classes.TextInputTitle}>
                        {t('COMMON_ADD_CATEGORY')}
                      </Box>
                      <Box className={classes.TextInputWrapper} mt={'1rem'}>
                        <TextField
                          id="category_title"
                          {...formik.getFieldProps('category_title')}
                          className={classes.TextInput}
                          fullWidth
                          InputProps={{ disableUnderline: true }}
                          inputProps={{ style: { padding: '1rem' } }}
                          disabled={formik.isSubmitting}
                        />
                        {formik.touched.category_title &&
                        formik.errors.category_title ? (
                          <Box className={classes.user_input_error}>
                            <Box pr="1rem">
                              <WarningIcon fontSize="small" />
                            </Box>
                            <Box>{formik.errors.category_title}</Box>
                          </Box>
                        ) : null}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <ShowCategorySelect formik={formik} />
                  </Grid>
                </Grid>
              </Box>
              <Box className={classes.DialogFooter}>
                <Box className={classes.Buttons}>
                  <Button
                    type={'button'}
                    onClick={handleCancelClick}
                    className={classes.ButtonCancel}
                  >
                    {t('COMMON_CANCEL')}
                  </Button>
                  <Button
                    type={'button'}
                    disabled={!formik.isValid || formik.isSubmitting}
                    onClick={formik.handleSubmit}
                    className={classes.ButtonOK}
                  >
                    {t('MENU_REGISTER')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </form>
      </Dialog>
    </>
  );
}
