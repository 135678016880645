import React from 'react';

import { Box } from '@material-ui/core';
import { LYNKED_DEEP_GREY } from 'src/consts/colors';

import active_lang from 'src/langs/jp_en';
import ShowDebug from 'src/components/ShowDebug';
import { useTranslation } from 'react-i18next';

export default function BillListEmpty() {
  const { t } = useTranslation();

  return (
    <>
      <Box
        style={{
          width: '100%',
          margin: '2rem',
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: LYNKED_DEEP_GREY,
        }}
      >
        {t('BILL_CONFIRM_PAYMENT_MSG')}
        <ShowDebug>YOU_HAVE_NO_BILLS_AT_THE_MOMENT</ShowDebug>
      </Box>
    </>
  );
}
