const LANG_DISPLAY_KEY = 'lang_display';
const LANG_CLIENT_KEY = 'client_lang_display'
const LANGUAGES = {
  JAPAN: 'ja',
  ENGLISH: 'en',
};

const SITES = {
  CMS: 'cms',
  CLIENT: 'client',
};

export { SITES, LANGUAGES, LANG_DISPLAY_KEY, LANG_CLIENT_KEY };
