import React from 'react';
import { isEqual, isUndefined } from 'lodash';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from '@material-ui/core';

import ShowDebug from 'src/components/ShowDebug';
import active_lang from 'src/langs/jp_en';
import {
  DOW_SUN,
  DOW_MON,
  DOW_TUE,
  DOW_WED,
  DOW_THU,
  DOW_FRI,
  DOW_SAT,
} from 'src/consts/WEEK';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import DayCard from './DayCard';

export default ({ formik }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [opening_schedule_available, setOpeningScheduleAvailable] =
    React.useState(false);

  React.useEffect(() => {
    if (formik.values?.opening_schedule) setOpeningScheduleAvailable(true);
  }, [formik.values]);

  if (!opening_schedule_available)
    return (
      <ShowDebug>
        opening schedule not available
        <pre>
          {JSON.stringify(
            { opening_schedule_available, 'formik.values': formik.values },
            null,
            2
          )}
        </pre>
      </ShowDebug>
    );

  return (
    <>
      <Card>
        <CardHeader title={t('SETTINGS_BUSINESS_HOURS')} />
        <ShowDebug>OPENING_HOURS_SETTING_TITLE</ShowDebug>
        <Divider />
        <CardContent>
          <Grid container>
            <Grid item xs={12} md={3} className={classes.day_card_container}>
              <DayCard formik={formik} day_of_week={DOW_MON} />
            </Grid>
            <Grid item xs={12} md={3} className={classes.day_card_container}>
              <DayCard formik={formik} day_of_week={DOW_TUE} />
            </Grid>
            <Grid item xs={12} md={3} className={classes.day_card_container}>
              <DayCard formik={formik} day_of_week={DOW_WED} />
            </Grid>
            <Grid item xs={12} md={3} className={classes.day_card_container}>
              <DayCard formik={formik} day_of_week={DOW_THU} />
            </Grid>
          </Grid>
          <Grid container justify="center">
            <Grid item xs={12} md={3} className={classes.day_card_container}>
              <DayCard formik={formik} day_of_week={DOW_FRI} />
            </Grid>
            <Grid item xs={12} md={3} className={classes.day_card_container}>
              <DayCard formik={formik} day_of_week={DOW_SAT} />
            </Grid>
            <Grid item xs={12} md={3} className={classes.day_card_container}>
              <DayCard formik={formik} day_of_week={DOW_SUN} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
