import React from 'react';

import NavButton from 'src/components/NavButton';

import active_lang from 'src/langs/jp_en';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { useTranslation } from 'react-i18next';

export default function NavMenuMenuManagement({ handleCloseNavBar }) {
  const { t } = useTranslation();

  return (
    <>
      <NavButton
        text={t('SIDEMENU_MENU_MANAGE')}
        href={`/app/menu_management`}
        icon={<MenuBookIcon />}
        handleCloseNavBar={handleCloseNavBar}
        id="nav-menu-management"
      />
    </>
  );
}
