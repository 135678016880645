import * as Yup from 'yup';
import moment from 'moment-timezone';

const LOG_PREFIX = 'time_picker_input';

let ERROR_FAILED_ISO_FORMAT = '';

export let iso_time_schema = 'YYYY-MM-DDTHH:mm:ss.000Z';

let validateIsoTime = Yup.string().test(
  'test string if iso time',
  'error is not a iso time',
  (value) => moment(value, iso_time_schema, true).isValid()
);

let validateDayControl = Yup.object()
  .shape({
    utc_start_time: Yup.string().test(
      'check iso format',
      ERROR_FAILED_ISO_FORMAT,
      (value) => validateIsoTime.isValidSync(value)
    ),
    utc_end_time: Yup.string().test(
      'check iso format',
      ERROR_FAILED_ISO_FORMAT,
      (value) => validateIsoTime.isValidSync(value)
    ),
    enabled: Yup.boolean().required(),
  })
  .test(
    'check time difference',
    'SETTINGS_BUSINESS_START_TIME_ERROR',
    (value) => {
      if (value.enabled === true) {
        let t_start = moment(value.utc_start_time, iso_time_schema, true);
        var t_start_HH_mm = moment(t_start.format('HH:mm'), 'HH:mm');

        let t_end = moment(value.utc_end_time, iso_time_schema, true);
        var t_end_HH_mm = moment(t_end.format('HH:mm'), 'HH:mm');

        let result = t_start_HH_mm.diff(t_end_HH_mm);

        console.log(LOG_PREFIX, { result, t_start_HH_mm, t_end_HH_mm });
        return result < 0;
      } else {
        // skip checking when time disabled
        return true;
      }
    }
  );

let validateOpeningSchedule = Yup.object().shape({
  MON: validateDayControl,
  TUE: validateDayControl,
  WED: validateDayControl,
  THU: validateDayControl,
  FRI: validateDayControl,
  SAT: validateDayControl,
  SUN: validateDayControl,
});

let validateRestaurantConfig = Yup.object().shape({
  opening_schedule: validateOpeningSchedule,
});

export {
  validateIsoTime,
  validateDayControl,
  validateOpeningSchedule,
  validateRestaurantConfig,
};
