import React from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import AssignTableDialog from 'src/components/AssignTableDialog';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import { useStyles } from './styles';
import active_lang from 'src/langs/jp_en';
import Popover from '@material-ui/core/Popover';
import EditTableInformationDialog from '../EditTableInformationDialog/index';
import DeleteEntryDialog from '../DeleteEntryDialog';

export default function ReceptionListCard({ line_up_info, idx }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const username = line_up_info.data['lineup-username'];
  const adult = line_up_info.data['num-of-adult'];
  const child = line_up_info.data['num-of-child'];
  const notes = line_up_info.data['lineup-note'];
  const request_time_utc = line_up_info.data['request-time-utc'];
  const human_request_time =
    moment(request_time_utc).format(t('COMMON_TIME_FORMAT'));

  let [open_assign_table_dialog, setOpenAssignTableDialog] =
    React.useState(false);
  let [assign_waiting_entry, setAssignWaitingEntry] = React.useState(null);
  const [openEditTableInformationDialog, setOpenEditTableInformationDialog] =
    React.useState(false);
  const [openDeleteEntryDialog, setOpenDeleteEntryDialog] =
    React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleWaitingEntryOnClick = (e, line_up_info) => {
    e.preventDefault();
    setAssignWaitingEntry(line_up_info);
    setOpenAssignTableDialog(true);
    handleClose();
  };

  const handleEditTableInformationDialog = (e, line_up_info) => {
    setAssignWaitingEntry(line_up_info);
    setOpenEditTableInformationDialog(true);
    handleClose();
  };

  const handleOpenDeleteEntryDialog = (e, line_up_info) => {
    setAssignWaitingEntry(line_up_info);
    setOpenDeleteEntryDialog(true);
    handleClose();
  };

  return (
    <>
      <AssignTableDialog
        open={open_assign_table_dialog}
        setOpen={setOpenAssignTableDialog}
        assign_waiting_entry={assign_waiting_entry}
      />
      <DeleteEntryDialog
        open={openDeleteEntryDialog}
        setOpen={setOpenDeleteEntryDialog}
        assign_waiting_entry={assign_waiting_entry}
      />
      <EditTableInformationDialog
        open={openEditTableInformationDialog}
        setOpen={setOpenEditTableInformationDialog}
        assign_waiting_entry={assign_waiting_entry}
      />

      <Box className={classes.ReceptionListCardWrapper}>
        <Box className={classes.statusIndicator}></Box>

        <Button
          style={{ width: '100%', height: '100%' }}
          id={`reception-list-card-${idx}`}
          disableRipple
          onClick={(e) => handleWaitingEntryOnClick(e, line_up_info)}
        >
          <Box className={classes.CardContent}>
            <Box className={classes.ReceptionCardHeader}>
              <Grid container>
                <Grid item xs={3} className={classes.ReceptionCardLeft0}>
                  {t('COMMON_RECEPTION_NUMBER')}{' '}
                  {line_up_info.data.current_ticket}
                </Grid>

                <Grid item xs={6} className={classes.ReceptionCardLeft0}>
                  {t('RECEPTION_NUMBER_PERSON', {
                    userName: username,
                    numberOfAdult: adult,
                    numberOfChildren: child,
                  })}
                </Grid>
              </Grid>
            </Box>
            <Box className={classes.ReceptionCardBody}>
              <Grid container>
                <Grid item xs={5} className={classes.ReceptionCardBody0}>
                  <Box>
                    <Box>{human_request_time}</Box>
                  </Box>
                  <Box style={{ color: 'white' }}>&nbsp;</Box>
                  <Box style={{ color: 'white' }}>&nbsp;</Box>
                </Grid>
                <Grid item xs={2}>
                  <Box className={classes.Remarks}>{t('COMMON_REMARKS')}:</Box>
                </Grid>
                <Grid item xs={5}>
                  <Box className={classes.ReceptionCardBody1}>
                    {notes
                      .trim()
                      .split('\n')
                      .map(function (item, idx) {
                        return (
                          <span key={idx}>
                            {item}
                            <br />
                          </span>
                        );
                      })}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Button>
        <Box className={classes.ThreeDot}>
          <MoreHorizIcon onClick={handleClick} />
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Box style={{ display: 'flex', flexFlow: 'column' }}>
              <Button
                style={{ width: '200px', height: '40px' }}
                onClick={(e) =>
                  handleEditTableInformationDialog(e, line_up_info)
                }
              >
                <Box
                  style={{
                    display: 'flex',
                    flexFlow: 'row',
                    justifyContent: 'flex-start',
                    width: '100%',
                  }}
                >
                  <Box
                    style={{
                      marginLeft: '1rem',
                      font: 'normal normal normal 14px/19px Hiragino Sans',
                    }}
                  >
                    {t('RECEPTION_EDIT')}
                  </Box>
                </Box>
              </Button>
              <Button
                style={{ width: '200px', height: '40px' }}
                onClick={(e) => handleOpenDeleteEntryDialog(e, line_up_info)}
              >
                <Box
                  style={{
                    display: 'flex',
                    flexFlow: 'row',
                    justifyContent: 'flex-start',
                    width: '100%',
                  }}
                >
                  <Box
                    style={{
                      marginLeft: '1rem',
                      font: 'normal normal normal 14px/19px Hiragino Sans',
                    }}
                  >
                    {t('COMMON_DELETE')}
                  </Box>
                </Box>
              </Button>
            </Box>
          </Popover>
        </Box>
      </Box>
    </>
  );
}
