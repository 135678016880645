import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: { width: '100%', padding: '3rem' },
  help_request_table_container: {
    [theme.breakpoints.up('xs')]: {
      height: '50vh',
      overflowY: 'auto',
      width: '100%',
      // backgroundColor: 'green',
      paddingTop: '1rem',
      paddingBottom: '1rem',
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
    [theme.breakpoints.up('sm')]: {
      // ipad
      height: '57vh',
      overflowY: 'auto',
      width: '100%',
      // backgroundColor: 'green',
      padding: '3rem',
    },
    [theme.breakpoints.up('md')]: {
      // ipad pro
      height: '59vh',
      overflowY: 'auto',
      width: '100%',
      // backgroundColor: 'green',
      padding: '3rem',
    },
    [theme.breakpoints.up('lg')]: {
      height: '100%',
      overflowY: 'auto',
      width: '100%',
      // backgroundColor: 'green',
    },
  },
}));

export { useStyles };
