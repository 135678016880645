import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import {
  styled,
  Box,
  TextField,
  Button,
  makeStyles,
  Input,
} from '@material-ui/core';

import {
  LYNKED_DEEP_GREY,
  LYNKED_LIGHT_GREY,
  LYNKED_DEEP_GREY_HOVER,
  LYNKED_GREEN,
  LYNKED_GREEN_HOVER,
  LYNKED_RED,
  LYNKED_WHITE,
} from 'src/consts/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  button_yes: {
    color: theme.palette.success.main,
  },
  button_no: {
    color: theme.palette.error.main,
  },
  TextInput: {
    border: '1px solid #E0E0E0',
    font: 'normal normal bold 12px/16px Roboto',
    padding: '3px 3px',
    '& ::placeholder': {
      color: LYNKED_LIGHT_GREY,
    },
  },
  food_availability: {
    border: '1px solid #e2e2e2',
    padding: '0.5rem',
  },
  icon: {
    color: '#707070',
    marginRight: '1rem',
    marginTop: '0.25rem',
  },
  DialogBottomButtons: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  Buttons: {
    width: '48%',
    padding: '10px',
    color: 'white',
    font: 'normal normal normal 11px/15px Noto Sans JP',

    letterSpacing: '0.12px',
    borderRadius: '1px',
  },
  ButtonCancel: {
    backgroundColor: LYNKED_DEEP_GREY,
    '&:hover': {
      backgroundColor: LYNKED_DEEP_GREY_HOVER,
    },
  },
  ButtonOK: {
    backgroundColor: LYNKED_GREEN,
    '&:hover': {
      backgroundColor: LYNKED_GREEN_HOVER,
    },
  },
  AddImageButton: {
    color: LYNKED_RED,
  },
  AddImagePlaceHolder: {
    background: `${LYNKED_WHITE} 0% 0% no-repeat padding-box`,
    border: `1px dashed ${LYNKED_RED}`,
    borderRadius: '9px',

    width: '100%',
    minHeight: '300px',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  test_icon_style: {
    color: LYNKED_RED,
  },
  user_input_error: {
    color: LYNKED_RED,
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '0.25rem',
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export { useStyles, Transition };
