import React from 'react';
import { Button, Dialog, Box, makeStyles, Grid } from '@material-ui/core';

import active_lang from 'src/langs/jp_en';

import {
  LYNKED_WHITE,
  LYNKED_GREEN,
  LYNKED_GREEN_HOVER,
  LYNKED_DEEP_GREY,
  LYNKED_DEEP_GREY_HOVER,
} from 'src/consts/colors';

import { Transition } from './styles';
import WarningIcon from '@material-ui/icons/Warning';
import { dialog_transition_duration } from 'src/consts/TRANSITION';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: { fontFamily: 'Noto Sans JP' },
  helloworld: {},
  DialogBottom: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',

    width: '100%',
    justifyContent: 'space-between',
  },
  ConfirmChangeToOpenWrapper: {
    margin: '30px',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  DialogTitle: {
    paddingBottom: '2rem',
  },
  QuestionsWrapper: {
    textAlign: 'center',
    paddingBottom: '3rem',
  },
  FooterButtonsGroup: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  FooterButtons: {
    width: '48%',
  },
  ButtonCancel: {
    width: '48%',
    backgroundColor: LYNKED_DEEP_GREY,
    '&:hover': {
      backgroundColor: LYNKED_DEEP_GREY_HOVER,
    },
    color: LYNKED_WHITE,
    borderRadius: '1px',
  },
  ButtonOK: {
    width: '48%',
    backgroundColor: LYNKED_GREEN,
    '&:hover': {
      backgroundColor: LYNKED_GREEN_HOVER,
    },
    color: LYNKED_WHITE,
    borderRadius: '1px',
  },
}));

export default function ConfirmChangeToOpenDialog({
  open,
  handleClose,
  handleConfirmChangeState,
}) {
  let classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Dialog
        className={classes.root}
        open={open}
        TransitionComponent={Transition}
        transitionDuration={dialog_transition_duration}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="xs"
      >
        <Box className={classes.ConfirmChangeToOpenWrapper}>
          <Box
            style={{
              paddingTop: '1rem',
              paddingLeft: '1rem',
              paddingRight: '1rem',
            }}
          >
            <Box
              className={classes.DialogTitle}
              style={{ textAlign: 'center' }}
            >
              <Box style={{ paddingBottom: '0.5rem' }}>
                {t('COMMON_NOTES')}
              </Box>
            </Box>

            <Box
              className={classes.DialogBody}
              style={{
                textAlign: 'center',
                paddingBottom: '2rem',
                paddingLeft: '1rem',
                paddingRight: '1rem',
              }}
            >
              <Grid container>
                <Grid item xs={12} sm={1}>
                  <Box>
                    <WarningIcon fontSize="small" />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={11}>
                  <Box mb="1rem">
                    {t('OPERATING_CAN_MAKE_ORDER_MSG')}
                  </Box>

                  <Box className={classes.QuestionsWrapper}>
                    <Box style={{ color: LYNKED_DEEP_GREY, fontSize: 'small' }}>
                      {active_lang.OPENING_HOURS_PREPARING_TO_OPEN_MESSAGE}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box className={classes.DialogBottom}>
            <Button
              onClick={handleClose}
              className={`${classes.Buttons} ${classes.ButtonCancel}`}
              id={'restaurant-change-to-opening-cancel'}
            >
              {t('COMMON_CANCEL')}
            </Button>
            <Button
              onClick={handleConfirmChangeState}
              className={`${classes.Buttons} ${classes.ButtonOK}`}
              id={'restaurant-change-to-opening-ok'}
            >
              {t('COMMON_OK')}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
