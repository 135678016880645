import React from 'react';
import { Box } from '@material-ui/core';

import Page from 'src/components/Page';
import ShowOrdersPage from './ShowOrdersPage';
import OrdersPageLoading from './OrdersPageLoading';

import { useStyles } from './styles';

export default function OrdersPage() {
  const classes = useStyles();

  let [show_content, setShowContent] = React.useState(false);

  React.useEffect(() => {
    setShowContent(true);
  }, []);

  return (
    <>
      {/* align to billing Page */}
      <Page className={classes.root}>
        <Box
          style={{
            width: '100%',
            paddingBottom: '5rem',
          }}
        >
          {show_content ? (
            <>
              <ShowOrdersPage />
            </>
          ) : (
            <>
              <OrdersPageLoading />
            </>
          )}
        </Box>
      </Page>
    </>
  );
}
