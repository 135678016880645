import { useQuery } from 'react-query';
import { listAllActiveAdvertisementByRestId } from 'src/modals/advertisement_config';

const useListAdvertisement = (rest_id) => {
  return useQuery('list-advertisement', () => {
    return listAllActiveAdvertisementByRestId(rest_id)
      .then((result) => {
        return result;
      })
      .catch((err) => {
        console.error('listAllActiveAdvertisementByRestId', 'err', err);
      });
  });
};

export default useListAdvertisement;
