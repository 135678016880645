import React from 'react';
import ALERT_SVG from './alert.svg';

export default function AlertMarkSvg() {
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${ALERT_SVG})`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          width: '16px',
          height: '16px',
        }}
      ></div>
    </>
  );
}
