import React, { useContext } from 'react';
import clsx from 'clsx';

import firebase from 'firebase/app';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { isNumber } from 'lodash';

import WarningIcon from '@material-ui/icons/Warning';
import { Box, Button, Input, Dialog } from '@material-ui/core';

import active_lang from 'src/langs/jp_en';
import devices_configs from 'src/configs/devices';
import { GlobalContext } from 'src/contexts/Global';
import { assignTableByDocId } from 'src/modals/lineup';
import createEmptybill from 'src/helpers/createEmptyBill';
import { LYNKED_RED } from 'src/consts/colors';
import ShowDebug from 'src/components/ShowDebug';
import { sanitizeFullWidthNumber } from 'src/utils/sanitizeFullWidthNumber';

import { REGEX_FW_HW_NUMBER_WITH_SPACE } from './regex_consts';
import { useStyles, Transition } from './styles';
import './AssignTableDialog_style.css';

import { SEAT_ASSIGNED } from 'src/modals/lineup';
import { MonitorRestaurantConfigContext } from 'src/contexts/MonitorRestaurantConfig';
import { dialog_transition_duration } from 'src/consts/TRANSITION';
import { RestaurantActiveContext } from 'src/contexts/RestaurantActiveContext';
import { useTranslation } from 'react-i18next';

const initial_value = {
  'table-number-input': '',
};

export default function ShowAssignTableDialog({
  open,
  setOpen,
  assign_waiting_entry,
}) {
  const classes = useStyles();
  const { rest_id } = React.useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { restaurant_config } = React.useContext(
    MonitorRestaurantConfigContext
  );
  const isSkippableToAssignTable = useContext(RestaurantActiveContext);

  const formik = useFormik({
    initialValues: initial_value,
    validationSchema: Yup.object({
      'table-number-input': Yup.string().required(
        t('RECEPTION_REQUIRED_TABLE_NUMBER')
      ),
      // MENY-171, update to handle unit_price checking
    }),
    onSubmit: async (values, { resetForm }) => {
      let saintized_table_number = sanitizeFullWidthNumber(
        values['table-number-input']
      ).trim();
      if (saintized_table_number !== '') {
        console.log('saintized_table_number', saintized_table_number);
        // alert(JSON.stringify(saintized_table_number, null, 2));

        return assignTableByDocId(rest_id, assign_waiting_entry.id, {
          status: SEAT_ASSIGNED,
          assign_table_name: saintized_table_number,
          assign_table_time: firebase.firestore.Timestamp.now(),
          restaurant_open_id: restaurant_config.restaurant_open_id,
        })
          .then(() => {
            return createEmptybill({
              rest_id,
              lineup_id: assign_waiting_entry.id,
              order_table_number: saintized_table_number,
              order_username: assign_waiting_entry.data['lineup-username'],
              num_of_adult: assign_waiting_entry.data['num-of-adult'],
              num_of_child: assign_waiting_entry.data['num-of-child'],
            });
          })
          .then(() => {
            resetForm({});

            enqueueSnackbar(t('RECEPTION_TABLE_CONFIRM'), {
              variant: 'success',
            });

            handleClose();
          })
          .catch(() => {
            console.error('fail to allocate table');
          });
      }
    },
  });

  const handleClose = () => {
    devices_configs.forceScreenScrollUp();
    document.activeElement.blur();
    setOpen(false);
  };

  const handleReset = () => {};
  // const handleTableNumberInputOnChange = (e) => {
  //   formik.handleChange(e);
  // };
  const handleTableNumberInputOnBlur = (e) => {
    if (e.target.value !== '') {
      let temp = sanitizeFullWidthNumber(e.target.value).trim();
      console.log('handleTableNumberInputOnBlur', 'temp', temp);
      if (isNumber(temp)) {
        e.target.value = temp;
      }
    }
    formik.handleChange(e);
  };

  return (
    <>
      <form
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            formik.handleSubmit();
          }
        }}
      >
        <Dialog
          open={open}
          TransitionComponent={Transition}
          transitionDuration={dialog_transition_duration}
          keepMounted
          onClose={() => {
            handleReset();
            handleClose();
          }}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <Box className={classes.AssignedTAbleDialogWrapper}>
            <Box className={classes.statusIndicator}></Box>
            <Box className={classes.CardContentWrapper}>
              <Box className={classes.CardContent}>
                <Box style={{ fontWeight: 'bold' }}>
                  {t('RECEPTION_NUMBER_PERSON', {
                    userName: assign_waiting_entry.data['lineup-username'],
                    numberOfAdult: assign_waiting_entry.data['num-of-adult'],
                    numberOfChildren: assign_waiting_entry.data['num-of-child'],
                  })}
                </Box>

                <Box>
                  <Input
                    className={classes.TextInput}
                    id="table-number-input"
                    value={formik.values['table-number-input']}
                    fullWidth
                    disableUnderline={true}
                    // InputProps={{ disableUnderline: true }}
                    inputProps={{ style: { padding: '1rem' } }}
                    {...formik.getFieldProps('table-number-input')}
                    // onChange={(e) => handleTableNumberInputOnChange(e)}
                    placeholder={t('RECEPTION_TABLE_NUMBER')}
                    onBlur={(e) => handleTableNumberInputOnBlur(e)}
                  />
                  {/* <Box>
                      {JSON.stringify(, null, 2)}
                    </Box> */}
                  {formik.errors['table-number-input'] ? (
                    <Box
                      style={{
                        color: LYNKED_RED,
                        display: 'flex',
                        flexFlow: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        paddingLeft: '0.25rem',
                        paddingTop: '0.25rem',
                        paddingBottom: '0.25rem',
                      }}
                    >
                      <Box pr="1rem">
                        <WarningIcon fontSize="small" />
                      </Box>
                      <Box>{formik.errors['table-number-input']}</Box>
                    </Box>
                  ) : null}
                </Box>

                <Box className={classes.DialogBottom}>
                  <Box>
                    <Button
                      disabled={formik.isSubmitting}
                      onClick={() => {
                        formik.resetForm();
                        handleClose();
                      }}
                      className={clsx(classes.Buttons, classes.ButtonCancel)}
                      type="button"
                    >
                      {t('COMMON_CANCEL')}
                    </Button>
                  </Box>

                  <Box>
                    <Button
                      id="assign-table-number-ok"
                      disabled={!formik.isValid || formik.isSubmitting}
                      type="submit"
                      onClick={formik.handleSubmit}
                      className={clsx(classes.Buttons, classes.ButtonOK)}
                    >
                      {t('COMMON_CONFIRM')}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          {/* <ShowDebug>
            <pre>{JSON.stringify(formik.values)}</pre>
            <pre>{JSON.stringify(formik.touched)}</pre>
            <pre>{JSON.stringify(formik.errors)}</pre>
          </ShowDebug> */}
        </Dialog>
      </form>
    </>
  );
}
