import { makeStyles } from '@material-ui/core';

import { LYNKED_BACKGROUND_LIGHT_GREY } from 'src/consts/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',

    // padding bottom controlled by list inside
    // paddingTop: theme.spacing(3),
  },
  DashboardContainer: (props) => ({
    height: props.current_client_height,
  }),
  ListContainer: (props) => ({
    width: '100%',
    height: props.current_client_height * 0.75,
  }),
  NavBodyMainRight: {
    // maxHeight: '71vh',
    // overflowY: 'auto',
    borderLeft: '1px solid #E0E0E0',
    backgroundColor: LYNKED_BACKGROUND_LIGHT_GREY,

    // paddingRight: '2rem',
    // paddingBottom: theme.spacing(30),
  },
  SubListContainer: (props) => ({
    minHeight: props.current_client_height * 0.75,
  }),
}));

export { useStyles };
