import { useMutation } from 'react-query';
import { REST_MNGT_ENDPOINT } from 'src/config';

const useMutationRunRestaurantClosedProcedure = () => {
  return useMutation(
    `run_restaurant_open_procedure`,
    async (rest_id) => {
      const response = await fetch(
        `${REST_MNGT_ENDPOINT}/runRestaurantClosedProcedure?rest_id=${rest_id}`
      );

      if (!response.ok) {
        // alert('RunRestaurantClosedProcedure error');
        console.error(
          'error during calling RunRestaurantClosedProcedure ',
          response
        );
      }

      return response.json();
    },
    { retry: 5 }
  );
};

export default useMutationRunRestaurantClosedProcedure;
