import React from 'react';

import { GlobalContext } from 'src/contexts/Global';
import active_lang from 'src/langs/jp_en';

import ShowAdImpressionsTiles from './ShowAdImpressionsTiles';
import { useTranslation } from 'react-i18next';

export default function AdImpressionTiles() {
  const { rest_id } = React.useContext(GlobalContext);
  const { t } = useTranslation();

  if (rest_id) return <ShowAdImpressionsTiles rest_id={rest_id} />;

  return <>{t('COMMON_LOADING')}</>;
}
