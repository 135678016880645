import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';

import ShowDebug from 'src/components/ShowDebug';

import { MonitorOrderContext } from 'src/contexts/MonitorOrder';

import BillingManagementCard from 'src/components/BillingManagementCard';

export default function BillList({ filter_words }) {
  const { currentOrders } = useContext(MonitorOrderContext);
  return (
    <Grid container justify="flex-start" spacing={3}>
      {currentOrders
        .filter((x) => {
          if (filter_words) return x.data.order_table_number === filter_words;

          // default return
          return true;
        })
        .sort((a, b) => {
          if (a?.data?.order_create_time && b?.data?.order_create_time)
            return a.data.order_create_time?.seconds <
              b.data.order_create_time?.seconds
              ? -1
              : 1;

          // default return
          return 1;
        })
        .map((x, idx) => (
          <>
            <Grid item xs={12} lg={6}>
              {/* <ShowDebug>
                <pre>{JSON.stringify(x.data)}</pre>
              </ShowDebug> */}
              <BillingManagementCard bill={x.data} id={x.id} idx={idx} />
            </Grid>
          </>
        ))}
    </Grid>
  );
}
