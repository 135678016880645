import React from 'react';

import { Grid, Box } from '@material-ui/core';

import SearchInput from 'src/components/SearchInput';
import ViewHistoryButton from 'src/components/ViewHistoryButton';
import OrderTable from 'src/components/OrderTable';

export default function ShowOrdersPage() {
  let [filter_by_words, setFilterByWords] = React.useState('');
  const handleSearchInput = (e) => {
    if (e.target) {
      setFilterByWords(e.target.value.trim());
    }
  };

  return (
    <>
      <Box style={{ width: '100%' }}>
        <Grid container>
          <Grid item xs={6}>
            <Box style={{ font: 'normal normal bold 20px/26px Roboto' }}>
              <SearchInput handleUpdateFilterWords={handleSearchInput} />
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <div style={{ display: 'none' }}>
              <ViewHistoryButton />
            </div>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <OrderTable filter_by_words={filter_by_words} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
