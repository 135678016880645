import React from 'react';

import './EditTableInformationDialog_style.css';
import ShowEditTableInformationDialog from './ShowEditTableInformationDialog';

export default function EditTableInformationDialog({
  open,
  setOpen,
  assign_waiting_entry,
}) {
  return (
    <>
      {assign_waiting_entry ? (
        <ShowEditTableInformationDialog
          open={open}
          setOpen={setOpen}
          assign_waiting_entry={assign_waiting_entry}
        />
      ) : (
        <></>
      )}
    </>
  );
}
