import { makeStyles } from '@material-ui/core/styles';

import {
  LYNKED_GREEN,
  LYNKED_GREEN_HOVER,
  LYNKED_LIGHT_GREY,
  LYNKED_BLACK,
} from 'src/consts/colors';

const useStyles = makeStyles({
  line1: {},
  line2: { font: "normal normal bold 12px/16px 'Noto Sans JP'" },
  table: {
    minWidth: 650,
    font: "normal normal bold 12px/16px 'Noto Sans JP'",
    '& thead': {
      background: '#E0E0E0 0% 0% no-repeat padding-box',
      textTransform: 'uppercase',
    },
    '& tbody': {
      '& th': {},
      '& td': { padding: '8px' },
    },
  },
  Buttons: {
    width: '120px',
    height: '30px',
    color: 'white',
    letterSpacing: '0.12px',
    borderRadius: '1px',
    backgroundColor: LYNKED_GREEN,
    font: "normal normal bold 10px/14px 'Noto Sans JP'",
    '&:hover': {
      backgroundColor: LYNKED_GREEN_HOVER,
    },
  },
  tableHeadText: {
    color: LYNKED_BLACK,
    font: 'normal normal bold 12px/16px Roboto',
  },
  tableBodyText: {
    color: LYNKED_LIGHT_GREY,
    font: "normal normal bold 12px/16px 'Noto Sans JP'",
  },
});

export { useStyles };
