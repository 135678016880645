import React from 'react';

import './DeleteEntryDialog_style.css';
import ShowDeleteEntryDialog from './ShowDeleteEntryDialog';

export default function DeleteEntryDialog({
  open,
  setOpen,
  assign_waiting_entry,
}) {
  return (
    <>
      {assign_waiting_entry ? (
        <ShowDeleteEntryDialog
          open={open}
          setOpen={setOpen}
          assign_waiting_entry={assign_waiting_entry}
        />
      ) : (
        <></>
      )}
    </>
  );
}
