import React from 'react';

import { ServiceRequestsAlertsContextProvider } from 'src/contexts/ServiceRequestsAlerts';
import { NewOrderAlertsContextProvider } from 'src/contexts/NewOrderAlerts';
import { NewLineupRequestAlertsContextProvider } from 'src/contexts/NewLineupRequestAlerts';

import { NetworkStatusContextProvider } from 'src/contexts/NetworkStatus';

import AudioNotification from 'src/contexts/AudioNotification';

export const AlertsContext = React.createContext();
export const AlertsContextProvider = ({ children }) => {
  const hwAlertContext = () => {
    // alert('hello alert context');
  };

  return (
    <>
      <AlertsContext.Provider value={{ hwAlertContext }}>
        <NetworkStatusContextProvider>
          <AudioNotification>
            <ServiceRequestsAlertsContextProvider>
              <NewOrderAlertsContextProvider>
                <NewLineupRequestAlertsContextProvider>
                  {children}
                </NewLineupRequestAlertsContextProvider>
              </NewOrderAlertsContextProvider>
            </ServiceRequestsAlertsContextProvider>
          </AudioNotification>
        </NetworkStatusContextProvider>
      </AlertsContext.Provider>
    </>
  );
};
