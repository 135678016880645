import { useMutation } from 'react-query';
import { addAdvertisementByAdvertisementId } from 'src/modals/advertisement_config';

export default function MutateAddAdvertisement() {
  const mutation = useMutation(
    'add-advertisement',
    ({ advertisement_id, payload }) => {
      console.log(
        'MutateAddAdvertisement',
        'advertisement_id',
        advertisement_id
      );
      console.log('MutateAddAdvertisement', 'payload', payload);
      return addAdvertisementByAdvertisementId(advertisement_id, payload);
    },
    { retry: 10 }
  );

  return mutation;
}
