let MENU_MANAGEMENT_FOOD_CATEGORY_TITLE_IS_MISSING_ERROR = '入力が必須な項目です。カテゴリーをご入力ください。';

let MENU_MANAGEMENT_ADD_NEW_FOOD_CATEGORY_DONE = '更新が完了しました';

let MENU_MANAGEMENT_EDIT_FOOD_CATEGORY_DONE = '更新が完了しました';

let SORRY_BUT_THE_FOOD_MENU_LIST_IS_EMPTY = '商品が登録されていません';

let FOOD_MENU_LOADING = '読み込み中...';
let SELLING_STATUS = '販売状況';
let CUSTOMIZING_GROUP = 'オプション';

// FOOD_DETAIL DIALOG
let ADD_FOOD_DETAIL = '商品追加';
let EDIT_FOOD_DETAIL = '商品編集';

// food detail dialog add
let FOOD_DETAIL_ADD_IMAGE = '画像を追加する';
let FOOD_DETAIL_ADD_IMAGE_APPENDIX = '(推奨のサイズは1MB以下(PNG, JPG可))';
let FOOD_DETAIL_ADD_ITEM_NAME = '商品名';
let FOOD_DETAIL_ADD_ITEM_PRICE = '値段';
let FOOD_DETAIL_ADD_ITEM_CATEGORY = 'カテゴリー';
let FOOD_DETAIL_ADD_ITEM_CATEGORY_SELECT = 'カテゴリーを選択する';
let FOOD_DETAIL_ADD_ITEM_DESCRIPTION = '商品の詳細';
let FOOD_DETAIL_ADD_CONFIRM = '登録する';
let FOOD_DETAIL_ADD_CANCEL = 'キャンセル';

// food delete dialog
let FOOD_DETAIL_DELETE = '商品削除';
let FOOD_DETAIL_DELETE_EXPLAIN = '商品を削除しますか？';
let FOOD_DETAIL_DELETE_CONFIRM = '削除';
let FOOD_DETAIL_DELETE_CANCEL = 'キャンセル';

// category delete dialog
let FOOD_CATEGORY_DELETE = 'カテゴリー削除';
let FOOD_CATEGORY_DELETE_EXPLAIN = 'カテゴリーを削除しますか?';
let FOOD_CATEGORY_DELETE_CONFIRM = '削除';
let FOOD_CATEGORY_DELETE_CANCEL = 'キャンセル';

// category add dialog
let FOOD_CATEGORY_ADD = 'カテゴリー追加';
let FOOD_CATEGORY_ADD_CONFIRM = '登録する';
let FOOD_CATEGORY_ADD_CANCEL = 'キャンセル';

// category edit dialog
let FOOD_CATEGORY_EDIT = 'カテゴリー編集';
let FOOD_CATEGORY_EDIT_CONFIRM = '保存する';
let FOOD_CATEGORY_EDIT_CANCEL = 'キャンセル';
let SHOW_FOOD = '';

let FOOD_SHOW = '公開';
let FOOD_HIDE = '未公開';

let SOLD_OUT = '品切れ';

let CATEGORY_DELETE_DONE = 'カテゴリー削除が完了しました';
let FOOD_DELETE_DONE = 'メニュー削除が完了しました';

export default {
  SELLING_STATUS,
  CUSTOMIZING_GROUP,
  FOOD_MENU_LOADING,
  SORRY_BUT_THE_FOOD_MENU_LIST_IS_EMPTY,

  MENU_MANAGEMENT_FOOD_CATEGORY_TITLE_IS_MISSING_ERROR,

  MENU_MANAGEMENT_ADD_NEW_FOOD_CATEGORY_DONE,
  MENU_MANAGEMENT_EDIT_FOOD_CATEGORY_DONE,

  SHOW_FOOD,

  FOOD_SHOW,
  FOOD_HIDE,
  SOLD_OUT,

  ADD_FOOD_DETAIL,
  EDIT_FOOD_DETAIL,
  FOOD_DETAIL_ADD_IMAGE,
  FOOD_DETAIL_ADD_IMAGE_APPENDIX,
  FOOD_DETAIL_ADD_ITEM_NAME,
  FOOD_DETAIL_ADD_ITEM_PRICE,
  FOOD_DETAIL_ADD_ITEM_CATEGORY,
  FOOD_DETAIL_ADD_ITEM_CATEGORY_SELECT,
  FOOD_DETAIL_ADD_ITEM_DESCRIPTION,
  FOOD_DETAIL_ADD_CONFIRM,
  FOOD_DETAIL_ADD_CANCEL,
  FOOD_DETAIL_DELETE,
  FOOD_DETAIL_DELETE_EXPLAIN,
  FOOD_DETAIL_DELETE_CONFIRM,
  FOOD_DETAIL_DELETE_CANCEL,
  FOOD_CATEGORY_DELETE,
  FOOD_CATEGORY_DELETE_EXPLAIN,
  FOOD_CATEGORY_DELETE_CONFIRM,
  FOOD_CATEGORY_DELETE_CANCEL,
  FOOD_CATEGORY_ADD,
  FOOD_CATEGORY_ADD_CONFIRM,
  FOOD_CATEGORY_ADD_CANCEL,
  FOOD_CATEGORY_EDIT,
  FOOD_CATEGORY_EDIT_CONFIRM,
  FOOD_CATEGORY_EDIT_CANCEL,

  CATEGORY_DELETE_DONE,
  FOOD_DELETE_DONE,
};
