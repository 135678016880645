import { colors } from '@material-ui/core';

const data_default = {
  datasets: [
    {
      backgroundColor: colors.indigo[500],
      data: [0],
      label: 'This year',
    },
  ],
  labels: [''],
};

export { data_default };
