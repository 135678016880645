import React from 'react';

import { listNotClearedServiceRequestAfterCutoffTime } from 'src/modals/service_requests';

import { GlobalContext } from 'src/contexts/Global';
import { ServiceRequestsAlertsContext } from 'src/contexts/ServiceRequestsAlerts';

import { RESTAURANT_OPEN_STATE_OPENED } from 'src/consts/RESTAURANT_OPEN_STATE';

import ShowDebug from 'src/components/ShowDebug';

export const MonitorServiceRequestContext = React.createContext();

export function MonitorServiceRequestContextProvider({ children }) {
  const { rest_id, restaurant_config } = React.useContext(GlobalContext);
  const [list_active_service_request, setListActiveServiceRequest] =
    React.useState(null);
  const [active_service_request_list, setActiveServiceRequestList] =
    React.useState({ list: [], len: 0 });
  const [last_alert_service_request_list, setLastAlertServiceRequestList] =
    React.useState({ list: [], len: 0 });

  const { showServiceAlert } = React.useContext(ServiceRequestsAlertsContext);

  React.useEffect(() => {
    // alert('opening function');
    const closingFunction = () => {
      // alert('closing function');
    };
    return closingFunction;
  }, []);

  React.useEffect(() => {
    let active_len = active_service_request_list.list.length;
    let last_active_len = last_alert_service_request_list.list.length;

    if (active_len > last_active_len && active_len > 0) {
      setLastAlertServiceRequestList(active_service_request_list);
      showServiceAlert();
    } else {
      setLastAlertServiceRequestList(active_service_request_list);
    }
  }, [active_service_request_list]);

  function monitorServiceRequest({ rest_id }) {
    return listNotClearedServiceRequestAfterCutoffTime(rest_id).onSnapshot(
      (qss) => {
        let a_d = [];

        qss.forEach((doc) => a_d.push({ id: doc.id, data: doc.data() }));

        setListActiveServiceRequest(
          a_d.map((d) => {
            return {
              id: d.id,
              table_name: d.data.table_name,
              update_time: d.data.update_time,
              username: d.data.username,
              _raw: d.data,
            };
          })
        );

        setActiveServiceRequestList({ list: a_d, len: a_d.length });
      }
    );
  }

  React.useEffect(() => {
    let unsubMonitor;

    function resetMonitor() {
      if (unsubMonitor) unsubMonitor();
    }

    if (rest_id && restaurant_config) {
      if (rest_id) {
        //MENY-426, keep showing remaining request when restaurant closed
        unsubMonitor = monitorServiceRequest({ rest_id });
      } else {
        // restaurant closed
        resetMonitor();
      }
    }

    let componentUnload = () => resetMonitor;

    return componentUnload;
  }, [rest_id, restaurant_config?.restaurant_open_state]);

  return (
    <>
      <MonitorServiceRequestContext.Provider
        value={{ hello: 'world', list_active_service_request }}
      >
        {/* <ShowDebug>{JSON.stringify(list_active_service_request)}</ShowDebug> */}
        {children}
      </MonitorServiceRequestContext.Provider>
    </>
  );
}
