import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import { Box, Button } from '@material-ui/core';

import { GlobalContext } from 'src/contexts/Global';

import active_lang from 'src/langs/jp_en';

import useMutateUpdateBillByOrderId from 'src/hooks/useMutateUpdateBillByOrderId';

import { acknowledgeServiceRequestByTableName } from 'src/modals/service_requests';

import ShowDebug from 'src/components/ShowDebug';

import { useStyles, Transition } from './styles';
import { isUndefined } from 'lodash';
import { dialog_transition_duration } from 'src/consts/TRANSITION';
import { useTranslation } from 'react-i18next';

const BILL_CLEARED = 'CLEARED';

export default function ConfirmClearBillDialog({
  open,
  setOpen,
  id,
  handleCloseBillDetail,
  table_name,
}) {
  const classes = useStyles();
  // const [open, setOpen] = React.useState(false);
  let { rest_id } = React.useContext(GlobalContext);
  const { t } = useTranslation();
  let [disable_button, setDisableButton] = React.useState(false);

  const handleClose = () => {
    if (handleCloseBillDetail != null) {
      handleCloseBillDetail();
    }
    setOpen(false);
  };

  const handleCancelClick = () => {
    handleClose();
  };

  let mutate_update_bill_by_order_id = useMutateUpdateBillByOrderId();
  const handleConfirmClearClick = () => {
    setDisableButton(true);
    mutate_update_bill_by_order_id
      .mutateAsync({
        rest_id,
        id,
        payload: { bill_status: BILL_CLEARED },
      })
      .then(() => {
        // try best to clear pending requests
        return isUndefined(table_name)
          ? ''
          : acknowledgeServiceRequestByTableName(rest_id, table_name);
      })
      .then(() => {
        handleClose();
      })
      .catch((err) => {
        console.error('handleConfirmClearClick', 'error', err);
      })
      .finally(() => {
        setDisableButton(false);
      });
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        transitionDuration={dialog_transition_duration}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        fullWidth
      >
        <Box className={classes.ConfirmDeleteDialogWrapper}>
          <Box className={classes.DialogTitle}>
            <Box>{t('BILL_PAYMENT_CONFIRM')}</Box>
          </Box>
          <Box className={classes.QuestionsWrapper}>
            <Box>{t('BILL_COMPLETE_PAYMENT_HINT')}</Box>
          </Box>

          <Box>
            <ShowDebug>
              {JSON.stringify({ table_name, rest_id }, null, 2)}
            </ShowDebug>
          </Box>

          <Box className={classes.DialogBottom}>
            <Button
              disabled={disable_button}
              onClick={handleCancelClick}
              className={`${classes.Buttons} ${classes.ButtonCancel}`}
            >
              {t('COMMON_CANCEL')}
            </Button>
            <Button
              disabled={disable_button}
              id="bill-detail-confirm-finish-bill"
              onClick={handleConfirmClearClick}
              className={`${classes.Buttons} ${classes.ButtonOK}`}
            >
              {t('COMMON_CONFIRM')}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
