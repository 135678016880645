// MENY-171, update to handle fullwidth to halfwidth with numbers
// https://www.unicode.org/charts/PDF/UFF00.pdf

import { isString } from 'lodash';
import { FwToHwNumeric } from './FwToHwNumeric';

let ASCII_MINUS = 45;
let ASCII_0 = 48;
let ASCII_9 = 57;
let ASCII_FW_0 = 65296;
let ASCII_FW_9 = 65305;
let ASCII_FW_COLON = 65306;

const sanitizeFullWidthNumber = (test_input) => {
  if (isString(test_input)) {
    return test_input
      .split('')
      .map((original_char) => {
        let char_code = original_char.charCodeAt();
        if (char_code >= ASCII_FW_0 && char_code <= ASCII_FW_9) {
          return FwToHwNumeric(original_char);
        }
        if (char_code >= ASCII_0 && char_code <= ASCII_9) {
          return original_char;
        }
        if (char_code === ASCII_MINUS) return original_char;
        if (char_code === ASCII_FW_COLON) return ':';

        return original_char.trim();
      })
      .join('')
      .trim();
  } else {
    return test_input;
  }
};

export { sanitizeFullWidthNumber };
