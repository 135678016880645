import React from 'react';
import {
  Box,
  makeStyles,
  FormControl,
  MenuItem,
  Select,
  withStyles,
  InputBase,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import active_lang from 'src/langs/jp_en';
import {
  LYNKED_RED,
  LYNKED_WHITE,
  LYNKED_GREEN,
  LYNKED_GREEN_HOVER,
  LYNKED_LIGHT_GREY,
  LYNKED_DEEP_GREY,
  LYNKED_DEEP_GREY_HOVER,
} from 'src/consts/colors';
import { select_values } from 'src/modals/advertisement_config';

import ShowDebug from 'src/components/ShowDebug';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  button_yes: {
    color: theme.palette.success.main,
  },
  button_no: {
    color: theme.palette.error.main,
  },
  TextInput: {
    border: '1px solid #E0E0E0',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 'bold',
    padding: '3px 3px',
    '& ::placeholder': {
      color: LYNKED_LIGHT_GREY,
    },
  },
  food_availability: {
    border: '1px solid #e2e2e2',
    padding: '0.5rem',
  },
  icon: {
    color: '#707070',
    marginRight: '1rem',
    marginTop: '0.25rem',
  },
  DialogBottomButtons: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  Buttons: {
    width: '48%',
    padding: '10px',
    color: 'white',
    fontSize: '11px',
    lineHeight: '15px',

    letterSpacing: '0.12px',
    borderRadius: '1px',
  },
  ButtonCancel: {
    backgroundColor: LYNKED_DEEP_GREY,
    '&:hover': {
      backgroundColor: LYNKED_DEEP_GREY_HOVER,
    },
  },
  ButtonOK: {
    backgroundColor: LYNKED_GREEN,
    '&:hover': {
      backgroundColor: LYNKED_GREEN_HOVER,
    },
  },
  AddImageButton: {
    color: LYNKED_RED,
  },
  AddImagePlaceHolder: {
    background: `${LYNKED_WHITE} 0% 0% no-repeat padding-box`,
    border: `1px dashed ${LYNKED_RED}`,
    borderRadius: '9px',

    width: '100%',
    minHeight: '300px',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  test_icon_style: {
    color: LYNKED_RED,
  },
}));

const AdActionInput = withStyles(() => ({}))(InputBase);

export default function SelectMenyAdConfigureSlot({ formik }) {
  let classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Box mt={'0.5rem'}>
        <FormControl fullWidth>
          <div style={{ fontWeight: 'bold' }}>{t('COMMON_ACTION')}</div>
          <Select
            id="form_ad_action"
            {...formik.getFieldProps('form_ad_action')}
            classes={{
              root: classes.food_availability,
              icon: classes.icon,
            }}
            input={<AdActionInput />}
            IconComponent={KeyboardArrowDownIcon}
            style={{ paddingTop: '0.5rem' }}
            onChange={(e) => {
              formik.setFieldValue('form_ad_destination', 'please_select');
              formik.handleChange(e);
            }}
          >
            <MenuItem value={select_values.ad_action.FOOD_MENU.name}>
              {t('COMMON_CATEGORY')}
              <ShowDebug>{'AD_FOOD_MENU'}</ShowDebug>
            </MenuItem>
            <MenuItem value={select_values.ad_action.FOOD_DETAIL.name}>
              {t('COMMON_MENU_DETAILS')}
              <ShowDebug>{'AD_FOOD_DETAIL'}</ShowDebug>
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    </>
  );
}
