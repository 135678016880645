import { makeStyles } from '@material-ui/core';

import { LYNKED_WHITE } from 'src/consts/colors';

const useStyles = makeStyles((theme) => ({
  helloworld: { height: '100vh' },
  ProfilePicCell: {
    backgroundColor: LYNKED_WHITE,

    textAlign: 'center',

    borderBottom: '1px solid #E0E0E0',
  },
  ProfilePicContainer: {
    [theme.breakpoints.up('xs')]: {
      height: '10vh',
    },
    [theme.breakpoints.up('lg')]: {
      height: '10vh',
    },
  },
  ProfilePic: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  ProfileEmail: {
    paddingTop: '0.1rem',
    font: 'normal normal bold 12px/16px Roboto',
    letterSpacing: '0.12px',
  },
  ProfileDebug: {
    font: 'normal normal normal 10px/18px Hiragino Sans',
  },
  ProfileRestaurantName: {
    paddingTop: '0.1rem',
    font: 'normal normal bold 12px/16px Roboto',
    letterSpacing: '0.12px',
  },
}));

export { useStyles };
