import React from 'react';
import { Box } from '@material-ui/core';

export default function AdImageThumbnail({ image_url }) {
  return (
    <>
      <Box
        style={{
          width: '100%',
          height: '100%',
          backgroundPosition: 'center',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${image_url})`,
        }}
      ></Box>
    </>
  );
}
