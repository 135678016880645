import React from 'react';
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';

import { MonitorOrderContext } from 'src/contexts/MonitorOrder';
import NavButton from 'src/components/NavButton';
import { StyledBadge } from 'src/components/RestaurantNavButtonColumn/NavButtons/styles';

import { useTranslation } from 'react-i18next';

let LOG_PREFIX = 'NavMenuOrders';

export default ({ handleCloseNavBar }) => {
  const { show_order_list, flattened_order_list } =
    React.useContext(MonitorOrderContext);
  const [number_active_order, setNumberActiveOrder] = React.useState(null);
  const { t } = useTranslation();

  React.useEffect(() => {
    console.log(LOG_PREFIX, 'show_order_list', show_order_list);
    console.log(
      LOG_PREFIX,
      'flattened_order_list',
      flattened_order_list.orders.length
    );
    if (show_order_list && flattened_order_list) {
      setNumberActiveOrder(flattened_order_list.orders.length);
    } else {
      setNumberActiveOrder(null);
    }
  }, [show_order_list]);

  if (!show_order_list) return <></>;

  return (
    <>
      <NavButton
        text={t('SIDEMENU_ORDER_MANAGE')}
        href={`/app/orders`}
        icon={
          <StyledBadge badgeContent={number_active_order}>
            <FormatListBulletedOutlinedIcon />
          </StyledBadge>
        }
        id="nav-orders"
        handleCloseNavBar={handleCloseNavBar}
      />
    </>
  );
};
