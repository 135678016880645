import React from 'react';

import { Box, Button, makeStyles, Grid } from '@material-ui/core';

export default function EmptyPage() {
  return (
    <>
      <Box>EmptyPage</Box>
    </>
  );
}
