import db from '../Firebase/db';

function getMenyManageUsageStatistics(rest_id) {
  return db
    .collection('meny_manage_usage_statistics')
    .doc(rest_id)
    .collection('usage_statistics')
    .get();
}

export { getMenyManageUsageStatistics };
