import React from 'react';
import { Box, makeStyles, Grid } from '@material-ui/core';

import NumberOfTablesInput from 'src/components/NumberOfTablesInput';

import { GlobalContext } from 'src/contexts/Global';
import URLS from 'src/consts/URLS';
import active_lang from 'src/langs/jp_en';

import { useDebounce } from 'src/hooks/useDebounce';
import { sanitizeFullWidthNumber } from 'src/utils/sanitizeFullWidthNumber';
import { useTranslation } from 'react-i18next';
import GridQR from './GridQR';

const useStyles = makeStyles(() => ({ root: { flexGrow: 1 } }));

export default function QRGeneratorMenyLight() {
  const classes = useStyles();
  const { rest_id } = React.useContext(GlobalContext);
  const { t } = useTranslation();

  let menu_id = rest_id;
  const [number_of_tables, setNumberOfTables] = React.useState(10);

  const getMenyLightLink = (table_num) => {
    return `${client_meny_light_link}/${rest_id}/${menu_id}/table_${table_num}`;
  };

  // client
  const client_meny_light_link =
    process.env.NODE_ENV === 'development'
      ? `http://${process.env.REACT_APP_MENY_CLIENT_TEST_HOST}`
      : URLS.MENY_APP_URL;

  const restaurant_link = 'https://meny-manage.menymeny.com';

  const debouncedSearchTerm = useDebounce(number_of_tables, 100);

  return (
    <>
      <Box>
        <Grid container className={classes.root}>
          <Grid item xs></Grid>
          <Grid item xs={6} justify="center">
            <form
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  document.activeElement.blur();
                }
              }}
            >
              <NumberOfTablesInput
                onChange={(e) => {
                  let saintized_table_number = sanitizeFullWidthNumber(
                    e.target.value
                  );

                  setNumberOfTables(
                    Math.min(100, Math.max(0, saintized_table_number))
                  );
                }}
                value={number_of_tables}
              />
            </form>
          </Grid>
          <Grid item xs></Grid>
        </Grid>

        <Box mt={'3rem'}>
          <Grid container className={classes.root}>
            <Grid item xs={12}>
              <Grid container justify="center" spacing={8}>
                <GridQR
                  link={restaurant_link}
                  text={t('QR_RESTAURANT_LOG_PAGE')}
                  id_of_qr={`meny_light_restaurant_login_page`}
                />

                {Array(debouncedSearchTerm)
                  .fill(null)
                  .map((_, idx) => {
                    let table_num = idx + 1;
                    return (
                      <>
                        <GridQR
                          link={getMenyLightLink(table_num)}
                          text={`${t('QR_TABLE')}${table_num}`}
                          id_of_qr={`meny_light_table_${table_num}`}
                        />
                      </>
                    );
                  })}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
