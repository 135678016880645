import React, { useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import initialData from './initial-data';

import Column from './column';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'gold',
  },
}));

function App({
  food_menu,
  setFoodMenu,
  handleRemoveFoodCategory,
  handleUpdateFoodCategoryTitle,
  loadFoodDetail,
  processDeleteFoodDetail,
  addFoodCategory,
  handleOpenAddNewFoodDialogPrefillCategory,
  handleSaveFoodDetailClick,
  handleFoodCategoriesChange,
  food_categories,
}) {
  const classes = useStyles();

  const [stateInitialData, setStateInitialData] = useState(initialData);

  const [columnOrder, setColumnOrder] = useState([]);
  const [columns, setColumns] = useState();
  // const [tasks, setTasks] = useState(stateInitialData.tasks);
  const [food_details, setFoodDetails] = useState();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  React.useEffect(() => {
    console.log('food_menu', food_menu);
    if (food_menu != null) {
      if (Object.keys(food_menu).includes('columnOrder')) {
        setColumnOrder(food_menu.columnOrder);
      }
      if (Object.keys(food_menu).includes('columns')) {
        setColumns(food_menu.columns);
      }
      if (Object.keys(food_menu).includes('food_details')) {
        setFoodDetails(food_menu.food_details);
      }
    }
  }, [food_menu]);

  React.useEffect(() => {
    setFoodMenu({
      // tasks: tasks,
      columns: columns,
      columnOrder: columnOrder,
      food_details: food_details,
    });
  }, [columnOrder, columns, food_details]);

  function onDragStart() {
    console.log('onDragStart');
    return;
  }

  function onDragEnd(result) {
    document.body.style.color = 'inherit';
    const { destination, source, draggableId, type } = result;

    // dropping an item to column itself cause destination == null
    if (!destination) {
      return;
    }

    // blocking for same food already in target category
    if (type == 'column') {
    } else {
      // alert(JSON.stringify(source, null, 2));

      let destination_columns = destination.droppableId;
      let source_columns = source.droppableId;

      if (source_columns == destination_columns) {
        // re-order occur inside same category
      } else {
        let dragged_food_detail_id = draggableId.split('_')[1];
        let blocking_food_detail_ids = columns[destination_columns].taskIds.map(
          (taskId) => taskId.split('_')[1]
        );
        // lookup blocking list
        // alert(JSON.stringify(columns[dest_columns].blocking, null, 2));
        // droppabledId: Cat0
        // alert(JSON.stringify(destination, null, 2));
        // Cat0_FoodDetail0
        // alert(JSON.stringify(draggableId, null, 2));
        // alert(JSON.stringify(blocking_food_detail_ids, null, 2));
        // alert(dragged_food_detail_id);

        if (blocking_food_detail_ids.includes(dragged_food_detail_id)) {
          enqueueSnackbar(
            'ターゲットが同じ食べ物を得たのでキャンセルされました ',
            { variant: 'warning' }
          );

          return;
        }
      }
    }

    try {
      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }
    } catch (error) {
      console.log(destination);
    }
    // NOTE: check if the position changed

    console.log('type', type);

    if (type === 'column') {
      const newColumnOrder = Array.from(columnOrder);
      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, draggableId);

      setColumnOrder(newColumnOrder);
      return;
    }

    const start = columns[source.droppableId];
    const finish = columns[destination.droppableId];

    if (true) {
      // compare the start column and the finish column
      if (start === finish) {
        const newTaskIds = Array.from(start.taskIds);

        // NOTE: remove old item
        newTaskIds.splice(source.index, 1);
        // NOTE: insert into new array
        newTaskIds.splice(destination.index, 0, draggableId);

        const newColumn = {
          ...start,
          taskIds: newTaskIds,
        };

        setColumns({
          ...columns,
          [newColumn.id]: newColumn,
        });

        return;
      }

      // NOTE: consider the case, move away from start column and drop into the finish column
      // Moving from one list to another
      const startTaskIds = Array.from(start.taskIds);
      // NOTE: move away from start column
      startTaskIds.splice(source.index, 1);
      const newStart = {
        ...start,
        taskIds: startTaskIds,
      };

      const finishTaskIds = Array.from(finish.taskIds);
      // NOTE: drop into finish column
      finishTaskIds.splice(destination.index, 0, draggableId);
      const newFinish = {
        ...finish,
        taskIds: finishTaskIds,
      };

      setColumns({
        ...columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      });
    }
  }

  return (
    <>
      {/* <pre>{JSON.stringify(food_menu, null, 2)}</pre> */}
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        <Droppable droppableId="all-columns" direction="vertical" type="column">
          {(provided) => {
            return (
              <>
                <Box
                  className={classes.container}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {columnOrder.map((columnId, index) => {
                    const column = columns[columnId];

                    // TODO: look back here
                    // const temp_tasks = column.taskIds.map((taskId) => tasks[taskId]);
                    // return <Column key={column.id} column={column} tasks={temp_tasks} index={index} food_details={food_details} />;

                    const temp_tasks = column.taskIds.map((taskId) => {
                      let food_detail_id = taskId.split('_').slice(0)[1];
                      return food_details[food_detail_id];
                    });
                    return (
                      <>
                        <Column
                          key={column.id}
                          column={column}
                          tasks={temp_tasks}
                          index={index}
                          category_id={column.id}
                          food_details={food_details}
                          handleRemoveFoodCategory={handleRemoveFoodCategory}
                          handleUpdateFoodCategoryTitle={
                            handleUpdateFoodCategoryTitle
                          }
                          loadFoodDetail={loadFoodDetail}
                          processDeleteFoodDetail={processDeleteFoodDetail}
                          addFoodCategory={addFoodCategory}
                          handleOpenAddNewFoodDialogPrefillCategory={
                            handleOpenAddNewFoodDialogPrefillCategory
                          }
                          columnOrder={columnOrder}
                          columns={columns}
                          setColumns={setColumns}
                          handleFoodCategoriesChange={
                            handleFoodCategoriesChange
                          }
                          food_categories={food_categories}
                        />
                      </>
                    );
                  })}
                  {provided.placeholder}
                </Box>
              </>
            );
          }}
        </Droppable>
      </DragDropContext>
    </>
  );
}

export default React.memo(App);
