import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Grid, Button } from '@material-ui/core';

import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';

import { MonitorLineupContext } from 'src/contexts/MonitorLineup';
import { LYNKED_LIGHT_GREY, LYNKED_RED } from 'src/consts/colors';
import active_lang from 'src/langs/jp_en';
import { UtilContext } from 'src/contexts/Util';
import { useTranslation } from 'react-i18next';
import ReservationRequestList from './ReservationRequestList';

export default () => {
  let { line_up_list } = React.useContext(MonitorLineupContext);
  const { t } = useTranslation();

  let {
    device_screen,
    SCREEN_DESKTOP,
    // screen_height,
    SCREEN_IPAD_LANDSCAPE,
    SCREEN_IPAD_PORTRAIT,
    SCREEN_IPAD_PRO_PORTRAIT,
    // screen_width,
  } = React.useContext(UtilContext);

  let [max_card_len, setMaxCardLen] = React.useState(1);

  // 1. For windows, it is discussed in last discussion that help request and reception should only show 2 maximum. (current behavior, it show more than 2)
  // 2. For ipad, portrait mode should only show 1 help request and reception, landscape mode same as widows, show 2 maximum (current behavor, it show more than 2)

  React.useEffect(() => {
    switch (device_screen) {
      case SCREEN_DESKTOP:
        setMaxCardLen(2);
        break;
      case SCREEN_IPAD_PRO_PORTRAIT:
        setMaxCardLen(1);
        break;
      case SCREEN_IPAD_LANDSCAPE:
        setMaxCardLen(2);
        break;
      case SCREEN_IPAD_PORTRAIT:
        setMaxCardLen(1);
        break;
      default:
        setMaxCardLen(1);
        break;
    }
  }, [device_screen]);

  return (
    <>
      <Box style={{ padding: '0.1rem' }}>
        <Grid container>
          <Grid
            item
            xs={12}
            container
            style={{
              paddingTop: '1rem',
              paddingLeft: '1rem',
              paddingRight: '1rem',
              paddingBottom: '0.5rem',
            }}
          >
            <Grid
              item
              xs={6}
              style={{ display: 'inline-flex', justifyContent: 'flex-start' }}
            >
              <Box
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: LYNKED_LIGHT_GREY,
                }}
              >
                <NotificationsActiveIcon style={{ height: '1rem' }} />
                {t('COMMON_RECEPTION_LIST')}
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ display: 'inline-flex', justifyContent: 'flex-end' }}
            >
              <Button component={RouterLink} to="/app/reception_list">
                <Box
                  style={{
                    display: 'flex',
                    flexFlow: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: LYNKED_RED,
                  }}
                >
                  {t('COMMON_SEE_ALL')}
                  <ChevronRightOutlinedIcon style={{ height: '1rem' }} />
                </Box>
              </Button>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            container
            style={{
              paddingLeft: '1rem',
              paddingRight: '1rem',
              paddingBottom: '0.25rem',
            }}
          >
            <Box
              style={{
                width: '100%',
                overflowX: 'auto',
                paddingTop: '0.25rem',
              }}
            >
              <ReservationRequestList
                max_card_len={max_card_len || 2}
                line_up_list={line_up_list}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
