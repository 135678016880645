import React from 'react';
import { Box, Grid } from '@material-ui/core';

import Page from 'src/components/Page';
import ReceptionList from 'src/components/ReceptionList';
import AssignedTablesList from 'src/components/AssignedTablesList';

import { useStyles } from './styles';

export default function ReceptionListPage() {
  const classes = useStyles({
    current_client_height: window.innerHeight,
  });

  return (
    <>
      <Page className={classes.root}>
        <Box className={classes.ListContainer}>
          <Grid container className={classes.SubListContainer}>
            <Grid item xs={12} md={6}>
              <Box style={{ paddingTop: '1rem', paddingLeft: '1rem' }}>
                <ReceptionList />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} className={classes.NavBodyMainRight}>
              <Box style={{ paddingTop: '1rem', paddingLeft: '1rem' }}>
                <AssignedTablesList />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Page>
    </>
  );
}
