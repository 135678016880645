import React from 'react';

import { styled, Box, TextField, Button, makeStyles, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import {
  LYNKED_RED,
  LYNKED_RED_HOVER,
  LYNKED_WHITE,
  LYNKED_BLUE,
  LYNKED_BLUE_HOVER,
  LYNKED_GREEN,
  LYNKED_GREEN_HOVER,
  LYNKED_LIGHT_GREY,
  LYNKED_LIGHT_GREY_HOVER,
  LYNKED_DEEP_GREY,
  LYNKED_DEEP_GREY_HOVER,
  LYNKED_BLACK,
  LYNKED_BLACK_HOVER,
  LYNKED_WHITE_HOVER,
} from 'src/consts/colors';

const useStyles = makeStyles((theme) => ({
  helloworld: {},
  EmailInput: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    // boxShadow: '0px 1px 2px #00000029',
    border: '1px solid #B3B3B3',
    width: '100%',
    height: '45px',
    backgroundColor: LYNKED_WHITE,
  },
  TextInput: {
    // backgroundColor: 'gold',
    margin: '0 1rem',
    textAlign: 'left',
  },
}));

export default function EmailInput() {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.EmailInput}>
        <TextField
          fullWidth
          className={classes.TextInput}
          placeholder="メールアドレス"
          InputProps={{ disableUnderline: true }}
          // value="TABLE NUMBER"
        />
      </Box>
    </>
  );
}
