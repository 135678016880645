import React from 'react';

export default function ShowDebug({ children }) {
  return (
    <>
      <div
        style={{
          display: process.env.NODE_ENV == 'development' ? 'block' : 'none',
          backgroundColor: 'gold',
        }}
      >
        {children}
      </div>
    </>
  );
}
