/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import { isNull, isUndefined } from 'lodash';
import { Button, Grid, Box } from '@material-ui/core';

import {
  LYNKED_WHITE,
  LYNKED_GREEN,
  LYNKED_DEEP_GREY,
} from 'src/consts/colors';
import active_lang from 'src/langs/jp/settings';
import useMutateUpdateRestaurantConfig from 'src/hooks/useMutateUpdateRestaurantConfig';
import {
  ENQUEUE_SUCCESS_CONFIG,
  ENQUEUE_WARNING_CONFIG,
} from 'src/consts/ENQUEUE_CONFIG';
import { validateRestaurantConfig } from 'src/validator/time_picker_input';
import { SERVICE_MENY, SERVICE_MENY_LIGHT } from 'src/consts/MENY_SERVICE';

import OpeningHoursConfigForm from '../OpeningHoursConfigForm';
import LineupPageSettingsForm from '../LineupPageSettingsForm';
import TranslationForm from '../TranslationForm';
import ShowDebug from 'src/components/ShowDebug';
import { toTzOpeningSchedule } from 'src/utils/TimezoneFormat';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
const LOG_PREFIX = 'MainSettingsForm';

export default ({ rest_id, current_setting, meny_service_class }) => {
  const { enqueueSnackbar } = useSnackbar();
  let mutate_update_restaurant_config = useMutateUpdateRestaurantConfig();
  const { t } = useTranslation();

  let [enable_lineup_setting_form, setEnableLineupSettingForm] =
    React.useState(false);
  let [enable_opening_hour_setting_form, setEnableOpeningHourSettingForm] =
    React.useState(false);

  React.useEffect(() => {
    if (isNull(meny_service_class) || isUndefined(meny_service_class)) {
    } else {
      if (meny_service_class.id === SERVICE_MENY_LIGHT) {
        // meny_light
        setEnableLineupSettingForm(false);
        setEnableOpeningHourSettingForm(true);
      } else if (meny_service_class.id === SERVICE_MENY) {
        // meny
        setEnableLineupSettingForm(true);
        setEnableOpeningHourSettingForm(true);
      } else {
      }
    }
  }, [meny_service_class]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: current_setting,

    validate: (values) => {
      try {
        validateRestaurantConfig.validateSync(values, { abortEarly: false });
      } catch (error) {
        // consider: validation error
        console.log(LOG_PREFIX, 'input invalid', { values });
        let output = {};

        error.inner.forEach(({ path, errors }) => {
          let day_of_week = path.split('.')[1];
          output = {
            ...output,
            opening_schedule: {
              ...output.opening_schedule,
              [`${day_of_week}`]: errors,
            },
          };
        });

        return output;
      }
    },
    onSubmit: (values, { setSubmitting }) => {
      console.log(LOG_PREFIX, { values });

      // ignore update of restaurant_open_state by changing setting
      delete values.advertisement_tags;
      delete values.enable_rest_ad_control;
      delete values.meny_service_class;
      delete values.name;
      delete values.restaurant_open_state;
      delete values.restaurant_operators;
      delete values.restaurant_profile_pic;
      delete values.status;
      delete values.take_away_enabled;

      values.opening_schedule = toTzOpeningSchedule(
        values.opening_schedule,
        values?.timezone
      );
      mutate_update_restaurant_config
        .mutateAsync({
          rest_id,
          rest_config: {
            ...values,
            lineup: {
              ...values.lineup,
              lineup_table_placeholder: current_setting.isSkippableToAssignTable
                ? values.lineup.lineup_table_placeholder
                : '',
            },
          },
        })
        .then(() => {
          enqueueSnackbar(t('COMMON_UPDATING'), ENQUEUE_WARNING_CONFIG);
        })
        .then(() => {
          enqueueSnackbar(t('COMMON_UPDATED'), ENQUEUE_SUCCESS_CONFIG);
        })
        .then(() => formik.resetForm({ values }))
        .catch((err) => console.error(LOG_PREFIX, err))
        .finally(() => setSubmitting(false));
    },
  });

  return (
    <>
      <form
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            formik.setSubmitting(true);
            formik.handleSubmit();
            document.activeElement.blur();
          }
        }}
      >
        <Box style={{ width: '100%', paddingBottom: '5rem' }}>
          <Grid container>
            <Grid item xs={12}>
              <Box mt={3}>
                <TranslationForm formik={formik} />
              </Box>
            </Grid>
            {enable_lineup_setting_form ? (
              <Grid item xs={12}>
                <Box mt={3}>
                  <LineupPageSettingsForm
                    formik={formik}
                    current_setting={current_setting}
                  />
                </Box>
              </Grid>
            ) : (
              <></>
            )}

            {enable_opening_hour_setting_form ? (
              <Grid item xs={12}>
                <Box mt={3}>
                  <OpeningHoursConfigForm formik={formik} />
                </Box>
              </Grid>
            ) : (
              <></>
            )}

            <Grid item xs={12}>
              <Box
                mt={'3rem'}
                mb={'3rem'}
                style={{
                  display: 'flex',
                  flexFlow: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  id="save_settings"
                  type="button"
                  style={{
                    backgroundColor: formik.isSubmitting
                      ? LYNKED_DEEP_GREY
                      : LYNKED_GREEN,
                    color: LYNKED_WHITE,
                  }}
                  disabled={formik.isSubmitting}
                  onClick={formik.handleSubmit}
                >
                  {t('SETTINGS_SAVE')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </form>
      <ShowDebug>{JSON.stringify(current_setting)}</ShowDebug>
    </>
  );
};
