import React, { useContext } from 'react';
import firebase from 'firebase/app';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Box, TextField, Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { GlobalContext } from 'src/contexts/Global';
import { AuthContext } from 'src/Firebase/context';

import active_lang from 'src/langs/jp_en';
import { REST_MNGT_ENDPOINT } from 'src/config';
import { useStyles } from './styles';
import { isUndefined } from 'lodash';
import { useTranslation } from 'react-i18next';
import app from 'src/Firebase/config';
import { RestaurantActiveContext } from 'src/contexts/RestaurantActiveContext';

export default function LoginForm() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { updateUser } = useContext(AuthContext);
  const { setRestInfo, resetApplicationState } = useContext(GlobalContext);
  const { _restaurant_active, setRestaurantActive } = React.useContext(
    RestaurantActiveContext
  );

  return (
    <>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(t('LOGIN_VALID_EMAIL'))
            .max(255)
            .required('LOGIN_REQUIRED_EMAIL'),
          password: Yup.string()
            .max(255)
            .required('LOGIN_REQUIRED_PASSWORD'),
        })}
        onSubmit={async (values) => {
          // await new Promise((r) => setTimeout(r, 500));
          console.log('onSubmit');

          let email = values.email;
          let password = values.password;

          return await firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then((user) => {
              console.log('signInWithEmailAndPassword', 'user', user);
              updateUser(user);
              return user;
            })
            .then((user) =>
              fetch(
                `${REST_MNGT_ENDPOINT}/checkRestInfo?user_id=${user.user.uid}`
              )
            )
            .then((res) => res.json())
            .then((res_json) => {
              console.log(`LoginForm`, res_json);
              if (isUndefined(res_json.restaurant_active)) {
                navigate('/app', { replace: true });
              } else {
                if (res_json.restaurant_active === true) {
                  setRestInfo(res_json);
                  setRestaurantActive(true);

                  navigate('/app', { replace: true });
                } else {
                  app
                    .auth()
                    .signOut()
                    .then(() => {
                      enqueueSnackbar(
                        t('LOGIN_RESTAURANT_NOT_ACTIVE'),
                        {
                          variant: 'error',
                        }
                      );
                      resetApplicationState();
                    })
                    .then(() => navigate('/login'));
                }
              }
            })
            .catch((error) => {
              // user cannot verified
              var errorCode = error.code;
              var errorMessage = error.message;

              enqueueSnackbar(t('LOGIN_EMAIL_PASSWORD_INCORRECT'), { variant: 'error' });
              console.error(
                'signInWithEmailAndPassword',
                'errorCode',
                errorCode
              );
              console.error(
                'signInWithEmailAndPassword',
                'errorMessage',
                errorMessage
              );
            });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            className={classes.login_form}
            name="login-form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <Box className={classes.loginInputText}>
              {/* <LoginTextField id="email" placeholder="email" size="small" /> */}
              {/* <EmailInput /> */}
              <Box className={classes.EmailInput}>
                <TextField
                  id="email"
                  className={classes.TextInput}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && t(errors.email)}
                  placeholder={t('LOGIN_EMAIL_ADDRESS')}
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  InputProps={{ disableUnderline: true }}
                />
              </Box>
            </Box>
            <Box className={classes.loginInputPasswordText}>
              <Box className={classes.EmailInput}>
                {/* <PasswordInput /> */}
                <TextField
                  id="password"
                  className={classes.TextInput}
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && t(errors.password)}
                  placeholder={t('LOGIN_PASSWORD')}
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  InputProps={{ disableUnderline: true }}
                />
              </Box>
            </Box>
            <Box>
              <Button
                id="login_button"
                className={classes.loginInputButton}
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                {t('LOGIN_TITLE')}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
}
