import React from 'react';
import { Select, MenuItem, FormControl, NativeSelect } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import LanguageIcon from '@material-ui/icons/Language';
import { LANG_DISPLAY_KEY, LANGUAGES, SITES } from '../consts/language';
import { makeStyles } from '@material-ui/core/styles';
import useGetSourceLang from 'src/hooks/useGetSourceLang';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import {
  LYNKED_DEEP_GREEN,
  LYNKED_BLACK_HOVER,
  LYNKED_WHITE,
  LYNKED_BLACK,
} from 'src/consts/colors';
const useStyles = makeStyles({
  selectIcon: {
    color: 'white',
    fontSize: '20px',
    top: 'unset',
  },
  select: {
    '& ul': {
      padding: 0,
    },
    '& li': {
      color: LYNKED_WHITE,
      display: 'flex',
      justifyContent: 'center',
    },
    '& li:hover': {
      backgroundColor: 'rgba(0,0,0,0.2)',
    },
    '& .Mui-selected': {
      backgroundColor: LYNKED_BLACK,
    },
    '& .Mui-selected:hover': {
      backgroundColor: LYNKED_BLACK,
    },
    '&': {
      left: 'initial !important',
      right: '90px',
      backgroundColor: LYNKED_DEEP_GREEN,
    },
  },
});

export default function LanguageDropdown({ paperClass }) {
  const { i18n } = useTranslation();
  const { data } = useGetSourceLang(SITES.CMS);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleChangeOption = (event) => {
    i18n.changeLanguage(event.target.value);
    localStorage.setItem(LANG_DISPLAY_KEY, event.target.value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const menuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
      <LanguageIcon onClick={handleOpen} style={{ cursor: 'pointer' }} />
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <Select
          IconComponent={KeyboardArrowDownIcon}
          onChange={handleChangeOption}
          style={{ color: 'white' }}
          value={i18n.language}
          onClose={handleClose}
          onOpen={handleOpen}
          variant="standard"
          disableUnderline
          open={open}
          classes={{
            icon: classes.selectIcon,
          }}
          MenuProps={{
            classes: {
              paper: `${classes.select} ${paperClass}`,
            },
            ...menuProps,
          }}
        >
          {Object.keys(data || {}).map((key) => (
            <MenuItem key={key} value={key}>
              {data[key].KEY_VALUE}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
