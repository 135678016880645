import React from 'react';
import { useMutation } from 'react-query';
import { getNewAdvertisementId } from 'src/modals/advertisement_config';

// useMutateNewAdvertisementId
export default function MutateNewAdvertisementId() {
  const mutation = useMutation(
    'get-new-advertisement-id',
    () => {
      return getNewAdvertisementId();
    },
    {
      retry: 10,
    }
  );

  return mutation;
}
