import React from 'react';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  withStyles,
  InputBase,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import active_lang from 'src/langs/jp_en';

import { useStyles } from './styles';

import { select_values } from 'src/modals/advertisement_config';

import ShowDebug from 'src/components/ShowDebug';
import { useTranslation } from 'react-i18next';

const AdWindowInput = withStyles(() => ({}))(InputBase);

function SelectMenyLightAdConfigureSlotFoodDetailOnly({
  formik,
  advertisement_result,
}) {
  let classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Select
        id="form_ad_window"
        {...formik.getFieldProps('form_ad_window')}
        classes={{
          root: classes.food_availability,
          icon: classes.icon,
        }}
        input={<AdWindowInput />}
        IconComponent={KeyboardArrowDownIcon}
        style={{ paddingTop: '0.5rem' }}
        onChange={(e) => {
          switch (e.target.value) {
            case select_values.ad_window.FOOD_MENU.name:
              formik.setFieldValue('form_ad_destination', 'please_select');
              break;
            case select_values.ad_window.FOOD_DETAIL.name:
              formik.setFieldValue('form_ad_destination', 'food_detail');
              break;
            default:
              formik.setFieldValue('form_ad_destination', 'please_select');
              break;
          }

          formik.handleChange(e);
        }}
      >
        <MenuItem value={select_values.ad_window.FOOD_DETAIL.name} style={{fontWeight: 'bold'}}>
          {t('COMMON_MENU_DETAILS')}
          <ShowDebug>{'AD_FOOD_DETAIL'}</ShowDebug>
        </MenuItem>
      </Select>
    </>
  );
}

function SelectMenyLightAdConfigureSlotFoodMenuOnly({
  formik,
  advertisement_result,
}) {
  let classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Select
        id="form_ad_window"
        {...formik.getFieldProps('form_ad_window')}
        classes={{
          root: classes.food_availability,
          icon: classes.icon,
        }}
        input={<AdWindowInput />}
        IconComponent={KeyboardArrowDownIcon}
        style={{ paddingTop: '0.5rem' }}
        onChange={(e) => {
          switch (e.target.value) {
            case select_values.ad_window.FOOD_MENU.name:
              formik.setFieldValue('form_ad_destination', 'please_select');
              break;
            case select_values.ad_window.FOOD_DETAIL.name:
              formik.setFieldValue('form_ad_destination', 'food_detail');
              break;
            default:
              formik.setFieldValue('form_ad_destination', 'please_select');
              break;
          }

          formik.handleChange(e);
        }}
      >
        <MenuItem value={select_values.ad_window.FOOD_MENU.name}>
          {t('COMMON_MENU')}
          <ShowDebug>{'AD_FOOD_MENU'}</ShowDebug>
        </MenuItem>
      </Select>
    </>
  );
}

function SelectMenyAdConfigureSlotFoodMenuAndFoodDetail({
  formik,
  advertisement_result,
}) {
  let classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Select
        id="form_ad_window"
        {...formik.getFieldProps('form_ad_window')}
        classes={{
          root: classes.food_availability,
          icon: classes.icon,
        }}
        input={<AdWindowInput />}
        IconComponent={KeyboardArrowDownIcon}
        style={{ paddingTop: '0.5rem' }}
        onChange={(e) => {
          switch (e.target.value) {
            case select_values.ad_window.FOOD_MENU.name:
              formik.setFieldValue('form_ad_destination', 'please_select');
              break;
            case select_values.ad_window.FOOD_DETAIL.name:
              formik.setFieldValue('form_ad_destination', 'food_detail');
              break;
            default:
              formik.setFieldValue('form_ad_destination', 'please_select');
              break;
          }

          formik.handleChange(e);
        }}
      >
        <MenuItem value={select_values.ad_window.FOOD_MENU.name}>
          {t('COMMON_MENU')}
          <ShowDebug>{'AD_FOOD_MENU'}</ShowDebug>
        </MenuItem>
        <MenuItem value={select_values.ad_window.FOOD_DETAIL.name}>
          {t('COMMON_MENU_DETAILS')}
          <ShowDebug>{'AD_FOOD_DETAIL'}</ShowDebug>
        </MenuItem>
      </Select>
    </>
  );
}

function SelectMenyLightAdConfigureSlotFiltered({
  formik,
  advertisement_result,
}) {
  switch (advertisement_result.data.length) {
    case 1:
      if (advertisement_result.data[0].data.ad_window.name == 'food_menu') {
        return (
          <SelectMenyLightAdConfigureSlotFoodDetailOnly
            formik={formik}
            advertisement_result={advertisement_result}
          />
        );
      }
      if (advertisement_result.data[0].data.ad_window.name == 'food_detail') {
        return (
          <>
            <SelectMenyLightAdConfigureSlotFoodMenuOnly
              formik={formik}
              advertisement_result={advertisement_result}
            />
          </>
        );
      }

    default:
      return (
        <>
          <SelectMenyAdConfigureSlotFoodMenuAndFoodDetail
            formik={formik}
            advertisement_result={advertisement_result}
          />
        </>
      );
  }
}

export default function SelectMenyLightAdConfigureSlot({
  formik,
  advertisement_result,
}) {
  let classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Box mt={'0.5rem'}>
        <FormControl fullWidth>
          <div>{t('COMMON_BANNER_POSITION')}</div>
          <SelectMenyLightAdConfigureSlotFiltered
            formik={formik}
            advertisement_result={advertisement_result}
          />
        </FormControl>
      </Box>
    </>
  );
}
