import React from 'react';

import { Box, TextField, makeStyles } from '@material-ui/core';

import devices_configs from 'src/configs/devices';

import { LYNKED_WHITE } from 'src/consts/colors';

const useStyles = makeStyles((theme) => ({
  helloworld: {},
  EmailInput: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    // boxShadow: '0px 1px 2px #00000029',
    border: '1px solid #B3B3B3',
    width: '100%',
    height: '45px',
    backgroundColor: LYNKED_WHITE,
  },
  TextInput: {
    // backgroundColor: 'gold',
    margin: '0 1rem',
    textAlign: 'left',
  },
}));

export default function NumberOfTablesInput({ onChange, value }) {
  const classes = useStyles();

  const handleInputOnBlur = () => {
    devices_configs.forceScreenScrollUp();
  };

  return (
    <>
      <Box className={classes.EmailInput}>
        <TextField
          onBlur={(e) => {
            handleInputOnBlur(e);
          }}
          fullWidth
          className={classes.TextInput}
          placeholder="number of tables"
          InputProps={{ disableUnderline: true }}
          onChange={onChange}
          value={value}
          type="number"
        />
      </Box>
    </>
  );
}
