import { isUndefined } from 'lodash';

import {
  AD_ACTION_JUMP_TO_CATEGORY,
  AD_ACTION_JUMP_TO_FOOD_DETAIL,
} from 'src/consts/AD_ACTION';
import lookupFoodNameByFoodDetailId from './lookupFoodNameByFoodDetailId';
import lookupFoodCategoryTitleByCatId from './lookupFoodCategoryTitleByCatId';

function preSelectAdDestinationByValue(
  food_details,
  destination_type,
  destination_value
) {
  let sanitized_ad_destination = '';
  switch (destination_type) {
    case AD_ACTION_JUMP_TO_CATEGORY:
      if (
        isUndefined(destination_value) ||
        isUndefined(destination_value.cat_id) ||
        isUndefined(
          lookupFoodCategoryTitleByCatId(food_details, destination_value.cat_id)
        )
      ) {
        sanitized_ad_destination = 'please_select';
      } else {
        sanitized_ad_destination = destination_value.cat_id;
      }
      break;
    case AD_ACTION_JUMP_TO_FOOD_DETAIL:
      if (
        // MENY-222,tryout3 -> tryout4 fix first issue
        isUndefined(destination_value) ||
        isUndefined(destination_value.food_detail_id) ||
        isUndefined(
          lookupFoodNameByFoodDetailId(
            food_details,
            destination_value.food_detail_id
          )
        )
      ) {
        sanitized_ad_destination = 'please_select';
      } else {
        sanitized_ad_destination = destination_value.food_detail_id;
      }
      break;
    default:
      break;
  }
  return sanitized_ad_destination;
}

export { preSelectAdDestinationByValue };
