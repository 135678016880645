import React from 'react';
import { SnackbarProvider } from 'notistack';
import { isDevelopment } from 'src/utils/isDevelopment';
import { Button } from '@material-ui/core';

import { QueryClient, QueryClientProvider } from 'react-query';

import { BrowserZoomingContextProvider } from 'src/contexts/BrowserZoomingContext';

import { DeviceInfo } from 'src/contexts/DeviceInfo';
import DeviceInfoSharedVar from '../DeviceInfo/shared_var';

const queryClient = new QueryClient();

const auto_hide_duration = isDevelopment() ? null : 3000;

export const UtilContext = React.createContext();

export function UtilProvider({ children }) {
  // snack bar
  const notistackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };
  // snack bar

  let [hello_share, setHelloShare] = React.useState('world');

  let shared_varirables = {
    hello_share,
    setHelloShare,

    ...DeviceInfoSharedVar(),
  };

  return (
    <>
      <UtilContext.Provider value={shared_varirables}>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider
            ref={notistackRef}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={auto_hide_duration}
            maxSnack={2}
            action={(key) => {
              if (isDevelopment()) {
                return (
                  <Button
                    id={'dismiss-alert-button'}
                    onClick={onClickDismiss(key)}
                  >
                    X
                  </Button>
                );
              } else {
                return null;
              }
            }}
          >
            <BrowserZoomingContextProvider />
            <DeviceInfo />
            {children}
          </SnackbarProvider>
        </QueryClientProvider>
      </UtilContext.Provider>
    </>
  );
}
