import React, { useMemo } from 'react';
import { colors } from '@material-ui/core';

import { zipArray } from 'src/utils/zipArray';

import moment from 'moment-timezone';

import { GlobalContext } from 'src/contexts/Global';

import active_lang from 'src/langs/jp_en';

import { getAllServiceRequest } from 'src/modals/service_requests';

import ShowHelpRequestTiles from './ShowHelpRequestTiles';

import { data_default } from './data_default';
import { useTranslation } from 'react-i18next';

const SHOW_HISTORY_LENGTH = 7;

export default function HelpRequestTiles() {
  let [loading, setLoading] = React.useState(true);
  const { t, i18n } = useTranslation();

  const { rest_id } = React.useContext(GlobalContext);

  const [counting, setCounting] = React.useState(data_default);
  const [dates_from_db, setDatesFromDb] = React.useState();
  const [counts_from_db, setCountsFromDb] = React.useState();
  let DAY_ARRAY_IN_UTC = [
    [
      moment()
        .tz(moment.tz.guess())
        .add(0, 'day')
        .startOf('day')
        .format('DD/MM'),
      moment().tz(moment.tz.guess()).add(0, 'day').startOf('day').utc(),
      moment().tz(moment.tz.guess()).add(0, 'day').endOf('day').utc(),
    ],
  ];

  const countingWithTranslation = useMemo(() => {
    const datasets = counting.datasets.map((data) => ({
      ...data,
      label: t('DASHBOARD_CALL_NUMBER'),
    }));

    return {
      ...counting,
      datasets,
    };
  }, [i18n.language, counting]);

  React.useEffect(() => {
    if (rest_id != null) {
      for (let i = 1; i < SHOW_HISTORY_LENGTH; i++) {
        DAY_ARRAY_IN_UTC.push([
          moment().tz(moment.tz.guess()).subtract(i, 'days').format('DD/MM'),
          moment()
            .tz(moment.tz.guess())
            .subtract(i, 'days')
            .startOf('day')
            .utc(),
          moment().tz(moment.tz.guess()).subtract(i, 'days').endOf('day').utc(),
        ]);
      }

      console.log('rest_id', rest_id);

      let DDMM_collections = {};

      // initialize x axis
      DAY_ARRAY_IN_UTC.forEach((day_utc) => (DDMM_collections[day_utc[0]] = 0));

      getAllServiceRequest(rest_id)
        .then((ss) => {
          let a_d = [];
          ss.forEach((doc) => {
            a_d.push(doc.data());
          });
          return a_d;
        })
        .then((a_d) => {
          let unix_timestamps = a_d.map((d) => {
            if (d.request_create_time) {
              return d.request_create_time.seconds;
            }
          });
          console.log('unix_timestamps', unix_timestamps);

          let moment_timestamps = unix_timestamps.map((t) =>
            moment.unix(t).format('DD/MM')
          );
          console.log('moment_timestamps', moment_timestamps);

          moment_timestamps.forEach((mt) => {
            if (Object.keys(DDMM_collections).includes(mt)) {
              DDMM_collections[mt] = DDMM_collections[mt] + 1;
            }
          });
          console.log('DDMM_collections', DDMM_collections);

          let dates = Object.keys(DDMM_collections);
          let counts = dates.map((d) => DDMM_collections[d] || 0);
          console.log('dates', dates);
          console.log('counts', counts);

          setDatesFromDb(dates.reverse());
          setCountsFromDb(counts.reverse());

          // validate data before feed to graph
          let untested = zipArray(dates, counts);
          let validated_date = [];
          let validated_count = [];

          untested.forEach((ut) => {
            if (ut[0] != 'Invalid date') {
              validated_date.push(ut[0]);
              validated_count.push(ut[1]);
            }
          });

          setCounting({
            datasets: [
              {
                backgroundColor: colors.indigo[500],
                data: validated_count,
                label: active_lang.TILES_HELP_REQUEST,
              },
            ],
            labels: validated_date,
          });
          setLoading(false);
        });
    }
  }, [rest_id]);

  return (
    <>
      {loading ? (
        <>{t('COMMON_LOADING')}</>
      ) : (
        <ShowHelpRequestTiles
          dates={dates_from_db}
          counts={counts_from_db}
          chart_data={countingWithTranslation}
        />
      )}
    </>
  );
}
