import React, { useContext, useEffect, useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import {
  styled,
  Box,
  TextField,
  Button,
  makeStyles,
  Input,
} from '@material-ui/core';

import {
  LYNKED_RED,
  LYNKED_RED_HOVER,
  LYNKED_WHITE,
  LYNKED_BLUE,
  LYNKED_BLUE_HOVER,
  LYNKED_GREEN,
  LYNKED_GREEN_HOVER,
  LYNKED_LIGHT_GREY,
  LYNKED_LIGHT_GREY_HOVER,
  LYNKED_DEEP_GREY,
  LYNKED_DEEP_GREY_HOVER,
  LYNKED_BLACK,
  LYNKED_BLACK_HOVER,
  LYNKED_WHITE_HOVER,
} from 'src/consts/colors';

import active_lang from 'src/langs/jp_en';

import { MonitorOrderContext } from 'src/contexts/MonitorOrder';
import ReactExport from 'react-export-excel';
import moment from 'moment';
import { getAllOrders } from 'src/modals/orders';
import { useTranslation } from 'react-i18next';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles((theme) => ({
  helloworld: {},
  ExportButton: {
    height: '45px',
    width: '150px',
    padding: '10px 10px',
    font: 'normal normal bold 12px/16px Roboto',
    borderRadius: '1px',

    color: LYNKED_WHITE,
    backgroundColor: LYNKED_BLUE,
    '&:hover': {
      backgroundColor: LYNKED_BLUE_HOVER,
    },
    '&.Mui-disabled': {
      color: LYNKED_WHITE,
      backgroundColor: LYNKED_DEEP_GREY,
    },
  },
  Export: { position: 'relative' },
  OverlayDisabled: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  },
}));

const handleFoodName = (col) => {
  return col?.item_customize_settings?.length
    ? `${col.item_name} (${col.item_customize_settings
        ?.map((item) => item.name)
        .join(', ')})`
    : col?.item_name;
};

export default function ExportButton({ startDate, endDate }) {
  const classes = useStyles();
  const { allOrders } = useContext(MonitorOrderContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [exportedData, setExportedData] = useState([]);

  useEffect(() => {
    const handleExportData = () => {
      setLoading(true);
      const exportedData = handleExport(allOrders);
      setExportedData(exportedData);
      setLoading(false);
    };

    handleExportData();
  }, [allOrders]);

  const handleExport = (allOrders) => {
    const listExport = [];

    allOrders?.forEach((col) => {
      if (!col.data.orders.length) {
        listExport.push({ ...col });
      }

      col.data.orders?.forEach((item) => {
        const matchingOrder = allOrders.find((order) => col.id === order.id);
        if (matchingOrder) {
          listExport.push({ ...item, ...matchingOrder });
        }
      });
    });

    return listExport.sort((a, b) => {
      return (
        a?.data?.order_create_time?.seconds -
        b?.data?.order_create_time?.seconds
      );
    });
  };

  return (
    <Box className={classes.Export}>
      {loading && (
        <Button className={classes.ExportButton} disabled={true}>
          {t('BILL_EXPORT')}
        </Button>
      )}
      {!loading && (
        <ExcelFile
          filename="bills"
          element={
            <Button
              className={classes.ExportButton}
              disabled={!(startDate && endDate && !(startDate >= endDate))}
            >
              {t('BILL_EXPORT')}
            </Button>
          }
        >
          <ExcelSheet data={exportedData} name="Employees">
            <ExcelColumn label="orders_document_id" value="id" />
            <ExcelColumn
              label="table_no"
              value={(col) => col?.data?.order_table_number}
            />
            <ExcelColumn
              label="name"
              value={(col) => col?.data?.order_username}
            />
            <ExcelColumn
              label="order_date"
              value={(col) =>
                col?.data?.order_create_time?.seconds
                  ? moment(
                      new Date(col?.data?.order_create_time?.seconds * 1000)
                    ).format(t('COMMON_TIME_FORMAT'))
                  : ''
              }
            />
            <ExcelColumn
              label="order_date_time"
              value={(col) =>
                col?.created?.seconds
                  ? moment(new Date(col?.created?.seconds * 1000)).format(
                      t('COMMON_TIME_FORMAT')
                    )
                  : ''
              }
            />
            <ExcelColumn
              label="food_name"
              value={(col) => handleFoodName(col)}
            />
            <ExcelColumn
              label="food_quantity"
              value={(col) => col.item_quantity}
            />
            <ExcelColumn label="food_status" value={(col) => col?.status} />
            <ExcelColumn
              label="food_price"
              value={(col) => {
                if (col.item_price) {
                  return Number(col.item_price).toFixed(2);
                } else {
                  return;
                }
              }}
            />
            <ExcelColumn
              label="food_subtotal"
              value={(col) => {
                if (col.item_subtotal) {
                  return Number(col.item_subtotal).toFixed(2);
                } else {
                  return;
                }
              }}
            />
            <ExcelColumn
              label="bill_status"
              value={(col) => col?.data?.bill_status}
            />
          </ExcelSheet>
        </ExcelFile>
      )}
      {!(startDate && endDate && startDate < endDate) && (
        <Box className={classes.OverlayDisabled}></Box>
      )}
    </Box>
  );
}