import React, { useState } from 'react';
import moment from 'moment-timezone';

import { Button, Grid } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';

import ShowDebug from 'src/components/ShowDebug';

import { MonitorOrderContext } from 'src/contexts/MonitorOrder';

import {
  LYNKED_GREEN,
  LYNKED_GREEN_HOVER,
  LYNKED_LIGHT_GREY,
  LYNKED_BLACK,
  LYNKED_BLACK_HOVER,
  LYNKED_WHITE,
  LYNKED_DEEP_GREY,
  LYNKED_DEEP_GREY_HOVER,
} from 'src/consts/colors';

import active_lang from 'src/langs/jp_en';

import PreparingButton from './PreparingButton';
import CanceledButton from './CanceledButton';
import DeliveredButton from './DeliveredButton';

import {
  STATUS_PREPARING,
  STATUS_DELIVERED,
  STATUS_CANCELLED,
} from 'src/consts/ORDER_STATUS';

import AlertMarkSvg from './AlertMarkSvg';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  line1: {},
  line2: { font: "normal normal bold 12px/16px 'Noto Sans JP'" },
  table: {
    minWidth: 650,
    font: "normal normal bold 12px/16px 'Noto Sans JP'",
    '& thead': {
      background: '#E0E0E0 0% 0% no-repeat padding-box',
      textTransform: 'uppercase',
    },
    '& tbody': {
      '& th': {},
      '& td': { padding: '16px' },
    },
  },
  Buttons: {
    width: '120px',
    height: '30px',
    color: 'white',
    letterSpacing: '0.12px',
    borderRadius: '1px',
    backgroundColor: LYNKED_GREEN,
    font: "normal normal bold 10px/14px 'Noto Sans JP'",
    '&:hover': {
      backgroundColor: LYNKED_GREEN_HOVER,
    },
  },
  tableHeadText: {
    color: LYNKED_BLACK,
    font: 'normal normal bold 12px/16px Noto Sans JP',
  },
  tableBodyText: {
    color: LYNKED_LIGHT_GREY,
    font: "normal normal bold 16px/20px 'Noto Sans JP'",
  },
  food_item_name_text: {
    color: LYNKED_LIGHT_GREY,
    font: "normal normal bold 20px/22px 'Noto Sans JP'",
  },
  food_customization_text: {
    color: LYNKED_LIGHT_GREY,
    font: "normal normal bold 16px/20px 'Noto Sans JP'",
  },
  cancelledBtn: {
    backgroundColor: LYNKED_DEEP_GREY,
    '&:hover': {
      backgroundColor: LYNKED_DEEP_GREY_HOVER,
    },
    color: LYNKED_WHITE,
    width: '120px',
    height: '20px',
    font: 'normal normal normal 11px/15px Hiragino Sans',
  },
});

export default function ShowOrderTable({ orders, filter_by_words }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { order_list } = useState(MonitorOrderContext);

  const filterLogic = (o, filter_by_words) => {
    if (filter_by_words == '') return true;
    if (o.item_name.search(filter_by_words) > -1) return true;
    if (o.table_number.search(filter_by_words) > -1) return true;
  };

  return (
    <>
      <Box style={{ marginTop: '1rem' }}>
        <ShowDebug>
          <pre>{JSON.stringify(order_list, null, 2)}</pre>
        </ShowDebug>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  className={classes.tableHeadText}
                  style={{ width: `${(100 / 12) * 1}%` }}
                >
                  {t('COMMON_RECEPTION_NUMBER')}
                </TableCell>
                <TableCell className={classes.tableHeadText}>{t('COMMON_ITEM_NAME')}</TableCell>
                <TableCell
                  className={classes.tableHeadText}
                  style={{ width: `${(100 / 12) * 1}%` }}
                >
                  {t('ORDER_AMOUNT')}
                </TableCell>
                <TableCell
                  className={classes.tableHeadText}
                  style={{ width: `${(100 / 12) * 1}%` }}
                >
                  {t('COMMON_TABLE')}
                </TableCell>
                <TableCell
                  className={classes.tableHeadText}
                  style={{ width: `${(100 / 12) * 2.5}%` }}
                >
                  {t('COMMON_RECEPTION_TIME')}
                </TableCell>
                <TableCell
                  className={classes.tableHeadText}
                  style={{ width: `${(100 / 12) * 1}%` }}
                >
                  {t('COMMON_STATUS')}
                </TableCell>
                <TableCell
                  style={{ display: 'none' }}
                  className={classes.tableHeadText}
                ></TableCell>
                {/* <ShowDebug>
                  <TableCell className={classes.tableHeadText}>Debug</TableCell>
                </ShowDebug> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {orders &&
                orders
                  .filter((o) => {
                    return filterLogic(o, filter_by_words);
                  })
                  .sort((a, b) => {
                    if (a.created.seconds == b.created.seconds) {
                      return a.order_idx > b.order_idx ? 1 : -1;
                    } else {
                      return a.created.seconds < b.created.seconds ? -1 : 1;
                    }
                  })
                  .map((order, idx) => (
                    <TableRow key={idx}>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.tableBodyText}
                      >
                        {idx + 1}
                      </TableCell>
                      <TableCell>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            className={classes.food_item_name_text}
                            style={{ color: 'black' }}
                          >
                            <div>{order.item_name}</div>
                          </Grid>
                          <Grid item xs={12} style={{ paddingTop: '3px' }}>
                            <Grid
                              container
                              className={classes.food_customization_text}
                              style={{ color: 'black' }}
                            >
                              {order.item_customize_settings ? (
                                order.item_customize_settings.map(
                                  (cust_setting) => (
                                    <>
                                      <Grid
                                        item
                                        xs={1}
                                        style={{
                                          display: 'flex',
                                          flexFlow: 'row',
                                          justifyContent: 'flex-end',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <AlertMarkSvg />
                                      </Grid>
                                      <Grid
                                        item
                                        xs={11}
                                        style={{ paddingLeft: '10px' }}
                                      >
                                        <div>{cust_setting.name}</div>
                                      </Grid>
                                    </>
                                  )
                                )
                              ) : (
                                <></>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell className={classes.tableBodyText}>
                        {order.item_quantity}
                      </TableCell>
                      <TableCell className={classes.tableBodyText}>
                        #{order.table_number}
                      </TableCell>
                      <TableCell className={classes.tableBodyText}>
                        <Box>
                          {moment
                            .unix(order.created.seconds)
                            .format(t('COMMON_TIME_FORMAT'))}
                        </Box>
                      </TableCell>
                      <TableCell>
                        {order.status === STATUS_PREPARING && (
                          <PreparingButton
                            button_id={`update-status-button-${idx}`}
                            status={order.status}
                            order_id={order.id}
                            order_idx={order.order_idx}
                          />
                        )}
                        {order.status === STATUS_DELIVERED && (
                          <DeliveredButton
                            button_id={`update-status-button-${idx}`}
                            status={order.status}
                            order_id={order.id}
                            order_idx={order.order_idx}
                          />
                        )}
                        {order.status === STATUS_CANCELLED && (
                          <CanceledButton
                            button_id={`update-status-button-${idx}`}
                            status={order.status}
                            order_id={order.id}
                            order_idx={order.order_idx}
                          />
                        )}
                      </TableCell>
                      <TableCell style={{ display: 'none' }}>
                        <MoreHorizOutlinedIcon />
                      </TableCell>
                      {/*
                    <ShowDebug>
                      <TableCell>
                        <pre>{JSON.stringify(order, null, 2)}</pre>
                      </TableCell>
                    </ShowDebug>
                     */}
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}
