import { useQuery } from 'react-query';
import config from 'src/config';
let { REST_MNGT_ENDPOINT } = config;

const useGetSourceLang = (site) => {
  let url = `${REST_MNGT_ENDPOINT}/getSourceLang?site=${site}`;
  return useQuery(
    url,
    async () => {
      try {
        const response = await fetch(url);
        return response.json();
      } catch (error) {
        console.log(error);
      }
    },
    { retry: 5 }
  );
};

export default useGetSourceLang;
