import React from 'react';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  TextField,
} from '@material-ui/core';
import Slider from '@material-ui/core/Slider';

import LineupIphoneXScreenshotPng from './lineup-iphone-x-screenshot-new.png';
import LineupIphoneXScreenshotSkippablePng from './lineup-iphone-x-screenshot-skippable-new.png';

import ShowDebugJson from 'src/components/ShowDebugJson';

import { LYNKED_RED } from 'src/consts/colors';

import devices_configs from 'src/configs/devices';

import { useStyles } from './styles';
import { isUndefined } from 'lodash';
import { useTranslation } from 'react-i18next';

const marks = [
  { value: 0, label: 0 },
  { value: 25, label: 25 },
  { value: 50, label: 50 },
  { value: 100, label: 100 },
];

function valuetext(value) {
  return `${value}°C`;
}

export default function LineupPageSettingsForm({ formik, current_setting }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const handleInputOnBlur = () => {
    devices_configs.forceScreenScrollUp();
  };

  let [children_value, setChildrenValue] = React.useState(0);
  let [adult_value, setAdultValue] = React.useState(1);

  const handleAdultChange = (v) => {
    setAdultValue(v);
  };

  const handleChildrenChange = (v) => {
    setChildrenValue(v);
  };

  React.useEffect(() => {
    if (isUndefined(formik.values) || isUndefined(formik.values.lineup)) {
    } else {
      setChildrenValue(formik.values.lineup.max_num_of_children);
      setAdultValue(formik.values.lineup.max_num_of_adult);
    }
  }, [formik.values]);

  return (
    <>
      <Card>
        <CardHeader
          title={t('SETTINGS_RECEPTION_PAGE_SETTINGS')}
          titleTypographyProps={{ style: { fontWeight: 'bold' } }}
        />
        <Divider />
        <CardContent>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Grid item xs={12}>
                <Box style={{ margin: '1rem' }}>
                  <Typography
                    id="max-number-of-adult"
                    gutterBottom
                    style={{ marginBottom: '1rem', fontWeight: 'bold' }}
                  >
                    {t('SETTINGS_TEXT_HINT')}
                  </Typography>

                  {current_setting.isSkippableToAssignTable && (
                    <TextField
                      id="lineup_table_placeholder"
                      name="lineup.lineup_table_placeholder"
                      {...formik.getFieldProps(
                        'lineup.lineup_table_placeholder'
                      )}
                      className={classes.TextInput}
                      placeholder={
                        formik.values.lineup?.lineup_table_placeholder ||
                        t('SETTINGS_ROOM')
                      }
                      defaultValue={
                        formik.values.lineup?.lineup_table_placeholder ||
                        t('SETTINGS_ROOM')
                      }
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ style: { padding: '1rem' } }}
                      onBlur={(e) => {
                        handleInputOnBlur();
                        formik.handleBlur(e);
                      }}
                      fullWidth
                    />
                  )}
                  <TextField
                    id="lineup_username_placeholder"
                    name="lineup.lineup_username_placeholder"
                    {...formik.getFieldProps(
                      'lineup.lineup_username_placeholder'
                    )}
                    className={classes.TextInput}
                    placeholder={
                      formik.values.lineup?.lineup_username_placeholder ||
                      t('COMMON_NAME')
                    }
                    defaultValue={
                      formik.values.lineup?.lineup_username_placeholder ||
                      t('COMMON_NAME')
                    }
                    InputProps={{ disableUnderline: true }}
                    inputProps={{ style: { padding: '1rem' } }}
                    onBlur={(e) => {
                      handleInputOnBlur();
                      formik.handleBlur(e);
                    }}
                    fullWidth
                  />
                </Box>
              </Grid>

              <Grid container>
                <Grid item xs={6}>
                  <Box style={{ margin: '1rem' }}>
                    <Typography
                      id="max-number-of-adult"
                      gutterBottom
                      style={{ marginBottom: '3rem', fontWeight: 'bold' }}
                    >
                      {t('SETTINGS_MAX_ADULTS')}
                    </Typography>

                    <Slider
                      name="lineup.max_num_of_adult"
                      {...formik.getFieldProps('lineup.max_num_of_adult')}
                      onChange={(e, v) => handleAdultChange(v)}
                      onChangeCommitted={(e, v) => {
                        formik.setFieldValue('lineup.max_num_of_adult', v);
                        formik.handleBlur({
                          ...e,
                          target: {
                            ...e.target,
                            name: 'lineup.max_num_of_adult',
                          },
                        });
                      }}
                      value={adult_value}
                      getAriaValueText={valuetext}
                      marks={marks}
                      min={1}
                      step={1}
                      defaultValue={100}
                      style={{ color: LYNKED_RED }}
                      valueLabelDisplay="on"
                      onBlur={(e) => {}}
                      aria-labelledby="max-number-of-adult"
                    />
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Box style={{ margin: '1rem' }}>
                    <Typography
                      id="max-num-of-child"
                      gutterBottom
                      style={{ marginBottom: '3rem', fontWeight: 'bold' }}
                    >
                      {t('SETTINGS_MAX_CHILDREN')}
                    </Typography>
                    <Slider
                      name="lineup.max_num_of_children"
                      onChange={(e, v) => handleChildrenChange(v)}
                      value={children_value}
                      onChangeCommitted={(e, v) => {
                        formik.setFieldValue('lineup.max_num_of_children', v);
                        formik.handleBlur({
                          ...e,
                          target: {
                            ...e.target,
                            name: 'lineup.max_num_of_children',
                          },
                        });
                      }}
                      getAriaValueText={valuetext}
                      marks={marks}
                      min={0}
                      step={1}
                      defaultValue={100}
                      style={{ color: LYNKED_RED }}
                      valueLabelDisplay="on"
                      onBlur={(e) => {}}
                      aria-labelledby="max-num-of-child"
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box style={{ margin: '1rem' }}>
                  <Typography
                    id="max-number-of-adult"
                    gutterBottom
                    style={{ marginBottom: '1rem', fontWeight: 'bold' }}
                  >
                    {t('SETTINGS_REMARKS')}
                  </Typography>
                  {/* <ShowDebugJson>CLIENT_REMARKS_TITLE</ShowDebugJson> */}
                  <TextField
                    id="lineup_remarks_placeholder"
                    name="lineup.lineup_remarks_placeholder"
                    {...formik.getFieldProps(
                      'lineup.lineup_remarks_placeholder'
                    )}
                    className={classes.TextInput}
                    placeholder={t('RECEPTION_NOTES')}
                    defaultValue={''}
                    InputProps={{ disableUnderline: true }}
                    inputProps={{ style: { padding: '1rem' } }}
                    onBlur={(e) => {
                      handleInputOnBlur();
                      formik.handleBlur(e);
                    }}
                    rows={4}
                    rowsMax={8}
                    fullWidth
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid item xs={12}>
                <Box
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexFlow: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    className={classes.screencapture}
                    style={{
                      marginTop: '3rem',
                      width: '50%',
                      maxWidth: `${375 / 2}px`,
                      height: `${812 / 2}px`,
                      backgroundImage: `url(${
                        current_setting.isSkippableToAssignTable
                          ? LineupIphoneXScreenshotSkippablePng
                          : LineupIphoneXScreenshotPng
                      })`,
                      backgroundSize: 'contain',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',

                      borderRadius: '5px',
                      boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.25)',
                    }}
                  ></Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
      </Card>
    </>
  );
}
