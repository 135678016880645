import React from 'react';
import { Navigate } from 'react-router-dom';

import AppLayout from 'src/layouts/AppLayout';
import FullScreenLayout from 'src/layouts/FullScreenLayout';

import OverviewPage from 'src/pages/OverviewPage';
import ReceptionListPage from 'src/pages/ReceptionListPage';
import HelpRequestsPage from 'src/pages/HelpRequestsPage';
import OrdersPage from 'src/pages/OrdersPage';
import AdvertisementPage from 'src/pages/AdvertisementPage';
import BillingManagementPage from 'src/pages/BillingManagementPage';
import MenuManagementPage from 'src/pages/MenuManagementPage';
import LoginPage from 'src/pages/LoginPage';
import LogoutPage from 'src/pages/LogoutPage';
import Error404Page from 'src/pages/Error404Page';
import TermsAndCondsPage from 'src/pages/TermsAndCondsPage';
import QRGeneratorPage from 'src/pages/QRGeneratorPage';
import SettingsPage from 'src/pages/SettingsPage';
import ChangeLogPage from 'src/pages/ChangeLogPage';
import ExampleQueries from 'src/pages/ExampleQueries';
import SorryForInconvenienceCausedPage from 'src/pages/SorryForInconvenienceCausedPage';

import ExampleComponents from 'src/components/ExampleComponents';

var ENV_PUBLIC_URL = process.env.PUBLIC_URL;

function AboutLynked() {
  React.useEffect(() => {
    window.open('https://lynked.co.jp');
    window.history.back();
  });
  return <></>;
}

const production_routes = [
  {
    path: `${ENV_PUBLIC_URL}/app`,
    element: <AppLayout />,
    children: [
      { path: 'overview', element: <OverviewPage /> },
      { path: 'reception_list', element: <ReceptionListPage /> },
      { path: 'help_requests', element: <HelpRequestsPage /> },
      { path: 'orders', element: <OrdersPage /> },
      { path: 'edit_advertisement', element: <AdvertisementPage /> },
      { path: 'billing_management', element: <BillingManagementPage /> },
      { path: 'menu_management', element: <MenuManagementPage /> },
      { path: 'qr_gen', element: <QRGeneratorPage /> },
      { path: 'settings', element: <SettingsPage /> },
      { path: 'change_log', element: <ChangeLogPage /> },

      { path: '', element: <OverviewPage /> },
      { path: '*', element: <Navigate to={`${ENV_PUBLIC_URL}/404`} /> },
    ],
  },
  {
    path: `${ENV_PUBLIC_URL}/`,
    element: <FullScreenLayout />,
    children: [
      // { path: 'load_resources', element: <LoadingResourcesPage /> },
      { path: 'login', element: <LoginPage /> },
      { path: 'logout', element: <LogoutPage /> },
      { path: 'example_queries', element: <ExampleQueries /> },
      { path: 'example_components', element: <ExampleComponents /> },
      { path: 'terms_and_conds', element: <TermsAndCondsPage /> },
      { path: 'about_lynked', element: <AboutLynked /> },
      { path: '404', element: <Error404Page /> },
      { path: 'sorry_error', element: <SorryForInconvenienceCausedPage /> },
      { path: '/', element: <Navigate to={`${ENV_PUBLIC_URL}/login`} /> },
      { path: '*', element: <Navigate to={`${ENV_PUBLIC_URL}/404`} /> },
    ],
  },
];

export default production_routes;
