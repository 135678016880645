let SIMPLE_VERSIONING = 'V_20201208_1';
let RESTAURANT_STRING = '食店控制介面 / Restaurant Mgnt Panel';

let TEST_ALERT_SHOW_DURATION = 6000;
let APP_LANDING_PAGE = '/app/overview';

let DEVELOPER_MODE = true;

let FIREBASE_FUNCTION_URL =
  'https://us-central1-lynked-demo-tryout.cloudfunctions.net';
let LOCAL_FIREBASE_FUNCTION_URL = `http://${process.env.REACT_APP_FIREBASE_FUNCTION_EMULATOR_HOST}/lynked-demo-tryout/us-central1`;

let FIREBASE_FUNCTION_HOST =
  process.env.NODE_ENV === 'development'
    ? LOCAL_FIREBASE_FUNCTION_URL
    : FIREBASE_FUNCTION_URL;

let REST_MNGT_ENDPOINT = FIREBASE_FUNCTION_HOST + '/api';

export {
  TEST_ALERT_SHOW_DURATION,
  SIMPLE_VERSIONING,
  RESTAURANT_STRING,
  APP_LANDING_PAGE,
  DEVELOPER_MODE,
  REST_MNGT_ENDPOINT,
};

export default {
  TEST_ALERT_SHOW_DURATION,
  SIMPLE_VERSIONING,
  RESTAURANT_STRING,
  APP_LANDING_PAGE,
  DEVELOPER_MODE,
  REST_MNGT_ENDPOINT,
};
