import 'firebase/firestore';

import firebase from 'firebase/app';

import db_config from 'src/configs/db_config';
import db from 'src/Firebase/db';

// const AD_TYPE_REST_DEFINE_ADVERTISEMENT = 'AD_TYPE_REST_DEFINE_ADVERTISEMENT';
// const AD_TYPE_MENY_REST_ADVERTISEMENT = 'AD_TYPE_MENY_REST_ADVERTISEMENT';

const select_values = {
  is_enabled: { ENABLED: true, DISABLED: false },
  ad_window: {
    FOOD_MENU: { id: 1, name: 'food_menu' },
    FOOD_DETAIL: { id: 2, name: 'food_detail' },
    ORDER_LIST: { id: 3, name: 'order_list' },
  },
  ad_type: {
    AD_TYPE_MENYLIGHT_ADVERTISEMENT: 'AD_TYPE_MENYLIGHT_ADVERTISEMENT',
    AD_TYPE_MENY_REST_ADVERTISEMENT: 'AD_TYPE_MENY_REST_ADVERTISEMENT',
    AD_TYPE_REST_DEFINE_ADVERTISEMENT: 'AD_TYPE_REST_DEFINE_ADVERTISEMENT',
  },
  ad_action: {
    FOOD_MENU: { id: 1, name: 'food_menu' },
    FOOD_DETAIL: { id: 2, name: 'food_detail' },
  },
};

const default_ad_config = {
  data: {
    title: '',
    image_url: ['', '', ''],
  },
};

const ad_db_ref = db.collection(db_config.DB_TABLE_ADVERTISEMENT_CONFIG);
const ADVERTISEMENT_REF = db.collection(
  db_config.DB_TABLE_ADVERTISEMENT_CONFIG
);

function getAdvertisementByRestId(rest_id) {
  return db
    .collection(db_config.DB_TABLE_ADVERTISEMENT_CONFIG)
    .where('target_restaurants', 'array-contains-any', ['all', rest_id]);
}

function addAdvertisementByAdvertisementId(advertisement_id, payload) {
  let DocRef = ad_db_ref.doc(advertisement_id);

  return db
    .runTransaction((transaction) => {
      return transaction.get(DocRef).then((sfDoc) => {
        transaction.set(
          DocRef,
          {
            ...payload,
            ad_create_time: firebase.firestore.FieldValue.serverTimestamp(),
          },
          { merge: true }
        );
      });
    })
    .then(() => {
      console.log('Transaction successfully committed!');
    })
    .catch((error) => {
      console.error('Transaction failed: ', payload, error);
    });
}
function setAdvertisementByAdvertisementId(advertisement_id, payload) {
  let DocRef = ad_db_ref.doc(advertisement_id);

  return db
    .runTransaction((transaction) => {
      return transaction.get(DocRef).then((sfDoc) => {
        transaction.set(DocRef, { ...payload }, { merge: true });
      });
    })
    .then(() => console.log('Transaction successfully committed!'))
    .catch((error) => console.error('Transaction failed: ', payload, error));
}

function listAllAdvertisementByRestId(rest_id) {
  return db
    .collection(db_config.DB_TABLE_ADVERTISEMENT_CONFIG)
    .where(
      'ad_type',
      '==',
      select_values.ad_type.AD_TYPE_MENY_REST_ADVERTISEMENT
    )
    .get()
    .then((result) => {
      return result.docs.map((doc) => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });
    });
}

function listAllActiveAdvertisementByRestId(rest_id) {
  return db
    .collection(db_config.DB_TABLE_ADVERTISEMENT_CONFIG)
    .where('rest_id', '==', rest_id)
    .where(
      'ad_type',
      '==',
      select_values.ad_type.AD_TYPE_MENY_REST_ADVERTISEMENT
    )
    .where('is_enabled', '==', select_values.is_enabled.ENABLED)
    .orderBy('ad_create_time')
    .get()
    .then((result) => {
      return result.docs.map((doc) => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });
    })
    .catch((err) => {
      console.error('listAllActiveAdvertisementByRestId', 'err', err);
    });
}

function getAllAdvertisement() {
  return db
    .collection(db_config.DB_TABLE_ADVERTISEMENT_CONFIG)
    .get()
    .then((result) => {
      return result.docs.map((doc) => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });
    });
}

function listAllAdvertisement() {
  return ADVERTISEMENT_REF.get().then((result) => {
    return result.docs.map((doc) => {
      return {
        id: doc.id,
        data: doc.data(),
      };
    });
  });
}

function getAdvertisement(doc_id) {
  return ad_db_ref.doc(doc_id).get();
}

function updateAdvertisement(doc_id, ad_info_value) {
  return ad_db_ref.doc(doc_id).set({ ...ad_info_value });
}

function getNewAdvertisementId() {
  let temp = ad_db_ref.doc().id;
  console.log('getNewAdvertisementId', temp);
  return temp;
}

function addAdvertisement(new_ad_config) {
  alert(new_ad_config);
  return ad_db_ref
    .add(new_ad_config)
    .then(() => {
      console.log('add advertisement done');
    })
    .catch((err) => {
      console.error('error during adding advertisement', err);
    });
}

function getFoodMenuAdvertisements(rest_id) {
  return getAdvertisementByRestId(rest_id)
    .where('ad_window', '==', select_values.ad_window.FOOD_MENU.name)
    .get()
    .then((result) => {
      return result.docs.map((doc) => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });
    });
}

function deleteAdvertisementById(doc_id) {
  console.log('deleteAdvertisementById', 'doc_id', doc_id);

  let DocRef = ad_db_ref.doc(doc_id);
  return db
    .runTransaction((transaction) => {
      return transaction.get(DocRef).then((sfDoc) => {
        transaction.delete(DocRef);
      });
    })
    .then(() => {
      console.log('Transaction successfully committed!');
    })
    .catch((error) => {
      console.error('Transaction failed: ', error);
    });
}

function getFoodDetailAdvertisements(rest_id) {
  return getAdvertisementByRestId(rest_id)
    .where('ad_window', '==', select_values.ad_window.FOOD_DETAIL.name)
    .get()
    .then((result) => {
      return result.docs.map((doc) => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });
    });
}

export {
  select_values,
  addAdvertisement,
  default_ad_config,
  deleteAdvertisementById,
  getAllAdvertisement,
  getFoodDetailAdvertisements,
  getFoodMenuAdvertisements,
  updateAdvertisement,
  listAllAdvertisement,
  getAdvertisement,
  getNewAdvertisementId,
  listAllAdvertisementByRestId,
  addAdvertisementByAdvertisementId,
  setAdvertisementByAdvertisementId,
  listAllActiveAdvertisementByRestId,
};
