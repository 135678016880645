import { useQuery } from 'react-query';
import { loadRestaurantConfig } from 'src/modals/restaurant_config';

const useQueryRestaurantConfigByRestId = (rest_id) => {
  return useQuery(
    `use-load-restaurant-config-${rest_id}`,
    () => {
      return loadRestaurantConfig(rest_id).then((result) => {
        return result;
      });
    },
    // consider:
    // suspend auto refetch when user click outside window, prevent change time reset
    { refetchOnWindowFocus: false }
  );
};

export default useQueryRestaurantConfigByRestId;
