import React from 'react';
import { Box, Grid } from '@material-ui/core';
import moment from 'moment-timezone';
import { merge } from 'lodash';
import TimePicker from 'rc-time-picker';

import { LYNKED_DEEP_GREY_HOVER, LYNKED_BLACK } from 'src/consts/colors';
import active_lang from 'src/langs/jp_en';

import { clock_icon_svg } from './clock_icon_svg';
import './rc-time-picker.css';
import { useTranslation } from 'react-i18next';

const LOG_PREFIX = 'StartTimePicker';

export default ({ formik, day_of_week }) => {
  const start_time_picker_ref = React.useRef(null);
  const { t } = useTranslation();

  let [start_utc, setStartUtc] = React.useState(
    moment(formik.values['opening_schedule'][day_of_week].utc_start_time)
  );

  let start_enabled = formik.values['opening_schedule'][day_of_week].enabled;

  const handleStartTimeChange = (new_utc_value) => {
    console.log(LOG_PREFIX, { new_utc_value: new_utc_value._d });
    let start_HH_mm = new_utc_value
      .startOf('minute')
      .toISOString(true)
      .split('T')[1];
    formik.setFieldValue(
      `opening_schedule.${day_of_week}.utc_start_time`,
      `1970-01-01T${start_HH_mm}`
    );

    setStartUtc(new_utc_value);
  };

  const handleOnClose = () => {
    formik.setTouched(
      merge(formik.touched, {
        opening_schedule: { [`${day_of_week}`]: { utc_start_time: true } },
      })
    );
  };

  return (
    <>
      <Grid container style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
        <Grid
          item
          xs={4}
          style={{
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            alignItems: 'flex-end',
          }}
        >
          <Box
            style={{
              color: start_enabled ? LYNKED_BLACK : LYNKED_DEEP_GREY_HOVER,
              fontWeight: 'bold'
            }}
          >
            {t('SETTINGS_BUSINESS_START_TIME')}
          </Box>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={7} style={{ textAlign: 'center' }}>
          <Box
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {formik.values['opening_schedule'][day_of_week].enabled ? (
              <TimePicker
                id="start_time_picker"
                ref={start_time_picker_ref}
                name={`opening_schedule.${day_of_week}.utc_start_time`}
                showSecond={false}
                minuteStep={15}
                format={'HH:mm'}
                inputIcon={clock_icon_svg}
                value={start_utc}
                allowEmpty={false}
                onChange={handleStartTimeChange}
                onClose={handleOnClose}
                // MENY-312, clicking start & end time field will trigger virtual keypad
                inputReadOnly
              />
            ) : (
              <TimePicker
                id="start_time_picker"
                ref={start_time_picker_ref}
                showSecond={false}
                minuteStep={15}
                format={'--:--'}
                value={moment()}
                inputIcon={clock_icon_svg}
                // MENY-312, clicking start & end time field will trigger virtual keypad
                inputReadOnly
                // MENY-314, disabled day still allow to open dropdown start & end time
                disabled={true}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
