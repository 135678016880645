import React from 'react';
import active_lang from 'src/langs/jp_en';
import { Grid } from '@material-ui/core';

import { LYNKED_DEEP_GREY } from 'src/consts/colors';
import { useTranslation } from 'react-i18next';

export default function ListLoadingScreen() {
  const { t } = useTranslation();

  return (
    <>
      <Grid
        container
        justify="center"
        style={{ color: LYNKED_DEEP_GREY, textAlign: 'center' }}
      >
        <Grid item xs={4}>
          {t('AD_LOADING_BANNER')}
        </Grid>
      </Grid>
    </>
  );
}
