import React from 'react';
import { Box, Button, Grid, Popover } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import active_lang from 'src/langs/jp_en';

import moment from 'moment-timezone';

import { useStyles } from './styles';
import { useState } from 'react';
import EditTableInformationDialog from '../EditTableInformationDialog';
import { useTranslation } from 'react-i18next';

export default function AssignedTableListCard({ assigned_table_info }) {
  const classes = useStyles();
  const { t } = useTranslation();

  let username = assigned_table_info.data['lineup-username'];
  let request_time_utc = assigned_table_info.data['request-time-utc'];
  let assigned_table_name = assigned_table_info.data['assign_table_name'];
  let lineup_note = assigned_table_info.data['lineup-note'];
  let num_of_adult = assigned_table_info.data['num-of-adult'];
  let num_of_child = assigned_table_info.data['num-of-child'];
  // const human_request_time =
  //   moment(request_time_utc).format('YYYY年MM月DD日 HH時mm分');
  const human_request_time =
    process.env.NODE_ENV === 'test'
      ? t('COMMON_TIME_FORMAT')
      : moment(request_time_utc).format(t('COMMON_TIME_FORMAT'));
  const [assignedTableInfo, setAssignedTableInfo] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openEditTableInformationDialog, setOpenEditTableInformationDialog] =
    React.useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditTableInformationDialog = (e, assigned_table_info) => {
    setAssignedTableInfo(assigned_table_info);
    setOpenEditTableInformationDialog(true);
    handleClose();
  };

  return (
    <>
      <EditTableInformationDialog
        open={openEditTableInformationDialog}
        setOpen={setOpenEditTableInformationDialog}
        assign_waiting_entry={assignedTableInfo}
      />
      <Box className={classes.ReceptionListCardWrapper}>
        <Box className={classes.statusIndicator}></Box>

        <Button style={{ width: '100%', height: '100%' }} disableRipple>
          <Box className={classes.CardContent}>
            <Box className={classes.ReceptionCardHeader}>
              <Grid container>
                <Grid item xs={6} className={classes.ReceptionCardLeft0}>
                  {t('RECEPTION_NUMBER_PERSON', {
                    userName: username,
                    numberOfAdult: num_of_adult,
                    numberOfChildren: num_of_child,
                  })}
                </Grid>

                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  <MoreHorizIcon onClick={handleClick} />
                  <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <Box style={{ display: 'flex', flexFlow: 'column' }}>
                      <Button
                        style={{ width: '200px', height: '40px' }}
                        onClick={(e) =>
                          handleEditTableInformationDialog(
                            e,
                            assigned_table_info
                          )
                        }
                      >
                        <Box
                          style={{
                            display: 'flex',
                            flexFlow: 'row',
                            justifyContent: 'flex-start',
                            width: '100%',
                          }}
                        >
                          <Box
                            style={{
                              marginLeft: '1rem',
                              font: 'normal normal normal 14px/19px Hiragino Sans',
                            }}
                          >
                            {t('RECEPTION_EDIT')}
                          </Box>
                        </Box>
                      </Button>
                    </Box>
                  </Popover>
                </Grid>
              </Grid>
            </Box>
            <Box className={classes.ReceptionCardBody}>
              <Grid container>
                <Grid item xs={5} className={classes.ReceptionCardBody0}>
                  <Box>
                    <Box>{human_request_time}</Box>
                    <Box>
                      {t('COMMON_RECEPTION_NUMBER')}
                      {assigned_table_info.data['current_ticket']}
                    </Box>
                    <Box>
                      {t('COMMON_TABLE')} {assigned_table_name}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box className={classes.Remarks}>{t('COMMON_REMARKS')}:</Box>
                </Grid>
                <Grid item xs={5}>
                  <Box className={classes.ReceptionCardBody1}>
                    {lineup_note && lineup_note.length > 60
                      ? lineup_note.substring(0, 60) + '...'
                      : lineup_note}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Button>
      </Box>
    </>
  );
}
