import React from 'react';

import { Box, Grid } from '@material-ui/core';

import Page from 'src/components/Page';
import HelpRequestsTable from 'src/components/HelpRequestsTable';

import { MonitorServiceRequestContext } from 'src/contexts/MonitorServiceRequest';

import active_lang from 'src/langs/jp_en';

import { useStyles } from './styles';
import { GlobalContext } from 'src/contexts/Global';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function HelpRequestsPage() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();

  let [service_requests, setServiceRequests] = React.useState(null);
  const { list_active_service_request } = React.useContext(
    MonitorServiceRequestContext
  );
  const { restaurant_config } = React.useContext(GlobalContext);

  React.useEffect(() => {
    setServiceRequests(list_active_service_request);
  }, [list_active_service_request]);

  React.useEffect(() => {
    if (!restaurant_config.isEnableBellRing) {
      navigate('/app');
    }
  }, [restaurant_config]);

  return (
    <>
      {/* align to billing Page */}
      <Page className={classes.root}>
        <Box style={{ width: '100%', paddingBottom: '5rem' }}>
          <Grid container>
            <Grid item xs={12}>
              <Box style={{ font: 'normal normal bold 20px/26px Roboto' }}>
                {t('COMMON_CALL_LIST')}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <HelpRequestsTable
                service_requests={service_requests}
                setServiceRequests={setServiceRequests}
              />
            </Grid>
          </Grid>
        </Box>
      </Page>
    </>
  );
}
