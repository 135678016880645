import { useMutation } from 'react-query';
import { updateBillByOrderId } from 'src/modals/orders';

export default function useMutateUpdateBillByOrderId() {
  const mutation = useMutation(
    ({ rest_id, id, payload }) => {
      return updateBillByOrderId(rest_id, id, payload).catch((err) => {
        console.error('useMutateUpdateBillByOrderId', 'error', err);
      });
    },
    {
      retry: 10,
    }
  );

  return mutation;
}
