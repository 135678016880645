import React from 'react';

import { Box } from '@material-ui/core';

import { LYNKED_RED } from 'src/consts/colors';

export default function ShowCompareSame({ value }) {
  return (
    <Box
      style={{
        fontSize: '14px',
        lineHeight: '18px',
        font: 'normal normal normal 14px/18px Helvetica',
      }}
    >
      &#x2192; {Math.max(value, 0)}
    </Box>
  );
}
