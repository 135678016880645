import { useMutation } from 'react-query';
import { loadRestaurantConfig } from 'src/modals/restaurant_config';

export default function useMutateRestaurantConfigByRestId() {
  return useMutation(
    ({ rest_id }) => {
      console.log('useMutateRestaurantConfigByRestId', 'rest_id', rest_id);
      return loadRestaurantConfig(rest_id).then((result) => {
        console.log('useMutateRestaurantConfigByRestId', result.data());
        return result.data();
      });
    },
    { retry: 10 }
  );
}
