import React from 'react';
import { Box, makeStyles, Grid } from '@material-ui/core';

import { GlobalContext } from 'src/contexts/Global';
import URLS from 'src/consts/URLS';
import { SERVICE_MENY, SERVICE_MENY_LIGHT } from 'src/consts/MENY_SERVICE';
import active_lang from 'src/langs/jp_en';
import { isUndefined } from 'lodash';
import { useTranslation } from 'react-i18next';
import GridQR from './GridQR';
import LoadingGridQR from './LoadingGridQR';

const useStyles = makeStyles(() => ({ root: { flexGrow: 1 } }));

export default function QRGeneratorMeny() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { rest_id, meny_service_class } = React.useContext(GlobalContext);

  let menu_id = rest_id;

  const getMenyLineupLink = () => {
    return `${client_meny_link}/${rest_id}/${menu_id}/NewUserlineup`;
    // return `${client_meny_link}`;
  };

  const getMenyLightLink = () => {
    return `${client_meny_light_link}/${rest_id}/${menu_id}/table_1`;
  };

  // client
  const client_host =
    process.env.NODE_ENV === 'development'
      ? `http://${process.env.REACT_APP_MENY_CLIENT_TEST_HOST}`
      : URLS.MENY_APP_URL;
  const client_meny_link = `${client_host}/meny`;
  const client_meny_light_link = client_host;

  const ShowClientQR = ({ meny_service_class }) => {
    if (isUndefined(meny_service_class) || isUndefined(meny_service_class.id)) {
      return (
        <>
          <LoadingGridQR />
        </>
      );
    } else {
      // if (meny_service_class != null && meny_service_class.id != 'undefined') {
      console.log('ShowClientQR', 'meny_service_class', meny_service_class);
      if (meny_service_class.id === SERVICE_MENY) {
        return (
          <GridQR
            link={getMenyLineupLink()}
            text={t('QR_MENY_RECEPTION_PAGE')}
            id_of_qr={`meny_menu_page`}
          />
        );
      }
      if (meny_service_class.id === SERVICE_MENY_LIGHT) {
        return (
          <GridQR
            link={getMenyLightLink()}
            text="meny light link"
            id_of_qr={`meny_light_link`}
          />
        );
      }
    }
  };

  return (
    <>
      <Box>
        <Grid container className={classes.root}>
          <Grid item xs></Grid>
          <Grid item xs></Grid>
        </Grid>

        <Grid container className={classes.root}>
          <Grid item xs={12}>
            <Grid container justify="center" spacing={8}>
              <GridQR
                link={'https://meny-manage.menymeny.com'}
                text={t('QR_RESTAURANT_LOG_PAGE')}
                id_of_qr={`qr_restaurant_login_page`}
              />

              <ShowClientQR meny_service_class={meny_service_class} />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
