import React from 'react';

import { AuthContext } from 'src/Firebase/context';
import AlertNewLineupRequest from 'src/components/dialogs/AlertNewLineupRequest';
import { ServiceRequestSoundContext } from 'src/contexts/AudioNotification/ServiceRequestSound';

export const NewLineupRequestAlertsContext = React.createContext();
export const NewLineupRequestAlertsContextProvider = ({ children }) => {
  const { user } = React.useContext(AuthContext);
  const ServiceRequestSound = React.useContext(ServiceRequestSoundContext);
  const hwNewLineupRequestAlertsContext = () => {
    // alert('hello service request alerts context');
  };

  const [open_alert_dialog, setOpenAlertDialog] = React.useState(false);
  const showNewLineupAlert = () => {
    if (user) {
      setOpenAlertDialog(true);
      console.log('ServiceRequestSound', ServiceRequestSound);
      ServiceRequestSound.handlePlaySound();
    }
  };

  const handleCloseAlert = () => {
    ServiceRequestSound.handleStopSound();
    setOpenAlertDialog(false);
  };

  return (
    <>
      <NewLineupRequestAlertsContext.Provider
        value={{ hwNewLineupRequestAlertsContext, showNewLineupAlert }}
      >
        <AlertNewLineupRequest
          open={open_alert_dialog}
          setOpen={setOpenAlertDialog}
          onClose={handleCloseAlert}
        />
        {children}
      </NewLineupRequestAlertsContext.Provider>
    </>
  );
};
