import React from 'react';
import Sound from 'react-sound';

import DongSoundFile from 'src/assets/swiftly-610.wav';

export const OrderSoundContext = React.createContext();
export const OrderSoundContextProvider = ({ children }) => {
  let [play_sound, setPlaySound] = React.useState(false);

  const handlePlaySound = () => {
    // alert('helloworld');
    var audio1 = document.getElementById('audio-order-sound');
    audio1
      .play()
      .then(() => {
        console.log('play successfully');
      })
      .catch((err) => {
        console.log('play failed');
      });
  };

  React.useEffect(() => {
    if (play_sound) {
      handlePlaySound();
    }
  }, [play_sound]);

  const hwOrderSoundContext = () => {
    alert('hello audio context');
  };

  return (
    <>
      <OrderSoundContext.Provider
        value={{ handlePlaySound, hwOrderSoundContext }}
      >
        <audio id="audio-order-sound">
          <source src={DongSoundFile} type="audio/wav" />
        </audio>

        {children}
      </OrderSoundContext.Provider>
    </>
  );
};
