import React from 'react';

import {
  LYNKED_RED,
  LYNKED_WHITE,
  LYNKED_GREEN,
  LYNKED_GREEN_HOVER,
  LYNKED_LIGHT_GREY,
  LYNKED_DEEP_GREY,
  LYNKED_DEEP_GREY_HOVER,
} from 'src/consts/colors';

import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  helloworld: {},
  BillingCardContainer: {
    //
    borderRadius: '2px',
    boxShadow: '0px 1px 2px #00000029',
  },
  CardHeader: {
    backgroundColor: LYNKED_LIGHT_GREY,
    padding: '10px 20px',

    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
  },
  BillToTable: {
    font: 'normal normal normal 15px/20px Hiragino Sans',
  },
  BillToPerson: {
    font: 'normal normal normal 15px/20px Hiragino Sans',
  },
  BillEntryWrapper: {
    border: '1px solid #E0E0E0',
    padding: '5px 20px',
    maxHeight: '58px',
    minHeight: '58px',
  },
  BillPlaceholderWrapper: {
    padding: '5px 20px',
    maxHeight: '58px',
    minHeight: '58px',
  },
  BillEntryRowTop: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  BillEntryRowBottom: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  BillTime: {
    font: 'normal normal normal 9px/18px Hiragino Sans',
    color: LYNKED_LIGHT_GREY,
  },
  BillItemName: {
    padding: '5px 0px',
    font: 'normal normal normal 12px/18px Hiragino Sans',
  },
  ItemSubTotal: {
    color: LYNKED_RED,
  },
  CardFooter: {
    display: 'flex',
    flexFlow: 'column',
    padding: '0px 20px',
    paddingBottom: '20px',
    backgroundColor: '#FAFAFA',
  },
  BillTotal: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#FAFAFA',
  },
  FooterBody: {
    padding: '20px 2px',
    width: '45%',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    font: 'normal normal normal 15px/28px Hiragino Sans',
    color: LYNKED_GREEN,
  },
  FooterTotalPrice: {
    padding: '20px 0px',
    flexGrow: 1,
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    font: 'normal normal normal 12px/18px Hiragino Sans',
  },
  FooterItem: {},
  TotalPrice: {
    padding: '0px 10px',
    textAlign: 'right',
    font: 'normal normal normal 15px/28px Hiragino Sans',
    color: LYNKED_GREEN,
  },
  TotalExplain: {
    font: 'normal normal normal 10px/15px Hiragino Sans',
  },
  FooterButtonsGroup: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  FooterButtons: {
    width: '48%',
  },
  FooterButtonViewAllL: {
    width: '100%',
    backgroundColor: LYNKED_DEEP_GREY,
    color: LYNKED_WHITE,
    borderRadius: '1px',
    '&:hover': {
      backgroundColor: LYNKED_DEEP_GREY_HOVER,
    },
  },
  FooterButtonFinishBill: {
    width: '100%',
    backgroundColor: LYNKED_GREEN,
    color: LYNKED_WHITE,
    borderRadius: '1px',
    '&:hover': {
      backgroundColor: LYNKED_GREEN_HOVER,
    },
  },
}));

export default function BillPlaceholder() {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.BillPlaceholderWrapper}>
        <Box className={classes.BillEntryRowTop}>
          <Box className={classes.BillTime}></Box>
        </Box>
        <Box className={classes.BillEntryRowBottom}>
          <Box className={classes.BillItemName}></Box>
          <Box className={classes.ItemSubTotal}></Box>
        </Box>
      </Box>
    </>
  );
}
