import React from 'react';
import { GlobalContext } from 'src/contexts/Global';
import useMutateRestaurantConfigByRestId from 'src/hooks/useMutateRestaurantConfigByRestId';

export const RestaurantActiveContext = React.createContext();

export const RestaurantActiveProvider = ({ children }) => {
  const { rest_id } = React.useContext(GlobalContext);
  const [debug_res_json, setDebugResJson] = React.useState();
  const [restaurant_active, setRestaurantActive] = React.useState();
  const [isSkippableToAssignTable, setIsSkippableToAssignTable] =
    React.useState();
  let use_mutate_restaurant_config_by_rest_id =
    useMutateRestaurantConfigByRestId();

  React.useEffect(() => {
    if (rest_id) {
      use_mutate_restaurant_config_by_rest_id
        .mutateAsync({ rest_id })
        .then((res_json) => {
          setDebugResJson(res_json);
          setIsSkippableToAssignTable(res_json.isSkippableToAssignTable);
          if (res_json?.restaurant_active) {
            setRestaurantActive(res_json.restaurant_active);
          } else {
            // default to true if not defined
            setRestaurantActive(true);
          }
        });
    }
  }, [rest_id]);

  return (
    <RestaurantActiveContext.Provider
      value={{
        restaurant_active,
        setRestaurantActive,
        debug_res_json,
        isSkippableToAssignTable,
      }}
    >
      {children}
    </RestaurantActiveContext.Provider>
  );
};
