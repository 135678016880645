import React from 'react';
import { useTranslation } from 'react-i18next';

import active_lang from 'src/langs/jp_en';

export default function SubscribeToSeeReservationRequestedShortList() {
  const { t } = useTranslation();

  return (
    <>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {t('DASHBOARD_AVAIABLE_PLAN')}
      </div>
    </>
  );
}
