import React from 'react';

import { Box, Button, Grid } from '@material-ui/core';

import Page from 'src/components/Page';

import active_lang from 'src/langs/jp_en';

import MenyUsageTile from './MenyUsageTile';
import HelpRequestTile from './HelpRequestTile';
import AdImpressionsTile from './AdImpressionsTile';

import PerCustomerSpentTile from './PerCustomerSpentTile';
import PerOrderSpentTile from './PerOrderSpentTile';
import PerTableCustomersTile from './PerTableCustomersTile';
import TotalCustomersTodayTile from './TotalCustomersTodayTile';
import TotalOrdersTodayTile from './TotalOrdersTodayTile';
import TotalRevenueTodayTile from './TotalRevenueTodayTile';

import { GlobalContext } from 'src/contexts/Global';
import { isNull, isUndefined } from 'lodash';

import { SERVICE_MENY } from 'src/consts/MENY_SERVICE';

// const useStyles = makeStyles(() => ({
//   root: { width: '100%', padding: '3rem' },
//   DashboardContainer: { height: '100vh' },
//   Topbar: {
//     backgroundColor: LYNKED_RED,
//     minHeight: '7vh',
//     display: 'flex',
//     flexFlow: 'row',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//   },
//   Navbar: {
//     width: '16%',
//     minHeight: '93vh',
//     display: 'flex',
//     flexFlow: 'column',
//     justifyContent: 'space-between',
//   },
//   TopNavBody: { width: '100%' },
//   NavbarTopPart: { backgroundColor: 'white' },
//   NavbarBottomPart: {},
//   NavBody: { backgroundColor: LYNKED_WHITE, width: '84%' },
//   DashboardBody: { display: 'flex', flexFlow: 'row' },
//   ProfilePic: { minHeight: '22vh', backgroundColor: 'cyan' },
//   NavBodyHead: {
//     minHeight: '22vh',
//     backgroundColor: LYNKED_BACKGROUND_LIGHT_GREY,
//   },
//   NavBodyMainRight: {
//     backgroundColor: LYNKED_BACKGROUND_LIGHT_GREY,
//     height: '71vh',
//   },
//   blurContent: {
//     display: 'block',
//     filter: 'blur(15px)',
//     opacity: '0.95',
//   },
// }));

// https://www.chartjs.org/samples/latest/

import { useStyles } from './styles';

export default function SubscribeToSeeMoreButton() {
  return (
    <Box
      style={{
        position: 'absolute',
        top: '0px',
        width: '100%',
        height: '100%',

        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box style={{ font: 'normal normal bold 23px/30px', margin: '1rem' }}>
        {active_lang.UPGRADE_YOUR_PLAN_TO_SEE}
      </Box>
      <Box style={{ font: 'normal normal normal 12px/16px', margin: '1rem' }}>
        {active_lang.PLEASE_CONTACT_THE_SALES_TEAM}
      </Box>
      <Button
        color="primary"
        variant="contained"
        style={{ padding: '1rem', margin: '3rem' }}
        component="a"
        href="mailto:sales@lynked.co.jp"
      >
        sales@lynked.co.jp
      </Button>
    </Box>
  );
}
