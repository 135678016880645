import React from 'react';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  withStyles,
  InputBase,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import active_lang from 'src/langs/jp_en';
import { GlobalContext } from 'src/contexts/Global';
import WarningIcon from '@material-ui/icons/Warning';

import ShowDebug from 'src/components/ShowDebug';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

const AdWindowInput = withStyles(() => ({}))(InputBase);

export default function SelectFoodMenuCategory({ formik, food_details }) {
  let classes = useStyles();
  let { rest_id } = React.useContext(GlobalContext);
  const { t } = useTranslation();
  let { data, isLoading } = food_details;

  return (
    <>
      <Box mt={'0.5rem'}>
        <FormControl fullWidth>
          <Box>
            {t('COMMON_CATEGORY')}
            <ShowDebug>AD_CATEGORY_NAME</ShowDebug>
          </Box>
          {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
          {isLoading ? (
            <>{active_lang.AD_FOOD_CATEGORY_LOADING}</>
          ) : (
            <>
              <Select
                id="form_ad_destination"
                {...formik.getFieldProps('form_ad_destination')}
                value={formik.values.form_ad_destination}
                classes={{
                  root: classes.food_availability,
                  icon: classes.icon,
                }}
                input={<AdWindowInput />}
                IconComponent={KeyboardArrowDownIcon}
                style={{ paddingTop: '0.5rem' }}
                defaultValue={'please_select'}
              >
                <MenuItem value={'please_select'} disabled>
                  {t('AD_SELECT')}
                </MenuItem>
                {Object.keys(data.columns)
                  .sort()
                  .map((k, idx) => {
                    let column = data.columns[k];
                    return (
                      <MenuItem value={column.id} key={idx}>
                        {column.title}
                      </MenuItem>
                    );
                  })}
              </Select>
              {formik.touched.form_ad_destination &&
              formik.errors.form_ad_destination ? (
                <Box className={classes.user_input_error}>
                  <Box pr="1rem">
                    <WarningIcon fontSize="small" />
                  </Box>
                  <Box>{active_lang.AD_INVALID_FOOD_CATEGORY}</Box>
                </Box>
              ) : null}
            </>
          )}
        </FormControl>
      </Box>
    </>
  );
}
