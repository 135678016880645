import React from 'react';
import 'firebase/storage';

import { Dialog } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import NewFoodEditForm from './NewFoodEditForm';

import { Box } from '@material-ui/core';

import { GlobalContext } from 'src/contexts/Global';

import active_lang from 'src/langs/jp_en';
import { useTranslation } from 'react-i18next';

import { useStyles, Transition } from './styles';
import { dialog_transition_duration } from 'src/consts/TRANSITION';

export default function AddFoodDetailDialog({
  open,
  setOpen,
  loadFoodDetail,
  columns,
  food_menu,
  pre_select_category,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { rest_id } = React.useContext(GlobalContext);

  const [test, setTest] = React.useState(null);

  const handleClose = () => {
    // loadFoodDetail(rest_id);
    // resetForm();
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      transitionDuration={dialog_transition_duration}
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      keepMounted
      maxWidth={'md'}
    >
      <Box className={classes.DialogWrapper}>
        <Box className={classes.DialogHead}>
          <Box className={classes.title}>
            {/* 商品編集 */}
            {t('MENU_EDIT_ITEM')}
          </Box>
          <Box
            className={classes.DialogHeadButtons}
            style={{ display: 'none' }}
          >
            <Box>
              <FileCopyIcon />
            </Box>
            <Box>
              <DeleteIcon />
            </Box>
          </Box>
        </Box>
        <Box className={classes.DialogBody}>
          {false ? (
            <>{active_lang.FOOD_DETAIL_IS_LOADING}</>
          ) : (
            <>
              <NewFoodEditForm
                rest_id={rest_id}
                initial_value={test}
                handleClose={handleClose}
                columns={columns}
                food_menu={food_menu}
                pre_select_category={pre_select_category}
              />
            </>
          )}
        </Box>
      </Box>
    </Dialog>
  );
}
