import React from 'react';
import { Box } from '@material-ui/core';

import { useStyles } from './styles';

export default function BillPlaceholder() {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.BillPlaceholderWrapper}>
        <Box className={classes.BillEntryRowTop}>
          <Box className={classes.BillTime}></Box>
        </Box>
        <Box className={classes.BillEntryRowBottom}>
          <Box className={classes.BillItemName}></Box>
          <Box className={classes.ItemSubTotal}></Box>
        </Box>
      </Box>
    </>
  );
}
