import React from 'react';

import EditFoodDetailDialog from 'src/components/EditFoodDetailDialog';
import ConfirmRemoveFoodDetailDialog from 'src/components/ConfirmRemoveFoodDetailDialog';
import EditCategoryTitleDialog from 'src/components/EditCategoryTitleDialog';
import ConfirmRemoveFoodCategoryDialog from 'src/components/ConfirmRemoveFoodCategoryDialog';
import AddNewCategoryDialog from 'src/components/AddNewCategoryDialog';
import AddFoodDetailDialog from 'src/components/AddFoodDetailDialog';

import useMutateUpdateAllFoods from 'src/hooks/useMutateUpdateAllFoods';

// MENY-191, restaurant finish added new category, but try first time add new food, does not able to find the new created category in category list field
import { subscribeFoodMenuChange } from 'src/modals/food_details';

// import initial_data from '../long-initial-data';

import { GlobalContext } from 'src/contexts/Global';

import configs_devices from 'src/configs/devices';
import { isNull, isUndefined } from 'lodash';
import useMutateAddFoodCategory from 'src/hooks/useMutateAddFoodCategory';

import performDeleteCategory from 'src/utils/performDeleteCategory';
import { useSnackbar } from 'notistack';

import active_lang from 'src/langs/jp_en';
import { useTranslation } from 'react-i18next';

const init_food_menu = {
  columnOrder: [],
  food_details: {},
  columns: {},
};

let ShareContext = React.createContext();

export default function ShareContextProvider({ children }) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  let { rest_id } = React.useContext(GlobalContext);
  let [food_menu, setFoodMenu] = React.useState(init_food_menu);

  let mutationUpdateAllFoods = useMutateUpdateAllFoods();

  let [edit_food_detail, setEditFoodDetail] = React.useState(null);
  let [delete_food_detail, setDeleteFoodDetail] = React.useState({
    id: null,
    name: null,
  });
  const [open_edit_food_detail_dialog, setOpenEditFoodDetailDialog] =
    React.useState(false);
  const [open_add_food_detail_dialog, setOpenAddFoodDetailDialog] =
    React.useState(false);

  const [pre_select_category, setPreSelectCategory] = React.useState(null);

  const [categories_available, setCategoriesAvailable] = React.useState([
    { title: 'loading', id: 'loading' },
  ]);

  const [
    open_confirm_remove_food_detail_dialog,
    setOpenConfirmRemoveFoodDetailDialog,
  ] = React.useState(false);

  const [open_category_dialog, setOpenCategoryDialog] = React.useState(false);
  const [open_edit_category_title, setOpenEditCategoryTitle] =
    React.useState(null);

  const processUpdateCategoryTitle = ({
    category_id,
    category_title,
    category_show,
  }) => {
    let new_columns = food_menu.columns;
    new_columns[category_id].title = category_title;
    new_columns[category_id].category_show = category_show;
    let new_food_menu = { ...food_menu, columns: new_columns };

    console.log('processUpdateCategoryTitle', 'new_columns', new_columns);
    console.log('processUpdateCategoryTitle', 'new_food_menu', new_food_menu);

    return mutationUpdateAllFoods
      .mutateAsync({ rest_id, food_menu: new_food_menu })
      .catch((err) => {
        alert('save error');
        console.log('processUpdateCategoryTitle', 'err', err);
      });
  };

  const [category_id_delete, setCategoryIdDelete] = React.useState(null);

  const [
    open_confirm_remove_food_category_dialog,
    setOpenConfirmRemoveFoodCategoryDialog,
  ] = React.useState(false);

  const [last_save_food_menu, setLastSaveFoodMenu] = React.useState(null);

  const processDeleteCategory = (e, category_id, food_menu) => {
    mutationUpdateAllFoods.mutateAsync({
      rest_id,
      food_menu: performDeleteCategory(e, category_id, food_menu),
    });
  };

  const getCurrentCategoryTitle = (category_id) => {
    try {
      if (category_id !== null) {
        return food_menu.columns[category_id].title;
      } else {
        return 'null';
      }
    } catch (error) {
      console.error('cannot find category_id', category_id);
    }
  };

  const getCurrentCategoryShow = (category_id) => {
    try {
      if (isNull(category_id)) {
        return null;
      } else {
        return food_menu.columns[category_id].category_show;
      }
    } catch (error) {
      console.error('cannot find category_id', category_id);
    }
  };

  const [category_id_edit, setCategoryIdEdit] = React.useState(null);

  const processDeleteFoodDetail = (e, food_detail_id) => {
    try {
      let temp_food_details = food_menu.food_details;
      let new_food_details = {};

      // remove food details
      Object.keys(temp_food_details).forEach((k) => {
        if (k !== food_detail_id) {
          new_food_details[k] = temp_food_details[k];
        }
      });

      // remove food id in columns
      let temp_columns = food_menu.columns;
      Object.keys(temp_columns).forEach((k) => {
        temp_columns[k].taskIds = temp_columns[k].taskIds.filter(
          (x) => x !== food_detail_id
        );
      });
      const newFoodMenu = {
        ...food_menu,
        food_details: new_food_details,
        columns: temp_columns,
      };
      mutationUpdateAllFoods.mutateAsync({
        rest_id,
        food_menu: newFoodMenu,
      });
    } catch (error) {
    } finally {
      enqueueSnackbar(t('MENU_MENU_DELETED'), {
        variant: 'success',
      });
    }
  };

  const updateCategorySelector = (food_menu) => {
    console.log('updateCategorySelector', 'food_menu', food_menu);
    if (isUndefined(food_menu) || isUndefined(food_menu.columns)) {
    } else {
      let cat_list = Object.keys(food_menu.columns)
        .filter((k) => food_menu.columns[k].title !== 'initialize')
        .map((column_id) => {
          return {
            title: food_menu.columns[column_id].title,
            id: column_id,
          };
        });

      setCategoriesAvailable(cat_list);
      console.log('updateCategorySelector', 'cat_list', cat_list);
    }
  };

  let [load_food_detail_done, setLoadFoodDetailDone] = React.useState(false);
  const loadFoodDetail = (rest_id) => {
    if (rest_id !== null) {
      subscribeFoodMenuChange(rest_id).onSnapshot(
        { includeMetadataChanges: true },
        (doc) => {
          if (doc.data()) {
            setFoodMenu(doc.data());
            updateCategorySelector(doc.data());
            setLoadFoodDetailDone(true);
          } else {
            setFoodMenu({
              food_details: {},
              columns: {},
              columnOrder: [],
            });
          }
        }
      );
    }
  };

  const handleSaveFoodDetailClick = () => {
    mutationUpdateAllFoods
      .mutateAsync({ rest_id, food_menu })
      .then(() => {
        // alert('save done');
        console.log('handleSaveFoodDetailClick', 'save done');
      })
      .catch((err) => {
        // alert('save error');
        console.log('handleSaveFoodDetailClick', 'err', err);
      });
  };

  // MENY-264, After changed category name, add new food will show old category name
  let mutationAddFoodCategory = useMutateAddFoodCategory();
  const handleAddFoodCategory = ({ category_title, category_show }) => {
    return mutationAddFoodCategory
      .mutateAsync({ rest_id, category_title, category_show })
      .catch(() => {
        console.log('error during adding food category');
      });
  };

  const saveJson = () => {
    console.log('save json');
  };

  const reloadJson = () => {
    // console.log('reload json');
    // loadFoodDetail(rest_id);
  };

  let handleHelloworld = () => {
    console.log('helloworld');
  };

  const handleRefreshJson = () => {
    saveJson();
    // reloadJson();
  };

  const handleColumnEditClick = (e, column_id) => {
    // alert(`handleColumnEditClick ${column_id}`);
    console.log('handleColumnEditClick');
    setOpenEditCategoryTitle(true);
    setCategoryIdEdit(column_id);
  };
  const handleColumnDeleteClick = (e, column_id) => {
    setOpenConfirmRemoveFoodCategoryDialog(true);
    setCategoryIdDelete(column_id);
    handleRefreshJson();
  };
  const handleItemEditClick = (e, item_id) => {
    // alert(`handleItemEditClick ${item_id}`);
    let temp_food_detail = food_menu.food_details[item_id];
    console.log('temp_food_detail', temp_food_detail);
    setEditFoodDetail(temp_food_detail);
    setOpenEditFoodDetailDialog(true);
    handleRefreshJson();
  };
  const handleItemDeleteClick = (e, food_detail) => {
    console.log('delete_food_detail', 'food_detail', food_detail);
    setDeleteFoodDetail(food_detail);
    setOpenConfirmRemoveFoodDetailDialog(true);
    handleRefreshJson();
  };

  let [test, setTest] = React.useState(null);
  React.useEffect(() => {
    // updateCategorySelector();
    // alert('helloworld food_menu');
    console.log('food_menu', 'updateCategorySelector', food_menu);

    if (isNull(food_menu) || isUndefined(food_menu)) {
    } else {
      if (isNull(test)) {
        setTest(food_menu);
      } else {
        if (load_food_detail_done) {
          setLastSaveFoodMenu(food_menu);
          // handleSaveFoodDetailClick();
        }
      }
    }
  }, [food_menu]);

  React.useEffect(() => {
    console.log('last_save_food_menu', last_save_food_menu);
  }, [last_save_food_menu]);

  // React.useEffect(() => {
  //   // load food menu once rest_id ready
  //   if (rest_id !== null) {
  //     handleLoadFoodDetailClick();
  //     updateCategorySelector();
  //   }
  // }, [rest_id]);

  React.useEffect(() => {
    if (open_add_food_detail_dialog === false) {
      // handle close food detail dialog
      configs_devices.forceScreenScrollUp();
    }
    if (open_edit_food_detail_dialog === false) {
      // handle close food detail edit dialog
      configs_devices.forceScreenScrollUp();
    }
    if (open_edit_category_title === false) {
      // handle close food category edit dialog
      configs_devices.forceScreenScrollUp();
    }
    if (open_category_dialog === false) {
      // handle close food category add dialog
      configs_devices.forceScreenScrollUp();
    }
  }, [
    open_add_food_detail_dialog,
    open_edit_food_detail_dialog,
    open_edit_category_title,
    open_category_dialog,
  ]);

  return (
    <ShareContext.Provider
      value={{
        loadFoodDetail,
        handleHelloworld,
        handleColumnEditClick,
        handleColumnDeleteClick,
        handleItemEditClick,
        handleItemDeleteClick,
        setEditFoodDetail,
        food_menu,
        setFoodMenu,
        setOpenAddFoodDetailDialog,
        setOpenCategoryDialog,
        init_food_menu,
        pre_select_category,
        setPreSelectCategory,
        categories_available,
      }}
    >
      <EditFoodDetailDialog
        open={open_edit_food_detail_dialog}
        setOpen={setOpenEditFoodDetailDialog}
        food_detail={edit_food_detail}
        loadFoodDetail={loadFoodDetail}
        setEditFoodDetail={setEditFoodDetail}
        // columnOrder={`columnOrder`}
        columns={food_menu.columns}
        food_menu={food_menu}
        setFoodMenu={setFoodMenu}
      />

      <ConfirmRemoveFoodDetailDialog
        open={open_confirm_remove_food_detail_dialog}
        setOpen={setOpenConfirmRemoveFoodDetailDialog}
        delete_food_detail={delete_food_detail}
        setDeleteFoodDetail={setDeleteFoodDetail}
        loadFoodDetail={loadFoodDetail}
        processDeleteFoodDetail={processDeleteFoodDetail}
      />

      <EditCategoryTitleDialog
        open={open_edit_category_title}
        setOpen={setOpenEditCategoryTitle}
        processUpdateCategoryTitle={processUpdateCategoryTitle}
        category_id_to_edit={category_id_edit}
        setCategoryIdEdit={setCategoryIdEdit}
        getCurrentCategoryTitle={getCurrentCategoryTitle}
        getCurrentCategoryShow={getCurrentCategoryShow}
        loadFoodDetail={loadFoodDetail}
      />

      <ConfirmRemoveFoodCategoryDialog
        open={open_confirm_remove_food_category_dialog}
        setOpen={setOpenConfirmRemoveFoodCategoryDialog}
        processDeleteCategory={processDeleteCategory}
        category_id_to_delete={category_id_delete}
        setCategoryIdDelete={setCategoryIdDelete}
        getCurrentCategoryTitle={getCurrentCategoryTitle}
        food_menu={food_menu}
      />

      <AddNewCategoryDialog
        open={open_category_dialog}
        setOpen={setOpenCategoryDialog}
        handleAddCategory={handleAddFoodCategory}
        loadFoodDetail={loadFoodDetail}
      />

      <AddFoodDetailDialog
        open={open_add_food_detail_dialog}
        setOpen={setOpenAddFoodDetailDialog}
        categories_available={categories_available}
        loadFoodDetail={loadFoodDetail}
        pre_select_category={pre_select_category}
        handleSaveFoodDetailClick={handleSaveFoodDetailClick}
        columns={food_menu.columns}
        food_menu={food_menu}
      />
      {children}
    </ShareContext.Provider>
  );
}

export { ShareContext };
