import React from 'react';
import moment from 'moment-timezone';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import ShowDebug from 'src/components/ShowDebug';

import { acknowledgeServiceRequestById } from 'src/modals/service_requests';

import { GlobalContext } from 'src/contexts/Global';
import { useTranslation } from 'react-i18next';

import active_lang from 'src/langs/jp_en';
import { useStyles } from './styles';

function NoActiveHelpRequest() {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ textAlign: 'center', padding: '3rem' }}>
        {t('COMMON_NO_CALLS_MSG')}
        <ShowDebug>THERE_ARE_NO_HELP_REQUESTS_AT_THE_MOMENT</ShowDebug>
      </div>
    </>
  );
}

function ListHelpRequestsTable({ service_requests, rest_id }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [show_list, setShowList] = React.useState(service_requests);

  React.useEffect(() => {
    setShowList(show_list);
  }, [service_requests]);

  React.useEffect(() => {
    moment.locale('ja');
  }, [])

  const handleAcknowledgeServiceRequestClick = (_e, sr_id) => {
    // TODO: use rest_id
    acknowledgeServiceRequestById(rest_id, sr_id);
  };

  return (
    <>
      <Box style={{ marginTop: '1rem' }}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  className={classes.tableHeadText}
                  style={{ width: `${(100 / 12) * 2}%` }}
                >
                  {t('COMMON_RECEPTION_NUMBER')}
                </TableCell>
                <TableCell
                  className={classes.tableHeadText}
                  style={{ width: `${(100 / 12) * 3}%` }}
                >
                  {t('COMMON_RECEPTION_TIME')}
                </TableCell>
                <TableCell
                  className={classes.tableHeadText}
                  style={{ width: `${(100 / 12) * 2}%` }}
                >
                  {t('COMMON_TABLE')}
                  <ShowDebug>Table</ShowDebug>
                </TableCell>
                <TableCell
                  className={classes.tableHeadText}
                  style={{ width: `${(100 / 12) * 2}%` }}
                >
                  { t('HELP_REQUEST_NAME') }
                </TableCell>
                <TableCell
                  className={classes.tableHeadText}
                  style={{ width: `${(100 / 12) * 3}%` }}
                >
                  {t('COMMON_STATUS')}
                </TableCell>

                <Box style={{ display: 'none' }}>
                  <ShowDebug>
                    <TableCell className={classes.tableHeadText}>
                      debug
                    </TableCell>
                  </ShowDebug>
                </Box>
              </TableRow>
            </TableHead>

            <TableBody>
              {service_requests.map((row, idx) => (
                <TableRow key={`${row.id}`}>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.tableBodyText}
                  >
                    {`${idx + 1}`}
                  </TableCell>
                  <TableCell className={classes.tableBodyText}>
                    {moment(row.update_time).format(t('COMMON_TIME_FORMAT'))}
                  </TableCell>
                  <TableCell className={classes.tableBodyText} style={{ color: 'black' }}>
                    {row.table_name}
                  </TableCell>
                  <TableCell className={classes.tableBodyText}>
                    {row.username}
                  </TableCell>
                  <TableCell>
                    <Button
                      id={`button-confirm-help-request-${idx}`}
                      className={classes.Buttons}
                      onClick={(e) => {
                        handleAcknowledgeServiceRequestClick(e, row.id);
                      }}
                    >
                      {t('HELP_REQUEST_CONFIRM')}
                    </Button>
                  </TableCell>

                  <Box style={{ display: 'none' }}>
                    <ShowDebug>
                      <TableCell>
                        <pre>{JSON.stringify(row, null, 2)}</pre>
                      </TableCell>
                    </ShowDebug>
                  </Box>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box style={{ display: 'none' }}>
          <ShowDebug>
            <pre>{JSON.stringify(service_requests, null, 2)}</pre>
          </ShowDebug>
        </Box>
      </Box>
    </>
  );
}

export default function HelpRequestsTable({
  service_requests,
  setServiceRequests,
}) {
  let [show_service_requests, setShowServiceRequests] =
    React.useState(service_requests);
  let { rest_id } = React.useContext(GlobalContext);

  React.useEffect(() => {
    if (service_requests == null) {
      setShowServiceRequests([]);
    } else {
      setShowServiceRequests(service_requests);
    }
  }, [service_requests]);

  return (
    <>
      {service_requests == null || service_requests.length < 1 ? (
        <NoActiveHelpRequest />
      ) : (
        <ListHelpRequestsTable
          service_requests={show_service_requests}
          setServiceRequests={setServiceRequests}
          rest_id={rest_id}
        />
      )}
    </>
  );
}
