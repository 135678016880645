// meny-210, Add/Edit category dialog text field does not handle "Enter" keypress event on windows and iPad

import React from 'react';

import Slide from '@material-ui/core/Slide';

import { makeStyles } from '@material-ui/core';

import {
  LYNKED_RED,
  LYNKED_WHITE,
  LYNKED_GREEN,
  LYNKED_GREEN_HOVER,
  LYNKED_LIGHT_GREY,
  LYNKED_DEEP_GREY,
  LYNKED_DEEP_GREY_HOVER,
} from 'src/consts/colors';

// MENY-191, restaurant finish added new category, but try first time add new food, does not able to find the new created category in category list field

const useStyles = makeStyles(() => ({
  helloworld: {},
  AddCategoryDialogWrapper: {
    margin: '1rem',
  },
  TextInput: {
    border: '1px solid #E0E0E0',
    font: 'normal normal bold 12px/16px Roboto',
    '& ::placeholder': {
      color: LYNKED_LIGHT_GREY,
      padding: '10px 20px',
    },
  },
  DialogFooter: {
    width: '100%',

    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
  },
  DialogBody: {
    padding: '3rem 0px',
  },
  ButtonCancel: {
    width: '48%',
    backgroundColor: LYNKED_DEEP_GREY,
    '&:hover': {
      backgroundColor: LYNKED_DEEP_GREY_HOVER,
    },
    color: LYNKED_WHITE,
    borderRadius: '1px',
    fontSize: '11px',
  },
  ButtonOK: {
    width: '48%',
    backgroundColor: LYNKED_GREEN,
    '&:hover': {
      backgroundColor: LYNKED_GREEN_HOVER,
    },
    color: LYNKED_WHITE,
    borderRadius: '1px',
    fontSize: '11px',
  },
  Buttons: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    borderRadius: '1px',
  },
  user_input_error: {
    color: LYNKED_RED,
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '0.25rem',
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
  },
  TextInputTitle: {
    fontWeight: 'bold'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export { useStyles, Transition };
