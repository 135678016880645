import React from 'react';
import { useNavigate } from 'react-router-dom';

import { IconButton, Box } from '@material-ui/core';

import NotificationsIcon from '@material-ui/icons/Notifications';
import EmailIcon from '@material-ui/icons/Email';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import LinkIcon from '@material-ui/icons/Link';

import ShowDebug from 'src/components/ShowDebug';
import LanguageDropdown from 'src/components/LanguageDropdown'
import { GlobalContext } from 'src/contexts/Global';

import OpeningHourToggleButton from 'src/components/OpeningHourToggleButton';

import { useStyles } from './styles';
import { UtilContext } from 'src/contexts/Util';

export default function Topbar({
  open_nav_bar,
  setOpenNavBar,
  show_opening_hour_toggle_button,
}) {
  const classes = useStyles();
  const navigate = useNavigate();
  let { screen_width, screen_height } = React.useContext(UtilContext);

  let { rest_id, restaurant_config } = React.useContext(GlobalContext);

  const handleLogoutButtonOnClick = () => {
    navigate('/logout');
  };

  const handleNotificationButtonOnClick = () => {};

  const handleMessagingButtonOnClick = () => {};

  // let [show_opening_hour_toggle_button, setShowOpeningHourToggleButton] =
  // React.useState(false);

  const handleToggleLeftNavOnClick = () => {
    // togoogle nav bar
    setOpenNavBar(!open_nav_bar);
  };

  return (
    <>
      <Box className={classes.Topbar}>
        <Box className={classes.TopBarLeft}>
          <Box id="lynked-logo-svg" className={classes.lynkedLogo}></Box>
          <ShowDebug>
            <Box className={classes.debug_viewport}>
              <Box className={classes.viewport_xs}>XS</Box>
              <Box className={classes.viewport_sm}>SM</Box>
              <Box className={classes.viewport_md}>MD</Box>
              <Box className={classes.viewport_lg}>LG</Box>
              <Box className={classes.viewport_xl}>XL</Box>
            </Box>
            <Box>{`${screen_width},${screen_height}`}</Box>
          </ShowDebug>
        </Box>
        <Box className={classes.TopBarRightButtonContainer}>
          <ShowDebug>
            <div style={{ display: 'flex' }}>
              <pre>{rest_id}</pre>
              <IconButton
                href={`http://${process.env.REACT_APP_MENY_CLIENT_TEST_HOST}/meny/${rest_id}/${rest_id}/NewUserlineup`}
                target={'_blank'}
                rel="noreferrer"
              >
                <LinkIcon />
              </IconButton>
            </div>
          </ShowDebug>

          {show_opening_hour_toggle_button ? (
            <OpeningHourToggleButton />
          ) : (
            <></>
          )}

          <Box className={classes.TopBarNavToggleContainer}>
            <IconButton id="show-nav-menu" onClick={handleToggleLeftNavOnClick}>
              <MenuIcon />
            </IconButton>
          </Box>

          <Box className={classes.TopBarRightSecondaryButtonContainer} style={{display: 'flex'}}>
            {restaurant_config?.isEnableBellRing && (
              <IconButton
                id={'top-nav-bar-notification'}
                onClick={handleNotificationButtonOnClick}
              >
                <NotificationsIcon />
              </IconButton>
            )}
            <IconButton
              id={'top-nav-bar-message'}
              onClick={handleMessagingButtonOnClick}
            >
              <EmailIcon />
            </IconButton>
            <LanguageDropdown />
            <IconButton
              id={'top-nav-bar-logout'}
              onClick={handleLogoutButtonOnClick}
            >
              <ExitToAppIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  );
}
