import { useQuery } from 'react-query';

import { getAdImpressionByRestId } from 'src/modals/usage_statistics';

const useQueryAdImpression = (rest_id) => {
  return useQuery(
    'query-advertisement',
    () => {
      return getAdImpressionByRestId(rest_id).then((result) => result);
    },
    { refetchOnWindowFocus: false }
  );
};

export default useQueryAdImpression;
