import React from 'react';
import { Box, Grid } from '@material-ui/core';

import AddAdvertisementDialog from 'src/components/AddAdvertisementDialog';
import AddNewAdsButton from 'src/components/AddNewAdsButton';
import AdvertisementTable from 'src/components/AdvertisementTable';
import SearchInput from 'src/components/SearchInput';

import useListAdvertisement from 'src/hooks/useListAdvertisement';
import MutateListFoodDetails from 'src/hooks/MutateListFoodDetails';

import ListLoadingScreen from './ListLoadingScreen';

import { isUndefined, isNull } from 'lodash';

export default function ShowAdvertisementPage({ rest_id }) {
  let [filter_by_words, setFilterByWords] = React.useState('');

  let advertisement_result = useListAdvertisement(rest_id);
  let { data, isLoading, status, refetch } = advertisement_result;
  let food_detail_result = MutateListFoodDetails(rest_id);

  const handleSearchInput = (e) => {
    if (e.target) {
      setFilterByWords(e.target.value.trim());
    }
  };

  let [open_add_advertisement_dialog, setOpenAddAdvertisementDialog] =
    React.useState(false);

  React.useEffect(() => {
    console.log('ShowAdvertisementPage', 'rest_id', rest_id);
  }, [rest_id]);

  let [show_loading, setShowLoading] = React.useState(true);
  React.useEffect(() => {
    // alert('helloworld');
    if (advertisement_result.isSuccess && food_detail_result.isSuccess) {
      setShowLoading(false);
    }
  }, [advertisement_result, food_detail_result]);

  React.useEffect(() => {
    food_detail_result.mutate();
  }, []);

  let [show_add_advertisement_button, setShowAddAdvertisementButton] =
    React.useState(true);

  React.useEffect(() => {
    if (isUndefined(data) || isNull(data)) {
    } else {
      if (data.length >= 2) {
        setShowAddAdvertisementButton(false);
      } else {
        setShowAddAdvertisementButton(true);
      }
    }
  }, [data]);

  return (
    <>
      <Box style={{ width: '100%' }}>
        {show_loading ? (
          <>
            <ListLoadingScreen />
          </>
        ) : (
          <>
            <AddAdvertisementDialog
              open={open_add_advertisement_dialog}
              setOpen={setOpenAddAdvertisementDialog}
              refetch={refetch}
              advertisement_result={advertisement_result}
            />
            <Grid container>
              <Grid item xs={6}>
                {/* <Box
                  style={{ font: 'normal normal bold 20px/26px Noto Sans JP' }}
                >
                  <SearchInput handleUpdateFilterWords={handleSearchInput} />
                </Box> */}
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                {show_add_advertisement_button ? (
                  <>
                    <AddNewAdsButton
                      onClick={(e) => {
                        setOpenAddAdvertisementDialog(true);
                      }}
                    />
                  </>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item xs={12}>
                <AdvertisementTable
                  filter_by_words={filter_by_words}
                  advertisements={data}
                  refetch={refetch}
                  food_details={food_detail_result.data}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </>
  );
}
