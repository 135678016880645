import React from 'react';
import { isNull, isUndefined } from 'lodash';
import {
  LYNKED_DEEP_GREY,
  LYNKED_DEEP_GREY_HOVER,
  LYNKED_GREEN,
  LYNKED_GREEN_HOVER,
  LYNKED_LIGHT_GREY,
  LYNKED_RED,
  LYNKED_WHITE,
} from 'src/consts/colors';

import BillPlaceholder from './BillPlaceholder';

import BillDetailDialog from 'src/components/BillDetailDialog';
import ConfirmClearBillDialog from 'src/components/ConfirmClearBillDialog';
import ShowYen from 'src/components/ShowYen';

import { Box, Button, makeStyles } from '@material-ui/core';

import active_lang from 'src/langs/jp_en';

import covertDecimalPrice from 'src/helpers/convertDecimalPrice';

import { STATUS_CANCELLED } from 'src/consts/ORDER_STATUS';

import BillEntry from './BillEntry';
import { useTranslation } from 'react-i18next';

const NOT_ACCOUNT_LIST = [STATUS_CANCELLED];

const useStyles = makeStyles(() => ({
  helloworld: {},
  BillingCardContainer: {
    //
    borderRadius: '2px',
    boxShadow: '0px 1px 2px #00000029',
  },
  CardHeader: {
    backgroundColor: LYNKED_LIGHT_GREY,
    padding: '10px 20px',

    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    color: 'black'
  },
  BillToTable: {
    font: 'bold normal normal 15px/20px Noto Sans JP',
  },
  BillToPerson: {
    font: 'bold normal normal 15px/20px Noto Sans JP',
  },
  BillEntryWrapper: {
    border: '1px solid #E0E0E0',
    padding: '5px 20px',
    maxHeight: '58px',
    minHeight: '58px',
  },
  BillPlaceholderWrapper: {
    padding: '5px 20px',
    maxHeight: '58px',
    minHeight: '58px',
  },
  BillEntryRowTop: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  BillEntryRowBottom: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  BillTime: {
    font: 'normal normal normal 9px/18px Noto Sans JP',
    color: LYNKED_LIGHT_GREY,
  },
  BillItemName: {
    padding: '5px 0px',
    font: 'normal normal normal 12px/18px Noto Sans JP',
  },
  ItemSubTotal: {
    color: LYNKED_RED,
  },
  CardFooter: {
    display: 'flex',
    flexFlow: 'column',
    padding: '0px 20px',
    paddingBottom: '20px',
    backgroundColor: '#FAFAFA',
  },
  BillTotal: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#FAFAFA',
    fontWeight: 'bold'
  },
  FooterBody: {
    padding: '20px 2px',
    width: '45%',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    font: 'bold normal normal 15px/28px Noto Sans JP',
    color: LYNKED_GREEN,
  },
  FooterTotalPrice: {
    padding: '20px 0px',
    flexGrow: 1,
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    font: 'bold normal normal 12px/18px Noto Sans JP',
  },
  FooterItem: {},
  TotalPrice: {
    padding: '0px 10px',
    textAlign: 'right',
    font: 'bold normal normal 15px/28px Noto Sans JP',
    color: LYNKED_GREEN,
  },
  TotalExplain: {
    font: 'bold normal normal 10px/15px Noto Sans JP',
  },
  FooterButtonsGroup: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  FooterButtons: {
    width: '48%',
  },
  FooterButtonViewAllL: {
    width: '100%',
    backgroundColor: LYNKED_DEEP_GREY,
    color: LYNKED_WHITE,
    borderRadius: '1px',
    '&:hover': {
      backgroundColor: LYNKED_DEEP_GREY_HOVER,
    },
  },
  FooterButtonFinishBill: {
    width: '100%',
    backgroundColor: LYNKED_GREEN,
    color: LYNKED_WHITE,
    borderRadius: '1px',
    '&:hover': {
      backgroundColor: LYNKED_GREEN_HOVER,
    },
  },
}));

export default function ShowBillingManagementCard({ bill, id, idx }) {
  const classes = useStyles();
  const { order_username, order_table_number, orders } = bill;
  const { t } = useTranslation();

  const [total_price, setTotalPrice] = React.useState(0);
  const [total_item, setTotalItem] = React.useState(0);
  const [open_bill_detail_dialog, setOpenBillDetailDialog] =
    React.useState(false);
  const [open_confirm_clear_bill_dialog, setOpenConfirmClearBillDialog] =
    React.useState(false);

  const handleViewBillDetail = () => {
    setOpenBillDetailDialog(true);
  };
  const handleFinishBill = () => {
    setOpenConfirmClearBillDialog(true);
  };

  React.useEffect(() => {
    let temp_total_price = 0;
    let valid_orders = orders.filter(
      (o) => !NOT_ACCOUNT_LIST.includes(o.status)
    );
    console.log('valid_orders', valid_orders);
    valid_orders.forEach(
      (o) => (temp_total_price = temp_total_price + o.item_subtotal)
    );

    setTotalPrice(Math.max(0, covertDecimalPrice(temp_total_price)));

    let temp_total_item = 0;
    valid_orders.forEach(
      (o) => (temp_total_item = temp_total_item + o.item_quantity)
    );
    setTotalItem(temp_total_item);
  }, [bill]);

  return (
    <>
      {/* TODO: change orders -> order as just a single item only */}
      {/* <pre>{JSON.stringify(bill, null, 2)}</pre> */}
      <BillDetailDialog
        orders={orders}
        open={open_bill_detail_dialog}
        setOpen={setOpenBillDetailDialog}
        bill={bill}
        id={id}
      />
      <ConfirmClearBillDialog
        orders={orders}
        open={open_confirm_clear_bill_dialog}
        setOpen={setOpenConfirmClearBillDialog}
        bill={bill}
        id={id}
        table_name={order_table_number}
      />
      <Box className={classes.BillingCardContainer}>
        <Box className={classes.CardHeader}>
          <Box className={classes.BillToTable}>
            {t('COMMON_TABLE') + order_table_number}
          </Box>
          <Box className={classes.BillToPerson}>{t('BILL_MR', { name: order_username })}</Box>
        </Box>
        <Box>
          {[0, 1, 2].map((i, idx) => {
            if (isNull(orders[i]) || isUndefined(orders[i])) {
              return <BillPlaceholder key={idx} />;
            } else {
              return <BillEntry entry={orders[i]} key={idx} />;
            }
          })}
        </Box>
        <Box className={classes.CardFooter}>
          <Box className={classes.BillTotal}>
            <Box className={classes.FooterBody}>
              <Box>{t('COMMON_TOTAL')} {total_item} {t('COMMON_POINTS')}</Box>
            </Box>
            <Box className={classes.FooterTotalPrice}>
              <Box className={classes.TotalPrice}>
                <ShowYen value_yen={total_price} />
              </Box>
              <Box className={classes.TotalExplain}>{ t('BILL_TOTAL') }</Box>
            </Box>
          </Box>
          <Box className={classes.FooterButtonsGroup}>
            <Box className={classes.FooterButtons}>
              <Button
                id="button-show-bill-detail"
                onClick={handleViewBillDetail}
                className={classes.FooterButtonViewAllL}
              >
                {t('COMMON_SEE_ALL')}
              </Button>
            </Box>
            <Box className={classes.FooterButtons}>
              <Button
                id={`button-finish-bill-${idx}`}
                className={classes.FooterButtonFinishBill}
                onClick={handleFinishBill}
              >
                {t('BILL_PAYMENT_COMPLETE')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
