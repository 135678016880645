import React from 'react';
import { Box, TextField, Container, Typography } from '@material-ui/core';

export default function ShowDebugJson({ children }) {
  const handleFocus = (event) => event.target.select();

  return (
    <>
      <Box
        style={{
          display: process.env.NODE_ENV == 'development' ? 'block' : 'none',
          backgroundColor: 'gold',
          width: '100%',
          maxheight: '3rem',
        }}
      >
        <TextField
          value={children}
          size="small"
          onFocus={handleFocus}
          fullWidth
          multiline
        />
      </Box>
    </>
  );
}
