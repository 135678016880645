import React from 'react';
import { SharedContext } from '../SharedContext';

import DongSoundFile from 'src/assets/swiftly-610.mp3';

export const PreCachesContext = React.createContext();

export function PreCaches() {
  let { setPreCacheDone } = React.useContext(SharedContext);

  React.useEffect(() => {
    fetch(DongSoundFile).then(() => {
      setPreCacheDone(true);
      console.log('PreCache', 'setPreCacheDone');
    });
  }, []);

  return <></>;
}
