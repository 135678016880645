import React from 'react';

import SelectMenyAdConfigureSlot from './SelectMenyAdConfigureSlot';
import {
  SERVICE_MENY_LIGHT,
  SERVICE_MENY,
  SERVICE_MENY_GO,
} from 'src/consts/MENY_SERVICE';
import SelectMenyLightAdConfigureSlot from './SelectMenyLightAdConfigureSlot';

import { isUndefined, isNull } from 'lodash';

import active_lang from 'src/langs/jp_en';

export default function SelectAdConfigureSlot({
  service_class,
  formik,
  advertisement_result,
}) {
  if (isUndefined(service_class) || isNull(service_class)) {
  } else {
    switch (service_class.id) {
      case SERVICE_MENY:
        return (
          <SelectMenyAdConfigureSlot
            formik={formik}
            advertisement_result={advertisement_result}
          />
        );
      case SERVICE_MENY_LIGHT:
        return (
          <SelectMenyLightAdConfigureSlot
            formik={formik}
            advertisement_result={advertisement_result}
          />
        );
      case SERVICE_MENY_GO:
        return (
          <SelectMenyAdConfigureSlot
            formik={formik}
            advertisement_result={advertisement_result}
          />
        );
      default:
        return <>{JSON.stringify(service_class, null, 2)}</>;
    }
  }

  return <>{active_lang.AD_ACTION_LOADING}</>;
}
