import React from 'react';

import Slide from '@material-ui/core/Slide';

import { makeStyles } from '@material-ui/core';

import {
  LYNKED_DEEP_GREY,
  LYNKED_LIGHT_GREY,
  LYNKED_DEEP_GREY_HOVER,
  LYNKED_GREEN,
  LYNKED_GREEN_HOVER,
  LYNKED_RED,
  LYNKED_WHITE,
} from 'src/consts/colors';

const useStyles = makeStyles(() => ({
  helloworld: {},
  TextInput: {
    border: '1px solid #E0E0E0',
    font: 'normal normal bold 12px/16px Roboto',
    padding: '3px 3px',
    '& ::placeholder': {
      color: LYNKED_LIGHT_GREY,
    },
  },
  ButtonCancel: {
    backgroundColor: LYNKED_DEEP_GREY,
    '&:hover': {
      backgroundColor: LYNKED_DEEP_GREY_HOVER,
    },
  },
  ButtonOK: {
    backgroundColor: LYNKED_GREEN,
    '&:hover': {
      backgroundColor: LYNKED_GREEN_HOVER,
    },
  },
  DialogHead: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  DialogWrapper: {
    margin: '30px 30px',
  },
  DialogBody: {
    padding: '10px',
  },
  DialogHeadButtons: {
    display: 'flex',
    flexFlow: 'row',
  },
  Buttons: {
    width: '48%',
    padding: '10px',
    color: 'white',
    font: 'normal normal normal 11px/15px Hiragino Sans',

    letterSpacing: '0.12px',
    borderRadius: '1px',
  },
  DialogBottomButtons: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  AddImageButton: {
    color: LYNKED_RED,
  },
  TextInputTitle: {
    font: 'normal normal bold 12px/16px Roboto',
    paddingBottom: '5px',
  },
  TextInputGroup: {
    // padding: '10px 10px',
    width: '100%',
  },
  icon: {
    color: '#707070',
    marginRight: '1rem',
    marginTop: '0.25rem',
  },
  root: {
    marginTop: '10px',
    // border: '1px solid #e2e2e2',
    borderRadius: '3px',
    padding: '10px 26px 10px 12px',
  },
  selectLabel: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '17px',
  },
  food_availability: {
    border: '1px solid #e2e2e2',
    padding: '0.5rem',
  },
  AddImagePlaceHolder: {
    background: `${LYNKED_WHITE} 0% 0% no-repeat padding-box`,
    border: `1px dashed ${LYNKED_RED}`,
    borderRadius: '9px',

    width: '100%',
    minHeight: '300px',
    height: '100%',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export { useStyles, Transition };
