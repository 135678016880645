import React from 'react';

import active_lang from 'src/langs/jp_en';

import ShowBillingManagementCard from './ShowBillingManagementCard';
import { useTranslation } from 'react-i18next';

export default function BillingManagementCard({ bill, id, idx }) {
  const { orders } = bill;
  const [show_content, setShowContent] = React.useState(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (bill && orders != null) {
      setShowContent(true);
    }
  }, [bill, orders]);

  return (
    <>
      {show_content ? (
        <>
          <ShowBillingManagementCard bill={bill} id={id} idx={idx} />{' '}
        </>
      ) : (
        <>{t('COMMON_LOADING')}</>
      )}
    </>
  );
}
