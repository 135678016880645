import React from 'react';
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
  useMutation,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { setAdvertisementByAdvertisementId } from 'src/modals/advertisement_config';

export default function useUpdateAdvertisement() {
  const mutation = useMutation(
    'update-advertisement',
    ({ advertisement_id, payload }) => {
      console.log('advertisement_id, payload', advertisement_id, payload);
      return setAdvertisementByAdvertisementId(advertisement_id, payload);
    }
  );

  return mutation;
}
