import React from 'react';
import { Box, IconButton } from '@material-ui/core';

import AddButton from './AddButton';

export default function AddCustomizeGroupButton({
  handleAddNewCustomizeSetting,
}) {
  return (
    <>
      <Box
        style={{
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <IconButton
          id="add_customize_group_button"
          onClick={handleAddNewCustomizeSetting}
        >
          <AddButton />
        </IconButton>
      </Box>
    </>
  );
}
