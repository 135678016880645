import React from 'react';

import active_lang from 'src/langs/jp_en';
import { useTranslation } from 'react-i18next';

export default function AdvertisementPageLoading() {
  const { t } = useTranslation();

  return <>{t('AD_LOADING_BANNER')}</>;
}
