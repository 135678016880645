import React from 'react';
import { Box, TextField, makeStyles } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

import active_lang from 'src/langs/jp_en';
import RubbishBinButton from './RubbishBinButton';

import { sanitizeFullWidthNumberWithPositiveAndNegative } from 'src/utils/sanitizeFullWidthNumberWithPositiveAndNegative';
import { testNotNullAndIsDefined } from 'src/utils/testNotNullAndIsDefined';

import {
  LYNKED_RED,
  LYNKED_WHITE,
  LYNKED_LIGHT_GREY,
  LYNKED_DEEP_GREY,
} from 'src/consts/colors';
import { isUndefined } from 'lodash';

import { REGEX_TEST_NUMBER_POS_NEG } from './regex_consts';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  TextInput: {
    border: '1px solid #E0E0E0',
    font: 'normal normal bold 12px/16px Roboto',
    padding: '3px 3px',
    '& ::placeholder': {
      color: LYNKED_LIGHT_GREY,
    },
  },
}));

export default function CustomizeGroupElement({
  customize_group_setting,
  idx,
  onChange,
  value,
  error_objs,
  formik,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const handleRubbishBinOnClick = () => {
    let new_customize_group_settings = value.filter((x, i) => i !== idx);
    onChange({
      target: {
        id: 'customize_group_settings',
        value: new_customize_group_settings,
      },
    });
  };

  const handleUpdateOptionName = (new_value, idx) => {
    let new_customize_group_settings = value;
    new_customize_group_settings[idx].name = new_value;
    onChange({
      target: {
        id: 'customize_group_settings',
        value: new_customize_group_settings,
      },
    });
  };

  const handleUpdateOptionPrice = (new_value, idx) => {
    console.log('handleUpdateOptionPrice', 'new_value', new_value);
    if (testNotNullAndIsDefined(new_value)) {
      let sanitized_new_value = new_value;
      let new_customize_group_settings = [...value];
      new_customize_group_settings[idx].price = sanitized_new_value;

      onChange({
        target: {
          id: 'customize_group_settings',
          value: new_customize_group_settings,
        },
      });
    }
  };

  const handleCustomizePriceFieldOnBlur = (e, idx) => {
    console.log('handleCustomizePriceFieldOnBlur');
    formik.handleBlur(e);
    if (testNotNullAndIsDefined(e.target)) {
      if (REGEX_TEST_NUMBER_POS_NEG.test(e.target.value)) {
        let new_customize_group_settings = value;
        let temp = sanitizeFullWidthNumberWithPositiveAndNegative(
          e.target.value
        );
        console.log('handleCustomizePriceFieldOnBlur', 'temp', Number(temp));
        new_customize_group_settings[idx].price = Number(temp);
        formik.handleChange({
          target: {
            id: 'customize_group_settings',
            value: new_customize_group_settings,
          },
        });
      } else {
        // skip if not match
        console.log('handleCustomizePriceFieldOnBlur', 'skip');
      }
    }
  };

  React.useEffect(() => {
    console.log('value', value);
  }, [value]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: '100%',
            background: LYNKED_WHITE,
            borderColor: LYNKED_DEEP_GREY,
            border: '1px solid ',
            boxSizing: 'border-box',
            borderRadius: '5px',

            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            alignItems: 'center',

            padding: '0.5rem',
          }}
        >
          {/* <pre>{JSON.stringify(customize_group_setting, null, 2)}</pre> */}
          <div>
            <RubbishBinButton handleOnClick={handleRubbishBinOnClick} />
          </div>
          <div
            style={{
              paddingTop: '1rem',
              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                fontFamily: 'Noto Sans JP',
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '14px',
                lineHeight: '20px',
                textAlign: 'center',
              }}
            >
              {t('MENU_TITLE')}
            </div>
            <TextField
              id={`customize_group_name_${idx}`}
              value={value[idx].name}
              className={classes.TextInput}
              InputProps={{ disableUnderline: true }}
              inputProps={{
                style: { padding: '0.25rem', textAlign: 'center' },
              }}
              onInput={(e) => {
                handleUpdateOptionName(e.target.value, idx);
              }}
              placeholder={t('MENU_TITLE')}
              onBlur={formik.handleBlur}
            />
            {(formik.touched[`customize_group_name_${idx}`] ||
              !isUndefined(formik.touched['customize_group_settings'])) &&
            error_objs &&
            !isUndefined(error_objs[idx]) &&
            !isUndefined(error_objs[idx].name) ? (
              <Box
                style={{
                  color: LYNKED_RED,
                  display: 'flex',
                  flexFlow: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  paddingLeft: '0.25rem',
                  paddingTop: '0.25rem',
                  paddingBottom: '0.25rem',
                }}
              >
                <Box pr="1rem">
                  <WarningIcon fontSize="small" />
                </Box>
                <Box>{error_objs[idx].name}</Box>
              </Box>
            ) : null}
          </div>

          <div
            style={{
              paddingTop: '0.5rem',
              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                fontFamily: 'Noto Sans JP',
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '14px',
                lineHeight: '20px',
                textAlign: 'center',
              }}
            >
              {t('COMMON_PRICE')}
            </div>
            <TextField
              id={`customize_group_price_${idx}`}
              value={customize_group_setting.price}
              className={classes.TextInput}
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{
                style: { padding: '0.25rem', textAlign: 'center' },
              }}
              onChange={(e) => {
                handleUpdateOptionPrice(e.target.value, idx);
              }}
              placeholder={t('COMMON_PRICE')}
              onBlur={(e) => {
                handleCustomizePriceFieldOnBlur(e, idx);
              }}
            />

            <Box>
              {(formik.touched[`customize_group_price_${idx}`] ||
                !isUndefined(formik.touched['customize_group_settings'])) &&
              error_objs &&
              !isUndefined(error_objs[idx]) &&
              !isUndefined(error_objs[idx].price) ? (
                <Box
                  style={{
                    color: LYNKED_RED,
                    display: 'flex',
                    flexFlow: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    paddingLeft: '0.25rem',
                    paddingTop: '0.25rem',
                    paddingBottom: '0.25rem',
                  }}
                >
                  <Box pr="1rem">
                    <WarningIcon fontSize="small" />
                  </Box>
                  <Box>{error_objs[idx].price}</Box>
                </Box>
              ) : null}
            </Box>
          </div>
        </div>
      </div>
    </>
  );
}
