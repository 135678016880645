import React from 'react';
import { Detector } from 'react-detect-offline';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { MonitorNetworkStatusContextProvider } from 'src/contexts/MonitorNetworkStatus';
import { SnackbarProvider } from 'notistack';

import WifiOffIcon from '@material-ui/icons/WifiOff';
import Typography from '@material-ui/core/Typography';
import { LYNKED_RED, LYNKED_WHITE } from 'src/consts/colors';

import active_lang from 'src/langs/jp_en';

export const NetworkStatusContext = React.createContext();

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    backgroundColor: LYNKED_RED,
    color: LYNKED_WHITE,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const NetworkDisconnectedCard = () => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <div
          style={{
            width: '100%',
            height: '5rem',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <Typography variant="h5" component="h2">
              <WifiOffIcon style={{ fontSize: '3rem' }} />
            </Typography>
          </div>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexFlow: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography>{active_lang.NO_NETWORK}</Typography>
        </div>
      </CardContent>
    </Card>
  );
};

export const NetworkStatusContextProvider = ({ children }) => {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  React.useEffect(() => {
    function helloEnter() {
      // alert('hello enter');
      return function helloQuit() {
        // alert('hello quit');
      };
    }
  }, []);

  return (
    <>
      <Detector
        polling={{ url: `${window.location.origin}/` }}
        render={({ online }) => (
          <NetworkStatusContext.Provider value={{ online: online }}>
            <SnackbarProvider
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              content={(key, message) => (
                <div id={key} message={message}>
                  <NetworkDisconnectedCard />
                </div>
              )}
            >
              <MonitorNetworkStatusContextProvider />
            </SnackbarProvider>
            {children}
          </NetworkStatusContext.Provider>
        )}
      />
    </>
  );
};
