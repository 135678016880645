import { makeStyles } from '@material-ui/core';

import { LYNKED_RED, LYNKED_LIGHT_GREY } from 'src/consts/colors';

const useStyles = makeStyles(() => ({
  helloworld: {},

  BillEntryRowTop: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  BillEntryRowBottom: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  BillTime: {
    font: 'normal normal normal 9px/18px Hiragino Sans',
    color: LYNKED_LIGHT_GREY,
  },
  BillItemName: {
    padding: '5px 0px',
    font: 'normal normal normal 12px/18px Hiragino Sans',
  },
  ItemSubTotal: {
    color: LYNKED_RED,
  },
}));

export { useStyles };
