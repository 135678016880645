import React from 'react';
import { isNull, isUndefined } from 'lodash';

import { Box } from '@material-ui/core';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';

import ReservationRequestCard from 'src/components/ReservationRequestCard';
import { acknowledgeServiceRequestById } from 'src/modals/service_requests';
import { GlobalContext } from 'src/contexts/Global';
import { LYNKED_DEEP_GREY } from 'src/consts/colors';
import active_lang from 'src/langs/jp_en';

import ShowDebug from '../ShowDebug';
import { useTranslation } from 'react-i18next';

export default function ReservationRequestList({ line_up_list, max_card_len }) {
  let { rest_id } = React.useContext(GlobalContext);
  const { t } = useTranslation();

  const handleAcknowledgeServiceRequestClick = (_e, sr_id) => {
    acknowledgeServiceRequestById(rest_id, sr_id);
  };

  if (isNull(line_up_list) || isUndefined(line_up_list)) {
    return (
      <>
        <ShowDebug>{JSON.stringify({ line_up_list })}</ShowDebug>
      </>
    );
  } else {
    if (line_up_list.length === 0) {
      return (
        <Box
          style={{
            width: '100%',
            display: 'flex',
            flexFlow: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box style={{ color: LYNKED_DEEP_GREY, paddingTop: '1rem' }}>
            {t('DASHBOARD_NO_RECEPTION_CONTENT_MSG')}
          </Box>
        </Box>
      );
    } else {
      return (
        <>
          <Box style={{ display: 'flex', flexFlow: 'row' }}>
            {line_up_list.slice(0, max_card_len).map((x, idx) => {
              return (
                <>
                  <Box
                    component="span"
                    style={{
                      paddingLeft: '0.5rem',
                      paddingTop: '0.25rem',
                      paddingBottom: '0.25rem',
                      paddingRight: '0.5rem',
                    }}
                    key={`reservation_request_card_${idx}`}
                  >
                    <ReservationRequestCard
                      line_up_info={x}
                      service_request_info={x}
                      handleAcknowledge={handleAcknowledgeServiceRequestClick}
                    />
                  </Box>
                </>
              );
            })}
            {line_up_list.length > max_card_len ? (
              <>
                <Box
                  style={{
                    padding: '0.25rem 0.25rem',
                    display: 'flex',
                    flexFlow: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <ChevronRightOutlinedIcon style={{ height: '3rem' }} />
                </Box>
              </>
            ) : (
              <></>
            )}
          </Box>
        </>
      );
    }
  }
}
