import { makeStyles } from '@material-ui/core';

import lynked_logo_svg from 'src/assets/tabi-life-white.svg';

import {
  LYNKED_RED,
  LYNKED_WHITE,
  LYNKED_BACKGROUND_LIGHT_GREY,
  LYNKED_DEEP_GREEN,
} from 'src/consts/colors';

const useStyles = makeStyles((theme) => ({
  DashboardContainer: {
    height: '100%',
    backgroundColor: LYNKED_WHITE,
  },
  Topbar: {
    backgroundColor: LYNKED_DEEP_GREEN,
    minHeight: '7vh',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    alignItems: 'center',
  },
  Navbar: {
    display: 'none',
    [theme.breakpoints.up('xs')]: {
      // backgroundColor: 'green',
      display: 'block',
      position: 'fixed',
      height: '100%',
      zIndex: '1900',
    },
    [theme.breakpoints.up('lg')]: {
      // backgroundColor: 'tomato',
      width: '16%',
      minHeight: '93vh',
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'space-between',
    },
  },
  TopNavBody: { width: '100%' },

  NavbarBottomPart: {},
  NavBody: {
    [theme.breakpoints.up('xs')]: {
      width: '100%',
      backgroundColor: LYNKED_WHITE,
      zIndex: 1,
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
      // backgroundColor: 'green',
    },
    [theme.breakpoints.up(1281)]: {
      width: '100%', //pass in chrome
      borderRight: '1px solid #E0E0E0',
      // backgroundColor: 'red',
    },
  },
  DashboardBody: {},

  NavBodyHead: {
    backgroundColor: LYNKED_BACKGROUND_LIGHT_GREY,
    // minHeight: '13vh',
  },
  NavBodyMain: {
    height: '75vh',
    width: '100%',

    overflowY: 'auto',
    overflowX: 'hidden',
  },

  NavBodyMainRight: {
    backgroundColor: LYNKED_BACKGROUND_LIGHT_GREY,
    height: '71vh',
  },
  TopBarRightButtonContainer: {
    [theme.breakpoints.up('xs')]: {
      color: LYNKED_WHITE,
      display: 'flex',
      flexFlow: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& button': {
        color: LYNKED_WHITE,
        margin: '0 0.5rem',
      },
    },
  },
  TopBarNavToggleContainer: {
    display: 'block',
    [theme.breakpoints.up(1281)]: {
      display: 'none',
    },
  },
  TopBarRightSecondaryButtonContainer: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  TopBarLeft: {
    display: 'flex',
    flexFlow: 'row',
  },
  lynkedLogo: {
    marginTop: '6px',
    minWidth: '150px',
    height: '25px',
    backgroundImage: `url('${lynked_logo_svg}')`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  debug_viewport: {
    display: 'flex',
    flexFlow: 'row',
    // color: 'white',
    justifyContent: 'center',
    alignItems: 'center',
  },
  viewport_xs: {
    display: 'none',
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      margin: '3px',
      paddingTop: '10px',
    },
  },
  viewport_sm: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      margin: '3px',
      paddingTop: '10px',
    },
  },
  viewport_md: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      margin: '3px',
      paddingTop: '10px',
    },
  },
  viewport_lg: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'block',
      margin: '3px',
      paddingTop: '10px',
    },
  },
  viewport_xl: {
    display: 'none',
    [theme.breakpoints.up('xl')]: {
      display: 'block',
      margin: '3px',
      paddingTop: '10px',
    },
  },
  top_left_list_window: {
    // height: '18vh',
  },
}));

export { useStyles };
