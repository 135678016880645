import React from 'react';
import firebase from 'firebase/app';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Box,
  Button,
  makeStyles,
  Input,
  Dialog,
  Slide,
} from '@material-ui/core';

import active_lang from 'src/langs/jp_en';
import devices_configs from 'src/configs/devices';
import { GlobalContext } from 'src/contexts/Global';
import { assignTableByDocId } from 'src/modals/lineup';

import createEmptybill from 'src/helpers/createEmptyBill';

import {
  LYNKED_RED,
  LYNKED_GREEN,
  LYNKED_GREEN_HOVER,
  LYNKED_LIGHT_GREY,
  LYNKED_BLACK,
  LYNKED_BLACK_HOVER,
} from 'src/consts/colors';

import './AssignTableDialog_style.css';

import { REGEX_FW_HW_NUMBER_WITH_SPACE } from './regex_consts';

import WarningIcon from '@material-ui/icons/Warning';
import ShowDebug from '../ShowDebug';

import { sanitizeFullWidthNumber } from 'src/utils/sanitizeFullWidthNumber';
import { isNumber } from 'lodash';

let ASCII_0 = 48;
let ASCII_9 = 57;
let ASCII_FW_0 = 65296;
let ASCII_FW_9 = 65305;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  helloworld: {},
  AssignedTAbleDialogWrapper: {
    width: '430px',
    // height: '201px',

    display: 'flex',
    flexFlow: 'row',

    font: 'normal normal normal 14px/18px Noto Sans JP',
  },
  statusIndicator: {
    width: '10px',
    // height: '100%',
    backgroundColor: LYNKED_RED,
    borderRadius: '2px 0px 0px 2px',
  },
  CardContentWrapper: {
    width: '100%',
    height: '100%',
    display: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  CardContent: {
    //

    margin: '30px 30px',
    '& div': {
      // backgroundColor: "blue",
      margin: '10px 0px',
    },
  },
  InputTextField: {
    background: '#FFFFFF',
    border: '1px solid #B3B3B3',
    borderRadius: '0px',
    opacity: '1',
    margin: '1rem',
  },

  TextInput: {
    border: '1px solid #E0E0E0',
    font: 'normal normal bold 12px/16px Noto Sans JP',
    '& ::placeholder': {
      color: LYNKED_LIGHT_GREY,
      padding: '10px 20px',
    },
  },
  DialogBottom: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
  },
  Buttons: {
    width: '172px',
    padding: '10px',
    color: 'white',
    font: 'normal normal bold 12px/16px Noto Sans JP',
    letterSpacing: '0.12px',
  },
  ButtonCancel: {
    backgroundColor: LYNKED_BLACK,
    '&:hover': {
      backgroundColor: LYNKED_BLACK_HOVER,
    },
  },
  ButtonOK: {
    backgroundColor: LYNKED_GREEN,
    '&:hover': {
      backgroundColor: LYNKED_GREEN_HOVER,
    },
  },
}));

export { useStyles, Transition };
