import { makeStyles } from '@material-ui/core';

import {
  LYNKED_WHITE,
  LYNKED_GREEN,
  LYNKED_GREEN_HOVER,
} from 'src/consts/colors';

const useStyles = makeStyles((theme) => ({
  boxWrapper: {
    height: '76px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 1px 2px #00000029',
    borderRadius: '2px',
    opacity: 1,

    width: '250px',

    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
  },

  strings: {
    width: '70%',
    padding: '0 10px',
  },
  dayString: {
    textAlign: 'left',
    font: 'normal normal normal 8px/24px Hiragino Sans',
    letterSpacing: '0px',
    color: '#525252',
    opacity: 1,
    height: '33%',
    paddingLeft: '10px',
    borderBottom: '1px solid #E0E0E0',
  },
  seatString: {
    height: '67%',
    display: 'inline-flex',
    alignItems: 'center',
    paddingLeft: '10px',
  },
  rightButton: {
    width: '30%',
  },
  FinishButton: {
    color: LYNKED_WHITE,
    borderRadius: '0 2px 2px 0',
    backgroundColor: LYNKED_GREEN,
    '&:hover': {
      backgroundColor: LYNKED_GREEN_HOVER,
    },
    width: '100%',
    height: '100%',
  },
}));

export { useStyles };
