import React from 'react';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';

import { styled, Box, TextField, Button, makeStyles, Input } from '@material-ui/core';

import { LYNKED_RED, LYNKED_WHITE, LYNKED_GREEN, LYNKED_LIGHT_GREY, LYNKED_DEEP_GREY, LYNKED_BLUE, LYNKED_RED_HOVER } from 'src/consts/colors';

const useStyles = makeStyles((theme) => ({
  helloworld: {},
  AddCategoryButton: {
    height: '45px',
    padding: '1rem 1rem',
    backgroundColor: LYNKED_RED,
    color: LYNKED_WHITE,
    boxShadow: '0px 1px 2px #00000029',
    '&:hover': {
      backgroundColor: LYNKED_RED_HOVER,
    },
  },
}));

export default function SaveFoodListButton({ onClick }) {
  const classes = useStyles();
  return (
    <>
      <Button className={classes.AddCategoryButton} startIcon={<SaveOutlinedIcon />} onClick={onClick}>
        存す
      </Button>
    </>
  );
}
