import React from 'react';
import { AuthContext } from 'src/Firebase/context';

import { AlertsContextProvider } from 'src/contexts/Alerts';

import useMutateCheckUserId from 'src/hooks/useMutateCheckUserRestId';
import useMutateCheckRestInfo from 'src/hooks/useMutateCheckRestInfo';
import useMutateSubscribeRestaurantConfig from 'src/hooks/useMutateSubscribeRestaurantConfig';
import { toLocalOpeningSchedule } from "src/utils/TimezoneFormat";

export const GlobalContext = React.createContext();

export const GlobalContextProvider = ({ children }) => {
  const { user, getUserUid } = React.useContext(AuthContext);

  let [restaurant_config, setRestaurantConfig] = React.useState();

  const [user_email, setUserEmail] = React.useState(null);
  const [restaurant_name, setRestaurantName] = React.useState(null);
  const [rest_id, setRestId] = React.useState(null);
  const [rest_info, setRestInfo] = React.useState(null);
  const [meny_service_class, setMenyServiceClass] = React.useState(null);
  const [restaurant_profile_pic, setRestaurantProfilePic] =
    React.useState(null);
  const mutate_subscribe_resutaurant_config =
    useMutateSubscribeRestaurantConfig();
  const mutate_check_user_rest_id = useMutateCheckUserId();
  let use_mutate_check_rest_info = useMutateCheckRestInfo();

  React.useEffect(() => {
    let user_uid = getUserUid();

    if (user_uid) {
      console.log('checkUserRestId', 'user_uid', user_uid);

      use_mutate_check_rest_info
        .mutateAsync(user_uid)
        .then((res_json) => {
          setRestInfo(res_json);
        })
        .catch((err) => {
          console.error('use_mutate_check_rest_info', err);
        });

      mutate_check_user_rest_id
        .mutateAsync(user_uid)
        .then((user_info_json) => {
          console.log(
            'mutate_check_user_rest_id',
            'user_info_json',
            user_info_json
          );
          setRestId(user_info_json.restaurant_id);
          setRestaurantName(user_info_json.restaurant_name);
          setUserEmail(user.email);
          setMenyServiceClass(user_info_json.meny_service_class);
          setRestaurantProfilePic(user_info_json.restaurant_profile_pic);
        })
        .catch((err) => {
          console.error('mutate_check_user_rest_id', 'err', err);
        });
    }
  }, [user]);

  React.useEffect(() => {
    if (rest_id) {
      mutate_subscribe_resutaurant_config.mutateAsync(rest_id).then((res) =>
        res.onSnapshot((doc) => {
          const config = doc.data();
          config.opening_schedule = toLocalOpeningSchedule(config.opening_schedule);
          setRestaurantConfig(config);
        })
      );
    }
  }, [rest_id]);
  const resetApplicationState = () => {
    setMenyServiceClass(null);
  };

  return (
    <>
      <GlobalContext.Provider
        value={{
          resetApplicationState,
          restaurant_name,
          rest_id,
          meny_service_class,
          restaurant_profile_pic,
          rest_info,
          setRestInfo,
          restaurant_config,
          // setRestaurantConfig,
          user_email,
        }}
      >
        <AlertsContextProvider>{children}</AlertsContextProvider>
      </GlobalContext.Provider>
    </>
  );
};
