import React from 'react';
import { Button } from '@material-ui/core';

import active_lang from 'src/langs/jp_en';

import { LYNKED_WHITE, LYNKED_DEEP_GREEN } from 'src/consts/colors';

import { RESTAURANT_OPEN_STATE_OPENED } from 'src/consts/RESTAURANT_OPEN_STATE';

import ConfirmChangeToOpenDialog from './ConfirmChangeToOpenDialog';
import { useTranslation } from 'react-i18next';

export default function RestaurantPreparingButton({
  handleChangeRestaurantOpenState,
}) {
  // preparing -> open
  let [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  const handleChangeStateOnClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmChangeState = () => {
    handleChangeRestaurantOpenState(RESTAURANT_OPEN_STATE_OPENED);
    setOpen(false);
  };

  return (
    <>
      <ConfirmChangeToOpenDialog
        open={open}
        handleClose={handleClose}
        handleConfirmChangeState={handleConfirmChangeState}
      />
      <Button
        onClick={handleChangeStateOnClick}
        style={{
          backgroundColor: LYNKED_WHITE,
          color: LYNKED_DEEP_GREEN,
          border: '2px solid #417537',
        }}
        id="toggle-restaurant-preparing-button"
      >
        {t('OPERATING_PREPARING')}
      </Button>
    </>
  );
}
