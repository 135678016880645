import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import clsx from 'clsx';

import useMutateDeleteAdvertisement from 'src/hooks/useMutateDeleteAdvertisement';

import { Box, Button } from '@material-ui/core';

import active_lang from 'src/langs/jp_en';
import { dialog_transition_duration } from 'src/consts/TRANSITION';
import { useStyles, Transition } from './styles';

import 'firebase/storage';
import { GlobalContext } from 'src/contexts/Global';
import { useTranslation } from 'react-i18next';

export default function ConfirmRemoveAdvertisement({
  open,
  setOpen,
  advertisement_id,
  refetch,
}) {
  const classes = useStyles();
  let { rest_id } = React.useContext(GlobalContext);
  const { t } = useTranslation();

  let use_mutate_delete_advertisement = useMutateDeleteAdvertisement();

  const handleClose = () => {
    refetch();
    setOpen(false);
  };

  const handleOkClick = () => {
    use_mutate_delete_advertisement
      .mutateAsync({ advertisement_id, rest_id })
      .then(() => handleClose())
      .catch((err) => console.error('ConfirmRemoveAdvertisement', 'err', err));
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        transitionDuration={dialog_transition_duration}
        keepMounted
        onClose={handleClose}
        aria-labelledby="confirm-user-delete-advertisement"
        aria-describedby="confirm-user-delete-advertisement"
        maxWidth="sm"
        fullWidth
      >
        <Box className={classes.ConfirmDeleteDialogWrapper}>
          <Box className={classes.DialogTitle}>
            <Box>{t('COMMON_DELETE')}</Box>
          </Box>
          <Box className={classes.QuestionsWrapper}>
            <Box>{t('AD_REMOVE_AD_MSG')}</Box>
          </Box>

          <Box className={classes.DialogBottom}>
            <Button
              onClick={handleClose}
              className={clsx(classes.Buttons, classes.ButtonCancel)}
            >
              {t('COMMON_CANCEL')}
            </Button>
            <Button
              onClick={handleOkClick}
              className={clsx(classes.Buttons, classes.ButtonAlertOK)}
            >
              {t('COMMON_CONFIRM')}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
