import React from 'react';

import Slide from '@material-ui/core/Slide';

import { Box, makeStyles } from '@material-ui/core';

import {
  LYNKED_RED,
  LYNKED_WHITE,
  LYNKED_GREEN,
  LYNKED_GREEN_HOVER,
  LYNKED_LIGHT_GREY,
  LYNKED_DEEP_GREY,
  LYNKED_DEEP_GREY_HOVER,
} from 'src/consts/colors';

import { STATUS_CANCELLED } from 'src/consts/ORDER_STATUS';

export default function BillStatusIndicator({ color }) {
  return (
    <>
      <Box style={{ paddingLeft: '50px' }}>
        <Box
          style={{
            height: '10px',
            width: '100px',
            backgroundColor: color,
          }}
        ></Box>
      </Box>
    </>
  );
}
