import firebase from 'firebase/app';

import 'firebase/firestore';
import 'firebase/storage';

import firebase_app from 'src/Firebase/config';

const { REACT_APP_EMULATOR_HOST, REACT_APP_FIREBASE_AUTH_EMULATOR_HOST } =
  process.env;

const db = firebase_app.firestore();

if (process.env.NODE_ENV === 'development') {
  db.useEmulator(REACT_APP_EMULATOR_HOST, 8080);
  firebase.auth().useEmulator(`http://${REACT_APP_EMULATOR_HOST}:9099`);
  firebase.storage().useEmulator(REACT_APP_EMULATOR_HOST, 9199);
}

export default db;
