import React from 'react';
import { Box, Grid } from '@material-ui/core';

import ShowDebug from 'src/components/ShowDebug';

import ShowAssignedTablesList from './ShowAssignedTablesList';

import { MonitorLineupContext } from 'src/contexts/MonitorLineup';
import { useTranslation } from 'react-i18next';
import { LYNKED_DEEP_GREY } from 'src/consts/colors';
import active_lang from 'src/langs/jp_en';

export default function AssignedTablesList() {
  let { assigned_table_list } = React.useContext(MonitorLineupContext);
  const { t } = useTranslation();

  return (
    <>
      <Box
        style={{
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          minHeight: '100px',
          margin: '1rem',
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexFlow: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            marginBottom: '0.5rem',
            borderBottom: '1px solid #E0E0E0',
          }}
        >
          <Box
            style={{
              paddingLeft: '1rem',
              paddingBottom: '2rem',
              font: 'normal normal bold 20px/26px Roboto',
            }}
          >
            {t('RECEPTION_TABLE_LIST')}
            <ShowDebug>ASSIGNED_TABLES_LIST</ShowDebug>
          </Box>
          <Box style={{ paddingRight: '1rem', paddingBottom: '2rem' }}></Box>
        </Box>
      </Box>

      {assigned_table_list && assigned_table_list.length > 0 ? (
        <Grid container style={{ paddingBottom: '3rem' }}>
          <Grid item xs={12}>
            <ShowAssignedTablesList />
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      ) : (
        <>
          <Box
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              // backgroundColor: 'gold',
              minHeight: '300px',
              margin: '1rem',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexFlow: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                marginBottom: '0.5rem',
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  flexFlow: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: LYNKED_DEEP_GREY,
                }}
              >
                <div>{t('RECEPTION_NO_TABLES_MSG')}</div>
                <ShowDebug>NO_ASSIGNED_TABLES_AT_THE_MOMENT</ShowDebug>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
