import React from 'react';
import { Button, Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

export default function ChangeLog() {
  const classes = useStyles();

  return (
    <>
      <Page className={classes.root}>
        <Container maxWidth="lg">
          <Box mt={3}>
            <h2>変更ログ</h2>
            <p>
              変更ログは、プロジェクトの各バージョンの注目すべき変更のキュレーションされた時系列のリストを含むファイルです。
            </p>
          </Box>
        </Container>
      </Page>
    </>
  );
}
