import React from 'react';

import { makeStyles, Slide } from '@material-ui/core';

import {
  LYNKED_BLACK,
  LYNKED_BLACK_HOVER,
  LYNKED_DEEP_GREY,
  LYNKED_DEEP_GREY_HOVER,
  LYNKED_GREEN,
  LYNKED_GREEN_HOVER,
  LYNKED_LIGHT_GREY,
  LYNKED_WHITE,
} from 'src/consts/colors';

import './DeleteEntryDialog_style.css';

let ASCII_0 = 48;
let ASCII_9 = 57;
let ASCII_FW_0 = 65296;
let ASCII_FW_9 = 65305;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((_theme) => ({
  helloworld: {},
  DialogBottom: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',

    width: '100%',
    justifyContent: 'space-between',
  },
  ConfirmDeleteDialogWrapper: {
    margin: '30px',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  DialogTitle: {
    paddingBottom: '2rem',
  },
  QuestionsWrapper: {
    textAlign: 'center',
    paddingBottom: '3rem',
  },
  FooterButtonsGroup: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  FooterButtons: {
    width: '48%',
  },
  ButtonCancel: {
    width: '48%',
    backgroundColor: LYNKED_DEEP_GREY,
    '&:hover': {
      backgroundColor: LYNKED_DEEP_GREY_HOVER,
    },
    color: LYNKED_WHITE,
    borderRadius: '1px',
  },
  ButtonOK: {
    width: '48%',
    backgroundColor: LYNKED_GREEN,
    '&:hover': {
      backgroundColor: LYNKED_GREEN_HOVER,
    },
    color: LYNKED_WHITE,
    borderRadius: '1px',
  },
}));

export { useStyles, Transition };
