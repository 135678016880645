import { useMutation } from 'react-query';

import { REST_MNGT_ENDPOINT } from 'src/config';

const useMutateCheckRestInfo = () => {
  return useMutation(
    'cancel-remaining-lineup-as-shop-closed',
    async (user_uid) => {
      const response = await fetch(
        `${REST_MNGT_ENDPOINT}/checkRestInfo?user_id=${user_uid}`
      );

      if (!response.ok) {
        // throw new Error('Network response was not ok');
        console.error('error during cancelling the remaining lineup request');
      }

      return response.json();
    },
    { retry: 5 }
  );
};

export default useMutateCheckRestInfo;
