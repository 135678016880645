import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Box, Button } from '@material-ui/core';

import ShowYen from 'src/components/ShowYen';
import active_lang from 'src/langs/jp_en';

import { STATUS_CANCELLED } from 'src/consts/ORDER_STATUS';

import ConfirmClearBillDialog from '../ConfirmClearBillDialog';
import BillEntry from '../BillEntry';
import { useStyles, Transition } from './styles';

import { dialog_transition_duration } from 'src/consts/TRANSITION';
import { useTranslation } from 'react-i18next';

const not_account_list = [STATUS_CANCELLED];

export default function ShowBillDetailDialog({
  orders,
  setOpen,
  open,
  bill,
  id,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { order_username, order_table_number } = bill
    ? bill
    : { order_username: '', order_table_number: '' };

  let [open_confirm_clear_bill_dialog, setOpenConfirmClearBillDialog] =
    React.useState(false);

  const [total_price, setTotalPrice] = React.useState(0);
  const [total_item, setTotalItem] = React.useState(0);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseBillDetail = () => {
    handleClose();
  };

  const handleConfirmClearClick = () => {
    setOpenConfirmClearBillDialog(true);
  };

  React.useEffect(() => {
    let temp_total_price = 0;
    let valid_orders =
      orders && orders.filter((o) => !not_account_list.includes(o.status));

    valid_orders &&
      valid_orders.forEach(
        (o) => (temp_total_price = temp_total_price + Number(o.item_subtotal))
      );
    setTotalPrice(Number(Math.max(0, temp_total_price).toFixed(2)));

    let temp_total_item = 0;
    valid_orders &&
      valid_orders.forEach(
        (o) => (temp_total_item = temp_total_item + o.item_quantity)
      );
    setTotalItem(temp_total_item);
  }, [bill]);

  return (
    <>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        BillDetailDialog
      </Button> */}

      <Dialog
        open={open}
        TransitionComponent={Transition}
        transitionDuration={dialog_transition_duration}
        keepMounted
        onClose={handleCloseBillDetail}
        maxWidth={'sm'}
        fullWidth={true}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Box className={classes.DialogWrapper}>
          <Box className={classes.BillingCardContainer}>
            <Box className={classes.CardHeader}>
              <Box
                className={classes.BillToTable}
              >{`${t('COMMON_TABLE')} ${order_table_number}`}</Box>
              <Box
                className={classes.BillToPerson}
              >{t('BILL_MR', { name: order_username })}</Box>
            </Box>
            <Box>
              {orders &&
                orders.map((o, idx) => {
                  return <BillEntry entry={o} key={`order_${idx}`} />;
                })}
            </Box>
            <Box className={classes.CardFooter}>
              <Box className={classes.BillTotal}>
                <Box className={classes.FooterBody}>
                  <Box>{t('COMMON_TOTAL')} {total_item} {t('COMMON_POINTS')}</Box>
                </Box>
                <Box className={classes.FooterTotalPrice}>
                  <Box className={classes.TotalPrice}>
                    <ShowYen value_yen={total_price} />
                  </Box>
                  <Box className={classes.TotalExplain}>
                    {t('BILL_TOTAL')}
                  </Box>
                </Box>
              </Box>
              <Box className={classes.FooterButtonsGroup}>
                <Box className={classes.FooterButtons}>
                  <Button
                    onClick={handleClose}
                    className={classes.FooterButtonViewAllL}
                  >
                    {t('BILL_CANCEL_COMPLETE_BILL')}
                  </Button>
                </Box>
                <Box className={classes.FooterButtons}>
                  <Button
                    id="button-clear-bill"
                    className={classes.FooterButtonFinishBill}
                    onClick={handleConfirmClearClick}
                  >
                    {t('BILL_PAYMENT_COMPLETE')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>

      <ConfirmClearBillDialog
        id={id}
        orders={orders}
        open={open_confirm_clear_bill_dialog}
        setOpen={setOpenConfirmClearBillDialog}
        handleCloseBillDetail={handleCloseBillDetail}
        bill={bill}
        table_name={order_table_number}
      />
    </>
  );
}
