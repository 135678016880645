import { REST_MNGT_ENDPOINT } from 'src/config';

const default_user_data = {
  photoURL: ``,
  jobTitle: '',
  name: '',
  rest_id_related: [],
};

const user = {
  photoURL:
    'https://firebasestorage.googleapis.com/v0/b/lynked-demo-tryout.appspot.com/o/test%2Fescale_header_logo.png?alt=media&token=71d56e50-0465-4e87-aeff-2d9fa71fcc9c',
  displayName: 'hello display name',
  email: 'test@helloworld.com',
  rest_id_related: [],
};

function getUserInfoByUid(uid) {
  return { ...default_user_data, ...user };
}

function checkUserRestId(user_id) {
  console.log(
    'checkUserRestId',
    `${REST_MNGT_ENDPOINT}/getRestId?user_id=${user_id}`
  );
  return fetch(`${REST_MNGT_ENDPOINT}/getRestId?user_id=${user_id}`).then(
    (res) => res.json()
  );
}

export { user, getUserInfoByUid, checkUserRestId };
