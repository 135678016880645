import React from 'react';
import { Link } from '@material-ui/core';

import edit_advertisement from './jp/edit_advertisement';
import overview from './jp/overview';

import about_lynked from './jp/about_lynked';
import billing_management from './jp/billing_management';
import change_log from './jp/change_log';
import example_components from './jp/example_components';
import example_queries from './jp/example_queries';
import help_requests from './jp/help_requests';
import login from './jp/login';
import logout from './jp/logout';
import menu_management from './jp/menu_management';
import orders from './jp/orders';
import qr_gen from './jp/qr_gen';
import reception_list from './jp/reception_list';
import settings from './jp/settings';
import sorry_error from './jp/sorry_error';
import terms_and_conds from './jp/terms_and_conds';
import test_page from './jp/test_page';

let CUSTOMER_NAME = '名前 / CUSTOMER_NAME';
let CUSTOMER_NOTES = '備考 / CUSTOMER_NOTES';
let NUMBER_OF_PEOPLES = '大人＋子供 / NUMBER_OF_PEOPLES';
let SUBMIT_TIME = '登錄時間';
let LINEUP_ORDERING = '排';

// Nav menu
let OVERVIEW = 'ダッシュボード';
let SEAT_RESERVATION = '席の予約管理 / SEAT_RESERV';
let ORDER_MANAGMENT = 'オーダー管理 / ORDER_MNGT';
let ACCOUNT_MANAGMENT = '会計管理 / ACC_MNGT';
let MENU_MANAGMENT = 'メニュー管理/ Menu Management';
let SETTINGS = '設定/ Settings';
let HELLOWORLD = 'HELLOWORLD';
let HELLOWORLD_ALERTS = 'ALERTS TRYOUT';
let ABOUT_ME = 'ページについて/ About Us';
let LOGIN_USING_SOCIAL_NETWORK = '經網絡の登綠 /  Login using social network';
let SIGN_IN_TITLE = 'ログイン/ Log In';
let SIGN_IN_DESC = '登綠到內部の系統 / Sign in on the internal platform';
let LOGIN = '登入 / Login';
let LOGOUT = 'ログアウト/ Logout';
let CHANGE_LOG = '変更ログ';
let ADD_CUSTOMER = '新加顧客 / ADD CUSTOMER';
let IMPORT = 'インポート/ Import';
let EXPORT = 'エクスポート/ Export';
let BUDGET = '本金 / BUDGET';
let TOTAL_CUSTOMERS = '總客人/T_CUST';
let UTILIZATION = '入座率 / UTILIZATION';
let TOTAL_PROFIT = '總利率 / TOTAL PROFIT';
let SINCE_LAST_MONTH = '從上個月の開始 / since last month';
let LATEST_SALES = '最近銷售の目數 / LATEST SALES';
let LAST_7_DAYS = '截至最近の七天 / COUNT TO LAST 7 DAYS';
let CUSTOMER_CATEGORY = '食客の種類 / CUSTOMER CATEGORY';
let LATEST_ORDERS = '最近の賬單 / latest orders';
let SEARCH_CUSTOMER = '顧客の搜尋 / Customer search';
let RESTAURANT_STRING = '飲食店の管理画面/ Restaurant Management Panel';
let LOGIN_SUCCESS = '登入の正常 / Login success ...';
let HELLOWORLD_FORMS = 'FORMの測試 / Form test';

let TEST_ALERT = '警號の測試 / Test alert ...';
let THIS_IS_SUCCESS_ALERT = '這是成功の警號 / this is success alert';

let RESTAURANT_NAME = 'カテゴリー / RESTAURANT NAME';
let FOOD_NAME = '名前/ Name';
let FOOD_DESCRIPTION = '詳細 / FOOD DESCRIPTION';
let FOOD_PRICE = '値段/ Price';
let FOOD_PHOTO = '画像/ FOOD PHOTO';
let PLEASE_SPECIFY_THE_FIRST_NAME = '請給食物の名字 / Please specify the first name';

let RECEPTION_LIST = '受付リスト';
let TOTAL = '合計';
let CALL_LIST = '呼び出しリスト';

let LOGIN_WITH_FACEBOOK = '經 FACEBOOK 登入 / login using facebook';
let VIEW_ALL = '全部の檢視 / VIEW ALL';
let CHART_OVERVIEW = '全部の概覽 / OVERVIEW';

let ORDER_REF = '賬單の參考 / Order Ref.';
let ORDER_STATUS = '狀の態 / Status';
let ORDER_PENDING = '等の待 / Pending';
let ORDER_DELIVERED = '送の達 / Delivered';
let ORDER_REFUNDED = '回の水 / Refunded';

let FOOD_DIALOG_TITLE = 'メニュー編集';
let NEW_FOOD_DIALOG = '新規メニュー';
let EDIT_FOOD_DIALOG = 'メニュー編集';

let DIALOG_REPLY_YES = 'はい/ Yes';
let DIALOG_REPLY_NO = 'いいえ/ No';

let PLEASE_SELECT_SIGN_IN_METHOD1 = '請SIGN_INの方法の選擇';
let PLEASE_SELECT_SIGN_IN_METHOD2 = 'please select sign in method:';

let SEARCH_FOOD = 'メニューの検索/ Food Search';
let SEARCH_CATEGORY = 'カテゴリー/ Category Search';
let PAGE_NOT_FOUND = '你の想要の網頁找不到';
let STATISTICS_VIEW = '一二三の統計';

let LYNKED_COPYRIGHT_RESERVED = (
  <>
    2020 <Link href="www.google.com">lynked</Link> copyright reserved
  </>
);

let TERMS_AND_CONDITIONS = (
  <>
    <Link href="www.example.com">Terms and conditions</Link>
  </>
);

let NUMBER_OF_CUSTOMER_WAITING_TICKET = '現在の客戶等侯の數 / number of customer waiting';
let NUMBER_OF_CUSTOMER_GOT_TICKETTODAY = '今天の客戶票の取 / number of customer got ticket today';

let LAST_UPDATE = '最後の更新 / last update';
let LINE_UP_ORDERING_REF = 'Ref';

let TELL_USER_SEAT_AVAILABLE = '枱の有';
let SEAT_ASSIGN_NOT_REQUESTED = '未編住';

let SEAT_REQUEST_NOTE = '特別の要求 / notes';
let TICKET_TAKE_TIME = '取票時間';
let ASSIGN_TICKET_DIALOG_TITLE = '取票の編輯 / assign_line_up_number';
let SET_SEAT_REQUEST_NUMBER = '編の號碼 / assign seat number';

let CONFIRM_DELETE_FOOD_ITEM = '食物の刪徐の確定？ / Confirm delete food item ?';
let DELETE_FOOD_ANS_YES = '是的の請便 / Yes, Please';
let DELETE_FOOD_ANS_NO = '不了の躝啦 / No, Get Out';

let MENU_DELETE = '削除/ Delete';
let MENU_PREVIEW_ON_WEBSITE = 'プレビュー/ Preview';

let FOOD_EDIT_TITLE = '食の物の編輯 / FOOD_EDIT_TITLE';

let ADD_FOOD = '新規メニュー/ Add New Menu';
let ADD_CATEGORY = '新規カテゴリー/ Add new Category';

let FEEDBACK_FORM_TITLE = '黎黎黎... 幫緊你幫緊你 / SOMEBODY HELLLLLLLP !!!';
let SEND_FEEDBACK = '快 L D整啦 / Please kindly help to fix... ';

let SEND_FEEDBACK_DEFAULT_TEXT = '伯母の你好 / Hi, Auntie';
let FEEDBACK_DETAIL_DESCRIPTION = '有咩想要 / wanted item';
let FEEDBACK_CONTACT_POINT = '可以點搵返我？ / you can reach me by...';

let QUEUE_MANAGE_TITLE = '排隊の安排 / queue managment';

let ABOUT_LYNKED = 'LYNKEDについて關於 LYNKED';
let FEEDBACK_FORM_STAR_RATING = '值幾多分? / How many star should I have ?';

let ANNOUNCEMENT_DISMISS = 'はい/ Ok';
let ANNOUNCEMENT_TITLE = '最新情報/ News';

let CONFIRM_LOGOUT_TITLE = '真係要走？/REALLY QUIT ?';
let CONFIRM_LOGOUT_BODY = '我好 L 唔拾得你喔....';

let TERMS_AND_CONDS = '利用規約';

let TNC_BACK = '回の去 / back';

let ASSIGNED_TABLE_NUMBER = '枱の號 / table number';

let UPDATE_ASSIGN_TABLE_DONE = '更新枱の編號の完成 / UPDATE_ASSIGN_TABLE_DONE';
let UPDATE_FOOD_DETAIL_DONE = '更新食の物の完成 / update food detail done';

let CURRENT_FOOD_IMAGE = '画像/ Image';

let FOOD_LONG_NAME = 'キャッチフレーズ/ Catchphrase';

let ADD_FOOD_DONE = '食物の增加完成 / add food done';
let DELETE_FOOD_DONE = '食物の刪徐完成 / remove food done';

let BOTTOM_NAV_MENU_TEXT = '菜の單';
let SHOPPING_CART_FOOD_ITEM = '食の物';
let SHOPPING_CART_QUANTITY = '數の量';
let SHOPPING_CART_PRICE = '價の錢';
let SHOPPING_CART_DELETE = '取の消';
let SHOPPING_CART_GO_TO_CHECKOUT = '注乜文乜確定乜乜';
let SHOPPING_CART_DELETE_TEXT = '刪の徐';

let SHOPPING_CART_ADD_BUTTON_TEXT = '加の';
let SHOPPING_CART_REMOVE_BUTTON_TEXT = '減の';

let RECEIPT_INDEX = '- ご注文履歴 -';
let RECEIPT_DATETIME = 'ご注文日時:13/12/2020, 00:30:53';

let RECEIPT_ITEM_STATUS_PREPARING = '準備中';
let RECEIPT_ITEM_STATUS_DELIVERED = 'ご提供済';

let RECEIPT_ITEM_DOLLAR_YEN = '円';
let RECEIPT_ITEM_UNIT = '点';
let RECEIPT_BILL_SPLITTING = '割り勘する';
let RECEIPT_BILL_PEOPLE = '人';
let RECEIPT_BILL_SUBTOTAL = '合計';
let RECEIPT_BILL_GRANDTOTAL = '總合計';
let RECEIPT_BILL_ONE_PERSON_SHOULD_PAY = '一人当たり';
let RECEIPT_BILL_SALES_TAX = 'サービス料+消費税';
let PERFORM_LINEUP_QUESTION = '真係排隊嗎？你講真？';
let RECEIVE_LINE_UP_NUMBER = '接個靚飛';
let TABLE_RESERVE_READY = '枱己有，😠快 L D 過黎!';
let CONFIRM_FOOD_QTY = '冇問題，想要幾多？';
let THANKS_FOR_PURCHASE = '腥生，多謝幫襯，我係９９號Louis，夏厠記住搵我喎...';
let BACK_TO_LANDING_PAGE = 'ダッシュボードへ/ Return to Dashboard';
let PAGE_NOT_FOUND_EXPLAIN = '第二時の痾尿の記得の講唔該の借借';

let GO_TO_COMPANY_WEB_SITE = 'LYNKED網の前の';
let CONTACT_US = '聯の絡我の們';
let CONTACT_US_EXPLAIN = 'painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but occasionally circumstances occur in which toil and pain can procu 💞🔤📗🔵👌';

let OUR_COMPANY = '關の於の我們';
let OUR_COMPANY_EXPLAIN = '応ハシカ向2捜せぽけリ覧郷ユヤワサ準助7子群メソマ直林無ノヱク総九るね法右み料生レソヱ犯2属スタリチ海新志合のみル。落国がどゅト備初ワ成東真友ルノ多府イとレせ後活ぱ力内せぞトん断社づけン経誓メノヨス人回ぞぜど司介トの通長めドラ供警の深能か鈴拠るより関達ツテ年撃とえふ桑夕阜じはさ。';

let OUR_VALUE = '我們の價の值';
let OUT_VALUE_EXPLAIN = '阪ハウワ応92勤常勉旋72依過らつ。門むば隊就メツヘ突長山テ碁人ゆッ歳両ら止都トカハキ締素ぎぜつ索能経初臣こ党求ぼ担効カタハネ文会ヲツレ端作67神フ測相りち挑坂ぶ。';

let ASK_CUSTOMER_FIRST_NAME = '閣の下​大の名';
let LINEUP_CUSTOMER_NAME = '名前/LINEUP_CUSTOMER_NAME';
let ASK_CUSTOMER_ADULT_NUMBER = '大人の';
let ASK_CUSTOMER_CHILDREN_NUMBER = '小童樣';
let ASK_CUSTOMER_BABY_NUMBER = '嬰の兒';
let GET_FROM_GOOGLE = 'GOOGLE名の取の';

let CLIENT_GOING_TO_RESTAURANT = '好呀，過緊黎';
let QUESTION_PROCEED_TO_CHECKOUT = '真の係のCHECKOUT樣?';
let CART_CHECKOUT_DONE = 'CHECKOUTの完の成';

let CHECKOUT_ITEM_PREPARING = '狀の態';
let CHECKOUT_ITEM_DELIVERED = '傳の送';
let RECEIPT_ITEM_CANCELLED = '取己消';

let ORDER_ITEMS_REMAINING = '殘の';

let UPDATE_DELIVERED_ITEM = '食物の更新';
let CANCEL_UPDATE = '取消の更新';

let DELETE_ORDER_DONE = 'ORDER鏟の完成';
let CONFIRM_DELETE_ORDER = '真的刪除嗎？🔞🔞🔞 / CONFIRM_DELETE_ORDER';

let PROCESS_LINEUP_DONE = '排左隊';

let TABLE_NUMBER_TEXT = '枱號';

let SHOPPING_CART_TEXT = '購物の車';
let RECEIPT_TEXT = '賬の單';
let EMPTY_RECEIPT_LIST = '賬單の空の賤念';

let CHECKOUT_DELETE = '刪の除';
let CEHCKOUT_FINISH_ORDER = '結の賬';

let ASK_FINISH_ORDER = '真的結の賬の嗎？';

let CHECK_OUT_CART_UNIT_PRICE_TEXT = '單の價';

let BACK_TO_MENU = 'MENUの返の';

let SIGN_IN_NOW = 'ログイン';
let DONT_HAVE_ACCOUNT = 'アカウント持ってない？';
let SIGN_UP = '登録へ';
let DEMO_ACCOUNTS = 'デモアカウント:';
let PASSWORD_IS_REQUIRED = 'パスワードが必要です';
let LOGIN_FAILED = 'メールアドレスまたはパスワードが正しくありません。もう一度お試しください。';

let REST_UTILIZATION_RATE = '入座の率';
let AVERAGE_WAITING_TIME = '平均の等侯の時間';

let HISTORY_LENGTH_SELECT = '長度の選擇';

let HISTORY_LENGTH_ONE_WEEK = '一の週';
let HISTORY_LENGTH_ONE_MONTH = '一の月';
let HISTORY_LENGTH_THREE_MONTHS = '三の月';
let HISTORY_LENGTH_SIX_MONTHS = '半の年';
let HISTORY_LENGTH_ONE_YEAR = '一の年';

let INCOMING_BUDGET = '收の入';
let INCOMING_BILL = '總帳の入';
let ITEM_SOLD = '賣物の數';

let CHART_THIS_WEEK = '今の週';
let CHART_LAST_WEEK = '上の週';

let CHART_DAY_TODAY = '今の天';

let NO_ORDER_WAITING = '保留中の注文はありません';
let LOADING_ORDERS = '注文の読み込み';

let LOADING_FOODS = 'メニューを取得中/ Loading Menu';
let NO_FOOD_DETAILS_IN_LIST = '現在沒の有FOODのDETAILS / no food details';

let USER_CHOOSE_DATE_RANGE = '用戶の選の';
let CHART_LENGTH_SELECT = '長度選擇';

let SEASON = '季';
let MONTH = '月';
let WEEK = '週';
let DAY = '天';
let FROM = '開始の';
let TO = '至の';
let UPDATE_CHART = '更新のchart';
let DEFAULT_CHART_DATE_RANGE = '預設の期限/default date range';
let CHECKING_USER_CREDENTIALS = 'ユーザーの資格情報を確認する';
let ALERT_HELLOWORLD = 'helloworldの警告';

let FIREBASE_STORAGE_UNAUTHORIZED = '不の容許 / unauthorized';
let FIREBASE_STORAGE_CANCELED = '上傳の取消 / cancelled';
let FIREBASE_STORAGE_UNKNOWN = '上傳の不知名錯誤 / unknown';
let FIREBASE_STORAGE_UPLOAD_DONE = '上傳の完成 / done';
let FIREBASE_STORAGE_UPLOAD_IN_PROGRESS = '上傳の進行中 / upload in progress';
let PERFORM_DELETE_IMAGE = '画像を削除/ Delete Image';
let CONFIRM_USER_DELETE_IMAGE = '真的IMAGEの刪の嗎';
let DELETE_IMAGE_IN_PROGRESS = 'IMAGEの刪の中';

let FOOD_CATEGORY_LABEL = 'カテゴリー/ Category';
let FOOD_DETAILS = 'メニュー詳細/ Menu Description';
let ASK_CONFIRM_DELETE = '結衣BB : 真係唔要我拿？ / KitEBB: really delete?';

let DEMO_PURPOSE_ONLY = 'テーブル';
let ASK_CONFIRM_CALLING_SERVICE = '呼び出しをしますか？';

let SERVICE_REQUEST_IN_PROGRESS = '呼び出しを出しています。/ Requesting help request.';
let SERVICE_REQUEST_RECEIVED = '呼び出しを完了しました。/ Help request completed.';
let SERVICE_REQUEST_NAV_NAME = '呼び出し管理/ Help Request Management';
let LOADING_SERVICE_REQUESTS = '服務の下蓮中...';
let ACKNOWLEDGE_SERVICE_REQUEST = '完了';
let SERVICE_REQUEST_ASK_FOR_HELP = ' の呼び出しリクエスト';
let NO_SERVICE_REQUEST_WAITING = '現在呼び出しリクエストがございません/ Currently there is no help request';
let SERVICE_REQUEST_CLEARED = 'CLEARED';
let SERVICE_REQUEST_NOT_CLEARED = 'NOT_CLEARED';
let ACKNOWLEDGED_PLEASE_PATIENT = 'ただ今スタフが参ります。少々お待ちください/ Please be patient as staff is coming for assistance.';

let ORDERING_HORIZONTAL = '左から古い順/ Starting old from left';
let ORDERING_VERTICAL = '上邊最舊，下邊最新 / old on top, new on bottom';

let YES_I_KNOW = 'はい';
let NEW_SERVICE_REQUEST_RECEIVED = '呼び出しリクエスト';
let NAV_ADVERTISEMENT_CONTROL = '廣告の管理';
let ADVERTISEMENT_SETTINGS_SAVE_CHANGE = '修改の儲存';
let CHANGE_ADVERTISEMENT_SETTINGS = '廣告の修改';
let DELETE_IMAGE_ERROR = '刪除影像の錯誤';
let DELETE_IMAGE_DONE = '刪除影像の完成';
let UPDATE_AD_CONFIG_DONE = '廣告の更新の完成';

let ADMIN_ADD_NEW_RESTAURANT = '新餐廳';
let ADMIN_ADD_NEW_USER = '新客戶';
let ADMIN_EDIT_RESTAURANT_INFO = '餐廳の資料編輯';
let ADMIN_EDIT_USER_INFO = '用戶の資料編輯';
let ADMIN_RESTAURANT_ACTIONS = '餐廳の編輯';
let ADMIN_RESTAURANT_EMAIL = '餐廳のemail';
let ADMIN_RESTAURANT_NAME = '餐廳の名';
let ADMIN_RESTAURANT_REMARKS = '餐廳のremarks';
let ADMIN_RESTAURANT_SERVICE_PLAN = '餐廳のplan';
let ADMIN_RESTAURANT_UPDATE_CANCEL = '返回の餐廳の加';
let ADMIN_RESTAURANT_UPDATE_OK = '同意の餐廳の加';
let ADMIN_USER_ACTIONS = '用戶の編輯';
let ADMIN_USER_EMAIL = '用戶のemail';
let ADMIN_USER_PASSWORD = '用戶のpassword';
let ADMIN_USER_NAME = '用戶の名';
let ADMIN_USER_REMARKS = '用戶のremarks';
let ADMIN_USER_SERVICE_PLAN = '用戶のplan';
let ADMIN_USER_UPDATE_CANCEL = '返回の';
let ADMIN_USER_UPDATE_OK = '同意の';

let ADMIN_ADD_NEW_ADVERTISEMENT = '新の廣告';
let ADMIN_ADVERTISEMENT_ACTIONS = '廣告の編輯';
let ADMIN_ADVERTISEMENT_EMAIL = '廣告のemail';
let ADMIN_ADVERTISEMENT_NAME = '廣告の名';
let ADMIN_ADVERTISEMENT_REMARKS = '廣告のremarks';
let ADMIN_ADVERTISEMENT_SERVICE_PLAN = '廣告のplan';
let ADMIN_ADVERTISEMENT_UPDATE_CANCEL = '返回の廣告の加';
let ADMIN_ADVERTISEMENT_UPDATE_OK = '同意の廣告の加';

let SUBSCRIBE_ADDITIONAL_SERVICE_TO_VIEW_MORE_1 = 'オプション購入で閲覧可能';
let SUBSCRIBE_ADDITIONAL_SERVICE_TO_VIEW_MORE_2 = 'Subscribe Additional Service to';
let SUBSCRIBE_ADDITIONAL_SERVICE_TO_VIEW_MORE_3 = 'View More';

// notifications page
let NOTIFICATIONS_PAGE_TITLE = '通知を管理設定画面';
let NOTIFICATIONS_PAGE_SUBTITLE = '通知を管理設定画面';

let MESSAGE = 'メッセージ';
let EMAIL = 'メール';
let PUSH_NOTIFICATIONS = 'プッシュメッセージ';
let PHONE_CALLS = '電話';

let NOTIFICATIONS = '通知';
let TEXT_MESSAGES = 'テキストメッセージ';
let SAVE = '保存';

let PASSWORD_CHANGE = 'パスワード管理';
let UPDATE_PASSWORD = 'パスワードの変更';

let INPUT_PASSWORD = 'パスワード入力';
let INPUT_PASSWORD_AGAIN = 'もう一度パスワード入力';
let UPDATE = '更新';

let UPLOAD_IMAGE = '画像をアップロード/ Upload Image';

let ADD_NEW_FOOD_OK = '新規作成/ Add';
let ADD_FOOD_CANCEL = 'キャンセル/ Cancel';

let EDIT_FOOD_OK = '保存/ Save';
let EDIT_FOOD_CANCEL = 'キャンセル/ Cancel';

let TABLE_ASKING_FOR_HELP = 'を呼び出しをリクエストしました';

let LOGIN_NOW = 'ログインする';

let LOGGED_OUT = 'ログアウトしました';
let BACK_TO_LOGIN = 'ログインページへ';

let EMAIL_ADDRESS = 'メールアドレス';
let PASSWORD = 'パスワード';
let RESTAURANT_OPERATOR_SELECT_TITLE = 'restaurant operator select';
let RESTAURANT_MENY_PLAN = 'restaurant meny plan';

let BACK_TO_MAIN = '返の';

let LOGIN_GREETINGS = 'お客様の Tabi Life アカウントを使用';

let LOGIN_WARN_EMAIL_IS_REQUIRED = 'メールが必要です';
let LOGIN_MUST_BE_A_VALID_EMAIL = '有効なメールアドレスである必要があります';

// overview
let OVERVIEW_HELP_REQUESTED = '呼び出しリスト';

// NAV Menu
let NAV_MENU_OVERVIEW = 'ダッシュボード';
let NAV_MENU_RECEPTION_LIST = '受付管理';
let NAV_MENU_HELP_REQUESTS = '呼び出し管理';
let NAV_MENU_ORDERS = 'オーダー管理';
let NAV_MENU_BILLING_MANAGEMENT = '会計管理';
let NAV_MENU_MENU_MANAGEMENT = 'メニュー管理';
let NAV_MENU_QR_GENERATOR = 'QRコード';
let NAV_MENU_EDIT_ADVERTISEMENT = '広告管理';

let NAV_MENU_SETTINGS = '設定';
let NAV_MENU_LOGOUT = 'ログアウト';
let NAV_MENU_CHANGE_LOG = 'バージョン履歴';
let NAV_MENU_ABOUT_ME = 'ページについて';

// TILES
// AD IMPRESSIONS

let UPGRADE_YOUR_PLAN_TO_SEE = 'プランを変更すると閲覧可能';
let PLEASE_CONTACT_THE_SALES_TEAM = 'プランの変更についてはLYNKED（株）の営業チームにお問い合わせください';

let TABLE_NUMBER = 'テーブル番号';

let HISTORY = '履歴';
let EXPORT_CSV = 'エクスポート';
let CLEAR = '消去';

let ERR_RESTAURANT_IS_NOT_ACTIVE = 'あなたが所属するレストランはアクティブではありません';

let BILL_CONFIRM_CLEAR_BILL_TITLE = '会計確認';
let BILL_CONFIRM_CLEAR_BILL_QUESTION = '会計を完了しますか？';

let BILL_LIST_LOADING = '読み込み中';
let TILES_LOADING = '読み込み中';
let QR_LOADING = '読み込み中';

let BILL_LIST_NO_ACTIVE_BILL = '有効な請求書はありません';
let NO_LINE_UP_PEOPLE_WAITING = '受付はありません';

let AUDIO_NOTIFICATION = '音声通知';

let SAVE_USER_CONFIG = '設定を保存';
let LOAD_USER_CONFIG = 'ユーザー設定を読み込む';
let AUDIO = 'オーディオ';
let NO_NETWORK = 'ネットワークが切断されました';

let PRE_CACHING_FILES = '事前キャッシュファイル';

let START_SAVING_USER_CONFIG = 'ユーザー設定の保存を開始します';
let SAVING_USER_CONFIG_DONE = 'ユーザー設定の保存が完了しました';
let ERROR_OCCUR_DURING_SAVING_USER_CONFIG = 'ユーザー設定の保存中にエラーが発生する';

// RECEPTION
let NEW_LINEUP_REQUEST_RECEIVED = '新しい受付がありました';
let NEW_ORDER_RECEIVED = '新しいオーダーがありました';

let QR_CODES = 'QRコード';

let CURRENTLY_THERE_ARE_NO_HELP_REQUESTS = '呼び出しはありません';
let CURRENTLY_THERE_ARE_NO_RESERVATIONS = '受付はありません';
let UPGRADE_YOUR_PLAN_TO_SEE_MORE_REQUEST = 'プランを変更すると利用可能';
let UPGRADE_YOUR_PLAN_TO_SEE_MORE_TILES = 'プランを変更すると閲覧可能';
let ONE_TIME = '1PV';

let ASSIGNED_TABLES_LIST = 'テーブル管理表';
let SUCCESS_TABLE_ASSIGNED = 'テーブルが確定しました';
let NO_RESERVATIONS_AT_THE_MOMENT = '受付はありません';
let NO_ASSIGNED_TABLES_AT_THE_MOMENT = '利用中のテーブルはありません';

let COMPLETED = 'ステータス';
let THERE_ARE_NO_HELP_REQUESTS_AT_THE_MOMENT = '呼び出しはありません';

let SEARCH = 'SEARCH';
let ORDER_REQUEST_NO = '受付番号';

let ORDER_TIME = '注文受付';
let COMPLETE_BILL = '会計完了';
let CANCEL_COMPLETE_BILL = '戻る';

let ADD_ITEM = '商品追加';
let UPDATING = '更新中';
let SUCCESSFULLY_UPDATED = '更新が完了しました';

let RESTAURANT_LOGIN_PAGE = 'レストランログインページ';
let MENY_DIGITAL_MENU_PAGE = 'meny受付ページ';
let TABLE = 'テーブル';

// let MENY_RESERVATIONS_REQUESTS_PAGE_SETTINGS = 'meny受付ページの設定';

let CURRENTLY_THERE_IS_NO_SETTING_FEATURE_AVAILABLE = 'ご契約のプランでは設定不要です';

let CUSTOMIZE_OPTION_NAME = '名称';
let CUSTOMIZE_PRICE = '値段';
let NO_CUSTOMIZATION = 'カスタマイズなし';

let FOOD_NAME_IS_MISSING_ERROR = '入力が必須な項目です。商品名をご入力ください。';

let FOOD_STOCK_COUNT_IS_MISSING_ERROR = '入力が必須な項目です。製品の数量を入力してください。';
let FOOD_STOCK_COUNT_IS_NOT_A_NUMBER = '数字を入力してください。';
let FOOD_STOCK_COUNT_IS_NOT_A_INTEGER = '数字が正確ではありません。再度、入力してください。';
let FOOD_STOCK_COUNT_IS_GREATER_THAN_ZERO = '数量はゼロより大きくなければなりません。';

let PRICE_IS_MISSING_ERROR = '入力が必須な項目です。値段をご入力ください。';
let PRICE_IS_NOT_A_NUMBER = '数字を入力してください。';
let PRICE_IS_NOT_A_INTEGER = '数字が正確ではありません。再度、入力してください。';
let CATEGORY_IS_MISSING_ERROR = '入力が必須な項目です。カテゴリー名をご入力ください。';
let OPTION_NAME_IS_MISSING_ERROR = '入力が必須な項目です。名称をご入力ください。';
let OPTION_PRICE_IS_MISSING_ERROR = '入力が必須な項目です。値段をご入力ください。';
let FOOD_DETAIL_IS_LOADING = 'food detail is loading';

let FAIL_TO_ALLOCATE_TABLE = 'テーブルの割り当てに失敗しました';

let TABLE_ASSIGN_CONFIRM = '確定';
let TABLE_ASSIGN_CANCEL = 'キャンセル';
let TABLE_NUMBER_IS_MISSING_ERROR = '入力が必須な項目です。テーブル番号をご入力ください。';
let TABLE_NUMBER_IS_NOT_A_NUMBER = '数字が正確ではありません。';
let TABLE_NUMBER_IS_NOT_A_INTEGER = '数字を入力してください。';

let PLEASE_ASSIGN_A_TABLE_NUMBER = 'テーブル番号';
let SHOW_FOOD = '食物顯示';

export default {
  // MENY_RESERVATIONS_REQUESTS_PAGE_SETTINGS,
  // PLACEHOLDER_TEXT,

  ...edit_advertisement,
  ...about_lynked,
  ...billing_management,
  ...change_log,
  ...example_components,
  ...example_queries,
  ...help_requests,
  ...login,
  ...logout,
  ...menu_management,
  ...orders,
  ...overview,
  ...qr_gen,
  ...reception_list,
  ...settings,
  ...sorry_error,
  ...terms_and_conds,
  ...test_page,
  SHOW_FOOD,
  NAV_MENU_EDIT_ADVERTISEMENT,
  PLEASE_ASSIGN_A_TABLE_NUMBER,
  TABLE_NUMBER_IS_MISSING_ERROR,
  TABLE_NUMBER_IS_NOT_A_NUMBER,
  TABLE_NUMBER_IS_NOT_A_INTEGER,
  TABLE_ASSIGN_CANCEL,
  TABLE_ASSIGN_CONFIRM,
  FAIL_TO_ALLOCATE_TABLE,
  FOOD_DETAIL_IS_LOADING,
  OPTION_NAME_IS_MISSING_ERROR,
  CATEGORY_IS_MISSING_ERROR,
  OPTION_PRICE_IS_MISSING_ERROR,
  FOOD_NAME_IS_MISSING_ERROR,
  FOOD_STOCK_COUNT_IS_MISSING_ERROR,
  FOOD_STOCK_COUNT_IS_NOT_A_NUMBER,
  FOOD_STOCK_COUNT_IS_NOT_A_INTEGER,
  FOOD_STOCK_COUNT_IS_GREATER_THAN_ZERO,
  PRICE_IS_NOT_A_NUMBER,
  PRICE_IS_MISSING_ERROR,
  PRICE_IS_NOT_A_INTEGER,
  NO_CUSTOMIZATION,
  CUSTOMIZE_OPTION_NAME,
  CUSTOMIZE_PRICE,

  SAVE,
  CURRENTLY_THERE_IS_NO_SETTING_FEATURE_AVAILABLE,
  RESTAURANT_LOGIN_PAGE,
  MENY_DIGITAL_MENU_PAGE,
  TABLE,
  UPDATING,
  SUCCESSFULLY_UPDATED,
  ADD_ITEM,
  ORDER_TIME,
  COMPLETE_BILL,
  CANCEL_COMPLETE_BILL,
  SEARCH,
  ORDER_REQUEST_NO,
  COMPLETED,
  THERE_ARE_NO_HELP_REQUESTS_AT_THE_MOMENT,
  ASSIGNED_TABLES_LIST,
  SUCCESS_TABLE_ASSIGNED,
  NO_RESERVATIONS_AT_THE_MOMENT,
  NO_ASSIGNED_TABLES_AT_THE_MOMENT,
  QR_CODES,
  CURRENTLY_THERE_ARE_NO_HELP_REQUESTS,
  CURRENTLY_THERE_ARE_NO_RESERVATIONS,
  UPGRADE_YOUR_PLAN_TO_SEE_MORE_REQUEST,
  UPGRADE_YOUR_PLAN_TO_SEE_MORE_TILES,
  PLEASE_CONTACT_THE_SALES_TEAM,
  ONE_TIME,
  NEW_LINEUP_REQUEST_RECEIVED,
  NEW_ORDER_RECEIVED,

  START_SAVING_USER_CONFIG,
  SAVING_USER_CONFIG_DONE,
  ERROR_OCCUR_DURING_SAVING_USER_CONFIG,

  PRE_CACHING_FILES,
  NO_NETWORK,
  AUDIO,
  SAVE_USER_CONFIG,
  LOAD_USER_CONFIG,
  AUDIO_NOTIFICATION,
  NO_LINE_UP_PEOPLE_WAITING,
  TILES_LOADING,
  QR_LOADING,
  BILL_LIST_LOADING,
  BILL_LIST_NO_ACTIVE_BILL,
  BILL_CONFIRM_CLEAR_BILL_TITLE,
  BILL_CONFIRM_CLEAR_BILL_QUESTION,
  ERR_RESTAURANT_IS_NOT_ACTIVE,

  TABLE_NUMBER,
  HISTORY,
  EXPORT_CSV,
  CLEAR,

  NAV_MENU_SETTINGS,
  NAV_MENU_LOGOUT,
  NAV_MENU_CHANGE_LOG,
  NAV_MENU_ABOUT_ME,

  UPGRADE_YOUR_PLAN_TO_SEE,
  NAV_MENU_OVERVIEW,
  NAV_MENU_RECEPTION_LIST,
  NAV_MENU_HELP_REQUESTS,
  NAV_MENU_ORDERS,
  NAV_MENU_BILLING_MANAGEMENT,
  NAV_MENU_MENU_MANAGEMENT,
  NAV_MENU_QR_GENERATOR,

  OVERVIEW_HELP_REQUESTED,
  LOGIN_WARN_EMAIL_IS_REQUIRED,
  LOGIN_MUST_BE_A_VALID_EMAIL,
  LOGIN_GREETINGS,
  LOGIN_NOW,
  RESTAURANT_OPERATOR_SELECT_TITLE,
  ADMIN_USER_PASSWORD,
  RESTAURANT_MENY_PLAN,
  DEMO_ACCOUNTS,
  PASSWORD_CHANGE,
  EMAIL_ADDRESS,
  PASSWORD,
  LOGGED_OUT,
  BACK_TO_LOGIN,
  TABLE_ASKING_FOR_HELP,
  ADD_NEW_FOOD_OK,
  ADD_FOOD_CANCEL,
  EDIT_FOOD_OK,
  EDIT_FOOD_CANCEL,
  UPLOAD_IMAGE,
  NEW_FOOD_DIALOG,
  EDIT_FOOD_DIALOG,
  CHANGE_LOG,
  UPDATE,
  MESSAGE,
  EMAIL,
  PUSH_NOTIFICATIONS,
  PHONE_CALLS,
  NOTIFICATIONS,
  TEXT_MESSAGES,
  UPDATE_PASSWORD,
  INPUT_PASSWORD,
  INPUT_PASSWORD_AGAIN,
  NOTIFICATIONS_PAGE_TITLE,
  NOTIFICATIONS_PAGE_SUBTITLE,
  ADD_CATEGORY,
  SEARCH_CATEGORY,
  SUBSCRIBE_ADDITIONAL_SERVICE_TO_VIEW_MORE_1,
  SUBSCRIBE_ADDITIONAL_SERVICE_TO_VIEW_MORE_2,
  SUBSCRIBE_ADDITIONAL_SERVICE_TO_VIEW_MORE_3,
  // RECEIPT_BILL_SPITTING,
  ADMIN_ADD_NEW_ADVERTISEMENT,
  ABOUT_LYNKED,
  ABOUT_ME,
  ACCOUNT_MANAGMENT,
  ACKNOWLEDGE_SERVICE_REQUEST,
  ACKNOWLEDGED_PLEASE_PATIENT,
  ADD_CUSTOMER,
  ADD_FOOD_DONE,
  ADD_FOOD,
  ADMIN_ADD_NEW_RESTAURANT,
  ADMIN_ADD_NEW_USER,
  ADMIN_ADVERTISEMENT_ACTIONS,
  ADMIN_ADVERTISEMENT_EMAIL,
  ADMIN_ADVERTISEMENT_NAME,
  ADMIN_ADVERTISEMENT_REMARKS,
  ADMIN_ADVERTISEMENT_SERVICE_PLAN,
  ADMIN_ADVERTISEMENT_UPDATE_CANCEL,
  ADMIN_ADVERTISEMENT_UPDATE_OK,
  ADMIN_EDIT_RESTAURANT_INFO,
  ADMIN_EDIT_USER_INFO,
  ADMIN_RESTAURANT_ACTIONS,
  ADMIN_RESTAURANT_EMAIL,
  ADMIN_RESTAURANT_NAME,
  ADMIN_RESTAURANT_REMARKS,
  ADMIN_RESTAURANT_SERVICE_PLAN,
  ADMIN_RESTAURANT_UPDATE_CANCEL,
  ADMIN_RESTAURANT_UPDATE_OK,
  ADMIN_USER_ACTIONS,
  ADMIN_USER_EMAIL,
  ADMIN_USER_NAME,
  ADMIN_USER_REMARKS,
  ADMIN_USER_SERVICE_PLAN,
  ADMIN_USER_UPDATE_CANCEL,
  ADMIN_USER_UPDATE_OK,
  ADVERTISEMENT_SETTINGS_SAVE_CHANGE,
  ALERT_HELLOWORLD,
  ANNOUNCEMENT_DISMISS,
  ANNOUNCEMENT_TITLE,
  ASK_CONFIRM_CALLING_SERVICE,
  ASK_CONFIRM_DELETE,
  ASK_CUSTOMER_ADULT_NUMBER,
  ASK_CUSTOMER_BABY_NUMBER,
  ASK_CUSTOMER_CHILDREN_NUMBER,
  ASK_CUSTOMER_FIRST_NAME,
  ASK_FINISH_ORDER,
  ASSIGN_TICKET_DIALOG_TITLE,
  ASSIGNED_TABLE_NUMBER,
  AVERAGE_WAITING_TIME,
  BACK_TO_LANDING_PAGE,
  BACK_TO_MAIN,
  BACK_TO_MENU,
  BOTTOM_NAV_MENU_TEXT,
  BUDGET,
  CANCEL_UPDATE,
  CART_CHECKOUT_DONE,
  CEHCKOUT_FINISH_ORDER,
  CHANGE_ADVERTISEMENT_SETTINGS,
  CHART_DAY_TODAY,
  CHART_LAST_WEEK,
  CHART_LENGTH_SELECT,
  CHART_OVERVIEW,
  CHART_THIS_WEEK,
  CHECK_OUT_CART_UNIT_PRICE_TEXT,
  CHECKING_USER_CREDENTIALS,
  CHECKOUT_DELETE,
  CHECKOUT_ITEM_DELIVERED,
  CHECKOUT_ITEM_PREPARING,
  CLIENT_GOING_TO_RESTAURANT,
  CONFIRM_DELETE_FOOD_ITEM,
  CONFIRM_DELETE_ORDER,
  CONFIRM_FOOD_QTY,
  CONFIRM_LOGOUT_BODY,
  CONFIRM_LOGOUT_TITLE,
  CONFIRM_USER_DELETE_IMAGE,
  CONTACT_US_EXPLAIN,
  CONTACT_US,
  CURRENT_FOOD_IMAGE,
  CUSTOMER_CATEGORY,
  CUSTOMER_NAME,
  CUSTOMER_NOTES,
  DAY,
  DEFAULT_CHART_DATE_RANGE,
  DELETE_FOOD_ANS_NO,
  DELETE_FOOD_ANS_YES,
  DELETE_FOOD_DONE,
  DELETE_IMAGE_DONE,
  DELETE_IMAGE_ERROR,
  DELETE_IMAGE_IN_PROGRESS,
  DELETE_ORDER_DONE,
  DEMO_PURPOSE_ONLY,
  DIALOG_REPLY_NO,
  DIALOG_REPLY_YES,
  DONT_HAVE_ACCOUNT,
  EMPTY_RECEIPT_LIST,
  EXPORT,
  FEEDBACK_CONTACT_POINT,
  FEEDBACK_DETAIL_DESCRIPTION,
  FEEDBACK_FORM_STAR_RATING,
  FEEDBACK_FORM_TITLE,
  FIREBASE_STORAGE_CANCELED,
  FIREBASE_STORAGE_UNAUTHORIZED,
  FIREBASE_STORAGE_UNKNOWN,
  FIREBASE_STORAGE_UPLOAD_DONE,
  FIREBASE_STORAGE_UPLOAD_IN_PROGRESS,
  FOOD_CATEGORY_LABEL,
  FOOD_DESCRIPTION,
  FOOD_DETAILS,
  FOOD_DIALOG_TITLE,
  FOOD_EDIT_TITLE,
  FOOD_LONG_NAME,
  FOOD_NAME,
  FOOD_PHOTO,
  FOOD_PRICE,
  FROM,
  GET_FROM_GOOGLE,
  GO_TO_COMPANY_WEB_SITE,
  HELLOWORLD_ALERTS,
  HELLOWORLD_FORMS,
  HELLOWORLD,
  HISTORY_LENGTH_ONE_MONTH,
  HISTORY_LENGTH_ONE_WEEK,
  HISTORY_LENGTH_ONE_YEAR,
  HISTORY_LENGTH_SELECT,
  HISTORY_LENGTH_SIX_MONTHS,
  HISTORY_LENGTH_THREE_MONTHS,
  IMPORT,
  INCOMING_BILL,
  INCOMING_BUDGET,
  ITEM_SOLD,
  LAST_7_DAYS,
  LAST_UPDATE,
  LATEST_ORDERS,
  LATEST_SALES,
  LINE_UP_ORDERING_REF,
  LINEUP_CUSTOMER_NAME,
  LINEUP_ORDERING,
  LOADING_FOODS,
  LOADING_ORDERS,
  LOADING_SERVICE_REQUESTS,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGIN_USING_SOCIAL_NETWORK,
  LOGIN_WITH_FACEBOOK,
  LOGIN,
  LOGOUT,
  LYNKED_COPYRIGHT_RESERVED,
  MENU_DELETE,
  MENU_MANAGMENT,
  MENU_PREVIEW_ON_WEBSITE,
  MONTH,
  NAV_ADVERTISEMENT_CONTROL,
  NEW_SERVICE_REQUEST_RECEIVED,
  NO_FOOD_DETAILS_IN_LIST,
  NO_ORDER_WAITING,
  NO_SERVICE_REQUEST_WAITING,
  NUMBER_OF_CUSTOMER_GOT_TICKETTODAY,
  NUMBER_OF_CUSTOMER_WAITING_TICKET,
  NUMBER_OF_PEOPLES,
  ORDER_DELIVERED,
  ORDER_ITEMS_REMAINING,
  ORDER_MANAGMENT,
  ORDER_PENDING,
  ORDER_REF,
  ORDER_REFUNDED,
  ORDER_STATUS,
  ORDERING_HORIZONTAL,
  ORDERING_VERTICAL,
  OUR_COMPANY_EXPLAIN,
  OUR_COMPANY,
  OUR_VALUE,
  OUT_VALUE_EXPLAIN,
  OVERVIEW,
  PAGE_NOT_FOUND_EXPLAIN,
  PAGE_NOT_FOUND,
  PASSWORD_IS_REQUIRED,
  PERFORM_DELETE_IMAGE,
  PERFORM_LINEUP_QUESTION,
  PLEASE_SELECT_SIGN_IN_METHOD1,
  PLEASE_SELECT_SIGN_IN_METHOD2,
  PLEASE_SPECIFY_THE_FIRST_NAME,
  RECEPTION_LIST,
  CALL_LIST,
  TOTAL,
  PROCESS_LINEUP_DONE,
  QUESTION_PROCEED_TO_CHECKOUT,
  QUEUE_MANAGE_TITLE,
  RECEIPT_BILL_GRANDTOTAL,
  RECEIPT_BILL_ONE_PERSON_SHOULD_PAY,
  RECEIPT_BILL_PEOPLE,
  RECEIPT_BILL_SALES_TAX,
  RECEIPT_BILL_SPLITTING,
  RECEIPT_BILL_SUBTOTAL,
  RECEIPT_DATETIME,
  RECEIPT_INDEX,
  RECEIPT_ITEM_CANCELLED,
  RECEIPT_ITEM_DOLLAR_YEN,
  RECEIPT_ITEM_STATUS_DELIVERED,
  RECEIPT_ITEM_STATUS_PREPARING,
  RECEIPT_ITEM_UNIT,
  RECEIPT_TEXT,
  RECEIVE_LINE_UP_NUMBER,
  REST_UTILIZATION_RATE,
  RESTAURANT_NAME,
  RESTAURANT_STRING,
  SEARCH_CUSTOMER,
  SEARCH_FOOD,
  SEASON,
  SEAT_ASSIGN_NOT_REQUESTED,
  SEAT_REQUEST_NOTE,
  SEAT_RESERVATION,
  SEND_FEEDBACK_DEFAULT_TEXT,
  SEND_FEEDBACK,
  SERVICE_REQUEST_ASK_FOR_HELP,
  SERVICE_REQUEST_CLEARED,
  SERVICE_REQUEST_IN_PROGRESS,
  SERVICE_REQUEST_NAV_NAME,
  SERVICE_REQUEST_NOT_CLEARED,
  SERVICE_REQUEST_RECEIVED,
  SET_SEAT_REQUEST_NUMBER,
  SETTINGS,
  SHOPPING_CART_ADD_BUTTON_TEXT,
  SHOPPING_CART_DELETE_TEXT,
  SHOPPING_CART_DELETE,
  SHOPPING_CART_FOOD_ITEM,
  SHOPPING_CART_GO_TO_CHECKOUT,
  SHOPPING_CART_PRICE,
  SHOPPING_CART_QUANTITY,
  SHOPPING_CART_REMOVE_BUTTON_TEXT,
  SHOPPING_CART_TEXT,
  SIGN_IN_DESC,
  SIGN_IN_NOW,
  SIGN_IN_TITLE,
  SIGN_UP,
  SINCE_LAST_MONTH,
  STATISTICS_VIEW,
  SUBMIT_TIME,
  TABLE_NUMBER_TEXT,
  TABLE_RESERVE_READY,
  TELL_USER_SEAT_AVAILABLE,
  TERMS_AND_CONDITIONS,
  TERMS_AND_CONDS,
  TEST_ALERT,
  THANKS_FOR_PURCHASE,
  THIS_IS_SUCCESS_ALERT,
  TICKET_TAKE_TIME,
  TNC_BACK,
  TO,
  TOTAL_CUSTOMERS,
  TOTAL_PROFIT,
  UPDATE_AD_CONFIG_DONE,
  UPDATE_ASSIGN_TABLE_DONE,
  UPDATE_CHART,
  UPDATE_DELIVERED_ITEM,
  UPDATE_FOOD_DETAIL_DONE,
  USER_CHOOSE_DATE_RANGE,
  UTILIZATION,
  VIEW_ALL,
  WEEK,
  YES_I_KNOW,
};
