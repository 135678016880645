import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { makeStyles, IconButton } from '@material-ui/core';

// import DragHandleOutlinedIcon from '@material-ui/icons/DragHandleOutlined';
import { ReactComponent as DragHandleOutlinedIcon } from 'src/assets/icons/moveable.svg';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

import { Draggable } from 'react-beautiful-dnd';

import { ShareContext } from './context/Share';

import {
  LYNKED_WHITE,
  LYNKED_RED_DARKEN,
  LYNKED_LIGHT_GREY,
  LYNKED_LIGHT_GREY_HOVER,
  LYNKED_RED,
} from 'src/consts/colors';

import ShowYen from 'src/components/ShowYen';

import { STATUS_SOLD_OUT, STATUS_FOOD_HIDE } from 'src/modals/food_details';
import { useTranslation } from 'react-i18next';

import active_lang from 'src/langs/jp_en';

const useStyles = makeStyles((_theme) => ({
  helloworld: {},
  container: (_props) => ({
    borderBottom: '1px solid lightgrey',
    borderRadius: '2px',
    padding: '8px',
    backgroundColor: 'white',
    // marginBottom: '8px',
    '&:hover': {
      backgroundColor: LYNKED_LIGHT_GREY_HOVER,
    },
  }),
  container_is_dragging: {
    backgroundColor: LYNKED_RED_DARKEN,
    color: LYNKED_WHITE,
  },
  container_is_stop: {
    backgroundColor: 'white',
  },
  container_is_collapsed: {
    height: '0px',
    display: 'none',
  },

  handle: {
    width: '20px',
    height: '20px',
    // backgroundColor: "orange",
    borderRadius: '4px',
    marginRight: '8px',
    color: '#E0E0E0',
  },
  food_detail_block: {
    display: 'flex',
    flexFlow: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',

    margin: '1rem 0.5rem',
  },
  food_detail_left: {
    width: '25%',
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  food_detail_buttons: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    margin: '0',
    marginLeft: '2rem',
  },
  food_image_container: {
    margin: '0',
    marginLeft: '1rem',
    width: '300px',
    maxHeight: '100px',
  },
  food_image: {
    width: '100px',
    height: '100px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  food_title_container: {
    margin: '0',
    marginLeft: '0.5rem',
  },
  food_title: {
    font: 'normal normal normal 12px/18px Hiragino Sans',
  },
  food_price: {
    font: 'normal normal normal 12px/18px Hiragino Sans',
  },

  food_description: {
    width: '75%',
    // padding: '0 4rem',
    margin: '0rem',
    marginLeft: '1rem',
    font: 'normal normal normal 12px/18px Hiragino Sans',
    color: LYNKED_LIGHT_GREY,
  },
  fd_buttons: {
    margin: '0 0.5rem',
  },
}));

function Task({ task, index, search }) {
  const classes = useStyles();
  const { t } = useTranslation();

  let { handleItemEditClick, handleItemDeleteClick } =
    React.useContext(ShareContext);

  const ShowTextSoldOutStatus = ({ status }) => {
    if (STATUS_SOLD_OUT === status) {
      return (
        <div
          style={{ paddingLeft: '1rem', color: LYNKED_RED }}
        >{`(  ${t('MENU_OUT_TO_STOCK')}  )`}</div>
      );
    }
    return <></>;
  };

  const ShowTextFoodHideStatus = ({ food_show_status }) => {
    if (STATUS_FOOD_HIDE === food_show_status) {
      return (
        <div style={{ paddingLeft: '1rem', color: LYNKED_RED }}>
          {`(  ${t('COMMON_NOT_PULIC')}  )`}
        </div>
      );
    }
    return <></>;
  };

  const pretty_food_description = (food_description) => {
    if (food_description.length > 0) {
      if (food_description.length > 40) {
        return food_description.replace(/\n/g, '<br />').slice(0, 40) + '...';
      } else {
        return food_description.replace(/\n/g, '<br />');
      }
    } else {
      return '';
    }
  };
  return (
    <>
      <Draggable
        draggableId={task.id}
        index={index}
        isDragDisabled={Boolean(search)}
      >
        {(provided, snapshot) => {
          return (
            <div
              className={`${classes.container} ${
                snapshot.isDragging
                  ? classes.container_is_dragging
                  : classes.container_is_stop
              }`}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <div className={classes.handle} {...provided.dragHandleProps}>
                  <DragHandleOutlinedIcon />
                </div>
                <div className={classes.food_image_container}>
                  <div
                    style={{
                      display: 'flex',
                      flexFlow: 'row',
                      justifyContent: 'center',
                    }}
                  >
                    <LazyLoadImage
                      src={task.image_url}
                      height={'68px'}
                      width={110}
                      style={{ objectFit: 'cover', borderRadius: 3 }}
                    />
                  </div>
                </div>

                <div className={classes.food_detail_block}>
                  <div className={classes.food_detail_left}>
                    <div className={classes.food_title_container}>
                      <div
                        className={classes.food_title}
                        style={{
                          display: 'flex',
                          flexFlow: 'row',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                        }}
                      >
                        <div className={classes.food_title}>{task.name}</div>
                        <div
                          style={{
                            display: 'flow',
                            flexFlow: 'column',
                            justifyContent: 'center',
                          }}
                        >
                          <div>
                            <ShowTextSoldOutStatus status={task.sold_out} />
                          </div>
                          <div>
                            <ShowTextFoodHideStatus
                              food_show_status={task.food_show_status}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={classes.food_price}>
                        <ShowYen value_yen={task.unit_price} />
                      </div>
                    </div>
                  </div>
                  <div
                    className={classes.food_description}
                    dangerouslySetInnerHTML={{
                      __html: pretty_food_description(task.description),
                    }}
                  >
                    {/* {food_detail.description} */}
                  </div>
                </div>

                <div>
                  <div className={classes.food_detail_buttons}>
                    <div
                      className={`${classes.fd_buttons} ${classes.EditButton}`}
                    >
                      <IconButton
                        id={`edit-food-item-button-${index}`}
                        onClick={(e) => {
                          handleItemEditClick(e, task.id);
                        }}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    </div>
                    <div
                      className={`${classes.fd_buttons} ${classes.DeleteButton}`}
                    >
                      <IconButton
                        onClick={(e) => {
                          handleItemDeleteClick(e, task);
                        }}
                      >
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Draggable>
    </>
  );
}

export default React.memo(Task);
