import React from 'react';

import BillPlaceholder from './BillPlaceholder';
import BillCancelled from 'src/components/BillCancelled';
import BillDelivered from 'src/components/BillDelivered';
import BillPreparing from 'src/components/BillPreparing';

import {
  STATUS_CANCELLED,
  STATUS_DELIVERED,
  STATUS_PREPARING,
} from 'src/consts/ORDER_STATUS';

export default function BillEntry({ entry }) {
  if (entry) {
    switch (entry.status) {
      case STATUS_PREPARING:
        return <BillPreparing entry={entry} />;

      case STATUS_DELIVERED:
        return <BillDelivered entry={entry} />;

      case STATUS_CANCELLED:
        return <BillCancelled entry={entry} />;

      default:
        return <BillPlaceholder />;
    }
  } else {
    return <></>;
  }
}
