const ENQUEUE_SUCCESS_CONFIG = {
  variant: 'success',
};
const ENQUEUE_ERROR_CONFIG = {
  variant: 'error',
};
const ENQUEUE_WARNING_CONFIG = {
  variant: 'warning',
};

export { ENQUEUE_SUCCESS_CONFIG, ENQUEUE_WARNING_CONFIG, ENQUEUE_ERROR_CONFIG };
