import { useMutation } from 'react-query';
import { addFoodCategory } from 'src/modals/food_details';

export default function useMutateAddFoodCategory() {
  const mutation = useMutation(
    ({ rest_id, category_title, category_show }) => {
      console.log('useMutateAddFoodCategory', 'rest_id', rest_id);
      console.log('useMutateAddFoodCategory', 'category_title', category_title);
      return addFoodCategory(rest_id, category_title, category_show);
    },
    {
      retry: 10,
    }
  );

  return mutation;
}
