import React from 'react';

import { makeStyles, Slide } from '@material-ui/core';

import {
  LYNKED_BLACK,
  LYNKED_BLACK_HOVER,
  LYNKED_GREEN,
  LYNKED_GREEN_HOVER,
  LYNKED_LIGHT_GREY,
} from 'src/consts/colors';

import './EditTableInformationDialog_style.css';

let ASCII_0 = 48;
let ASCII_9 = 57;
let ASCII_FW_0 = 65296;
let ASCII_FW_9 = 65305;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  helloworld: {},
  AssignedTAbleDialogWrapper: {
    width: '430px',

    display: 'flex',
    flexFlow: 'row',

    font: 'normal normal normal 14px/18px Noto Sans JP',
  },
  statusIndicator: {
    width: '10px',
    borderRadius: '2px 0px 0px 2px',
  },
  CardContentWrapper: {
    width: '100%',
    height: '100%',
    display: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  CardContent: {
    margin: '30px 30px',
  },
  InputTextField: {
    background: '#FFFFFF',
    border: '1px solid #B3B3B3',
    borderRadius: '0px',
    opacity: '1',
    margin: '1rem',
  },

  TextInput: {
    maxWidth: '205px',
    width: '100%',
    border: '1px solid #E0E0E0',
    borderRadius: '5px',
    font: 'normal normal bold 12px/16px Noto Sans JP',
    '& ::placeholder': {
      color: LYNKED_LIGHT_GREY,
    },
    '& > .MuiInput-input': {
      padding: '0.5rem 1rem !important',
    },
  },
  TextareaInput: {
    border: '1px solid #E0E0E0',
    borderRadius: '5px',
    font: 'normal normal bold 12px/16px Noto Sans JP',
    '& ::placeholder': {
      color: LYNKED_LIGHT_GREY,
    },
    '& > .MuiInput-input': {
      padding: '0.5rem 1rem !important',
    },
  },
  DialogBottom: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-end',
    columnGap: '20px',
    marginTop: '20px',
  },
  Buttons: {
    width: '120px',
    padding: '10px',
    color: 'white',
    font: 'normal normal bold 12px/16px Noto Sans JP',
    letterSpacing: '0.12px',
    borderRadius: '5px',
  },
  ButtonCancel: {
    backgroundColor: LYNKED_BLACK,
    '&:hover': {
      backgroundColor: LYNKED_BLACK_HOVER,
    },
  },
  ButtonOK: {
    backgroundColor: LYNKED_GREEN,
    '&:hover': {
      backgroundColor: LYNKED_GREEN_HOVER,
    },
  },
  CustomerName: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '10px',
    marginTop: '10px',
  },
  CustomerTable: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '10px',
  },
  NoOfGuest: {
    display: 'flex',
    alignItems: 'flex-start',
    columnGap: '10px',
    margin: '22px 0',
  },
  CustomerNameInput: {
    position: 'relative',
  },
  CustomerTableNumbernput: {
    position: 'relative',
  },
  NumberGuestWrapper: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '12px',
  },
  NumberGuestText: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#333',
  },
  IconButton: {
    width: '16px',
    height: '16px',
    border: '1px solid #333',
  },
  NumberGestQuantity: {
    fontSize: '14px',
    fontWeight: 400,
    margin: '0 7px',
  },
}));

export { useStyles, Transition };
