import React from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from 'src/Firebase/context';

import active_lang from 'src/langs/jp_en';
import { isNull } from 'lodash';
import { isUndefined } from 'lodash-es';
import { useTranslation } from 'react-i18next';

export default function CheckLogin() {
  const navigate = useNavigate();
  let { user } = React.useContext(AuthContext);
  const { t } = useTranslation();

  let [confirm_loaded, setConfirmLoaded] = React.useState(false);

  React.useEffect(() => {
    if (confirm_loaded) {
      // after confirm loaded
      if (isUndefined(user) || isNull(user)) {
        navigate('/login', { replace: true });
      }
    }
  }, [confirm_loaded]);

  React.useEffect(() => {
    console.log('AppLayout', 'index.js', 'starting');
    setTimeout(() => {
      setConfirmLoaded(true);
    }, 3000);
  }, []);

  return <>{t('LOGIN_CHECKING_USER')}</>;
}
