import React from 'react';
import { Box, Button } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import active_lang from 'src/langs/jp_en';

import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';

export default function AddNewAdsButton({ onClick }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Button className={classes.AddNewAdsButton} onClick={onClick}>
        <Box style={{ width: '24px', height: '24px' }}>
          <AddCircleOutlineIcon />
        </Box>
        <Box style={{ paddingLeft: '5px' }}>{t('AD_NEW_AD')}</Box>
      </Button>
    </>
  );
}
