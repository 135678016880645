function helloworld() {
  // alert('helloworld');
}
function checkXlUp() {
  return document.querySelector('html').clientWidth > 1920;
}

function checkLgUp() {
  return document.querySelector('html').clientWidth > 1280;
}

function checkMdUp() {
  return document.querySelector('html').clientWidth > 960;
}

function checkSmUp() {
  return document.querySelector('html').clientWidth > 1280;
}

function checkXsUp() {
  return document.querySelector('html').clientWidth > 0;
}

export { checkXlUp, checkLgUp, checkMdUp, checkSmUp, checkXsUp, helloworld };
