import React from 'react';

const SCREEN_DESKTOP = 'desktop';
const SCREEN_IPAD_LANDSCAPE = 'IPAD_LANDSCAPE';
const SCREEN_IPAD_PORTRAIT = 'IPAD_PORTRAIT';
const SCREEN_IPAD_PRO_PORTRAIT = 'IPAD_PRO_PORTRAIT';
const SCREEN_IPAD_PRO_LANDSCAPE = 'IPAD_PRO_LANDSCAPE';

export default function DeviceInfoContextSharedVar() {
  let [deviceinfocontext_to_monitor1, setDeviceInfoContextToMonitor1] =
    React.useState(null);

  let [screen_width, setScreenWidth] = React.useState();
  let [screen_height, setScreenHeight] = React.useState();
  let [device_screen, setDeviceScreen] = React.useState(SCREEN_DESKTOP);
  let [ios_device, setIosDevice] = React.useState(false);

  return {
    deviceinfocontext_to_monitor1,
    setDeviceInfoContextToMonitor1,

    device_screen,
    setDeviceScreen,
    ios_device,
    SCREEN_DESKTOP,
    screen_height,
    SCREEN_IPAD_LANDSCAPE,
    SCREEN_IPAD_PORTRAIT,
    SCREEN_IPAD_PRO_LANDSCAPE,
    SCREEN_IPAD_PRO_PORTRAIT,
    screen_width,
    setIosDevice,
    setScreenHeight,
    setScreenWidth,
  };
}
