import React from 'react';

import { ReceptionSoundContextProvider } from './ReceptionSound';
import { OrderSoundContextProvider } from './OrderSound';
import { ServiceRequestSoundContextProvider } from './ServiceRequestSound';

export default function ContextBundle({ children }) {
  return (
    <>
      <ReceptionSoundContextProvider>
        <ServiceRequestSoundContextProvider>
          <OrderSoundContextProvider>{children}</OrderSoundContextProvider>
        </ServiceRequestSoundContextProvider>
      </ReceptionSoundContextProvider>
    </>
  );
}
