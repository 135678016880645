import db_config from '../configs/db_config';

import db from '../Firebase/db';

const STATUS_FOOD_SHOW = 0;
const STATUS_FOOD_HIDE = 1;

const STATUS_CATEGORY_SHOW = 0;
const STATUS_CATEGORY_HIDE = 1;

const STATUS_HIDE_FROM_MENU = 'STATUS_HIDE_FROM_MENU';
const STATUS_AVAILABLE = 'STATUS_AVAILABLE';
const STATUS_SOLD_OUT = 'STATUS_SOLD_OUT';
const STATUS_IN_STOCK = 'STATUS_IN_STOCK';

const getRandomFoodId = () => {
  return Math.random().toString(36).substr(2, 10);
};

const default_new_food_detail = {
  customize_group_settings: [],
  description: '',
  food_id: '',
  image_url: '',
  long_name: '',
  max_quantity_per_order: 10,
  min_quantity_per_order: 1,
  name: '',
  price: 0,
  sold_out: STATUS_IN_STOCK,
  food_show_status: STATUS_FOOD_SHOW,
  tags_raw: '',
  tags: [],
  unit_price: 0,
  isEnableStockCount: false,
  foodStockCount: '',
};

function modals_food_detail_helloworld() {
  console.log('helloworld from modals/food_detail');
}

function oldGetAllFoods() {
  console.log('oldGetAllFoods');

  return db
    .collection(db_config.DB_TABLE_FOOD_DETAILS)
    .get()
    .then((querySnapshot) => {
      // let a_d = [];
      // querySnapshot.forEach(function (doc) {
      //   a_d.push({
      //     id: doc.id,
      //     data: doc.data(),
      //   });
      // });
      // return a_d;
      return querySnapshot.docs();
    });
}

function dbAllFoodDetails() {
  return db.collection(db_config.DB_TABLE_ALL_FOOD_DETAILS);
}

function getAllFoods(rest_id) {
  console.log('getAllFoods', 'rest_id', rest_id);

  return db
    .collection(db_config.DB_TABLE_ALL_FOOD_DETAILS)
    .doc(rest_id)
    .get()
    .then((querySnapshot) => {
      // let a_d = [];
      // querySnapshot.forEach(function (doc) {
      //   a_d.push({
      //     id: doc.id,
      //     data: doc.data(),
      //   });
      // });
      // return a_d;
      return querySnapshot.data();
    });
}

function subscribeFoodMenuChange(rest_id) {
  return db.collection(db_config.DB_TABLE_ALL_FOOD_MENU).doc(rest_id);
}

function setAllFoods(rest_id, payload) {
  // alert('helloworld');
  console.error('to be update using updateAllFoods');
  return updateAllFoods(rest_id, payload);
}

function updateAllFoods(rest_id, payload) {
  // alert('helloworld');
  let DocRef = db.collection(db_config.DB_TABLE_ALL_FOOD_DETAILS).doc(rest_id);

  return db
    .runTransaction((transaction) => {
      return transaction.get(DocRef).then(() => {
        transaction.set(DocRef, payload);
      });
    })
    .then(() => {
      console.log('updateAllFoods committed!');
      console.log('updateAllFoods', 'payload', payload);
    })
    .catch((error) => {
      console.log('updateAllFoods failed: ', error);
      console.log('updateAllFoods', 'payload', payload);
    });
}

function updateFoodDetails(rest_id, doc_id, update_food_detail) {
  console.log('updateFoodDetails', 'doc_id', doc_id);
  console.log('updateFoodDetails', 'update_food_detail', update_food_detail);
  let payload = {};
  payload[`food_details.${doc_id}`] = update_food_detail;

  const document = dbAllFoodDetails().doc(rest_id);
  let DocRef = document;

  return db
    .runTransaction((transaction) => {
      return transaction.get(DocRef).then(() => {
        transaction.update(DocRef, payload);
      });
    })
    .then(() => {
      console.log('Transaction successfully committed!');
    })
    .catch((error) => {
      console.log('Transaction failed: ', error);
    });
}

function addFoodIdToCategories(rest_id, category, food_id_to_add) {
  // alert('helloworld2');
  let category_id = category.id;

  console.log('addFoodIdToCategories', 'rest_id', rest_id);
  console.log('addFoodIdToCategories', 'category_id', category_id);
  console.log('addFoodIdToCategories', 'food_id_to_add', food_id_to_add);

  const document = dbAllFoodDetails().doc(rest_id);
  let DocRef = document;

  return db
    .runTransaction((transaction) => {
      return transaction
        .get(DocRef)
        .then((doc) => doc.data())
        .then(async (doc_data) => {
          console.log('addFoodIdToCategories', 'doc_data', doc_data);
          let current_task_ids = doc_data.columns[category_id]['taskIds'];

          current_task_ids.push(food_id_to_add);
          console.log('path', `columns.${category_id}.taskIds`);
          const update = {};
          update[`columns.${category_id}.taskIds`] = current_task_ids;

          return transaction.update(DocRef, update);
        });
    })
    .then(() => {
      console.log('Transaction successfully committed!');
    })
    .catch((error) => {
      console.log('Transaction failed: ', error);
    });
}

function getFoodDetails(rest_id) {
  console.log('getFoodDetails', 'rest_id', rest_id);
  return dbAllFoodDetails().doc(rest_id).get();
}

function addFoodDetails(rest_id, new_food_id, add_food_detail) {
  console.log('addFoodDetails', 'update_food_detail', add_food_detail);
  const document = dbAllFoodDetails().doc(rest_id);
  const payload = {};
  payload['food_details'] = {};
  payload['food_details'][new_food_id] = add_food_detail;

  let DocRef = document;

  return db
    .runTransaction((transaction) => {
      return transaction.get(DocRef).then(() => {
        transaction.set(DocRef, payload, { merge: true });
      });
    })
    .then(() => {
      console.log('Transaction successfully committed!');
    })
    .catch((error) => {
      console.log('Transaction failed: ', error);
    });
}

function setFoodDetails(rest_id, payload) {
  try {
    return dbAllFoodDetails().doc(rest_id).set(payload, { merge: true });
  } catch (error) {
    console.log('error', 'payload', payload);
    throw error;
  }
}

function getNewFoodCategoryId() {
  let temp = dbAllFoodDetails().doc().id;
  console.log('getNewFoodCategoryId', temp);
  return temp;
}

// MENY-191, restaurant finish added new category, but try first time add new food, does not able to find the new created category in category list field
function addFoodCategory(rest_id, category_name, category_show) {
  let DocRef = dbAllFoodDetails().doc(rest_id);
  let category_id = getNewFoodCategoryId();

  return db
    .runTransaction((transaction) => {
      return transaction
        .get(DocRef)
        .then((doc) => doc.data())
        .then((current_food_detail) => {
          console.log('current_food_detail', current_food_detail);
          return transaction.set(
            DocRef,
            {
              ...current_food_detail,
              columnOrder: [category_id, ...current_food_detail.columnOrder],
              columns: {
                ...current_food_detail.columns,
                [category_id]: {
                  taskIds: [],
                  title: category_name,
                  id: category_id,
                  category_show: category_show,
                },
              },
            },
            { merge: true }
          );
        })
        .then(() => {
          console.log('addFoodCategory', 'add food category done');
        })
        .catch((err) => {
          console.log('addFoodCategory', 'error during adding category');
          console.log('addFoodCategory', err);
        });
    })
    .then(() => {
      console.log('Transaction successfully committed!');
    })
    .catch((error) => {
      console.log('Transaction failed: ', error);
    });
}

function updateFoodCategroies(rest_id, categories) {
  let DocRef = dbAllFoodDetails().doc(rest_id);

  return db
    .runTransaction((transaction) => {
      return transaction.get(DocRef).then(() => {
        transaction.set(
          DocRef,
          {
            columns: categories,
          },
          { merge: true }
        );
      });
    })
    .then(() => {
      console.log('Transaction successfully committed!');
    })
    .catch((error) => {
      console.log('Transaction failed: ', error);
    });
}

// function addFoodDetails(rest_id, add_food_detail) {
//   let doc_id = 'new_food_id';

//   let payload = {};
//   payload[`food_details.${doc_id}`] = update_food_detail;
//   return dbAllFoodDetails().doc(rest_id).update(payload);

// }

// MENY-191, restaurant finish added new category, but try first time add new food, does not able to find the new created category in category list field
export {
  modals_food_detail_helloworld,
  getAllFoods,
  subscribeFoodMenuChange,
  oldGetAllFoods,
  updateFoodDetails,
  addFoodDetails,
  default_new_food_detail,
  setAllFoods,
  updateAllFoods,
  getRandomFoodId,
  addFoodIdToCategories,
  getFoodDetails,
  setFoodDetails,
  updateFoodCategroies,
  addFoodCategory,
  getNewFoodCategoryId,
  STATUS_FOOD_SHOW,
  STATUS_FOOD_HIDE,
  STATUS_HIDE_FROM_MENU,
  STATUS_AVAILABLE,
  STATUS_SOLD_OUT,
  STATUS_IN_STOCK,
  STATUS_CATEGORY_SHOW,
  STATUS_CATEGORY_HIDE,
};
