import React from 'react';

import ShowDebug from 'src/components/ShowDebug';

import ShowOrderTable from './ShowOrderTable';

import { MonitorOrderContext } from 'src/contexts/MonitorOrder';

import { LYNKED_DEEP_GREY } from 'src/consts/colors';

import active_lang from 'src/langs/jp_en';

export default function OrderTable({ filter_by_words }) {
  let [show_content, setShowContent] = React.useState(false);
  let { order_list, setFlattenedOrderList, flattened_order_list } =
    React.useContext(MonitorOrderContext);

  // let flattened_order_list = SAMPLE_LIST;

  React.useEffect(() => {
    console.log('filter_by_words', filter_by_words);
  }, [filter_by_words]);

  React.useEffect(() => {
    if (flattened_order_list != null) {
      setShowContent(true);
    }
  }, [flattened_order_list]);

  return (
    <>
      {show_content ? (
        flattened_order_list.orders.length > 0 ? (
          <ShowOrderTable
            orders={flattened_order_list.orders}
            filter_by_words={filter_by_words}
          />
        ) : (
          <>
            <div
              style={{
                width: '100%',
                margin: '2rem',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                color: LYNKED_DEEP_GREY,
              }}
            >
              {active_lang.YOU_HAVE_NO_ORDERS_AT_THE_MOMENT}
            </div>
          </>
        )
      ) : (
        <></>
      )}
      {show_content && flattened_order_list.inactiveOrders.length > 0 && (
        <ShowOrderTable
          orders={flattened_order_list.inactiveOrders}
          filter_by_words={filter_by_words}
        />
      )}
    </>
  );
}
