import React from 'react';
import { Grid } from '@material-ui/core';
import AddCustomizeGroupButton from './AddCustomizeGroupButton';
import { isUndefined, isNull } from 'lodash';

export default function AddNewCustomizeGroup({ id, onChange, value }) {
  const handleAddNewCustomizeSetting = () => {
    console.log('AddNewCustomizeGroup', 'value', value);

    if (!isUndefined(value) && !isNull(value)) {
      onChange({
        target: {
          id: 'customize_group_settings',
          value: [...value, { name: '', price: 0 }],
        },
      });
    } else {
      onChange({
        target: {
          id: 'customize_group_settings',
          value: [{ name: '', price: 0 }],
        },
      });
    }
  };

  return (
    <>
      <Grid
        item
        xs={2}
        style={{
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '200px',
        }}
      >
        <AddCustomizeGroupButton
          handleAddNewCustomizeSetting={handleAddNewCustomizeSetting}
        />
      </Grid>
    </>
  );
}
