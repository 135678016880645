import React from 'react';
import { Box } from '@material-ui/core';
import { isNull, isUndefined } from 'lodash';

import useQueryRestaurantConfigByRestId from 'src/hooks/useQueryRestaurantConfigByRestId';
import Page from 'src/components/Page';
import { GlobalContext } from 'src/contexts/Global';
import { SERVICE_MENY, SERVICE_MENY_LIGHT } from 'src/consts/MENY_SERVICE';
import ShowDebug from 'src/components/ShowDebug';

import NoSettingsAvailable from './NoSettingsAvailable';
import MainSettingsForm from './MainSettingsForm';
import SettingsLoading from './SettingsLoading';
import { useStyles } from './styles';
import useMutateSubscribeRestaurantConfig from 'src/hooks/useMutateSubscribeRestaurantConfig';
import { toLocalOpeningSchedule } from "src/utils/TimezoneFormat";
import { cloneDeep } from 'lodash';

const LOG_PREFIX = 'SettingsPage';

export default () => {
  const classes = useStyles();

  const { rest_id, meny_service_class } = React.useContext(GlobalContext);

  let [show_settings, setShowSettings] = React.useState(false);
  let [current_setting, setCurrentSetting] = React.useState({});

  let test_query = useQueryRestaurantConfigByRestId(rest_id);
  let { isLoading, data, isRefetching } = test_query;
  const mutateSubscribeRestaurantConfig = useMutateSubscribeRestaurantConfig();

  React.useEffect(() => {
    if (meny_service_class) {
      if (meny_service_class.id === SERVICE_MENY_LIGHT) {
        // meny_light
        setShowSettings(true);
      } else if (meny_service_class.id === SERVICE_MENY) {
        // meny
        setShowSettings(true);
      } else {
      }
    }
  }, [meny_service_class]);

  React.useEffect(() => {
    if (rest_id) {
      mutateSubscribeRestaurantConfig.mutateAsync(rest_id).then((res) =>
        res.onSnapshot((doc) => {
          const config = doc.data();
          config.opening_schedule = toLocalOpeningSchedule(config.opening_schedule);
          
          if (config.men_service_class?.name === 'meny(32)') {
            setCurrentSetting({
              ...config,
              lineup: {
                ...config.lineup,
                lineup_table_placeholder:
                  config.lineup.lineup_table_placeholder || '',
              },
            });
          } else {
            setCurrentSetting(config);
          }
        })
      );
    }
  }, [rest_id]);

  React.useEffect(() => {
    console.log(LOG_PREFIX, { test_query });
  }, [test_query]);

  if (isLoading || isRefetching) return <SettingsLoading />;
  if (!show_settings) return <NoSettingsAvailable />;

  return (
    <>
      <Page className={classes.root}>
        <Box style={{ width: '100%', paddingBottom: '5rem' }}>
          <MainSettingsForm
            rest_id={rest_id}
            current_setting={current_setting}
            meny_service_class={current_setting.meny_service_class}
          />
        </Box>
      </Page>
      <ShowDebug>{JSON.stringify(current_setting)}</ShowDebug>
    </>
  );
};
