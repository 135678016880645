import db_config from 'src/configs/db_config';
import db from 'src/Firebase/db';

import { getNearestCutOffTime } from 'src/modals/getNearestCutOffTime';

const DB_TABLE_ALL_SERVICE_REQUESTS_REF = db.collection(
  db_config.DB_TABLE_ALL_SERVICE_REQUESTS
);

function acknowledgeServiceRequestById(rest_id, service_request_id) {
  let DocRef = db
    .collection(db_config.DB_TABLE_ALL_SERVICE_REQUESTS)
    .doc(rest_id)
    .collection('requests')
    .doc(service_request_id);

  return db
    .runTransaction((transaction) => {
      return transaction.get(DocRef).then((sfDoc) => {
        transaction.set(DocRef, { status: 'CLEARED' }, { merge: true });
      });
    })
    .then(() => {
      console.log('Transaction successfully committed!');
    })
    .catch((error) => {
      console.log('Transaction failed: ', error);
    });
}

function acknowledgeServiceRequestByTableName(rest_id, table_name) {
  console.log('acknowledgeServiceRequestByTableName', 'rest_id', rest_id);
  console.log('acknowledgeServiceRequestByTableName', 'table_name', table_name);

  var batch = db.batch();
  return db
    .collection(db_config.DB_TABLE_ALL_SERVICE_REQUESTS)
    .doc(rest_id)
    .collection('requests')
    .where('table_name', '==', table_name)
    .get()
    .then((docs) => {
      docs.forEach((doc) => {
        // console.log('doc', doc.data());
        batch.update(doc.ref, { status: 'CLEARED' });
      });
    })
    .then(() => {
      batch.commit().then(() => {});
    });
}

function listAllServiceRequest(rest_id) {
  return DB_TABLE_ALL_SERVICE_REQUESTS_REF.doc(rest_id).collection('requests');
}

function listServiceRequestAfterCutoffTime(rest_id) {
  return listAllServiceRequest(rest_id).where(
    'update_time',
    '>',
    getNearestCutOffTime().unix() * 1000
  );
}

function listNotClearedServiceRequestAfterCutoffTime(rest_id) {
  return listServiceRequestAfterCutoffTime(rest_id)
    .where('status', '==', 'NOT_CLEARED')
    .orderBy('update_time');
}

function getAllServiceRequest(rest_id) {
  return listAllServiceRequest(rest_id).get();
}

function updateServiceRequestTableInfo(rest_id, newOrderId, tableInfor) {
  const DocRef = db
    .collection(db_config.DB_TABLE_ALL_SERVICE_REQUESTS)
    .doc(rest_id)
    .collection('requests');
  return DocRef.get().then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      if (doc.data()?.order_history_id === newOrderId) {
        const payload = {
          ...doc.data(),
          table_name: tableInfor['assign_table_name'],
          username: tableInfor['lineup-username'],
        };
        const docRef = DocRef.doc(doc.id);
        return db
          .runTransaction((transaction) => {
            return transaction.get(docRef).then((sfDoc) => {
              transaction.set(docRef, payload, { merge: true });
            });
          })
          .then(() => {
            console.log('Transaction successfully committed!');
          })
          .catch((error) => {
            console.log('Transaction failed: ', error);
          });
      }
    });
  });
}

export {
  acknowledgeServiceRequestById,
  acknowledgeServiceRequestByTableName,
  listAllServiceRequest,
  listServiceRequestAfterCutoffTime,
  listNotClearedServiceRequestAfterCutoffTime,
  getAllServiceRequest,
  updateServiceRequestTableInfo,
};
