import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

import { Box, Button, makeStyles } from '@material-ui/core';

import {
  LYNKED_RED,
  LYNKED_RED_HOVER,
  LYNKED_WHITE,
  LYNKED_DEEP_GREY,
  LYNKED_DEEP_GREY_HOVER,
} from 'src/consts/colors';

import active_lang from 'src/langs/jp_en';

import { isNull } from 'lodash';
import { dialog_transition_duration } from 'src/consts/TRANSITION';
import ShowDebug from 'src/components/ShowDebug';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((_theme) => ({
  helloworld: {},
  DialogBottom: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',

    width: '100%',
    justifyContent: 'space-between',
  },
  ConfirmDeleteDialogWrapper: {
    margin: '30px',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  DialogTitle: {
    paddingBottom: '2rem',
    fontWeight: 'bold'
  },
  QuestionsWrapper: {
    textAlign: 'center',
    paddingBottom: '3rem',
    fontWeight: 'bold'
  },
  FooterButtonsGroup: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  FooterButtons: {
    width: '48%',
  },
  ButtonCancel: {
    width: '48%',
    backgroundColor: LYNKED_DEEP_GREY,
    '&:hover': {
      backgroundColor: LYNKED_DEEP_GREY_HOVER,
    },
    color: LYNKED_WHITE,
    borderRadius: '1px',
  },
  ButtonAlertOk: {
    width: '48%',
    backgroundColor: LYNKED_RED,
    '&:hover': {
      backgroundColor: LYNKED_RED_HOVER,
    },
    color: LYNKED_WHITE,
    borderRadius: '1px',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmRemoveFoodCategoryDialog({
  open,
  setOpen,
  category_id_to_delete,
  processDeleteCategory,
  setCategoryIdDelete,
  getCurrentCategoryTitle,
  food_menu,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const classes = useStyles();
  let [category_name, setCategoryName] = React.useState('');

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setCategoryIdDelete(null);
    setOpen(false);
  };

  const handleOkClick = (e) => {
    try {
      processDeleteCategory(e, category_id_to_delete, food_menu);
    } catch (error) {
    } finally {
      enqueueSnackbar(t('MENU_CATEGORY_DELETED'), {
        variant: 'success',
      });
      handleClose();
    }
  };

  React.useEffect(() => {
    if (!isNull(category_id_to_delete)) {
      setCategoryName(getCurrentCategoryTitle(category_id_to_delete));
    }
  }, [category_id_to_delete]);

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        transitionDuration={dialog_transition_duration}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        fullWidth
      >
        <Box className={classes.ConfirmDeleteDialogWrapper}>
          <Box className={classes.DialogTitle}>
            <Box>{t('MENU_DELETE_CATEGORY')}</Box>
          </Box>
          <Box className={classes.QuestionsWrapper}>
            <Box>{t('MENU_CONFIRM_DELETE_CATEGORY')}</Box>
            <ShowDebug>{`${category_name}  (${category_id_to_delete})`}</ShowDebug>
          </Box>

          <Box className={classes.DialogBottom}>
            <Button
              id={'delete-food-category-cancel-button'}
              onClick={handleClose}
              className={`${classes.Buttons} ${classes.ButtonCancel}`}
            >
              {t('COMMON_CANCEL')}
            </Button>
            <Button
              id={'delete-food-category-confirm-button'}
              onClick={handleOkClick}
              className={`${classes.Buttons} ${classes.ButtonAlertOk}`}
            >
              {t('COMMON_DELETE')}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
