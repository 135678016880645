import React from 'react';
import clsx from 'clsx';
import { Box, Card, Divider, makeStyles, Typography } from '@material-ui/core';

import ShowYen from 'src/components/ShowYen';

import { LYNKED_GREEN, LYNKED_RED } from 'src/consts/colors';

import { GlobalContext } from 'src/contexts/Global';
import active_lang from 'src/langs/jp_en';

import { getBillsTodayArray } from 'src/modals/orders';
import covertDecimalPrice from 'src/helpers/convertDecimalPrice';

import { STATUS_DELIVERED, STATUS_PREPARING } from 'src/consts/ORDER_STATUS';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {},
}));

const ShowAdImpressionsTiles = ({ value }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [counts_loading, setCountsLoading] = React.useState(false);
  const [today_count, setTodayCount] = React.useState(2);
  const [yesterday_count, setYesterdayCount] = React.useState(1);

  return (
    <>
      <Card className={clsx(classes.root)} style={{ margin: '1rem' }}>
        <Box style={{ margin: '1rem' }}>
          <Box mb={'0.5rem'}>
            <Typography
              variant="h4"
              color="textPrimary"
              style={{
                fontSize: '12px',
                fontWeight: '700',
                marginBottom: '12px',
                marginTop: '12px',
              }}
            >
              {t('DASHBOARD_AVG_SALES_AMOUNT')}
            </Typography>
          </Box>
          <Divider />

          <Box
            mt={'0.5rem'}
            height={'80px'}
            position="relative"
            style={{ font: 'normal normal bold 31px/40px Roboto' }}
          >
            <Box
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box>
                <span style={{ font: 'normal normal bold 46px/60px Roboto' }}>
                  <ShowYen value_yen={value || 0} />
                </span>
              </Box>
            </Box>
          </Box>

          <Box
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            {/* {counts_loading ? (
              <>{active_lang.TILES_LOADING}</>
            ) : (
              <ShowCompare today={today_count} yesterday={yesterday_count} />
            )} */}

            {/* <Box
              style={{
                paddingLeft: '0.5rem',
                font: 'normal normal normal 14px/18px Helvetica',
              }}
            >
              昨日から
            </Box> */}
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default function AdImpressionTiles() {
  const { rest_id } = React.useContext(GlobalContext);
  const { t } = useTranslation();

  
  let [loading, setLoading] = React.useState(true);
  const [counting, setCounting] = React.useState(0);
  const [dates_from_db, setDatesFromDb] = React.useState();
  const [counts_from_db, setCountsFromDb] = React.useState();
  const [total_customer_today, setTotalCustomerToday] = React.useState(0);

  React.useEffect(() => {
    if (rest_id != null) {
      getBillsTodayArray(rest_id).then((a_d) => {
        let total_revenue = 0;
        a_d.forEach((d) => {
          let orders = d.orders;
          orders.forEach((o) => {
            if ([STATUS_PREPARING, STATUS_DELIVERED].includes(o.status)) {
              total_revenue = total_revenue + o.item_subtotal;
            }
          });
        });

        // **(Sum of all bill)/ number of table assigned in one day**
        const value = covertDecimalPrice(total_revenue / a_d.length);
        setTotalCustomerToday(value);

        setLoading(false);
      });
    }
  }, [rest_id]);

  return (
    <>
      {loading ? (
        <>{t('COMMON_LOADING')}</>
      ) : (
        <ShowAdImpressionsTiles value={total_customer_today} />
      )}
    </>
  );
}
