import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: { width: '100%', padding: '3rem' },
  search_input_container: {
    // backgroundColor: 'gold',
  },
  button_group: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

export { useStyles };
