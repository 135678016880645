import React from 'react';
import { isNull, isUndefined } from 'lodash';

import { Box, Button, Grid } from '@material-ui/core';

import Page from 'src/components/Page';
import active_lang from 'src/langs/jp_en';
import { GlobalContext } from 'src/contexts/Global';
import { SERVICE_MENY } from 'src/consts/MENY_SERVICE';

import MenyUsageTile from './MenyUsageTile';
import HelpRequestTile from './HelpRequestTile';
import AdImpressionsTile from './AdImpressionsTile';
import PerCustomerSpentTile from './PerCustomerSpentTile';
import PerOrderSpentTile from './PerOrderSpentTile';
import PerTableCustomersTile from './PerTableCustomersTile';
import TotalCustomersTodayTile from './TotalCustomersTodayTile';
import TotalOrdersTodayTile from './TotalOrdersTodayTile';
import TotalRevenueTodayTile from './TotalRevenueTodayTile';
import { useStyles } from './styles';
import SubscribeToSeeMoreButton from './SubscribeToSeeMoreButton';

export default function OverviewPage() {
  const classes = useStyles();
  const [show_advanced_metrics, setShowAdvancedMetriccs] =
    React.useState(false);

  const { meny_service_class } = React.useContext(GlobalContext);

  React.useEffect(() => {
    if (meny_service_class) {
      if (meny_service_class.id === SERVICE_MENY) {
        // meny
        setShowAdvancedMetriccs(true);
      }
    }
  }, [meny_service_class]);

  return (
    <>
      <Page className={classes.root}>
        <Grid container>
          <Grid item xs={12} container style={{ padding: '2rem' }}>
            <Grid item xs={12} md={6} lg={3}>
              <Box>
                <MenyUsageTile />
              </Box>
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <Box>
                <HelpRequestTile />
              </Box>
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <Box>
                <AdImpressionsTile />
              </Box>
            </Grid>
          </Grid>

          <Grid container item xs={12}
            style={{ position: 'relative', backgroundColor: '#F4F6F8', padding: '2rem' }}
            className={show_advanced_metrics ? '' : classes.blurContent}>
            <Grid item xs={12} container>
              <Grid item xs={12} md={3}>
                <Box>
                  <TotalCustomersTodayTile />
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box>
                  <TotalRevenueTodayTile />
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box>
                  <TotalOrdersTodayTile />
                </Box>
              </Grid>
            </Grid>

            <Grid item xs={12} container>
              <Grid item xs={12} md={3}>
                <Box>
                  <PerTableCustomersTile />
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box>
                  <PerCustomerSpentTile />
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box>
                  <PerOrderSpentTile />
                </Box>
              </Grid>
            </Grid>

            {show_advanced_metrics ? <></> : <SubscribeToSeeMoreButton />}
          </Grid>

        </Grid>
      </Page>
    </>
  );
}
