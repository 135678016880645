import { isNull, isUndefined } from 'lodash';

const testNotNullAndIsDefined = (obj_to_test) => {
  try {
    let obj_is_null = isNull(obj_to_test);
    let obj_is_undef = isUndefined(obj_to_test);

    return !obj_is_null && !obj_is_undef;
  } catch (error) {
    console.log('testNotNullAndIsDefined', 'obj_to_test', obj_to_test);
    return false;
  }
};

export { testNotNullAndIsDefined };
