import { useState, useCallback } from 'react';

const useHelloworld = (initialState = false) => {
  const [state, setState] = useState(initialState);
  const toggle = useCallback(() => setState((state) => !state), []);
  const sayHello = () => {
    // alert('helloworld from useHelloworld');
  };

  return [state, toggle, sayHello];
};

export default useHelloworld;
