import React from 'react';
import TimePicker from 'rc-time-picker';
import { merge } from 'lodash';
import { Box, Grid } from '@material-ui/core';
import moment from 'moment-timezone';

import { LYNKED_DEEP_GREY_HOVER, LYNKED_BLACK } from 'src/consts/colors';
import active_lang from 'src/langs/jp_en';

import { clock_icon_svg } from './clock_icon_svg';
import './rc-time-picker.css';
import { useTranslation } from 'react-i18next';

const LOG_PREFIX = 'EndTimePicker';

export default ({ formik, day_of_week }) => {
  const end_time_picker_ref = React.useRef(null);
  const { t } = useTranslation();

  let [end_utc, setEndUtc] = React.useState(
    moment(formik.values['opening_schedule'][day_of_week].utc_end_time)
  );

  let end_enabled = formik.values['opening_schedule'][day_of_week].enabled;

  const handleEndTimeChange = (new_utc_value) => {
    console.log(LOG_PREFIX, { new_utc_value: new_utc_value._d });
    let end_HH_mm = new_utc_value
      .startOf('minute')
      .toISOString(true)
      .split('T')[1];
    formik.setFieldValue(
      `opening_schedule.${day_of_week}.utc_end_time`,
      `1970-01-01T${end_HH_mm}`
    );

    setEndUtc(new_utc_value);
  };

  const handleOnClose = () => {
    formik.setTouched(
      merge(formik.touched, {
        opening_schedule: { [`${day_of_week}`]: { utc_end_time: true } },
      })
    );
  };

  return (
    <>
      <Grid container style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
        <Grid
          item
          xs={4}
          style={{
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            alignItems: 'flex-end',
          }}
        >
          <Box
            style={{
              color: end_enabled ? LYNKED_BLACK : LYNKED_DEEP_GREY_HOVER,
              fontWeight: 'bold'
            }}
          >
            {t('SETTINGS_BUSINESS_END_TIME')}
          </Box>
        </Grid>
        <Grid item xs={1}></Grid>

        <Grid item xs={7} style={{ textAlign: 'center' }}>
          <Box id="end_time_picker_click_away"></Box>
          <Box
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {formik.values['opening_schedule'][day_of_week].enabled ? (
              <TimePicker
                id="end_time_picker"
                ref={end_time_picker_ref}
                name={`opening_schedule.${day_of_week}.utc_end_time`}
                showSecond={false}
                minuteStep={15}
                format={'HH:mm'}
                inputIcon={clock_icon_svg}
                value={end_utc}
                allowEmpty={false}
                onChange={handleEndTimeChange}
                onClose={handleOnClose}
                // MENY-312, clicking start & end time field will trigger virtual keypad
                inputReadOnly
              />
            ) : (
              <TimePicker
                id="end_time_picker"
                ref={end_time_picker_ref}
                showSecond={false}
                minuteStep={15}
                format={'--:--'}
                value={moment()}
                inputIcon={clock_icon_svg}
                // MENY-312, clicking start & end time field will trigger virtual keypad
                inputReadOnly
                // MENY-314, disabled day still allow to open dropdown start & end time
                disabled={true}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
