import React, { useEffect, useMemo } from 'react';
import { colors } from '@material-ui/core';

import moment from 'moment-timezone';


import { GlobalContext } from 'src/contexts/Global';

import active_lang from 'src/langs/jp_en';

import ShowMenyUsage from './ShowMenyUsage';
import { getMenyManageUsageStatistics } from 'src/modals/meny_manage_usage_statistics';
import { useTranslation } from 'react-i18next';

const SHOW_HISTORY_LENGTH = 7;

const data_default = {
  datasets: [
    {
      backgroundColor: colors.indigo[500],
      data: [0],
      label: 'meny 利用率',
    },
  ],
  labels: [''],
};

export default function MenyUsageTiles() {
  let [loading, setLoading] = React.useState(true);
  const { t, i18n } = useTranslation();

  const { rest_id } = React.useContext(GlobalContext);

  const [counting, setCounting] = React.useState(data_default);
  const [dates_from_db, setDatesFromDb] = React.useState();
  const [counts_from_db, setCountsFromDb] = React.useState();
  let DAY_ARRAY_IN_UTC = [
    [
      moment()
        .tz(moment.tz.guess())
        .add(0, 'day')
        .startOf('day')
        .format('DD/MM/YYYY'),
      moment().tz(moment.tz.guess()).add(0, 'day').startOf('day').utc(),
      moment().tz(moment.tz.guess()).add(0, 'day').endOf('day').utc(),
    ],
  ];

  const countingWithTranslation = useMemo(() => {
    const datasets = counting.datasets.map((data) => ({
      ...data,
      label: t('DASHBOARD_RATE_USE'),
    }));

    return {
      ...counting,
      datasets,
    };
  }, [i18n.language, counting]);

  React.useEffect(() => {
    let counts = [];
    let dates = [];

    if (rest_id) {
      for (let i = 1; i < SHOW_HISTORY_LENGTH; i++) {
        DAY_ARRAY_IN_UTC.push([
          moment()
            .tz(moment.tz.guess())
            .subtract(i, 'days')
            .format('DD/MM/YYYY'),
          moment()
            .tz(moment.tz.guess())
            .subtract(i, 'days')
            .startOf('day')
            .utc(),
          moment().tz(moment.tz.guess()).subtract(i, 'days').endOf('day').utc(),
        ]);
      }

      console.log('DAY_ARRAY_IN_UTC', DAY_ARRAY_IN_UTC);

      console.log('rest_id', rest_id);

      getMenyManageUsageStatistics(rest_id).then((ss) => {
        ss.forEach((doc) => {
          let unix_timestamp = doc.id.split('_')[1];
          let human_time = moment.unix(unix_timestamp).format('DD/MM/YYYY');
          dates.push(human_time);
          counts.push(doc.data().count);
        });

        console.log('MenyUsageTile', dates);
        console.log('MenyUsageTile', counts);

        setDatesFromDb(dates);
        setCountsFromDb(counts);

        let untested = {};
        dates.forEach((date, idx) => (untested[date] = counts[idx]));

        console.log('DAY_ARRAY_IN_UTC', DAY_ARRAY_IN_UTC);
        let data_within_range = DAY_ARRAY_IN_UTC.map(
          ([date_string, date_start], idx) => {
            console.log('DAY_ARRAY_IN_UTC', 'idx', idx);
            console.log('DAY_ARRAY_IN_UTC', 'date_string', date_string);
            return {
              moment_obj: date_start,
              date: date_string,
              count: untested[date_string] || 0,
            };
          }
        );

        let dates_in_range = data_within_range.map((o) => {
          let splitted = o.date.split('/');
          let DD = splitted[0];
          let MM = splitted[1];
          return DD + '/' + MM;
        });
        let counts_in_range = data_within_range.map((o) => o.count);

        let reverse_dates_in_range = dates_in_range.reverse();
        let reverse_counts_in_range = counts_in_range.reverse();

        setCounting({
          datasets: [
            {
              backgroundColor: colors.indigo[500],
              data: reverse_counts_in_range,
              label: 'meny 利用率',
            },
          ],
          labels: reverse_dates_in_range,
        });
        setLoading(false);
      });
    }
  }, [rest_id]);

  return (
    <>
      {loading ? (
        <>{t('COMMON_LOADING')}</>
      ) : (
        <ShowMenyUsage
          dates={dates_from_db}
          counts={counts_from_db}
          chart_data={countingWithTranslation}
        />
      )}
    </>
  );
}
