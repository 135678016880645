import { getNearestCutOffTime } from './getNearestCutOffTime';

import db from 'src/Firebase/db';
import db_config from 'src/configs/db_config';

// let SEAT_WAITING = '等の待 / waiting';
// let SEAT_ASSIGNED = '位己編 / assigned';

let SEAT_WAITING = 'SEAT_WAITING';
let SEAT_ASSIGNED = 'SEAT_ASSIGNED';
let SEAT_LINEUP_CANCELLED_AS_SHOP_CLOSED =
  'SEAT_LINEUP_CANCELLED_AS_SHOP_CLOSED';

export { SEAT_ASSIGNED, SEAT_WAITING, SEAT_LINEUP_CANCELLED_AS_SHOP_CLOSED };

let LINEUP_REF = db.collection(db_config.DB_TABLE_LINEUP);

function subscribeLineupChange(rest_id) {
  return LINEUP_REF.doc(rest_id).collection('lineup');
}

function subscribeLineupChangeWithAssignedTable(rest_id) {
  return subscribeLineupChange(rest_id)
    .where('request-time-utc', '>', getNearestCutOffTime().unix() * 1000)
    .where('status', '==', SEAT_ASSIGNED)
    .limit(20);
}

function subscribeLineupChangeWithAssignedTableWithOpenId(
  rest_id,
  restaurant_open_id
) {
  return subscribeLineupChange(rest_id)
    .where('restaurant_open_id', '==', restaurant_open_id)
    .where('status', '==', SEAT_ASSIGNED)
    .limit(20);
}

function subscribeLineupChangeWithUnassignedTable(rest_id) {
  return subscribeLineupChange(rest_id)
    .where('request-time-utc', '>', getNearestCutOffTime().unix() * 1000)
    .where('status', '==', SEAT_WAITING)
    .limit(20);
}

function subscribeLineupChangeWithUnassignedTableWithOpenId({
  rest_id,
  restaurant_open_id,
}) {
  return subscribeLineupChange(rest_id)
    .where('restaurant_open_id', '==', restaurant_open_id)
    .where('status', '==', SEAT_WAITING)
    .limit(20);
}

function cancelRemainingLineupAsShopClosed(rest_id) {
  var batch = db.batch();

  let DocRefs = db
    .collection(db_config.DB_TABLE_LINEUP)
    .doc(rest_id)
    .collection('lineup')
    .where('status', '==', SEAT_WAITING)
    .limit(20);

  return DocRefs.get()
    .then((qss) => {
      qss.forEach((doc) => {
        let refs = db
          .collection(db_config.DB_TABLE_LINEUP)
          .doc(rest_id)
          .collection('lineup')
          .doc(doc.id);

        batch.update(refs, { status: SEAT_LINEUP_CANCELLED_AS_SHOP_CLOSED });
      });
    })
    .then(() => {
      batch.commit();
    })
    .catch((err) => {
      // alert('error during batch write');
      console.error('batch write', err);
    });
}

function assignTableByDocId(rest_id, doc_id, payload) {
  let DocRef = db
    .collection(db_config.DB_TABLE_LINEUP)
    .doc(rest_id)
    .collection('lineup')
    .doc(doc_id);

  return db
    .runTransaction((transaction) => {
      return transaction.get(DocRef).then(() => {
        transaction.set(DocRef, payload, { merge: true });
      });
    })
    .then(() => {
      console.log('Transaction successfully committed!');
    })
    .catch((error) => {
      console.log('Transaction failed: ', error);
    });
}

function updateTableInformation(rest_id, doc_id, payload) {
  const DocRef = db
    .collection(db_config.DB_TABLE_LINEUP)
    .doc(rest_id)
    .collection('lineup')
    .doc(doc_id);

  return db.runTransaction((transaction) => {
    return transaction.get(DocRef).then(() => {
      transaction.update(DocRef, payload);
    });
  });
}

function deleteEntry(rest_id, doc_id) {
  return db
    .collection(db_config.DB_TABLE_LINEUP)
    .doc(rest_id)
    .collection('lineup')
    .doc(doc_id)
    .delete();
}

function helloLineupModals() {
  // alert('helloLineupModals');
}

export {
  helloLineupModals,
  subscribeLineupChange,
  assignTableByDocId,
  subscribeLineupChangeWithUnassignedTable,
  subscribeLineupChangeWithAssignedTable,
  cancelRemainingLineupAsShopClosed,
  subscribeLineupChangeWithAssignedTableWithOpenId,
  subscribeLineupChangeWithUnassignedTableWithOpenId,
  updateTableInformation,
  deleteEntry,
};
