import { isUndefined } from 'lodash';

import {
  AD_ACTION_JUMP_TO_CATEGORY,
  AD_ACTION_JUMP_TO_FOOD_DETAIL,
} from 'src/consts/AD_ACTION';
import lookupFoodNameByFoodDetailId from './lookupFoodNameByFoodDetailId';
import lookupFoodCategoryTitleByCatId from './lookupFoodCategoryTitleByCatId';

function getAdDestinationValue(destination_type, destination_value) {
  let sanitized_ad_destination = '';
  switch (destination_type) {
    case AD_ACTION_JUMP_TO_CATEGORY:
      if (
        !isUndefined(destination_value) &&
        !isUndefined(destination_value.cat_id)
      ) {
        sanitized_ad_destination = destination_value.cat_id;
      }
      break;
    case AD_ACTION_JUMP_TO_FOOD_DETAIL:
      if (
        !isUndefined(destination_value) &&
        !isUndefined(destination_value.food_detail_id)
      ) {
        sanitized_ad_destination = destination_value.food_detail_id;
      }
      break;
    default:
      break;
  }
  return sanitized_ad_destination;
}

function getAdDestination(food_details, ad_action, ad_destination) {
  console.log('ad_action', ad_action);

  let sanitized_ad_destination = {};
  switch (ad_action) {
    case 'food_menu':
      sanitized_ad_destination = {
        cat_id: ad_destination,
        cat_name: lookupFoodCategoryTitleByCatId(food_details, ad_destination),
      };
      break;
    case 'food_detail':
      console.log(
        'lookupFoodNameByFoodDetailId',
        lookupFoodNameByFoodDetailId(food_details, ad_destination)
      );
      sanitized_ad_destination = {
        food_detail_id: ad_destination,
        food_detail_name: lookupFoodNameByFoodDetailId(
          food_details,
          ad_destination
        ),
      };
      break;

    default:
      break;
  }
  return sanitized_ad_destination;
}

export { getAdDestination, getAdDestinationValue };
