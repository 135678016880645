import React from 'react';
import moment from 'moment-timezone';
import clsx from 'clsx';
import { Grid, Chip } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Table } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
import Typography from '@material-ui/core/Typography';

import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import ShowDebug from 'src/components/ShowDebug';
import ShowDebugJson from 'src/components/ShowDebugJson';

import AdImageThumbnail from './AdImageThumbnail';

import {
  TABLE_CONTENT_LARGE_GREY,
  TABLE_CONTENT_SMALL,
  TABLE_HEAD_SMALL,
} from 'src/consts/typography';

import { GlobalContext } from 'src/contexts/Global';
import { MonitorOrderContext } from 'src/contexts/MonitorOrder';

import { getOrdersByOrderId, updateOrderStatus } from 'src/modals/orders';

import Popover from '@material-ui/core/Popover';

import NoAdImage from 'src/components/NoAdImage';

import {
  LYNKED_RED,
  LYNKED_RED_HOVER,
  LYNKED_WHITE,
  LYNKED_GREEN,
  LYNKED_GREEN_HOVER,
  LYNKED_LIGHT_GREY,
  LYNKED_LIGHT_GREY_HOVER,
  LYNKED_DEEP_GREY,
  LYNKED_DEEP_GREY_HOVER,
  LYNKED_BLACK,
} from 'src/consts/colors';

import active_lang from 'src/langs/jp_en';

import EditAdvertisementDialog from 'src/components/EditAdvertisementDialog';

import ConfirmRemoveAdvertisement from 'src/components/ConfirmRemoveAdvertisement';
import { isNull, isUndefined } from 'lodash';

import lookupFoodCategoryTitleByCatId from 'src/utils/lookupFoodCategoryTitleByCatId';
import lookupFoodNameByFoodDetailId from 'src/utils/lookupFoodNameByFoodDetailId';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  line1: {},
  line2: { font: "normal normal bold 12px/16px 'Noto Sans JP'" },
  table: {
    minWidth: 650,
    font: TABLE_HEAD_SMALL,
    '& thead': {
      background: '#E0E0E0 0% 0% no-repeat padding-box',
      textTransform: 'uppercase',
    },
    '& tbody': {
      '& th': {},
      '& td': { padding: '8px' },
    },
  },
  Buttons: {
    width: '120px',
    height: '30px',
    color: 'white',
    letterSpacing: '0.12px',
    borderRadius: '1px',
    backgroundColor: LYNKED_GREEN,
    font: TABLE_CONTENT_SMALL,
    '&:hover': {
      backgroundColor: LYNKED_GREEN_HOVER,
    },
  },
  tableHeadText: {
    color: LYNKED_BLACK,
    font: TABLE_HEAD_SMALL,
    textAlign: 'center',
  },
  tableBodyText: {
    color: LYNKED_BLACK,
    font: TABLE_CONTENT_SMALL,
    textAlign: 'center',
  },
  col_id: {
    maxWidth: '10%',
    // lineBreak: 'anywhere',
  },
  col_title: {
    maxWidth: `${90 / 6}%`,
    lineBreak: 'anywhere',
  },
  col_default: {
    width: `${90 / 6}%`,
    lineBreak: 'anywhere',
  },
  food_item_name_text: {
    color: LYNKED_LIGHT_GREY,
    font: "normal normal bold 20px/22px 'Noto Sans JP'",
  },
  food_customization_text: {
    color: LYNKED_LIGHT_GREY,
    font: "normal normal bold 16px/20px 'Noto Sans JP'",
  },
});

function Actions({
  refetch,
  advertisement_id,
  data,
  advertisement_table_data,
}) {
  let [open_edit_advertisement_dialog, setOpenEditAdvertisementDialog] =
    React.useState(false);

  let [
    open_confirm_remove_advertisement_dialog,
    setOpenConfirmRemoveAdvertisementDialog,
  ] = React.useState(false);

  return (
    <>
      <EditAdvertisementDialog
        open={open_edit_advertisement_dialog}
        setOpen={setOpenEditAdvertisementDialog}
        initial_data={data}
        advertisement_id={advertisement_id}
        refetch={refetch}
        advertisement_table_data={advertisement_table_data}
      />
      <ConfirmRemoveAdvertisement
        open={open_confirm_remove_advertisement_dialog}
        setOpen={setOpenConfirmRemoveAdvertisementDialog}
        advertisement_id={advertisement_id}
        delete_food_detail={data}
        refetch={refetch}
      />
      <IconButton
        onClick={(e) => {
          setOpenEditAdvertisementDialog(true);
        }}
      >
        <EditIcon />
      </IconButton>
      <IconButton
        onClick={(e) => setOpenConfirmRemoveAdvertisementDialog(true)}
      >
        <DeleteForeverIcon />
      </IconButton>
      {/*
        <ShowDebugJson>{JSON.stringify(data, null, 2)}</ShowDebugJson>
      */}
    </>
  );
}

function processShowColAdDestination({ food_details, data }) {
  const showCatName = (food_details, data) => {
    let cat_id = data.ad_destination.cat_id;
    let cat_name = data.ad_destination.cat_name;
    let lookup_result = lookupFoodCategoryTitleByCatId(food_details, cat_id);
    if (lookup_result) {
      return lookup_result;
    }
    return `${cat_name} (${'deleted'})`;
  };

  const showFoodDetailName = (food_details, data) => {
    let food_detail_id = data.ad_destination.food_detail_id;
    let food_detail_name = data.ad_destination.food_detail_name;
    let lookup_result = lookupFoodNameByFoodDetailId(
      food_details,
      food_detail_id
    );
    if (lookup_result) {
      return lookup_result;
    }
    return `${food_detail_name} (${'deleted'})`;
  };

  if (data.ad_window.name == 'food_menu') {
    if (data.ad_action.name == 'food_menu') {
      return showCatName(food_details, data);
    } else {
      return showFoodDetailName(food_details, data);
    }
  } else {
    return undefined;
  }
}

export default function ShowAdvertisementTable({
  advertisements,
  filter_by_words,
  refetch,
  food_details,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  function LookupDestination({ data }) {
    // console.log('LookupDestination', 'food_details', food_details);

    return <>{JSON.stringify(data.ad_destination)}</>;
  }

  let [advertisement_table_data, setAdvertisementTableData] =
    React.useState(advertisements);

  React.useEffect(() => {
    console.log('ShowAdvertisementTable', 'food_details', food_details);
    setAdvertisementTableData(
      advertisements.map(({ id, data }, idx) => {
        let temp = {
          id,
          data: {
            ...data,
            hello: data.ad_window.name == 'food_menu' ? 'true' : 'false',
            show_col_ad_destination: processShowColAdDestination({
              food_details,
              data,
            }),
            show_col_ad_action:
              data.ad_window.name == 'food_menu'
                ? data.ad_destination
                : undefined,
          },
        };

        return temp;
      })
    );
  }, [advertisements]);

  const lookupAdActionName = (ad_action_name) => {
    switch (ad_action_name) {
      case 'order_list':
        return t('AD_ORDER_LIST');
      case 'food_menu':
        return t('COMMON_CATEGORY');
      case 'food_detail':
        return t('COMMON_MENU_DETAILS');

      default:
        return t('COMMON_MENU_DETAILS');
    }
  };

  const lookupAdWindowName = (ad_window_name) => {
    switch (ad_window_name) {
      case 'order_list':
        return t('AD_ORDER_LIST');
      case 'food_menu':
        return t('COMMON_MENU');
      case 'food_detail':
        return t('COMMON_MENU_DETAILS');

      default:
        return t('COMMON_MENU_DETAILS');
    }
  };

  return (
    <>
      <Box style={{ marginTop: '1rem' }}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  className={clsx(classes.tableHeadText, classes.col_id)}
                >
                  {t('AD_NO')}
                </TableCell>
                <TableCell
                  className={clsx(classes.tableHeadText, classes.col_title)}
                >
                  {t('COMMON_BANNER_NAME')}
                </TableCell>
                <TableCell
                  className={clsx(classes.tableHeadText, classes.col_default)}
                >
                  {t('AD_IMAGE')}
                </TableCell>
                <TableCell
                  className={clsx(classes.tableHeadText, classes.col_default)}
                >
                  {t('COMMON_BANNER_POSITION')}
                </TableCell>
                <TableCell
                  className={clsx(classes.tableHeadText, classes.col_default)}
                >
                  {t('COMMON_ACTION')}
                </TableCell>
                <TableCell
                  className={clsx(classes.tableHeadText, classes.col_default)}
                >
                  {t('AD_DESTINATION')}
                </TableCell>
                <TableCell
                  className={clsx(classes.tableHeadText, classes.col_default)}
                >
                  {active_lang.COL_AD_EDIT}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {advertisement_table_data &&
                advertisement_table_data.map(({ id, data }, idx) => (
                  <>
                    <TableRow key={idx}>
                      <TableCell
                        component="th"
                        scope="row"
                        className={clsx(classes.tableBodyText, classes.col_id)}
                        style={{ width: '50px', height: '50px' }}
                      >
                        {idx + 1}
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.tableBodyText,
                          classes.col_title
                        )}
                      >
                        {data.title}
                      </TableCell>
                      <TableCell style={{ width: '200px', height: '50px' }}>
                        {isNull(data.image_urls) ||
                        isUndefined(data.image_urls) ||
                        data.image_urls[0] == '' ? (
                          <NoAdImage />
                        ) : (
                          <>
                            <AdImageThumbnail image_url={data.image_urls[0]} />
                          </>
                        )}
                      </TableCell>
                      {/* COL_AD_CONFIGURE_SLOT */}
                      <TableCell
                        className={clsx(
                          classes.tableBodyText,
                          classes.col_default
                        )}
                      >
                        {isUndefined(data.ad_window) ? (
                          <></>
                        ) : (
                          <Chip
                            label={lookupAdWindowName(data.ad_window.name)}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.tableBodyText,
                          classes.col_default
                        )}
                      >
                        {isUndefined(data.show_col_ad_action) ? (
                          <>-</>
                        ) : (
                          <Chip
                            label={lookupAdActionName(data.ad_action.name)}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.tableBodyText,
                          classes.col_default
                        )}
                      >
                        {isUndefined(data.show_col_ad_destination) ? (
                          <>-</>
                        ) : (
                          <Chip label={data.show_col_ad_destination} />
                          // <LookupDestination data={data} />
                        )}
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.tableBodyText,
                          classes.col_default
                        )}
                      >
                        <Actions
                          refetch={refetch}
                          advertisement_id={id}
                          data={data}
                          advertisement_table_data={advertisement_table_data}
                        />
                      </TableCell>
                    </TableRow>
                  </>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <ShowDebug>
          <pre>{JSON.stringify(advertisements, null, 2)}</pre>
        </ShowDebug>
      </Box>
    </>
  );
}
