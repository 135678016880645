import { makeStyles } from '@material-ui/core';

import { LYNKED_RED, LYNKED_RED_HOVER, LYNKED_WHITE } from 'src/consts/colors';

const useStyles = makeStyles((theme) => ({
  helloworld: {},

  AddNewAdsButton: {
    height: '45px',
    width: '150px',
    padding: '10px 10px',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 'bold',

    borderRadius: '1px',

    color: LYNKED_WHITE,
    backgroundColor: LYNKED_RED,
    '&:hover': {
      backgroundColor: LYNKED_RED_HOVER,
    },

    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export { useStyles };
