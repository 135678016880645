import React from 'react';

import { Box, TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';

import SearchIcon from '@material-ui/icons/Search';

import { useStyles } from './styles';

import active_lang from 'src/langs/jp_en';
import configs_devices from 'src/configs/devices';
import { useTranslation } from 'react-i18next';

export default function SearchInput({ handleUpdateFilterWords }) {
  const classes = useStyles();
  let input_ref = React.useRef();
  const { t } = useTranslation();

  const handleInputOnBlur = () => {
    configs_devices.forceScreenScrollUp();
  };

  return (
    <>
      <Box className={classes.SearchInput}>
        <TextField
          ref={input_ref}
          onBlur={handleInputOnBlur}
          onInput={handleUpdateFilterWords}
          className={classes.TextInput}
          placeholder={t('BILL_SEARCH')}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          // value="TABLE NUMBER"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              document.activeElement.blur();
            }
          }}
        />

        {/* <Button className={classes.SearchButton}>{'メニュー検索'}</Button> */}
      </Box>
    </>
  );
}
