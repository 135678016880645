import React from 'react';
import { Box } from '@material-ui/core';

import TuneIcon from '@material-ui/icons/Tune';

import active_lang from 'src/langs/jp_en';
import { LYNKED_DEEP_GREY } from 'src/consts/colors';

const NoSettingsAvailable = () => {
  return (
    <>
      <Box mt={3}>
        <div
          style={{
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: LYNKED_DEEP_GREY,
            paddingTop: '3rem',
          }}
        >
          <Box>
            <TuneIcon style={{ height: '100px', width: '100px' }} />
          </Box>
          <Box mt="1rem" style={{ color: LYNKED_DEEP_GREY }}>
            {active_lang.CURRENTLY_THERE_IS_NO_SETTING_FEATURE_AVAILABLE}
          </Box>
        </div>
      </Box>
    </>
  );
};

export default NoSettingsAvailable;
