import React from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from 'src/Firebase/context';
import active_lang from 'src/langs/jp_en';
import ShowLoginScreen from './ShowLoginScreen';
import { useTranslation } from 'react-i18next';

export default function LoginPage() {
  let { user } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [show_content, setShowContent] = React.useState(user);
  const [confirm_loaded, setConfirmLoaded] = React.useState(false);

  React.useEffect(() => {
    console.log('LoginPage', 'index.js', 'confirm_loaded');
    console.log('LoginPage', 'index.js', user);
    if (confirm_loaded && user) {
      if (user) {
        navigate('/app');
      } else {
        navigate('/login');
      }
    }
  }, [confirm_loaded]);

  React.useEffect(() => {
    console.log('LoginPage', 'index.js', 'starting');
    setTimeout(() => {
      setConfirmLoaded(true);
      setShowContent(true);
    }, 3000);
  }, []);

  return (
    <>
      {show_content ? (
        <ShowLoginScreen />
      ) : (
        <>{t('LOGIN_CHECKING_USER')}</>
      )}
    </>
  );
}
