import React from 'react';

import active_lang from 'src/langs/jp_en';

import ShowBillDetailDialog from './ShowBillDetailDialog';
import { useTranslation } from 'react-i18next';

export default function BillDetailDialog({ orders, setOpen, open, bill, id }) {
  const [show_content, setShowContent] = React.useState(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (orders) {
      setShowContent(true);
    }
  }, [orders]);

  return (
    <>
      {show_content ? (
        <ShowBillDetailDialog
          orders={orders}
          open={open}
          setOpen={setOpen}
          bill={bill}
          id={id}
        />
      ) : (
        <>{t('COMMON_LOADING')}</>
      )}
    </>
  );
}
