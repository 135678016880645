import React from 'react';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';

import NavButton from 'src/components/NavButton';

import active_lang from 'src/langs/jp_en';
import { MonitorServiceRequestContext } from 'src/contexts/MonitorServiceRequest';
import { isNull } from 'lodash';
import { StyledBadge } from 'src/components/RestaurantNavButtonColumn/NavButtons/styles';
import { useTranslation } from 'react-i18next';

// import { useStyles } from './styles';

export default function NavMenuHelpRequests({ handleCloseNavBar }) {
  // const classes = useStyles();
  const { t } = useTranslation();
  const { list_active_service_request } = React.useContext(
    MonitorServiceRequestContext
  );

  const [num_waiting_service_request, setNumWaitingServiceRequest] =
    React.useState(0);
  React.useEffect(() => {
    if (!isNull(list_active_service_request)) {
      setNumWaitingServiceRequest(list_active_service_request.length);
    }
  }, [list_active_service_request]);

  return (
    <>
      <NavButton
        text={t('SIDEMENU_CALL_MANAGE')}
        href={`/app/help_requests`}
        icon={
          <StyledBadge badgeContent={num_waiting_service_request}>
            <NotificationsNoneIcon />
          </StyledBadge>
        }
        id="nav-help-requests"
        handleCloseNavBar={handleCloseNavBar}
      />
    </>
  );
}
