import React from 'react';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

import {
  Box,
  makeStyles,
} from '@material-ui/core';

import BugReportOutlinedIcon from '@material-ui/icons/BugReportOutlined';

import NavButton from 'src/components/NavButton';

import ShowDebug from 'src/components/ShowDebug';

import {
  LYNKED_WHITE,
} from 'src/consts/colors';

import active_lang from 'src/langs/jp_en';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  helloworld: { height: '100vh' },
  TopNavBody: {
    backgroundColor: LYNKED_WHITE,
  },
  NavButtonGroup: { paddingTop: '1rem' },
  ButtonContent: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
}));

export default function SystemNavButtonColumn({ handleCloseNavBar }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Box className={classes.TopNavBody}>
        <Box className={classes.NavButtonGroup}>
          <div style={{ display: 'none' }}>
            <ShowDebug>
              <Box className={classes.NavButtonContainer}>
                <NavButton
                  text={'example queries'}
                  href={'/example_queries'}
                  icon={<BugReportOutlinedIcon />}
                  handleCloseNavBar={handleCloseNavBar}
                />
              </Box>

              <Box className={classes.NavButtonContainer}>
                <NavButton
                  text={'Example Components'}
                  href={'/example_components'}
                  icon={<BugReportOutlinedIcon />}
                  handleCloseNavBar={handleCloseNavBar}
                />
              </Box>
              <Box className={classes.NavButtonContainer}>
                <NavButton
                  text={'Helloworld'}
                  href={'/helloworld'}
                  icon={<BugReportOutlinedIcon />}
                  handleCloseNavBar={handleCloseNavBar}
                />
              </Box>
            </ShowDebug>
          </div>

          <Box className={classes.NavButtonContainer}>
            <NavButton
              text={t('SIDEMENU_SETTINGS')}
              href={'/app/settings'}
              icon={<SettingsOutlinedIcon />}
              handleCloseNavBar={handleCloseNavBar}
              id="nav-settings"
            />
          </Box>
          {/*
          <Box className={classes.NavButtonContainer}>
            <NavButton
              text={active_lang.NAV_MENU_CHANGE_LOG}
              href={'/app/change_log'}
              icon={<EventNoteOutlinedIcon />}
              handleCloseNavBar={handleCloseNavBar}
            />
          </Box>
          */}

          <Box className={classes.NavButtonContainer}>
            <NavButton
              text={t('SIDEMENU_LOGOUT')}
              href={'/logout'}
              icon={<ExitToAppOutlinedIcon />}
              handleCloseNavBar={handleCloseNavBar}
              id="nav-logout"
            />
          </Box>

          {/*
          <Box className={classes.NavButtonContainer}>
            <NavButton
              text={active_lang.NAV_MENU_ABOUT_ME}
              href={'/about_lynked'}
              icon={<HelpOutlineOutlinedIcon />}
              handleCloseNavBar={handleCloseNavBar}
            />
          </Box>
          */}
        </Box>
      </Box>
    </>
  );
}
