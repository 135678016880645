import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  helloworld: { height: '100vh' },
  NavButtonGroup: { paddingTop: '1rem' },
  ButtonContent: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  service_disabled: {
    display: 'none',
  },
  service_enabled: {
    display: 'block',
  },
}));

export { useStyles };
