import { makeStyles } from '@material-ui/core';

import { LYNKED_RED, LYNKED_WHITE_HOVER } from 'src/consts/colors';

const useStyles = makeStyles((theme) => ({
  ReceptionListCardWrapper: {
    minHeight: '126px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 1px 2px #00000029',
    borderRadius: '2px',
    opacity: 1,
    margin: '0.5rem 1rem',

    display: 'flex',
    flexFlow: 'row',

    fontSize: '14px',
    lineHeight: '18px',

    '&:hover': {
      backgroundColor: LYNKED_WHITE_HOVER,
    },
    marginTop: '1rem',
    position: 'relative',
  },
  statusIndicator: {
    width: '10px',
    minHeight: '100%',
    backgroundColor: LYNKED_RED,
    borderRadius: '2px 0px 0px 2px',
  },
  CardContent: {
    width: '100%',
    padding: '10px',
    display: 'flex',
    flexFlow: 'column',
  },
  ReceptionCardHeader: {
    borderBottom: '1px solid #E0E0E0',
    padding: '3px 10px',
    paddingBottom: '10px',
    display: 'flex',
    flexFlow: 'row',
    // justifyContent: "space-between",
  },
  ThreeDot: {
    cursor: 'pointer',
    position: 'absolute',
    top: '19px',
    right: '28px',
  },

  ReceptionCardBody: {
    display: 'flex',
    flexFlow: 'row',
    // justifyContent: "space-between",
    padding: '10px 10px',
  },
  HeaderContent: {},
  ReceptionCardLeft0: { textAlign: 'left', fontWeight: 'bold' },
  ReceptionCardLeft1: { textAlign: 'left' },
  ReceptionCardLeft2: { width: '10%' },

  ReceptionCardBody0: {
    fontSize: '10px',
    lineHeight: '18px',
    color: '#525252',
    textAlign: 'left',
  },
  Remarks: {
    fontSize: '12px',
    lineHeight: '18px',
    paddingRight: '5px',
  },
  ReceptionCardBody1: {
    fontSize: '12px',
    lineHeight: '18px',
    textAlign: 'left',
  },
  ReceptionCardBody2: {},
}));

export { useStyles };
