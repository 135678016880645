import React from 'react';

import FullScreenLayout from 'src/layouts/FullScreenLayout';
import AppLayout from 'src/layouts/AppLayout';

import EmptyPage from 'src/pages/EmptyPage';
import AdvertisementPageTest from 'src/pages/AdvertisementPage';
import ApiEndPointPageTest from 'src/pages/ApiEndPointPage';

import EditAdvertisementDialogTest from 'src/components/EditAdvertisementDialog';

var ENV_PUBLIC_URL = process.env.PUBLIC_URL;

const debug_routes = [
  {
    path: `${ENV_PUBLIC_URL}/draft/app`,
    element: <AppLayout />,
    children: [{ path: 'empty', element: <EmptyPage /> }],
  },
  {
    path: `${ENV_PUBLIC_URL}/draft`,
    element: <FullScreenLayout />,
    children: [
      {
        path: 'edit_advertisement_dialog',
        element: <EditAdvertisementDialogTest />,
      },
      {
        path: 'edit_advertisement',
        element: <AdvertisementPageTest />,
      },
      {
        path: 'api_endpoint_test',
        element: <ApiEndPointPageTest />,
      },
    ],
  },
];

export default debug_routes;
