import React from 'react';

import DongSoundFile from 'src/assets/swiftly-610.wav';

export const ServiceRequestSoundContext = React.createContext();
export const ServiceRequestSoundContextProvider = ({ children }) => {
  let [play_sound, setPlaySound] = React.useState(false);

  const handlePlaySound = () => {
    // alert('helloworld');
    var audio1 = document.getElementById('audio-service-request-sound');
    audio1
      .play()
      .then(() => {
        console.log('play successfully');
      })
      .catch((err) => {
        console.log('play failed');
      });
  };

  React.useEffect(() => {
    if (play_sound) {
      handlePlaySound();
    }
  }, [play_sound]);

  const handleStopSound = () => {
    setPlaySound(false);
  };
  const handleSongFinishedPlaying = () => {};

  const hwServiceRequestSoundContext = () => {
    // alert('hello audio context');
  };

  return (
    <>
      <ServiceRequestSoundContext.Provider
        value={{
          handlePlaySound,
          handleStopSound,
          hwServiceRequestSoundContext,
        }}
      >
        <audio id="audio-service-request-sound">
          <source src={DongSoundFile} type="audio/wav" />
        </audio>

        {children}
      </ServiceRequestSoundContext.Provider>
    </>
  );
};
