import React from 'react';

import { Box } from '@material-ui/core';

import { LYNKED_GREEN } from 'src/consts/colors';

export default function ShowCompareUp({ value }) {
  return (
    <Box
      style={{
        font: 'normal normal normal 14px/18px Helvetica',
        color: LYNKED_GREEN,
      }}
    >
      &#x2197; {Math.max(value, 0)}
    </Box>
  );
}
