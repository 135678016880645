import React, { useEffect, useState } from 'react';
import { GlobalContext } from 'src/contexts/Global';
import { NewOrderAlertsContext } from 'src/contexts/NewOrderAlerts';

import { getAllOrders, subscribeToOrderChange } from 'src/modals/orders';

import { STATUS_PREPARING } from 'src/consts/ORDER_STATUS';

const LOG_PREFIX = 'MonitorOrder';

export const MonitorOrderContext = React.createContext();

export function MonitorOrderContextProvider({ children }) {
  const { rest_id } = React.useContext(GlobalContext);

  let [order_list_from_db, setOrderListFromDb] = React.useState({ orders: [] });
  let [flattened_order_list, setFlattenedOrderList] = React.useState({
    orders: [],
    inactiveOrders: [],
  });
  let [show_order_list, setShowOrderList] = React.useState({ orders: [] });
  let [last_flattened_order_list, setLastFlattenedOrderList] = React.useState({
    orders: [],
  });
  let { showNewOrderAlert } = React.useContext(NewOrderAlertsContext);
  const now = new Date();
  const defaultStartDate = now.setHours(0, 0, 0, 0);
  const defaultEndDate = now.setHours(23, 59, 59, 999);

  const [startDate, setStartDate] = React.useState(defaultStartDate);
  const [endDate, setEndDate] = React.useState(defaultEndDate);
  const [currentOrders, setCurrentOrders] = useState(show_order_list.orders);
  const [allOrders, setAllOrders] = useState([]);
  // let [num_of_active_order, setNumOfActiveOrder] = React.useState(0);

  React.useEffect(() => {
    let order_list_len = flattened_order_list.orders.length;
    let last_flattened_order_list_len = last_flattened_order_list.orders.length;

    console.log(LOG_PREFIX, 'playSound', {
      order_list_len,
      last_flattened_order_list_len,
    });

    if (order_list_len > last_flattened_order_list_len && order_list_len > 0) {
      setLastFlattenedOrderList({ orders: flattened_order_list.orders });
      showNewOrderAlert();
    } else {
      setLastFlattenedOrderList({ orders: flattened_order_list.orders });
    }
  }, [flattened_order_list]);

  React.useEffect(() => {
    let orders_from_db = order_list_from_db.orders;
    // console.log(LOG_PREFIX, 'orders_from_db', orders_from_db);
    setShowOrderList(order_list_from_db);

    if (orders_from_db.length > 0) {
      let temp = [];
      orders_from_db.forEach((order) => {
        console.log(LOG_PREFIX, 'order.data', order.data);
        if (order.data.orders.length > 0) {
          order.data.orders.forEach((order_item, idx) => {
            temp.push({
              id: order.id,
              order_idx: idx,
              table_number: order.data.order_table_number,
              ...order_item,
            });
          });
        }
      });

      let sorted_temp = temp
        .sort((a, b) => (a.created < b.created ? -1 : 1))
        .filter((o) => {
          return o.status === STATUS_PREPARING;
        });

      const inactiveOrders = temp
        .sort((a, b) => (a.created < b.created ? -1 : 1))
        .filter((o) => {
          return o.status != STATUS_PREPARING;
        });

      setFlattenedOrderList({
        orders: sorted_temp,
        inactiveOrders: inactiveOrders,
      });
    } else {
      setFlattenedOrderList({
        orders: [],
        inactiveOrders: [],
      });
    }
    // else {
    //   console.error(LOG_PREFIX, 'order from db is empty');
    // }
  }, [order_list_from_db]);

  const monitorOrderChange = ({ rest_id }) => {
    return subscribeToOrderChange(rest_id)
      .where('bill_status', '==', 'NOT_CLEARED')
      .orderBy('order_table_number', 'asc')
      .onSnapshot((ss) => {
        let a_d = [];
        ss.forEach((doc) => a_d.push({ id: doc.id, data: doc.data() }));
        setOrderListFromDb({ orders: a_d });
      });
  };

  React.useEffect(() => {
    let unsubMonitor;
    if (rest_id) {
      unsubMonitor = monitorOrderChange({ rest_id });
    }

    function resetMonitor() {
      console.log(LOG_PREFIX, 'monitorOrderChange', 'resetMonitor');
      if (unsubMonitor) unsubMonitor();
    }

    let componentUnload = () => resetMonitor;
    return componentUnload;
  }, [rest_id, order_list_from_db?.length]);

  useEffect(() => {
    const orderListFilter = show_order_list?.orders?.filter((item) => {
      if (!startDate || !endDate) return true;
      return (
        startDate <= item?.data?.order_create_time?.seconds * 1000 &&
        item?.data?.order_create_time?.seconds * 1000 <= endDate
      );
    });
    const allOrdersFilter = allOrders?.filter((item) => {
      if (!startDate || !endDate) return true;
      return (
        startDate <= item?.data?.order_create_time?.seconds * 1000 &&
        item?.data?.order_create_time?.seconds * 1000 <= endDate
      );
    });
    setCurrentOrders(orderListFilter);
    setAllOrders(allOrdersFilter);
  }, [startDate, endDate]);

  useEffect(() => {
    setCurrentOrders(show_order_list.orders);
  }, [show_order_list]);

  useEffect(() => {
    if (rest_id) {
      getAllOrders(rest_id).onSnapshot((querySnapshot) => {
        if (querySnapshot.size) {
          const allOrders = [];
          querySnapshot.forEach((doc) => {
            allOrders.push({ id: doc.id, data: doc.data() });
          });
          setAllOrders(allOrders);
        }
      });
    }
  }, [rest_id]);

  return (
    <>
      <MonitorOrderContext.Provider
        value={{
          order_list: order_list_from_db,
          show_order_list,
          flattened_order_list,
          startDate,
          endDate,
          setStartDate,
          setEndDate,
          currentOrders,
          allOrders,
        }}
      >
        {children}
      </MonitorOrderContext.Provider>
    </>
  );
}
