import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import useGetSourceLang from 'src/hooks/useGetSourceLang';
import useQueryRestaurantConfigByRestId from 'src/hooks/useQueryRestaurantConfigByRestId';
import { isUndefined } from 'lodash';
import { SITES, LANGUAGES, LANG_CLIENT_KEY } from '../../../consts/language';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

export default function TranslationForm({ formik }) {
  const { data } = useGetSourceLang(SITES.CLIENT);
  const { t } = useTranslation();
  const [langCode, setLangCode] = React.useState(LANGUAGES.JAPAN);

  const handleChange = (event: SelectChangeEvent) => {
    setLangCode(event.target.value);
    formik.setFieldValue(LANG_CLIENT_KEY, event.target.value);
  };

  React.useEffect(() => {
    if (!formik.values.client_lang_display) {
      formik.setFieldValue(LANG_CLIENT_KEY, LANGUAGES.JAPAN);
      setLangCode(LANGUAGES.JAPAN);
    } else {
      formik.setFieldValue(LANG_CLIENT_KEY, formik.values.client_lang_display);
      setLangCode(formik.values.client_lang_display);
    }
  }, [formik.values]);

  return (
    <>
      <Card>
        <CardHeader
          title={t('SETTINGS_CHANGE_LANGUAGE_MOBILE')}
          titleTypographyProps={{ style: { fontWeight: 'bold' } }}
        />
        <Divider />
        <CardContent>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Grid item xs={12}>
                <Box style={{ margin: '1rem' }}>
                  <Typography
                    id="max-number-of-adult"
                    gutterBottom
                    style={{ marginBottom: '1rem', fontWeight: 'bold' }}
                  >
                    {t('SETTINGS_LANGUAGE')}
                  </Typography>
                  <FormControl
                    sx={{ m: 1, minWidth: 120 }}
                    style={{ width: '100%' }}
                  >
                    <Select
                      value={langCode}
                      onChange={handleChange}
                      displayEmpty
                      variant="outlined"
                      style={{ width: '100%' }}
                      IconComponent={KeyboardArrowDownIcon}
                    >
                      {data &&
                        Object.keys(data).map((key) => (
                          <MenuItem key={key} value={key}>
                            {data[key].KEY_VALUE}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
