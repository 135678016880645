import React from 'react';
import { Box, Button } from '@material-ui/core';

import AssignedTableListCard from 'src/components/AssignedTableListCard';

import { LYNKED_RED } from 'src/consts/colors';
import active_lang from 'src/langs/jp_en';
import { MonitorLineupContext } from 'src/contexts/MonitorLineup';
import { useTranslation } from 'react-i18next';

export default function ShowAssignedTablesList() {
  let { assigned_table_list } = React.useContext(MonitorLineupContext);
  const { t } = useTranslation();

  let [view_length_to_show, setViewLengthToShow] = React.useState(3);

  const handleSeeAllClick = () => {
    setViewLengthToShow(undefined);
  };

  return (
    <>
      <Box
        style={{
          paddingTop: '1rem',
          paddingBottom: '3rem',
          paddingRight: '1rem',
        }}
      >
        {assigned_table_list
          .sort((a, b) => {
            if (
              Object.keys(a.data).includes('assign_table_time') &&
              Object.keys(b.data).includes('assign_table_time')
            ) {
              return a.data.assign_table_time.seconds <
                b.data.assign_table_time.seconds
                ? 1
                : -1;
            } else {
              return 1;
            }
          })
          .slice(0, view_length_to_show)
          .map((x, idx) => {
            return <AssignedTableListCard assigned_table_info={x} key={idx} />;
          })}
      </Box>
      <Box
        style={{
          textAlign: 'right',
          textDecoration: 'underline',
          font: 'normal normal bold 10px/13px Roboto',

          paddingRight: '1rem',
          marginBottom: '2rem',
          paddingTop: '2rem',

          color: LYNKED_RED,
        }}
      >
        {/* TODO: show "list end" here after clicking SEE all */}
        <Button onClick={handleSeeAllClick} style={{ color: LYNKED_RED }}>
          {t('COMMON_SEE_ALL')}
        </Button>
      </Box>
    </>
  );
}
