import React from 'react';

import { MonitorServiceRequestContextProvider } from 'src/contexts/MonitorServiceRequest';
import { MonitorLineupContextProvider } from 'src/contexts/MonitorLineup';
import { MonitorOrderContextProvider } from 'src/contexts/MonitorOrder';
import { MonitorRestaurantConfigContextProvider } from 'src/contexts/MonitorRestaurantConfig';

export default function Contexts({ children }) {
  return (
    <>
      <MonitorServiceRequestContextProvider>
        <MonitorLineupContextProvider>
          <MonitorOrderContextProvider>
            <MonitorRestaurantConfigContextProvider>
              {children}
            </MonitorRestaurantConfigContextProvider>
          </MonitorOrderContextProvider>
        </MonitorLineupContextProvider>
      </MonitorServiceRequestContextProvider>
    </>
  );
}
