import moment from 'moment-timezone';

import db_config from 'src/configs/db_config';
import db from 'src/Firebase/db';

const DB_TABLE_USAGE_STATISTICS_REF = db.collection(
  db_config.DB_TABLE_USAGE_STATISTICS
);

function getAdImpressionByRestId(rest_id) {
  return DB_TABLE_USAGE_STATISTICS_REF.doc(rest_id)
    .collection('ad_impressions')
    .get()
    .then((ss) => {
      let counts = [];
      let dates = [];

      ss.forEach((doc) => {
        let unix_timestamp = doc.id.split('_')[1];
        let human_time = moment.unix(unix_timestamp).format('DD/MM');
        dates.push(human_time);
        counts.push(doc.data().count);
      });

      let counts_length = counts.length;

      return {
        dates,
        counts,
        counting: counts[counts_length - 1],
      };
    });
}

export { getAdImpressionByRestId };
