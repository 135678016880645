import { useMutation } from 'react-query';
import { updateAllFoods } from 'src/modals/food_details';

export default function useMutateUpdateAllFoods() {
  const mutation = useMutation(
    ({ rest_id, food_menu }) => {
      console.log('useMutateUpdateAllFoods', 'rest_id', rest_id);
      console.log('useMutateUpdateAllFoods', 'food_menu', food_menu);
      return updateAllFoods(rest_id, food_menu);
    },
    {
      retry: 10,
    }
  );

  return mutation;
}
