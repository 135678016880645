import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import {
  styled,
  Box,
  TextField,
  Button,
  makeStyles,
  Input,
  Grid,
} from '@material-ui/core';

import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';

import {
  LYNKED_RED,
  LYNKED_RED_HOVER,
  LYNKED_WHITE,
  LYNKED_BLUE,
  LYNKED_BLUE_HOVER,
  LYNKED_GREEN,
  LYNKED_GREEN_HOVER,
  LYNKED_LIGHT_GREY,
  LYNKED_LIGHT_GREY_HOVER,
  LYNKED_DEEP_GREY,
  LYNKED_DEEP_GREY_HOVER,
  LYNKED_BLACK,
  LYNKED_BLACK_HOVER,
  LYNKED_WHITE_HOVER,
  LYNKED_BACKGROUND_LIGHT_GREY,
  LYNKED_NAV_HIGHLIGHT,
} from 'src/consts/colors';

const useStyles = makeStyles((theme) => ({
  helloworld: { height: '100vh' },
  NavButtonGroup: { paddingTop: '1rem' },
  ButtonContent: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    font: 'normal normal bold 12px/16px Roboto',
    alignItems: 'center',
    textAlign: 'left',
  },
  NavButton: {
    '&:hover': {
      backgroundColor: LYNKED_NAV_HIGHLIGHT,
    },
    '&:selected': {
      backgroundColor: 'blue',
    },
  },
  menu_active: {
    color: LYNKED_RED,
  },
}));

export default function NavButton({
  handleCloseNavBar,
  text,
  href,
  icon,
  isactive,
  id,
}) {
  const classes = useStyles();
  // console.log('href', href);

  const navigate = useNavigate();

  const handleButtonClick = (e) => {
    // alert('handle button click');
    handleCloseNavBar(e);
  };
  const handleGo = (e, href) => {
    navigate(href);
    try {
      handleCloseNavBar(e);
    } catch (error) {
      console.error('handleCloseNavBar', 'error', error);
    }
  };
  return (
    <>
      {/* <Button className={classes.NavButton} fullWidth component={RouterLink} to={href}> */}
      <Button
        id={id}
        className={`${classes.NavButton} ${
          isactive == null ? '' : classes.menu_active
        }`}
        fullWidth
        onClick={(e) => {
          handleGo(e, href);
        }}
      >
        <Box className={classes.ButtonContent}>
          <Box style={{ paddingLeft: '2rem' }} component="span">
            {icon}
          </Box>
          <Box style={{ paddingLeft: '0.5rem' }} component="span">
            {text}
          </Box>
        </Box>
      </Button>
    </>
  );
}
