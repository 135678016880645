import React from 'react';
import { isNull, isUndefined } from 'lodash';

import { Box, makeStyles } from '@material-ui/core';

import Page from 'src/components/Page';
import { GlobalContext } from 'src/contexts/Global';

import QRGeneratorMeny from './QRGeneratorMeny';
import QRGeneratorMenyLight from './QRGeneratorMenyLight';

import LoadingQR from './LoadingQR';

import { SERVICE_MENY, SERVICE_MENY_LIGHT } from 'src/consts/MENY_SERVICE';

const useStyles = makeStyles(() => ({
  root: { width: '100%', padding: '3rem' },
}));

export default function QRGeneratorPage() {
  const classes = useStyles();
  const { meny_service_class } = React.useContext(GlobalContext);

  const QRPage = ({ meny_service_class }) => {
    if (isNull(meny_service_class) || isUndefined(meny_service_class.id)) {
    } else {
      if (meny_service_class.id === SERVICE_MENY) {
        return <QRGeneratorMeny meny_service_class={meny_service_class} />;
      }
      if (meny_service_class.id === SERVICE_MENY_LIGHT) {
        return <QRGeneratorMenyLight meny_service_class={meny_service_class} />;
      }
    }
    return <></>;
  };

  let [show_loading, setShowLoading] = React.useState(true);
  React.useEffect(() => {
    setTimeout(() => {
      setShowLoading(false);
    }, 100);
  }, []);

  return (
    <>
      <Page className={classes.root}>
        <Box
          style={{
            width: '100%',
            paddingBottom: '5rem',
          }}
        >
          {show_loading ? (
            <LoadingQR />
          ) : (
            <>
              <QRPage meny_service_class={meny_service_class} />
            </>
          )}
        </Box>
      </Page>
    </>
  );
}
