import { cloneDeep } from 'lodash';
import moment from "moment-timezone";
import { iso_time_schema } from "src/validator/time_picker_input";
import { DEFAULT_TIMEZONE } from "src/consts/defaultTimezone";

const toLocalTime = (date = '', keepTime = false) => {
  const localTz = moment.tz.guess(true);
  
  // 1970-01-01T09:00:00.000+09:00
  if (typeof date !== 'string') {
    return date;
  }

  if (keepTime) {
    if(date.includes("Z")) {
      const dateWithoutTz = moment(date.substring(0, date.length - 1));
      return dateWithoutTz.tz(localTz).format(iso_time_schema);
    }
    const dateWithoutTz = moment(date.substring(0, date.length - 6));
    return dateWithoutTz.tz(localTz).format(iso_time_schema);
  }

  return moment(date).tz(localTz).format(iso_time_schema);
}

const toTztime = (date = '', keepTime = false, timezone = DEFAULT_TIMEZONE) => {  
  if ((timezone !== 0 && !timezone)
    || timezone < -12
    || timezone > 14) {
    timezone = DEFAULT_TIMEZONE;
  }

  // 1970-01-01T09:00:00.000+09:00
  if (typeof date !== 'string') {
    return date;
  }

  if (keepTime) {
    const dateWithoutTz = moment(date.substring(0, date.length - 6));
    return dateWithoutTz.utcOffset(timezone, true).format(iso_time_schema);
  }
  
  return moment(date).utcOffset(timezone).format(iso_time_schema);
}

export const toLocalOpeningSchedule = (opening_schedule) => {
  const localSchedule = cloneDeep(opening_schedule);
  for (let key in localSchedule) {
    localSchedule[key].utc_end_time = toLocalTime(localSchedule[key].utc_end_time, true);
    localSchedule[key].utc_start_time = toLocalTime(localSchedule[key].utc_start_time, true);
  }

  return localSchedule;
};

export const toTzOpeningSchedule = (opening_schedule, timezone) => {
  const tzSchedule = cloneDeep(opening_schedule);
  for (let key in tzSchedule) {
    tzSchedule[key].utc_end_time = toTztime(tzSchedule[key].utc_end_time, true, timezone);
    tzSchedule[key].utc_start_time = toTztime(tzSchedule[key].utc_start_time, true, timezone);
  }

  return tzSchedule;
}