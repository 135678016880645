import React from 'react';
import { Box, Card, Divider, Typography } from '@material-ui/core';

import useQueryAdImpression from 'src/hooks/useQueryAdImpression';

import ShowCompare from './ShowCompare';

import active_lang from 'src/langs/jp_en';
import { isNull, isUndefined } from 'lodash';
import { useTranslation } from 'react-i18next';

export default function ShowAdImpressionsTiles({ rest_id }) {
  let { data, isLoading } = useQueryAdImpression(rest_id);
  const { t } = useTranslation();

  let [chart_data, setChartData] = React.useState(0);

  const [today_count, setTodayCount] = React.useState(0);
  const [yesterday_count, setYesterdayCount] = React.useState(0);

  React.useEffect(() => {
    console.log('ShowAdImpressionsTiles', 'data', data);
    if (isUndefined(data) || isNull(data) || isNaN(data.counting)) {
      setChartData(0);
    } else {
      setChartData(data.counting);
      setYesterdayCount(data.counts[data.counts.length - 2]);
      setTodayCount(data.counting);
    }
  }, [data, isLoading]);

  return (
    <>
      <Card style={{ margin: '1rem' }}>
        <Box style={{ margin: '1rem' }}>
          <Box mb={'0.5rem'}>
            <Typography
              variant="h4"
              color="textPrimary"
              style={{
                fontSize: '12px',
                fontWeight: '700',
                marginBottom: '12px',
                marginTop: '12px',
              }}
            >
              {t('DASHBOARD_NUMBER_BANNERS')}
            </Typography>
          </Box>
          <Divider />
          {isLoading ? (
            <>{t('COMMON_LOADING')}</>
          ) : (
            <>
              {isUndefined(data?.counts) ? (
                <></>
              ) : (
                <>
                  <Box
                    mt={'0.5rem'}
                    height={'150px'}
                    position="relative"
                    style={{
                      font: 'normal normal bold 31px/40px Roboto',
                      display: 'flex',
                      flexFlow: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Box>
                      <Box
                        id={'number-of-ads-view'}
                        component={'span'}
                        style={{ font: 'normal normal bold 46px/60px Roboto' }}
                      >
                        {isNaN(chart_data) ? 0 : chart_data}
                      </Box>
                      <Box
                        component={'span'}
                        style={{ font: 'normal normal bold 31px/40px Roboto' }}
                      >
                        {active_lang.TILES_VIEWS}
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    style={{
                      display: 'flex',
                      flexFlow: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <ShowCompare
                      today={today_count}
                      yesterday={yesterday_count}
                    />
                    <Box
                      style={{
                        paddingLeft: '0.5rem',
                        font: 'normal normal normal 14px/18px Helvetica',
                      }}
                    >
                      {t('DASHBOARD_SINCE_YESTERDAY')}
                    </Box>
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
      </Card>
    </>
  );
}
