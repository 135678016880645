import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import service_request_white_png from 'src/assets/alarm/alarm-svgrepo-com-white.svg';

import active_lang from 'src/langs/jp_en';

import { useStyles, Transition } from './styles';
import { dialog_transition_duration } from 'src/consts/TRANSITION';
import { GlobalContext } from 'src/contexts/Global';
import { useTranslation } from 'react-i18next';

export default function AlertNewServiceRequest({ open, onClose }) {
  const classes = useStyles();
  const { restaurant_config } = React.useContext(GlobalContext);
  const { t } = useTranslation();

  return (
    <Dialog
      open={open && restaurant_config.isEnableBellRing}
      TransitionComponent={Transition}
      transitionDuration={dialog_transition_duration}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert_new_service_request"
      aria-describedby="new_service_request"
    >
      <DialogTitle
        className={classes.dialog_background}
        id="alert_new_service_request"
        style={{ textAlign: 'center' }}
      >
        <Box className={classes.title}>
          {t('POPUP_REQUEST')}
        </Box>
      </DialogTitle>
      <DialogContent
        className={classes.dialog_background}
        style={{ textAlign: 'center' }}
      >
        <Box
          style={{
            display: 'inline-flex',
            flexFlow: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          className={classes.dialog_background}
        >
          <div
            style={{
              backgroundImage: `url(${service_request_white_png})`,
              backgroundSize: `contain`,
              backgroundRepeat: `no-repeat`,
              width: '50px',
              height: '50px',
              color: 'white',
              path: 'white',
            }}
          ></div>
        </Box>
      </DialogContent>
      <DialogActions
        className={classes.dialog_background}
        style={{ justifyContent: 'center' }}
      >
        <Box
          style={{
            paddingTop: '2rem',
          }}
        >
          <Button
            id="ack-service-request-dialog"
            onClick={onClose}
            className={classes.button_yes}
          >
            {t('COMMON_YES')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
