let YOU_HAVE_NO_BILLS_AT_THE_MOMENT = '全てのお客様会計済みです';

let CONFIRM_CLEAR_BILL_OK = '確定';
let CONFIRM_CLEAR_BILL_CANCEL = 'キャンセル';

export default {
  YOU_HAVE_NO_BILLS_AT_THE_MOMENT,
  CONFIRM_CLEAR_BILL_OK,
  CONFIRM_CLEAR_BILL_CANCEL,
};
