import React from 'react';

import {
  Box,
  FormControl,
  Select,
  MenuItem,
  InputBase,
} from '@material-ui/core';

import active_lang from 'src/langs/jp_en';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { useStyles } from './styles';

import { STATUS_FOOD_SHOW, STATUS_FOOD_HIDE } from 'src/modals/food_details';
import { useTranslation } from 'react-i18next';

export default function ShowFoodSelect({ formik }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Box className={classes.TextInputGroup} mt="0.5rem">
        <FormControl fullWidth>
          {/* <Box>{active_lang.SHOW_FOOD}</Box> */}
          <Select
            id="food_show_status"
            {...formik.getFieldProps('food_show_status')}
            classes={{
              root: classes.food_availability,
              icon: classes.icon,
            }}
            input={<InputBase />}
            IconComponent={KeyboardArrowDownIcon}
            style={{ paddingTop: '0.5rem' }}
          >
            <MenuItem value={STATUS_FOOD_SHOW}>
              {t('COMMON_PUBLIC')}
            </MenuItem>
            <MenuItem value={STATUS_FOOD_HIDE}>
              {t('COMMON_NOT_PULIC')}
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    </>
  );
}
