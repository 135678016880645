import React from 'react';
import clsx from 'clsx';
import { Line } from 'react-chartjs-2';
import { Box, Card, Divider, useTheme, Typography } from '@material-ui/core';

import active_lang from 'src/langs/jp_en';

import { useStyles } from './styles';

import ShowCompare from './ShowCompare';
import ShowDebug from 'src/components/ShowDebug';
import { useTranslation } from 'react-i18next';

export default function ShowMenyUsage({
  chart_data,
  _dates,
  counts,
  className,
  ...rest
}) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const [counts_loading] = React.useState(false);
  const [today_count, setTodayCount] = React.useState(2);
  const [yesterday_count, setYesterdayCount] = React.useState(1);

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 10,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: theme.palette.text.secondary,
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider,
          },
        },
      ],
    },
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary,
    },
  };

  let [counts_idx_today, setCountsIdxToday] = React.useState(0);

  React.useEffect(() => {
    if (counts) {
      let counts_len = counts.length;
      let idx_today = counts_len - 1;
      let idx_yesterday = idx_today - 1;

      console.log('idx_today', idx_today);
      console.log('idx_yesterday', idx_yesterday);
      setTodayCount(counts[idx_today]);
      setYesterdayCount(counts[idx_yesterday]);

      // debug
      setCountsIdxToday(counts[idx_today] || 0);
    }
  }, [counts]);

  return (
    <>
      <Card
        className={clsx(classes.root, className)}
        {...rest}
        style={{ margin: '1rem' }}
      >
        <Box style={{ margin: '1rem' }}>
          <Box mb={'0.5rem'}>
            <Typography
              variant="h4"
              color="textPrimary"
              style={{
                fontSize: '12px',
                fontWeight: '700',
                marginBottom: '12px',
                marginTop: '12px',
              }}
            >
              {t('DASHBOARD_RATE_USE')}
            </Typography>
          </Box>
          <Divider />

          <Box mt={'0.5rem'} height={'150px'} position="relative">
            <Line data={chart_data} options={options} />
          </Box>

          <Box
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            {counts_loading ? (
              <>{t('COMMON_LOADING')}</>
            ) : (
              <ShowCompare today={today_count} yesterday={yesterday_count} />
            )}

            <Box
              style={{
                paddingLeft: '0.5rem',
                font: 'normal normal normal 14px/18px Helvetica',
              }}
            >
              {t('DASHBOARD_SINCE_YESTERDAY')}
            </Box>
          </Box>
        </Box>
        {/* <ShowDebug>
          <div id="latest-meny-usage-count">
            {JSON.stringify(counts_idx_today)}
          </div>
        </ShowDebug> */}
      </Card>
    </>
  );
}
