import { Badge } from '@material-ui/core';

import { withStyles, makeStyles } from '@material-ui/core/styles';

import { LYNKED_RED, LYNKED_WHITE } from 'src/consts/colors';

const StyledBadge = withStyles((_theme) => ({
  badge: {
    backgroundColor: LYNKED_RED,
    color: LYNKED_WHITE,
  },
}))(Badge);

const useStyles = makeStyles(() => ({
  helloworld: {},
}));

export { StyledBadge, useStyles };
