import React from 'react';
import { isNull, isUndefined } from 'lodash';

import { Box } from '@material-ui/core';
import { ReactComponent as RightChevron } from 'src/assets/icons/right-double-arrow.svg';

import HelpRequestCard from 'src/components/HelpRequestCard';
import { acknowledgeServiceRequestById } from 'src/modals/service_requests';
import { GlobalContext } from 'src/contexts/Global';
import { LYNKED_DEEP_GREY } from 'src/consts/colors';
import active_lang from 'src/langs/jp_en';
import { useTranslation } from 'react-i18next';

export default function HelpRequestList({
  max_card_len,
  list_active_service_request,
}) {
  let { rest_id } = React.useContext(GlobalContext);
  const { t } = useTranslation();

  const handleAcknowledgeServiceRequestClick = (_e, sr_id) => {
    acknowledgeServiceRequestById(rest_id, sr_id);
    console.log('handleAcknowledgeServiceRequestClick', rest_id, sr_id);
  };

  if (
    isNull(list_active_service_request) ||
    isUndefined(list_active_service_request)
  ) {
    return <></>;
  } else {
    if (list_active_service_request.length === 0) {
      return (
        <Box
          style={{
            width: '100%',

            display: 'flex',
            flexFlow: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box style={{ color: LYNKED_DEEP_GREY, paddingTop: '1rem' }}>
            {t('COMMON_NO_CALLS_MSG')}
          </Box>
        </Box>
      );
    } else {
      return (
        <>
          <Box style={{ display: 'flex', flexFlow: 'row', justifyContent: 'space-between' }}>
            {list_active_service_request
              .slice(0, max_card_len)
              .map((active_service_request, idx) => {
                return (
                  <>
                    <Box
                      component="span"
                      style={{
                        paddingLeft: '0.5rem',
                        paddingTop: '0.25rem',
                        paddingBottom: '0.25rem',
                        paddingRight: '0.5rem',
                      }}
                      key={`service_request_card_${idx}`}
                    >
                      <HelpRequestCard
                        idx={idx}
                        service_request_info={active_service_request}
                        handleAcknowledge={handleAcknowledgeServiceRequestClick}
                      />
                    </Box>
                  </>
                );
              })}
            {list_active_service_request.length > max_card_len ? (
              <Box
                style={{
                  padding: '0.25rem 0.25rem',
                  marginLeft: '1rem',
                  display: 'flex',
                  flexFlow: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <RightChevron style={{ height: '3rem' }} />
              </Box>
            ) : (
              <></>
            )}
          </Box>
        </>
      );
    }
  }
}
