import React from 'react';
import FeaturedPlayListOutlinedIcon from '@material-ui/icons/FeaturedPlayListOutlined';

import { MonitorLineupContext } from 'src/contexts/MonitorLineup';

import { StyledBadge } from 'src/components/RestaurantNavButtonColumn/NavButtons/styles';
import NavButton from 'src/components/NavButton';

import { useTranslation } from 'react-i18next';

export default function NavMenuReceptionList({ handleCloseNavBar }) {
  let { number_of_active_line_up } = React.useContext(MonitorLineupContext);
  const { t } = useTranslation();

  return (
    <>
      <NavButton
        text={t('SIDEMENU_RECEPTION_MANAGE')}
        href={`/app/reception_list`}
        icon={
          <StyledBadge badgeContent={number_of_active_line_up}>
            <FeaturedPlayListOutlinedIcon />
          </StyledBadge>
        }
        handleCloseNavBar={handleCloseNavBar}
        id="nav-reception-list"
      />
    </>
  );
}
