import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { SkeletonTheme } from 'react-loading-skeleton';
import routes from 'src/routes/index';
import { LYNKED_RED_SUPER_LIGHT } from 'src/consts/colors';
import { I18nProvider } from 'src/utils/i18n';
import './App.css';

export default function App() {
  const routing = useRoutes(routes);

  return (
    <>
      <Suspense fallback={<></>}>
        <SkeletonTheme highlightColor={LYNKED_RED_SUPER_LIGHT}>
          <I18nProvider>
            <div>{routing}</div>
          </I18nProvider>
        </SkeletonTheme>
      </Suspense>
    </>
  );
}
