import React from 'react';
import {
  Box,
  makeStyles,
  FormControl,
  MenuItem,
  Select,
  InputBase,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import ShowDebug from 'src/components/ShowDebug';
import active_lang from 'src/langs/jp_en';

import {
  LYNKED_RED,
  LYNKED_WHITE,
  LYNKED_GREEN,
  LYNKED_GREEN_HOVER,
  LYNKED_LIGHT_GREY,
  LYNKED_DEEP_GREY,
  LYNKED_DEEP_GREY_HOVER,
} from 'src/consts/colors';
import { select_values } from 'src/modals/advertisement_config';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  button_yes: {
    color: theme.palette.success.main,
  },
  button_no: {
    color: theme.palette.error.main,
  },
  TextInput: {
    border: '1px solid #E0E0E0',
    font: 'normal normal bold 12px/16px Roboto',
    padding: '3px 3px',
    '& ::placeholder': {
      color: LYNKED_LIGHT_GREY,
    },
  },
  food_availability: {
    border: '1px solid #e2e2e2',
    padding: '0.5rem',
  },
  icon: {
    color: '#707070',
    marginRight: '1rem',
    marginTop: '0.25rem',
  },
  DialogBottomButtons: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  Buttons: {
    width: '48%',
    padding: '10px',
    color: 'white',
    font: 'normal normal normal 11px/15px Noto Sans JP',

    letterSpacing: '0.12px',
    borderRadius: '1px',
  },
  ButtonCancel: {
    backgroundColor: LYNKED_DEEP_GREY,
    '&:hover': {
      backgroundColor: LYNKED_DEEP_GREY_HOVER,
    },
  },
  ButtonOK: {
    backgroundColor: LYNKED_GREEN,
    '&:hover': {
      backgroundColor: LYNKED_GREEN_HOVER,
    },
  },
  AddImageButton: {
    color: LYNKED_RED,
  },
  AddImagePlaceHolder: {
    background: `${LYNKED_WHITE} 0% 0% no-repeat padding-box`,
    border: `1px dashed ${LYNKED_RED}`,
    borderRadius: '9px',

    width: '100%',
    minHeight: '300px',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  test_icon_style: {
    color: LYNKED_RED,
  },
}));

function SelectMenyAdConfigureSlotOrderListOnly({ formik }) {
  let classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Select
        id="form_ad_window"
        {...formik.getFieldProps('form_ad_window')}
        value={'order_list'}
        classes={{
          root: classes.food_availability,
          icon: classes.icon,
        }}
        input={<InputBase />}
        IconComponent={KeyboardArrowDownIcon}
        style={{ paddingTop: '0.5rem' }}
        onChange={(e) => {
          switch (e.target.value) {
            case select_values.ad_window.FOOD_MENU.name:
              // set to 'please_select' to block if user not select food_category / food_details
              formik.setFieldValue('form_ad_destination', 'please_select');
              break;
            case select_values.ad_window.ORDER_LIST.name:
              formik.setFieldValue('form_ad_destination', 'order_list');
              break;
            default:
              formik.setFieldValue('form_ad_destination', 'please_select');
              break;
          }

          formik.handleChange(e);
        }}
      >
        <MenuItem value={select_values.ad_window.ORDER_LIST.name}>
          {t('AD_ORDER_LIST')}
          <ShowDebug>{'AD_ORDER_LIST'}</ShowDebug>
        </MenuItem>
      </Select>
    </>
  );
}

function SelectMenyAdConfigureSlotFoodMenuOnly({ formik }) {
  let classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Select
        id="form_ad_window"
        {...formik.getFieldProps('form_ad_window')}
        classes={{
          root: classes.food_availability,
          icon: classes.icon,
        }}
        input={<InputBase />}
        IconComponent={KeyboardArrowDownIcon}
        style={{ paddingTop: '0.5rem' }}
        onChange={(e) => {
          switch (e.target.value) {
            case select_values.ad_window.FOOD_MENU.name:
              // set to 'please_select' to block if user not select food_category / food_details
              formik.setFieldValue('form_ad_destination', 'please_select');
              break;
            case select_values.ad_window.ORDER_LIST.name:
              formik.setFieldValue('form_ad_destination', 'order_list');
              break;
            default:
              formik.setFieldValue('form_ad_destination', 'please_select');
              break;
          }

          formik.handleChange(e);
        }}
      >
        <MenuItem value={select_values.ad_window.FOOD_MENU.name}>
          {t('COMMON_MENU')}
          <ShowDebug>{'AD_FOOD_MENU'}</ShowDebug>
        </MenuItem>
      </Select>
    </>
  );
}

function SelectMenyAdConfigureSlotFoodMenuAndOrderList({ formik }) {
  let classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Select
        id="form_ad_window"
        {...formik.getFieldProps('form_ad_window')}
        classes={{
          root: classes.food_availability,
          icon: classes.icon,
        }}
        input={<InputBase />}
        IconComponent={KeyboardArrowDownIcon}
        style={{ paddingTop: '0.5rem' }}
        onChange={(e) => {
          switch (e.target.value) {
            case select_values.ad_window.FOOD_MENU.name:
              // set to 'please_select' to block if user not select food_category / food_details
              formik.setFieldValue('form_ad_destination', 'please_select');
              break;
            case select_values.ad_window.ORDER_LIST.name:
              formik.setFieldValue('form_ad_destination', 'order_list');
              break;
            default:
              formik.setFieldValue('form_ad_destination', 'please_select');
              break;
          }

          formik.handleChange(e);
        }}
      >
        <MenuItem value={select_values.ad_window.FOOD_MENU.name}>
          {t('COMMON_MENU')}
          <ShowDebug>{'AD_FOOD_MENU'}</ShowDebug>
        </MenuItem>
        <MenuItem value={select_values.ad_window.ORDER_LIST.name}>
          {t('AD_ORDER_LIST')}
          <ShowDebug>{'AD_ORDER_LIST'}</ShowDebug>
        </MenuItem>
      </Select>
    </>
  );
}

function SelectMenyAdConfigureSlotFiltered({ formik, advertisement_result }) {
  switch (advertisement_result.data.length) {
    case 1:
      if (
        advertisement_result.data[0].data.ad_window.name ===
        select_values.ad_window.FOOD_MENU.name
      ) {
        return (
          <>
            <SelectMenyAdConfigureSlotOrderListOnly
              formik={formik}
              advertisement_result={advertisement_result}
            />
          </>
        );
      }
      if (
        advertisement_result.data[0].data.ad_window.name ===
        select_values.ad_window.ORDER_LIST.name
      ) {
        return (
          <>
            <SelectMenyAdConfigureSlotFoodMenuOnly
              formik={formik}
              advertisement_result={advertisement_result}
            />
          </>
        );
      }
      break;

    default:
      return (
        <>
          <SelectMenyAdConfigureSlotFoodMenuAndOrderList
            formik={formik}
            advertisement_result={advertisement_result}
          />
        </>
      );
  }
}

export default function SelectMenyAdConfigureSlot({
  formik,
  advertisement_result,
}) {
  const { t } = useTranslation();
  console.log('advertisement_result', advertisement_result);

  return (
    <>
      <Box mt={'0.5rem'}>
        <FormControl fullWidth>
          <div style={{fontWeight: 'bold'}}>{t('COMMON_BANNER_POSITION')}</div>
          {/* <ShowDebug>
            <pre>{JSON.stringify(advertisement_result)}</pre>
          </ShowDebug> */}
          <SelectMenyAdConfigureSlotFiltered
            formik={formik}
            advertisement_result={advertisement_result}
          />
        </FormControl>
      </Box>
    </>
  );
}
