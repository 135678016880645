import React, { useState } from 'react';

import { Box, Grid } from '@material-ui/core';

import SearchInput from 'src/components/SearchInput';

import Column from './Column';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import ShareContextProvider, { ShareContext } from './context/Share';

import { GlobalContext } from 'src/contexts/Global';

import AddCategoryButton from 'src/components/AddCategoryButton';
import AddFoodItemButton from 'src/components/AddFoodItemButton';

import ShowDebug from 'src/components/ShowDebug';

import { useStyles } from './styles';
import { isEqual, isUndefined } from 'lodash';

import Page from 'src/components/Page';
import { LYNKED_DEEP_GREY } from 'src/consts/colors';

import active_lang from 'src/langs/jp_en';
import useMutateUpdateAllFoods from 'src/hooks/useMutateUpdateAllFoods';
import { useTranslation } from 'react-i18next';

function ShowMenubody({ food_menu, setFoodMenu, rest_id }) {
  const [foods, setFoods] = useState(food_menu);
  const [search, setSearch] = useState('');
  const classes = useStyles();
  // const [category_id_edit, setCategoryIdEdit] = React.useState(null);
  const {
    setOpenAddFoodDetailDialog,
    setOpenCategoryDialog,
    categories_available,
    setPreSelectCategory,
  } = React.useContext(ShareContext);
  let mutationUpdateAllFoods = useMutateUpdateAllFoods();

  const onDragEnd = (result) => {
    const { destination, source, draggableId, type } = result;
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (type === 'column') {
      const newColumnOrder = Array.from(food_menu.columnOrder);
      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, draggableId);

      const newState = { ...food_menu, columnOrder: newColumnOrder };

      setFoodMenu(newState);
      setFoods(newState);

      mutationUpdateAllFoods.mutateAsync({
        rest_id,
        food_menu: newState,
      });
      return;
    }

    const start = food_menu.columns[source.droppableId];
    const finish = food_menu.columns[destination.droppableId];

    if (start === finish) {
      let newTaskIds = Array.from(start.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      let newColumn = {
        ...start,
        taskIds: newTaskIds,
      };

      let newState = {
        ...food_menu,
        columns: {
          ...food_menu.columns,
          [newColumn.id]: newColumn,
        },
      };

      console.log('onDragEnd', newState);

      setFoodMenu(newState);
      setFoods(newState);
      mutationUpdateAllFoods.mutateAsync({
        rest_id,
        food_menu: newState,
      });
      return;
    }

    // Moving from one list to another
    const startTaskIds = Array.from(start.taskIds);
    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      taskIds: startTaskIds,
    };

    const finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      taskIds: finishTaskIds,
    };

    const newState = {
      ...food_menu,
      columns: {
        ...food_menu.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };
    setFoodMenu(newState);
    setFoods(newState);

    mutationUpdateAllFoods.mutateAsync({
      rest_id,
      food_menu: newState,
    });
    return;
  };

  const handleUpdateFilterWords = (e) => {
    setSearch(e.target.value);
    const newFoodMenu = {
      columnOrder: [],
      columns: {},
      food_details: {},
    };
    let isMatchedCategory = false;
    let isMatchedFood = false;

    if (!e.target.value) {
      setFoods(food_menu);
      return;
    }

    // Matched category
    food_menu.columnOrder.forEach((columnId) => {
      if (food_menu.columns[columnId].title === e.target.value) {
        isMatchedCategory = true;
        newFoodMenu.columnOrder = [...newFoodMenu.columnOrder, columnId];
        newFoodMenu.columns = {
          ...newFoodMenu.columns,
          [columnId]: food_menu.columns[columnId],
        };
        food_menu.columns[columnId].taskIds.forEach((id) => {
          newFoodMenu.food_details[id] = food_menu.food_details[id];
        });
      }
    });
    if (isMatchedCategory) {
      setFoods(newFoodMenu);
      return;
    }

    // Matched food
    food_menu.columnOrder.forEach((columnId) => {
      Object.values(food_menu.food_details).forEach((food) => {
        if (
          food_menu.columns[columnId].taskIds.includes(food.id) &&
          food.name === e.target.value
        ) {
          isMatchedFood = true;
          newFoodMenu.columnOrder = [...newFoodMenu.columnOrder, columnId];
          newFoodMenu.columns = {
            ...newFoodMenu.columns,
            [columnId]: food_menu.columns[columnId],
          };
          newFoodMenu.food_details = {
            ...newFoodMenu.food_details,
            [food.id]: food,
          };
        }
      });
    });
    if (isMatchedFood) {
      setFoods(newFoodMenu);
      return;
    }

    // Not matched both category and food
    newFoodMenu.columnOrder = food_menu.columnOrder;
    food_menu.columnOrder.forEach((columnId, index) => {
      const column = food_menu.columns[columnId];
      if (column) {
        const taskIds = Object.values(food_menu.food_details)
          .filter(
            (food) =>
              column.taskIds.includes(food.id) &&
              food.name.includes(e.target.value)
          )
          .map((task) => task.id);

        const newFoodDetails = {};
        const newColumns = {};

        newColumns[columnId] = { ...column, taskIds };
        taskIds.forEach((id) => {
          newFoodDetails[id] = food_menu.food_details[id];
        });

        newFoodMenu.columnOrder = !taskIds.length
          ? newFoodMenu.columnOrder.filter((item) => item !== columnId)
          : newFoodMenu.columnOrder;
        newFoodMenu.columns = {
          ...newFoodMenu.columns,
          ...newColumns,
        };
        newFoodMenu.food_details = {
          ...newFoodMenu.food_details,
          ...newFoodDetails,
        };
      }
    });
    setFoods(newFoodMenu);
  };

  React.useEffect(() => {
    setFoods(food_menu);
  }, [food_menu]);

  return (
    <>
      {' '}
      <div style={{ width: '100%' }}>
        <Grid container>
          <Grid xs={12} sm={6}>
            <div>
              <SearchInput handleUpdateFilterWords={handleUpdateFilterWords} />
            </div>
          </Grid>

          <Grid xs={12} sm={6} className={classes.button_group}>
            <div style={{ marginRight: '0.5rem' }}>
              <AddCategoryButton
                onClick={() => {
                  setOpenCategoryDialog(true);
                }}
              />
            </div>

            <div>
              <AddFoodItemButton
                onClick={() => {
                  let last_one_id = food_menu.columnOrder[0];
                  setPreSelectCategory(
                    categories_available.filter((x) => x.id === last_one_id)[0]
                  );

                  setOpenAddFoodDetailDialog(true);
                }}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      {/* erratic records */}
      {isEqual(foods.columnOrder, []) ||
      isEqual(foods.food_details === {}) ||
      isEqual(foods.columns === {}) ? (
        <Grid
          container
          align="center"
          justify="center"
          alignItems="center"
          mt="3em"
        >
          <Grid item xs={4} style={{ textAlign: 'center' }}>
            <Box>{active_lang.SORRY_BUT_THE_FOOD_MENU_LIST_IS_EMPTY}</Box>
            <ShowDebug>SORRY_BUT_THE_FOOD_MENU_LIST_IS_EMPTY</ShowDebug>
          </Grid>
        </Grid>
      ) : (
        <>
          <div
            style={{
              width: '100%',
              paddingTop: '1rem',
              // paddingLeft: '3rem',
              // paddingRight: '3rem',
              // paddingBottom: '5rem',
            }}
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable
                droppableId="all-columns"
                // direction="horizontal"
                type="column"
              >
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {foods.columnOrder.map((columnId, index) => {
                      const column = foods.columns[columnId];
                      if (isUndefined(column)) {
                        return <></>;
                      } else {
                        const tasks = column.taskIds.map(
                          (taskId) => foods.food_details[taskId]
                        );
                        return (
                          <>
                            <Column
                              key={column.id}
                              column={column}
                              tasks={tasks}
                              index={index}
                              search={search}
                            />
                          </>
                        );
                      }
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </>
      )}
    </>
  );
}

function ShowLoading() {
  const { t } = useTranslation();

  return (
    <Grid
      container
      justify="center"
      style={{ color: LYNKED_DEEP_GREY, textAlign: 'center' }}
    >
      <Grid item xs={4}>
        {t('MENU_LOADING')}
      </Grid>
    </Grid>
  );
}

function Menubody() {
  let { rest_id } = React.useContext(GlobalContext);
  let { food_menu, setFoodMenu, loadFoodDetail, init_food_menu } =
    React.useContext(ShareContext);
  // let [food_menu, setFoodMenu] = React.useState(initial_data);

  React.useEffect(() => {
    if (rest_id != null) {
      loadFoodDetail(rest_id);
    }
  }, [rest_id]);

  return (
    <>
      {food_menu === init_food_menu ? (
        <ShowLoading />
      ) : (
        <>
          <ShowMenubody
            food_menu={food_menu}
            setFoodMenu={setFoodMenu}
            rest_id={rest_id}
          />

          {/* <ShowDebug>{JSON.stringify(food_menu, null, 2)}</ShowDebug> */}
        </>
      )}
    </>
  );
}

export default function App() {
  const classes = useStyles();
  return (
    <>
      <Page className={classes.root}>
        <Box style={{ width: '100%', paddingBottom: '5rem' }}>
          <ShareContextProvider>
            <Menubody />
          </ShareContextProvider>
        </Box>
      </Page>
    </>
  );
}
