// MENY-171, update to handle fullwidth to halfwidth with numbers

import { FwToHwNumeric } from './FwToHwNumeric';

let ASCII_MINUS = 45;
let ASCII_0 = 48;
let ASCII_9 = 57;
let ASCII_FW_0 = 65296;
let ASCII_FW_9 = 65305;
let ASCII_FW_MINUS = 65293;
let ASCII_JP_FW_MINUS = 12540;

const sanitizeFullWidthNumberWithPositiveAndNegative = (test_input) => {
  let output = test_input
    .split('')
    .map((original_char, idx) => {
      let char_code = original_char.charCodeAt();
      if (char_code >= ASCII_FW_0 && char_code <= ASCII_FW_9) {
        return FwToHwNumeric(original_char);
      }
      if (char_code >= ASCII_0 && char_code <= ASCII_9) {
        return original_char;
      }
      if ([ASCII_MINUS, ASCII_FW_MINUS, ASCII_JP_FW_MINUS].includes(char_code))
        return '-';
      return original_char.trim();
    })
    .join('');

  console.log(
    'sanitizeFullWidthNumberWithPositiveAndNegative',
    test_input,
    output
  );
  return output;
};

export { sanitizeFullWidthNumberWithPositiveAndNegative };
