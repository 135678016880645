import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import {
  LYNKED_BLACK,
  LYNKED_DEEP_GREY,
  LYNKED_WHITE,
} from 'src/consts/colors';

export default function AddButton() {
  return (
    <>
      <div
        style={{
          width: '40px',
          height: '40px',
          backgroundColor: LYNKED_WHITE,
          border: `3px solid ${LYNKED_DEEP_GREY}`,
          borderRadius: '20px',
          color: LYNKED_BLACK,

          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <AddIcon />
      </div>
    </>
  );
}
