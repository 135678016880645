import db from 'src/Firebase/db';
import db_config from 'src/configs/db_config';

let RESTAURANT_CONFIG_REF = db.collection(db_config.DB_TABLE_RESTAURANT_CONFIG);

function loadRestaurantConfig(rest_id) {
  return RESTAURANT_CONFIG_REF.doc(rest_id).get();
}

function subscribeRestaurantConfig(rest_id) {
  return RESTAURANT_CONFIG_REF.doc(rest_id);
}

function updateRestaurantConfig(rest_id, payload) {
  let DocRef = RESTAURANT_CONFIG_REF.doc(rest_id);

  return db.runTransaction((transaction) => {
    return transaction
      .get(DocRef)
      .then((sfDoc) => {
        transaction.set(DocRef, payload, { merge: true });
      })
      .catch((err) => {
        console.log('updateRestaurantConfig', 'error', err);
      });
  });
}

function monitorRestaurantConfig(rest_id, cb) {
  return RESTAURANT_CONFIG_REF.doc(rest_id).onSnapshot((qss) => {
    cb(qss);
  });
}

function updateRestaurantState(rest_id, restaurant_open_state) {
  // restaurant_open_state
  let DocRef = RESTAURANT_CONFIG_REF.doc(rest_id);

  return db.runTransaction((transaction) => {
    return transaction
      .get(DocRef)
      .then((sfDoc) => {
        transaction.set(DocRef, { restaurant_open_state }, { merge: true });
      })
      .catch((err) => {
        console.log('error', err);
      });
  });
}

export {
  loadRestaurantConfig,
  updateRestaurantConfig,
  updateRestaurantState,
  monitorRestaurantConfig,
  subscribeRestaurantConfig,
};
