import React from 'react';
import { useNavigate } from 'react-router-dom';
import Schedule from 'react-schedule-job';
import { isNull, isUndefined } from 'lodash-es';

import { AuthContext } from 'src/Firebase/context';
import app from 'src/Firebase/config';

import { GlobalContext } from 'src/contexts/Global';

import useMutateRestaurantConfigByRestId from 'src/hooks/useMutateRestaurantConfigByRestId';
import useMutateCheckRestInfo from 'src/hooks/useMutateCheckRestInfo';

import ShowAppLayout from 'src/layouts/AppLayout/ShowAppLayout';

import CheckLogin from 'src/components/CheckLogin';
import ShowDebug from 'src/components/ShowDebug';
import Contexts from './contexts';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const navigate = useNavigate();

  let { user } = React.useContext(AuthContext);
  const [show_content, setShowContent] = React.useState(false);
  let { rest_id, resetApplicationState, setRestaurantConfig } =
    React.useContext(GlobalContext);

  let [jobs, setJobs] = React.useState(null);

  let use_mutate_restaurant_config_by_rest_id =
    useMutateRestaurantConfigByRestId();

  let use_mutate_check_rest_info = useMutateCheckRestInfo();

  React.useEffect(() => {
    if (rest_id) {
      setJobs([
        {
          fn: () => {
            use_mutate_restaurant_config_by_rest_id
              .mutateAsync({ rest_id })
              .then((res_json) => {
                console.log('sch_check_activation', 'res_json', res_json);
                if (res_json) {
                  if (
                    isUndefined(res_json.restaurant_active) ||
                    isNull(res_json.restaurant_active)
                  ) {
                    console.log('sch_check_activation', 'default active');
                  } else {
                    if (res_json.restaurant_active === true) {
                      console.log('sch_check_activation', 'restaurant active');
                    } else {
                      console.log('sch_check_activation', 'not active');
                      app
                        .auth()
                        .signOut()
                        .then(() => resetApplicationState())
                        .then(() => navigate('/login'));
                    }
                  }
                }
              })
              .catch((err) => {
                console.error('sch_check_activation', 'err', err);
              });
          },
          id: '1',
          schedule: '0,5,10,15,20 6 * * *',
          // schedule: '0/1 * * * *',
        },
      ]);
    } else {
      setJobs(null);
    }
  }, [rest_id]);

  React.useEffect(() => {
    async function helloworld(user) {
      let res_json = await use_mutate_check_rest_info
        .mutateAsync(user.uid)
        .then((res_json) => {
          return res_json;
        });

      console.log('AppLayout', 'res_json', res_json);
      if (res_json.restaurant_id === 'no restaurant')
        navigate('/logout', { replace: true });

      let rest_config = await use_mutate_restaurant_config_by_rest_id
        .mutateAsync({ rest_id: res_json.restaurant_id })
        .then((res_json) => res_json);
      console.log('AppLayout', 'rest_config', rest_config);

      if (isUndefined(res_json.restaurant_active)) {
        console.log('AppLayout', 'point 1');
        // setRestaurantConfig(rest_config);
        setShowContent(true);
      } else {
        if (res_json.restaurant_active) {
          console.log('AppLayout', 'point 2');
          console.log('AppLayout', 'res_json', res_json);
          // setRestaurantConfig(rest_config);
          setShowContent(true);
        } else {
          console.log('AppLayout', 'point 3');
          navigate('/logout', { replace: true });
        }
      }
    }

    if (user) {
      helloworld(user);
    }
  }, [user]);

  if (!show_content) return <CheckLogin />;

  return (
    <>
      {jobs ? (
        <Schedule jobs={jobs} timeZone="local" dashboard={{ hidden: true }} />
      ) : (
        <ShowDebug>job list empty</ShowDebug>
      )}
      <Contexts>
        <ShowAppLayout />
      </Contexts>
    </>
  );
};
