import React from 'react';

import { useSnackbar } from 'notistack';

import { Box, Button, Dialog } from '@material-ui/core';

import devices_configs from 'src/configs/devices';
import { GlobalContext } from 'src/contexts/Global';

import './DeleteEntryDialog_style.css';
import { Transition, useStyles } from './styles';

import { dialog_transition_duration } from 'src/consts/TRANSITION';
import { deleteEntry } from 'src/modals/lineup';
import { useTranslation } from 'react-i18next';

export default function ShowDeleteEntryDialog({
  open,
  setOpen,
  assign_waiting_entry,
}) {
  const classes = useStyles();
  const { rest_id } = React.useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleClose = () => {
    devices_configs.forceScreenScrollUp();
    document.activeElement.blur();
    setOpen(false);
  };

  const handleDeleteEntry = () => {
    deleteEntry(rest_id, assign_waiting_entry.id)
      .then(() => {
        enqueueSnackbar('受付リストから削除されました', {
          variant: 'success',
        });
      })
      .catch((error) => {
        console.error('Error removing document: ', error);
      });
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        transitionDuration={dialog_transition_duration}
        keepMounted
        onClose={() => {
          handleClose();
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Box className={classes.ConfirmDeleteDialogWrapper}>
          <Box className={classes.DialogTitle}>
            <Box>{t('RECEPTION_DELETE_CONFIRM')}</Box>
          </Box>
          <Box className={classes.QuestionsWrapper}>
            <Box>{t('RECEPTION_CONFIRM_DELETE_MSG')}</Box>
          </Box>

          <Box className={classes.DialogBottom}>
            <Button
              className={`${classes.Buttons} ${classes.ButtonCancel}`}
              onClick={() => handleClose()}
            >
              {t('COMMON_CANCEL')}
            </Button>
            <Button
              className={`${classes.Buttons} ${classes.ButtonOK}`}
              onClick={handleDeleteEntry}
            >
              {t('COMMON_CONFIRM')}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
