import React from 'react';

import { UtilContext } from '../Util';

export const DeviceInfoContext = React.createContext();

export function DeviceInfo() {
  let {
    setDeviceScreen,
    SCREEN_DESKTOP,
    screen_height,
    SCREEN_IPAD_PORTRAIT,
    SCREEN_IPAD_PRO_PORTRAIT,
    screen_width,
    setIosDevice,
    setScreenHeight,
    setScreenWidth,
  } = React.useContext(UtilContext);

  const updateScreenSize = () => {
    setScreenHeight(document.documentElement.clientHeight);
    setScreenWidth(document.documentElement.clientWidth);
  };

  React.useEffect(() => {
    let is_a_ios_device =
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
        'MacIntel',
      ].includes(navigator.platform) ||
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

    if (is_a_ios_device) {
      setIosDevice(true);
      if (screen_width <= 768) {
        if (screen_height >= 954) {
          setDeviceScreen(SCREEN_IPAD_PORTRAIT);
        }
      } else if (screen_width <= 1366) {
        if (screen_height >= 1024) {
          setDeviceScreen(SCREEN_IPAD_PRO_PORTRAIT);
        }
      }
    } else {
      if (screen_width <= 768) {
        if (screen_height >= 954) {
          setDeviceScreen(SCREEN_IPAD_PORTRAIT);
        }
      } else if (screen_width <= 1366) {
        if (screen_height >= 1024) {
          setDeviceScreen(SCREEN_IPAD_PRO_PORTRAIT);
        }
      } else {
        setDeviceScreen(SCREEN_DESKTOP);
      }
    }
  }, [screen_width, screen_height]);

  React.useEffect(() => {
    updateScreenSize();
    window.addEventListener('resize', () =>
      setTimeout(updateScreenSize(), 1000)
    );
    return () => {
      window.removeEventListener('resize', updateScreenSize);
    };
  }, []);

  return (
    <>
      <DeviceInfoContext.Provider></DeviceInfoContext.Provider>
    </>
  );
}
