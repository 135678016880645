import { makeStyles } from '@material-ui/core';

import {
  LYNKED_RED,
  LYNKED_WHITE,
  LYNKED_BACKGROUND_LIGHT_GREY,
} from 'src/consts/colors';

const useStyles = makeStyles(() => ({
  root: { width: '100%', padding: '3rem 0' },
  DashboardContainer: { height: '100vh' },
  Topbar: {
    backgroundColor: LYNKED_RED,
    minHeight: '7vh',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  Navbar: {
    width: '16%',
    minHeight: '93vh',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
  },
  TopNavBody: { width: '100%' },
  NavbarTopPart: { backgroundColor: 'white' },
  NavbarBottomPart: {},
  NavBody: { backgroundColor: LYNKED_WHITE, width: '84%' },
  DashboardBody: { display: 'flex', flexFlow: 'row' },
  ProfilePic: { minHeight: '22vh', backgroundColor: 'cyan' },
  NavBodyHead: {
    minHeight: '22vh',
    backgroundColor: LYNKED_BACKGROUND_LIGHT_GREY,
  },
  NavBodyMainRight: {
    backgroundColor: LYNKED_BACKGROUND_LIGHT_GREY,
    height: '71vh',
  },
  blurContent: {
    display: 'block',
    filter: 'blur(15px)',
    opacity: '0.95',
  },
}));

export { useStyles };
