import React from 'react';

import Slide from '@material-ui/core/Slide';


import { makeStyles } from '@material-ui/core';

import {
  LYNKED_RED,
  LYNKED_RED_HOVER,
  LYNKED_WHITE,
  LYNKED_DEEP_GREY,
  LYNKED_DEEP_GREY_HOVER,
} from 'src/consts/colors';


const useStyles = makeStyles((_theme) => ({
  DialogBottom: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',

    width: '100%',
    justifyContent: 'space-between',
  },
  ConfirmDeleteDialogWrapper: {
    margin: '30px',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  DialogTitle: {
    paddingBottom: '2rem',
  },
  QuestionsWrapper: {
    textAlign: 'center',
    paddingBottom: '3rem',
  },
  ButtonCancel: {
    width: '48%',
    backgroundColor: LYNKED_DEEP_GREY,
    '&:hover': {
      backgroundColor: LYNKED_DEEP_GREY_HOVER,
    },
    color: LYNKED_WHITE,
    borderRadius: '1px',
  },
  ButtonAlertOK: {
    width: '48%',
    backgroundColor: LYNKED_RED,
    '&:hover': {
      backgroundColor: LYNKED_RED_HOVER,
    },
    color: LYNKED_WHITE,
    borderRadius: '1px',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export { useStyles, Transition };
