import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Box } from '@material-ui/core';

import { AuthContext } from 'src/Firebase/context';
import { GlobalContext } from 'src/contexts/Global';

import { useStyles } from './style';

export default function ProfilePic() {
  const classes = useStyles();
  const { restaurant_profile_pic, user_email, restaurant_name } =
    React.useContext(GlobalContext);

  const { user, getUserUid } = React.useContext(AuthContext);

  React.useEffect(() => {
    console.log('ProfilePic.js', 'getUserUid', getUserUid());
    console.log('ProfilePic.js', 'user', user);
  }, [user]);

  return (
    <>
      <Box
        className={classes.ProfilePicCell}
        style={{ height: '180px', padding: '1rem' }}
      >
        <Box
          style={{
            height: '100%',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            style={{
              width: '100%',
              height: '100%',
              maxHeight: '66%',
            }}
          >
            <Box
              id="restaurant-profile-pic"
              className={classes.ProfilePic}
              style={{
                width: '100%',
                height: '100%',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundImage: `url("${restaurant_profile_pic}")`,
              }}
            ></Box>
          </Box>

          <Box>
            <Box className={classes.ProfileRestaurantName}>
              {restaurant_name || (
                <Skeleton count={1} width={'50%'} height={'16px'} />
              )}
            </Box>
            <Box className={classes.ProfileEmail}>
              {user_email || (
                <Skeleton count={1} width={'50%'} height={'16px'} />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
