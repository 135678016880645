import React from 'react';
import { QRCode } from 'react-qr-svg';

import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Typography } from '@material-ui/core';

import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export default function GetQR({ link, text, id_of_qr }) {
  // let random_idx = getRandomInt(HTML_COLOR_NAMES_LENGTH);
  // let random_color = HTML_COLOR_NAMES[random_idx];
  // let random_color_name = random_color[0];
  // let random_color_value = random_color[1];
  let id_text = id_of_qr.replace(' ', '-');
  const classes = useStyles();

  const downloadQR = () => {
    const svg = document.getElementById(id_text);

    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = function () {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL('image/png');

      const downloadLink = document.createElement('a');
      downloadLink.download = id_text;
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };

    console.log('svgData', svgData);

    img.src = 'data:image/svg+xml;base64,' + btoa(svgData);
  };

  return (
    <>
      <div style_del={{ marginTop: '5rem', marginBottom: '5rem' }}>
        <div style_del={{ marginBottom: '1rem' }}></div>
      </div>

      <div
        style_del={{
          marginTop: '5rem',
          marginBottom: '5rem',
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div></div>
        <div>
          <div
            style_del={{
              border: '1px',
              borderStyle: 'dashed',
              padding: '4rem',
            }}
          >
            <div>
              <a href={link} target="_blank">
                <Typography
                  variant="h4"
                  color="textPrimary"
                  style={{
                    fontSize: '14px',
                    fontWeight: '700',
                    marginBottom: '20px',
                    marginTop: '20px',
                  }}
                >
                  {text}
                </Typography>
              </a>
            </div>

            <div>
              <QRCode
                id={id_text}
                bgColor="#FFFFFF"
                fgColor={'#000000'}
                level="Q"
                style={{ width: '100%' }}
                value={link}
              />
            </div>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <IconButton
                id={`dl_button_${id_of_qr}`}
                onClick={() => downloadQR()}
              >
                <GetAppIcon />
              </IconButton>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </>
  );
}
