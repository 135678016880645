import { isUndefined } from 'lodash';

function lookupFoodNameByFoodDetailId(food_details, food_detail_id) {
  if (['order_list', 'food_detail'].includes(food_detail_id)) {
    return food_detail_id;
  } else {
    if (
      isUndefined(food_details) ||
      isUndefined(food_details.food_details[food_detail_id])
    ) {
      return undefined;
    }
  }
  return food_details.food_details[food_detail_id].name;
}

export default lookupFoodNameByFoodDetailId;
