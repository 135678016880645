import React from 'react';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';

import NavButton from 'src/components/NavButton';

import active_lang from 'src/langs/jp_en';
import { StyledBadge } from 'src/components/RestaurantNavButtonColumn/NavButtons/styles';
import { MonitorOrderContext } from 'src/contexts/MonitorOrder';
import { useTranslation } from 'react-i18next';

let LOG_PREFIX = 'NavmenuBillingManagement';

export default function NavMenuBillingManagement({ handleCloseNavBar }) {
  const [number_active_bill, setNumberActiveBill] = React.useState(null);
  const { t } = useTranslation();

  const { show_order_list, flattened_order_list } =
    React.useContext(MonitorOrderContext);

  React.useEffect(() => {
    console.log(LOG_PREFIX, 'show_order_list', show_order_list);
    if (show_order_list && flattened_order_list) {
      setNumberActiveBill(show_order_list.orders.length);
    } else {
      setNumberActiveBill(null);
    }
  }, [show_order_list]);

  return (
    <>
      <NavButton
        text={t('SIDEMENU_ACCOUNT_MANAGE')}
        href={`/app/billing_management`}
        icon={
          <StyledBadge badgeContent={number_active_bill}>
            <AttachMoneyOutlinedIcon />
          </StyledBadge>
        }
        id="nav-billing-management"
        handleCloseNavBar={handleCloseNavBar}
      />
    </>
  );
}
