let DB_TABLE_ORDER_LIST = 'order';
let DB_TABLE_LINEUP = 'meny_manage_lineup';
let DB_TABLE_FOOD_DETAILS = 'food_details';
let DB_TABLE_USERS = 'users';

let DB_TABLE_ALL_FOOD_DETAILS = 'meny_manage_all_food_details';
let DB_TABLE_ALL_ORDERS = 'meny_manage_all_orders';
let DB_TABLE_ALL_FOOD_MENU = 'meny_manage_all_food_details';
let DB_TABLE_ALL_SERVICE_REQUESTS = 'meny_manage_all_service_requests';
let DB_TABLE_ADVERTISEMENT_CONFIG = 'meny_manage_advertisement_config';
let DB_TABLE_ADVERTISEMENT_INFO = 'meny_manage_advertisement_info';
let DB_TABLE_RESTAURANT_CONFIG = 'meny_manage_restaurant_config';
let DB_TABLE_USAGE_STATISTICS = 'meny_manage_usage_statistics';

let DB_TABLE_USER_SETTINGS = 'meny_manage_user_settings';

export default {
  DB_TABLE_ADVERTISEMENT_CONFIG,
  DB_TABLE_ADVERTISEMENT_INFO,
  DB_TABLE_ALL_FOOD_DETAILS,
  DB_TABLE_ALL_FOOD_MENU,
  DB_TABLE_ALL_ORDERS,
  DB_TABLE_ALL_SERVICE_REQUESTS,
  DB_TABLE_FOOD_DETAILS,
  DB_TABLE_LINEUP,
  DB_TABLE_ORDER_LIST,
  DB_TABLE_RESTAURANT_CONFIG,
  DB_TABLE_USER_SETTINGS,
  DB_TABLE_USERS,
  DB_TABLE_USAGE_STATISTICS,
};
