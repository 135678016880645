// MENY-171, update to handle fullwidth to halfwidth with numbers

let ASCII_MINUS = 45;
let ASCII_0 = 48;
let ASCII_9 = 57;
let ASCII_FW_0 = 65296;
let ASCII_FW_9 = 65305;

const FwToHwNumeric = (fw_number_in) => {
  let char_code = fw_number_in.charCodeAt();
  return String.fromCharCode(char_code - ASCII_FW_0 + ASCII_0);
};

export { FwToHwNumeric };
