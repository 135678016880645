import React from 'react';
import { useSnackbar } from 'notistack';

import { NetworkStatusContext } from 'src/contexts/NetworkStatus';

export const MonitorNetworkStatusContext = React.createContext();

export const MonitorNetworkStatusContextProvider = ({ children }) => {
  const { online } = React.useContext(NetworkStatusContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  let [snackbar_key, setSnackbarKey] = React.useState(null);

  const helloDisconnect = () => {
    enqueueSnackbar('No connection!', {
      variant: 'error',
      persist: true,
    });
  };

  React.useEffect(() => {
    if (online) {
      if (snackbar_key != null) {
        closeSnackbar(snackbar_key);
        setSnackbarKey(null);
      }
    } else {
      setSnackbarKey(
        enqueueSnackbar('No connection!', {
          variant: 'error',
          persist: true,
        })
      );
    }
  }, [online]);
  return (
    <>
      {/* <button onClick={helloCookies}>helloCookies from network status</button> */}
      {children}
    </>
  );
};
