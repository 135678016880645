import React from 'react';

import SelectFoodMenuCategory from './SelectFoodMenuCategory';
import SelectFoodDetail from './SelectFoodDetail';

import {
  AD_ACTION_JUMP_TO_CATEGORY,
  AD_ACTION_JUMP_TO_FOOD_DETAIL,
} from 'src/consts/AD_ACTION';

export default function SelectAdDestination({
  service_class,
  formik,
  food_details,
}) {
  const SelectAdDestinationContent = () => {
    switch (formik.values.form_ad_action) {
      case AD_ACTION_JUMP_TO_CATEGORY:
        return (
          <SelectFoodMenuCategory
            service_class={service_class}
            formik={formik}
            food_details={food_details}
          />
        );
      case AD_ACTION_JUMP_TO_FOOD_DETAIL:
        return (
          <SelectFoodDetail
            service_class={service_class}
            formik={formik}
            food_details={food_details}
          />
        );
      default:
        return <></>;
    }
    return <></>;
  };

  return (
    <>
      {/* <pre>{JSON.stringify(formik.values.form_ad_action, null, 2)}</pre> */}
      <SelectAdDestinationContent />
    </>
  );
}
