import React, { useContext, useState } from 'react';
import clsx from 'clsx';

import firebase from 'firebase/app';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { isNumber } from 'lodash';

import WarningIcon from '@material-ui/icons/Warning';
import {
  Box,
  Button,
  Input,
  Dialog,
  Typography,
  IconButton,
  TextField,
} from '@material-ui/core';

import active_lang from 'src/langs/jp_en';
import devices_configs from 'src/configs/devices';
import { GlobalContext } from 'src/contexts/Global';
import { assignTableByDocId, updateTableInformation } from 'src/modals/lineup';
import createEmptybill from 'src/helpers/createEmptyBill';
import { LYNKED_BLUE, LYNKED_RED } from 'src/consts/colors';
import ShowDebug from 'src/components/ShowDebug';
import { sanitizeFullWidthNumber } from 'src/utils/sanitizeFullWidthNumber';

import { REGEX_FW_HW_NUMBER_WITH_SPACE } from './regex_consts';
import { useStyles, Transition } from './styles';
import './EditTableInformationDialog_style.css';

import { SEAT_ASSIGNED } from 'src/modals/lineup';
import { MonitorRestaurantConfigContext } from 'src/contexts/MonitorRestaurantConfig';
import { dialog_transition_duration } from 'src/consts/TRANSITION';
import { RestaurantActiveContext } from 'src/contexts/RestaurantActiveContext';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { useEffect } from 'react';
import { getOrdersByOrderId, updateOrderStatus } from 'src/modals/orders';
import { updateServiceRequestTableInfo } from 'src/modals/service_requests';
import { useTranslation } from 'react-i18next';

export default function ShowEditTableInformationDialog({
  open,
  setOpen,
  assign_waiting_entry,
}) {
  const initial_value = {
    'lineup-username': assign_waiting_entry
      ? assign_waiting_entry?.data['lineup-username']
      : '',
    'lineup-note': assign_waiting_entry
      ? assign_waiting_entry?.data['lineup-note']
      : '',
    assign_table_name: assign_waiting_entry
      ? assign_waiting_entry?.data['assign_table_name']
      : '',
  };
  const classes = useStyles();
  const { rest_id } = React.useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [numOfAdult, setNumOfAdult] = useState(() =>
    assign_waiting_entry?.data ? assign_waiting_entry?.data['num-of-adult'] : 0
  );
  const [numOfChild, setNumOfChild] = useState(() =>
    assign_waiting_entry?.data ? assign_waiting_entry?.data['num-of-child'] : 0
  );

  useEffect(() => {
    if (assign_waiting_entry) {
      formik.setFieldValue(
        'lineup-username',
        assign_waiting_entry.data['lineup-username']
      );
      formik.setFieldValue(
        'lineup-note',
        assign_waiting_entry.data['lineup-note']
      );
      formik.setFieldValue(
        'assign_table_name',
        assign_waiting_entry.data['assign_table_name'] || ''
      );
      setNumOfAdult(assign_waiting_entry.data['num-of-adult']);
      setNumOfChild(assign_waiting_entry.data['num-of-child']);
    }
  }, [assign_waiting_entry]);

  const formik = useFormik({
    initialValues: initial_value,
    validationSchema: Yup.object({
      'lineup-username': Yup.string().test(
        'requried',
        t('RECEPTION_REQUIRED_FIELD'),
        (val) => {
          if (assign_waiting_entry?.data) {
            return Boolean(val);
          }
          return true;
        }
      ),
      assign_table_name: Yup.string().test(
        'requried',
        t('RECEPTION_REQUIRED_FIELD'),
        (val) => {
          if (
            assign_waiting_entry?.data &&
            assign_waiting_entry?.data?.status === SEAT_ASSIGNED
          ) {
            return Boolean(val);
          }
          return true;
        }
      ),
    }),
    onSubmit: async (values) => {
      let saintized_table_number = sanitizeFullWidthNumber(
        values['lineup-username']
      ).trim();
      if (saintized_table_number !== '') {
        return updateTableInformation(rest_id, assign_waiting_entry.id, {
          ...assign_waiting_entry.data,
          ...values,
          'num-of-adult': numOfAdult,
          'num-of-child': numOfChild,
        })
          .then(() => {
            const orderId = assign_waiting_entry.data.new_order_id;
            if (orderId) {
              getOrdersByOrderId(rest_id, orderId).then((doc) => {
                const payload = {
                  ...doc.data(),
                  customer_info: {
                    number_of_adult: numOfAdult,
                    number_of_child: numOfChild,
                  },
                  order_table_number: values['assign_table_name'],
                  order_username: values['lineup-username'],
                };

                updateOrderStatus(rest_id, orderId, payload);
              });
            }
          })
          .then(() => {
            const newOrderId = assign_waiting_entry.data.new_order_id;
            if (newOrderId) {
              updateServiceRequestTableInfo(rest_id, newOrderId, {
                ...assign_waiting_entry.data,
                ...values,
                'num-of-adult': numOfAdult,
                'num-of-child': numOfChild,
              });
            }
          })
          .then(() => {
            enqueueSnackbar(t('COMMON_UPDATED_COMPLETE'), {
              variant: 'success',
            });
            devices_configs.forceScreenScrollUp();
            document.activeElement.blur();
            setOpen(false);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
  });

  const handleClose = () => {
    devices_configs.forceScreenScrollUp();
    document.activeElement.blur();
    formik.setFieldValue(
      'lineup-username',
      assign_waiting_entry.data['lineup-username']
    );
    formik.setFieldValue(
      'lineup-note',
      assign_waiting_entry.data['lineup-note']
    );
    formik.setFieldValue(
      'assign_table_name',
      assign_waiting_entry.data['assign_table_name'] || ''
    );
    setNumOfAdult(assign_waiting_entry.data['num-of-adult']);
    setNumOfChild(assign_waiting_entry.data['num-of-child']);
    setOpen(false);
  };

  const handleTableNumberInputOnBlur = (e) => {
    if (e.target.value !== '') {
      let temp = sanitizeFullWidthNumber(e.target.value).trim();
      if (isNumber(temp)) {
        e.target.value = temp;
      }
    }
    formik.handleChange(e);
  };

  const handleDecreaseAdult = () => {
    setNumOfAdult((prev) => {
      prev--;
      return Math.max(prev, 0);
    });
  };
  const handleIncreaseAdult = () => {
    setNumOfAdult((prev) => {
      prev++;
      return prev;
    });
  };
  const handleDecreaseChild = () => {
    setNumOfChild((prev) => {
      prev--;
      return Math.max(prev, 0);
    });
  };
  const handleIncreaseChild = () => {
    setNumOfChild((prev) => {
      prev++;
      return prev;
    });
  };

  return (
    <>
      <form
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            formik.handleSubmit();
          }
        }}
      >
        <Dialog
          open={open}
          TransitionComponent={Transition}
          transitionDuration={dialog_transition_duration}
          keepMounted
          onClose={() => {
            handleClose();
          }}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <Box className={classes.AssignedTAbleDialogWrapper}>
            <Box
              className={classes.statusIndicator}
              sx={{
                backgroundColor:
                  assign_waiting_entry?.data?.status === SEAT_ASSIGNED
                    ? LYNKED_BLUE
                    : LYNKED_RED,
              }}
            ></Box>
            <Box className={classes.CardContentWrapper}>
              <Box className={classes.CardContent}>
                {assign_waiting_entry?.data?.status === SEAT_ASSIGNED && (
                  <Box
                    className={classes.CustomerTable}
                    mb={
                      formik.errors['assign_table_name'] &&
                      !formik.values['assign_table_name']
                        ? '50px'
                        : 0
                    }
                  >
                    <Box sx={{ fontWeight: 'bold', width: '80px' }}>
                      {t('COMMON_TABLE')}
                    </Box>

                    <Box className={classes.CustomerTableNumbernput}>
                      <Input
                        className={classes.TextInput}
                        value={formik.values['assign_table_name']}
                        fullWidth
                        disableUnderline={true}
                        inputProps={{ style: { padding: '1rem' } }}
                        {...formik.getFieldProps('assign_table_name')}
                        placeholder={t('RECEPTION_ROOM_NUMBER')}
                        onBlur={(e) => handleTableNumberInputOnBlur(e)}
                      />
                      {!formik.values['assign_table_name'] &&
                      formik.errors['assign_table_name'] ? (
                        <Box
                          style={{
                            color: LYNKED_RED,
                            display: 'flex',
                            flexFlow: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            position: 'absolute',
                            top: '100%',
                            left: 0,
                            width: '280px',
                            margin: '6px 0',
                          }}
                        >
                          <Box pr="1rem">
                            <WarningIcon fontSize="small" />
                          </Box>
                          <Box>{formik.errors['assign_table_name']}</Box>
                        </Box>
                      ) : null}
                    </Box>
                  </Box>
                )}

                <Box
                  className={classes.CustomerName}
                  mb={
                    formik.errors['lineup-username'] &&
                    !formik.values['lineup-username']
                      ? '50px'
                      : 0
                  }
                >
                  <Box sx={{ fontWeight: 'bold', width: '80px' }}>{t('COMMON_NAME')}</Box>

                  <Box className={classes.CustomerNameInput}>
                    <Input
                      className={classes.TextInput}
                      id="lineup-username"
                      value={formik.values['lineup-username']}
                      fullWidth
                      disableUnderline={true}
                      inputProps={{ style: { padding: '1rem' } }}
                      {...formik.getFieldProps('lineup-username')}
                      placeholder={t('RECEPTION_ERROR_NAME')}
                      onBlur={(e) => handleTableNumberInputOnBlur(e)}
                    />
                    {!formik.values['lineup-username'] &&
                    formik.errors['lineup-username'] ? (
                      <Box
                        style={{
                          color: LYNKED_RED,
                          display: 'flex',
                          flexFlow: 'row',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          position: 'absolute',
                          top: '100%',
                          left: 0,
                          width: '280px',
                          margin: '6px 0',
                        }}
                      >
                        <Box pr="1rem">
                          <WarningIcon fontSize="small" />
                        </Box>
                        <Box>{formik.errors['lineup-username']}</Box>
                      </Box>
                    ) : null}
                  </Box>
                </Box>

                <Box className={classes.NoOfGuest}>
                  <Box sx={{ fontWeight: 'bold', width: '80px' }}>{t('RECEPTION_NUMBER_PEOPLE')}</Box>

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '14px',
                    }}
                  >
                    <Box className={classes.NumberGuestWrapper}>
                      <Typography
                        component="span"
                        className={classes.NumberGuestText}
                      >
                        {t('RECEPTION_ADULT')}
                      </Typography>
                      <Box className={classes.NumberGuestGroup}>
                        <IconButton
                          className={classes.IconButton}
                          onClick={handleDecreaseAdult}
                        >
                          <RemoveIcon />
                        </IconButton>
                        <Typography
                          component="span"
                          className={classes.NumberGestQuantity}
                        >
                          {numOfAdult}
                        </Typography>
                        <IconButton
                          className={classes.IconButton}
                          onClick={handleIncreaseAdult}
                        >
                          <AddIcon />
                        </IconButton>
                      </Box>
                    </Box>
                    <Box className={classes.NumberGuestWrapper}>
                      <Typography
                        component="span"
                        className={classes.NumberGuestText}
                      >
                        {t('RECEPTION_CHILD')}
                      </Typography>
                      <Box className={classes.NumberGuestGroup}>
                        <IconButton
                          className={classes.IconButton}
                          onClick={handleDecreaseChild}
                        >
                          <RemoveIcon />
                        </IconButton>
                        <Typography
                          component="span"
                          className={classes.NumberGestQuantity}
                        >
                          {numOfChild}
                        </Typography>
                        <IconButton
                          className={classes.IconButton}
                          onClick={handleIncreaseChild}
                        >
                          <AddIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box className={classes.Note}>
                  <Box
                    sx={{
                      fontWeight: 'bold',
                      width: '60px',
                      marginBottom: '10px',
                    }}
                  >
                    {t('COMMON_REMARKS')}
                  </Box>
                  <Input
                    className={classes.TextareaInput}
                    fullWidth
                    disableUnderline={true}
                    inputProps={{ style: { padding: '1rem' } }}
                    placeholder={t('RECEPTION_REQUESTS_HINT')}
                    multiline
                    name="lineup-note"
                    onChange={formik.handleChange}
                    value={formik.values['lineup-note']}
                    rows={4}
                  />
                </Box>

                <Box className={classes.DialogBottom}>
                  <Box>
                    <Button
                      onClick={() => {
                        formik.resetForm();
                        handleClose();
                      }}
                      className={clsx(classes.Buttons, classes.ButtonCancel)}
                      type="button"
                    >
                      {t('COMMON_CANCEL')}
                    </Button>
                  </Box>

                  <Box>
                    <Button
                      id="assign-table-number-ok"
                      type="submit"
                      onClick={formik.handleSubmit}
                      className={clsx(classes.Buttons, classes.ButtonOK)}
                    >
                      {t('COMMON_CONFIRM')}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Dialog>
      </form>
    </>
  );
}
