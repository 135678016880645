import React from 'react';
import { useNavigate } from 'react-router-dom';

import app from 'src/Firebase/config';
import { GlobalContext } from 'src/contexts/Global';

import useStyles from './style';

const seconds_to_redirect = 3;

export default function LogoutPage() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { resetApplicationState } = React.useContext(GlobalContext);

  React.useEffect(() => {
    app
      .auth()
      .signOut()
      .then(() => {
        resetApplicationState();
      })
      .then(() => navigate('/login'));
  }, []);
  return (
    <>
      <div className={classes.loginRoot}>
        <div>
          ログアウトが完了すると、{seconds_to_redirect}
          秒でログインページにリダイレクトされます ...
        </div>
        <a href="/login">back to login</a>
      </div>
    </>
  );
}
