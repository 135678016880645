import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import firebase from 'firebase/app';
import 'firebase/storage';

import { isNull, isUndefined } from 'lodash';
import AddNewCustomizeGroup from './AddNewCustomizeGroup';
import ShowCustomizeGroup from './ShowCustomizeGroup';
import {
  Grid,
  FormControl,
  MenuItem,
  Select,
  withStyles,
  InputBase,
} from '@material-ui/core';

import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import WarningIcon from '@material-ui/icons/Warning';

import { sanitizeFullWidthNumber } from 'src/utils/sanitizeFullWidthNumber';
import { sanitizeFullWidthNumberWithPositiveAndNegative } from 'src/utils/sanitizeFullWidthNumberWithPositiveAndNegative';

import { IconButton, Box, TextField, Button } from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import ShowFoodSelect from './ShowFoodSelect';

import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';

import {
  addFoodDetails,
  getRandomFoodId,
  addFoodIdToCategories,
} from 'src/modals/food_details';

import active_lang from 'src/langs/jp_en';

import { ENQUEUE_SUCCESS_CONFIG } from 'src/consts/ENQUEUE_CONFIG';

import { LYNKED_RED } from 'src/consts/colors';

import {
  default_new_food_detail,
  STATUS_SOLD_OUT,
  STATUS_IN_STOCK,
} from 'src/modals/food_details';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import covertDecimalPrice from 'src/helpers/convertDecimalPrice';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useStyles } from './styles';

import {
  REGEX_TEST_NUMBER_POS_NEG,
  REGEX_TEST_NUMBER_WITH_DECIMAL_POINT,
  REGEX_TEST_NUMBER_WITHOUT_MINUS,
  REGEX_TEST_NUMBER_WITHOUT_MINUS_WITH_DECIMAL_POINT,
} from './regex_consts';
import { GlobalContext } from 'src/contexts/Global';
import { useTranslation } from 'react-i18next';

var storage = firebase.storage();
var storageRef = storage.ref();

const testHaveImageNotUploaded = (obj_to_test) => {
  try {
    let obj_is_null = isNull(obj_to_test);
    let obj_is_a_image =
      obj_to_test.search('data:image') > -1 ||
      obj_to_test.search('data:application') > -1;

    return !obj_is_null && obj_is_a_image;
  } catch (error) {
    return false;
  }
};

let schemaValidateCategoryValue = Yup.object().shape({
  title: Yup.string().required(),
  id: Yup.string().required('category is missing'),
});

const FoodStatusInput = withStyles(() => ({}))(InputBase);

const testNotNullAndIsDefined = (obj_to_test) => {
  try {
    let obj_is_null = isNull(obj_to_test);
    let obj_is_undef = isUndefined(obj_to_test);

    return !obj_is_null && !obj_is_undef;
  } catch (error) {
    return false;
  }
};

const testValidCustomizeGroupSettings = (obj_to_test) => {
  return testNotNullAndIsDefined(obj_to_test);
};

const testImageUrlValid = (url_to_test) => {
  try {
    let img_url_is_null = isNull(url_to_test);
    let img_url_is_empty = url_to_test.trim() === '';

    return !(img_url_is_null || img_url_is_empty);
  } catch (error) {
    return false;
  }
};

function ImageHandler({ image_url, onChange }) {
  const classes = useStyles();
  const file_ref = React.useRef(null);
  const {t} = useTranslation();
  const handleClearImageButtonClick = () => {
    onChange({ target: { id: 'image_url', value: '' } });
  };
  const handleFileInput = () => {
    let selectedFile = file_ref.current.files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      // handleUpdatePreview(e.target.result);
      onChange({ target: { id: 'image_url', value: e.target.result } });
    };
    reader.readAsDataURL(selectedFile);
  };

  const [show_image, setShowImage] = React.useState(false);
  React.useEffect(() => {
    setShowImage(testImageUrlValid(image_url));
  }, [image_url]);

  return (
    <>
      {show_image ? (
        <>
          <div
            style={{
              width: '100%',
              height: '300px',
              backgroundImage: `url(${image_url})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                left: '25%',
              }}
            >
              <IconButton
                onClick={handleClearImageButtonClick}
                color={'secondary'}
              >
                <HighlightOffOutlinedIcon />
              </IconButton>
            </div>
          </div>
        </>
      ) : (
        <>
          <input
            type="file"
            onChange={handleFileInput}
            ref={file_ref}
            accept=".png,.bmp,.jpg"
            style={{ display: 'none' }}
          />
          <Box className={classes.AddImagePlaceHolder}>
            <Box>
              <Button
                onClick={() => {
                  file_ref.current.click();
                }}
                className={classes.AddImageButton}
                startIcon={<AddAPhotoIcon />}
                style={{ textAlign: 'left' }}
              >
                {t('COMMON_UPLOAD_IMAGE')}
                <br />
                {t('COMMON_VALIDATE_IMAGE')}
              </Button>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}

export default function NewFoodEditForm({
  rest_id,
  handleClose,
  food_menu,
  pre_select_category,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { restaurant_config } = React.useContext(GlobalContext);

  let [show_add_new_customize_group, setShowAddNewCustomizeGroup] =
    React.useState(true);

  const [columns_available, setColumnsAvailable] = React.useState([]);
  const [current_food_categories, setCurrentFoodCategories] =
    React.useState(pre_select_category);

  // MENY-240, align beheaviour to ad edit page
  // React.useEffect(() => {
  //   setCurrentFoodCategories(columns_available[0]);
  // }, [columns_available]);

  React.useEffect(() => {
    if (isNull(food_menu) || isUndefined(food_menu)) {
    } else {
      let columns = food_menu.columns;
      if (isNull(columns) || isUndefined(columns)) {
      } else {
        setColumnsAvailable(
          Object.keys(columns).map((k) => {
            return { title: columns[k].title, id: columns[k].id };
          })
        );
      }
    }
  }, [food_menu]);

  const [category_error, setCategoryError] = React.useState(null);
  const updateFoodCategories = (new_value) => {
    setCurrentFoodCategories(new_value);

    schemaValidateCategoryValue
      .validate(new_value)
      .then((valid) => {
        setCategoryError(null);
        return true;
      })
      .catch(() => {
        setCategoryError(active_lang.CATEGORY_IS_MISSING_ERROR);
        return false;
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: default_new_food_detail,
    validationSchema: Yup.object({
      name: Yup.string().required(t('MENU_REQUIRED_NAME')),
      // MENY-171, update to handle unit_price checking
      unit_price: Yup.string()
        .matches(REGEX_TEST_NUMBER_WITHOUT_MINUS, (o) => {
          if (o.value.match(REGEX_TEST_NUMBER_WITH_DECIMAL_POINT)) {
            // 数字が正確ではありません。再度、入力してください。
            return;
          } else {
            // 数字を入力してください。
            return { message: t('COMMON_ERROR_NUMBER') };
          }
        })
        .required({ message: t('COMMON_REQUIRED_PRICE') }),
      // MENY-171, update to handle unit_price checking
      customize_group_settings: Yup.array().of(
        Yup.object({
          name: Yup.string().required(t('MENU_REQUIRED_TITLE')),
          price: Yup.string()
            .matches(REGEX_TEST_NUMBER_POS_NEG, (o) => {
              if (o.value.match(REGEX_TEST_NUMBER_WITH_DECIMAL_POINT)) {
                return;
              } else {
                // 数字を入力してください。
                return t('COMMON_ERROR_NUMBER');
              }
            })
            .required(t('COMMON_REQUIRED_PRICE')),
        })
      ),
      foodStockCount: Yup.string()
        .matches(REGEX_TEST_NUMBER_POS_NEG, (o) => {
          if (o.value.match(REGEX_TEST_NUMBER_WITH_DECIMAL_POINT)) {
            return active_lang.FOOD_STOCK_COUNT_IS_NOT_A_INTEGER;
          }
          return t('COMMON_ERROR_NUMBER');
        })
        .test(
          'required',
          t('MENU_STOCK_MANAGE_EMPTY'),
          (val) => {
            if (
              formik.values.sold_out === STATUS_IN_STOCK &&
              formik.values.isEnableStockCount
            ) {
              return Boolean(val);
            }
            return true;
          }
        )
        .test(
          'greater than 0',
          t('MENU_QUANTITY_ERROR'),
          (val) => {
            if (
              formik.values.sold_out === STATUS_IN_STOCK &&
              formik.values.isEnableStockCount
            ) {
              return parseInt(val) > 0;
            }
            return true;
          }
        ),
    }),
    onSubmit: async (values, { resetForm, setFieldValue }) => {
      enqueueSnackbar(t('COMMON_UPDATING'), { variant: 'warning' });
      formik.setFieldValue('foodStockCount', parseInt(values.foodStockCount));

      let check_result = await schemaValidateCategoryValue
        .validate(current_food_categories)
        .then((valid) => {
          setCategoryError(null);
          return true;
        })
        .catch(() => {
          setCategoryError(active_lang.CATEGORY_IS_MISSING_ERROR);
          return false;
        });
      if (!check_result) {
        return;
      }

      let new_food_id = getRandomFoodId();
      if (testHaveImageNotUploaded(values.image_url)) {
        let image_data_url = values.image_url;

        // var spaceRef = storageRef.child("images/test_ref/space.jpg");
        var spaceRef = storageRef.child(
          `food_detail/${rest_id}/${new_food_id}/food_image`
        );

        spaceRef
          .putString(image_data_url, 'data_url')
          .then(async (snapshot) => {
            let x = await snapshot.ref.getDownloadURL();

            // MENY-171, update to handle unit_price checking
            let sanitized_customize_group_settings = [];
            if (
              values &&
              testNotNullAndIsDefined(values.customize_group_settings)
            ) {
              values.customize_group_settings.forEach((c) => {
                sanitized_customize_group_settings.push({
                  name: c.name,
                  price: covertDecimalPrice(c.price),
                });
              });
            }

            let sanitized_values = {
              ...values,
              unit_price: covertDecimalPrice(values.unit_price),
              customize_group_settings: sanitized_customize_group_settings,
            };

            let values_with_image_upload_url = {
              ...sanitized_values,
              image_url: x,
              id: new_food_id,
            };

            return addFoodDetails(
              rest_id,
              new_food_id,
              values_with_image_upload_url
            );
          })
          .then(() => {
            return addFoodIdToCategories(
              rest_id,
              current_food_categories,
              new_food_id
            );
          })
          .then(() => {
            enqueueSnackbar(
              t('COMMON_UPDATED_COMPLETE'),
              ENQUEUE_SUCCESS_CONFIG
            );
            resetForm({});

            handleClose();
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        // MENY-171, update to handle unit_price checking
        let sanitized_customize_group_settings = [];
        if (
          values &&
          testNotNullAndIsDefined(values.customize_group_settings)
        ) {
          values.customize_group_settings.forEach((c) => {
            sanitized_customize_group_settings.push({
              name: c.name,
              price: covertDecimalPrice(c.price),
            });
          });
        }

        let sanitized_values = {
          ...values,
          image_url: '',
          id: new_food_id,
          unit_price: covertDecimalPrice(values.unit_price),
          customize_group_settings: sanitized_customize_group_settings,
        };

        // image is empty or image already defined
        // MENY-171, update to handle unit_price checking
        addFoodDetails(rest_id, new_food_id, sanitized_values)
          .then(() => {
            return addFoodIdToCategories(
              rest_id,
              current_food_categories,
              new_food_id
            );
          })
          .then(() => {
            enqueueSnackbar(t('COMMON_UPDATED_COMPLETE'), {
              variant: 'success',
            });
            resetForm({});
          })
          .then(() => {
            handleClose();
          })
          .catch((err) => console.error(err));
      }
    },
  });

  // MENY-171, update to handle unit_price checking
  const handleUnitPriceOnChange = (e) => {
    // // MENY-171, update to handle fullwidth to halfwidth with numbers
    if (testNotNullAndIsDefined(e.target)) {
      formik.handleChange(e);
    }
  };

  const handleUnitFoodStockOnChange = (e) => {
    if (testNotNullAndIsDefined(e.target)) {
      formik.handleChange(e);
    }
  };

  const handleUnitPriceFieldOnBlur = (e) => {
    formik.handleBlur(e);
    if (testNotNullAndIsDefined(e.target)) {
      if (REGEX_TEST_NUMBER_WITHOUT_MINUS.test(e.target.value)) {
        let temp = sanitizeFullWidthNumber(e.target.value);
        e.target.value = covertDecimalPrice(temp);
        formik.handleChange(e);
      } else {
        // skip if not match
      }
    }
  };

  const handleUnitFoodStockFieldOnBlur = (e) => {
    formik.handleBlur(e);
    if (testNotNullAndIsDefined(e.target)) {
      if (REGEX_TEST_NUMBER_WITHOUT_MINUS.test(e.target.value)) {
        let temp = sanitizeFullWidthNumber(e.target.value);
        e.target.value = temp;
        formik.handleChange(e);
      } else {
        // skip if not match
      }
    }
  };

  const checkUnitPriceValidWhenSubmit = (value_in) => {
    return REGEX_TEST_NUMBER_WITHOUT_MINUS.test(value_in);
  };

  const checkOptionPriceValidWhenSubmit = (value_in) => {
    return REGEX_TEST_NUMBER_POS_NEG.test(value_in);
  };

  const convertUnitPriceFunction = (value_in) => {
    try {
      if (typeof value_in != 'number') {
        let temp = sanitizeFullWidthNumber(value_in);
        return Number(temp).toFixed(2);
      }
      return value_in;
    } catch (error) {
      console.err(error);
    }
  };

  const convertOptionPriceFunction = (value_in) => {
    try {
      if (typeof value_in != 'number') {
        let temp = sanitizeFullWidthNumberWithPositiveAndNegative(value_in);
        return Number(temp).toFixed(2);
      }
      return value_in;
    } catch (error) {
      console.err(error);
    }
  };

  const handleChangeFoodStatus = async (e) => {
    formik.handleChange(e);
    if (e.target.value === STATUS_SOLD_OUT) {
      await formik.setFieldValue('foodStockCount', '');
      await formik.setFieldValue('isEnableStockCount', false);
    } else {
      await formik.setFieldValue('foodStockCount', '');
      await formik.setFieldValue('isEnableStockCount', false);
    }
  };

  const handleChangeChecked = async (e) => {
    formik.handleChange(e);
    if (!e.target.checked) {
      await formik.setFieldValue('foodStockCount', '');
    }
  };

  React.useEffect(() => {
    let customize_group_settings = formik.values.customize_group_settings;
    if (testValidCustomizeGroupSettings(customize_group_settings)) {
      if (customize_group_settings.length > 0) {
        setShowAddNewCustomizeGroup(false);
      }
    }
  }, [formik.values.customize_group_settings]);

  // React.useEffect(() => {
  //   setCurrentFoodCategories({
  //     title: 'ディナー Cat1',
  //     id: 'Cat1',
  //   });
  // }, []);

  React.useEffect(() => {
    setCurrentFoodCategories(pre_select_category);
  }, [pre_select_category]);

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();

          let unit_price_value = formik.getFieldProps('unit_price').value;
          if (checkUnitPriceValidWhenSubmit(unit_price_value)) {
            formik.setFieldValue(
              'unit_price',
              convertUnitPriceFunction(unit_price_value)
            );
          }

          let option_groups = formik.getFieldProps(
            'customize_group_settings'
          ).value;
          let temp = [];
          for (let i = 0; i < option_groups.length; i++) {
            let option_name = option_groups[i].name;
            let option_price = option_groups[i].price;
            if (checkOptionPriceValidWhenSubmit(option_price)) {
              temp.push({
                name: option_name,
                price: convertOptionPriceFunction(option_price),
              });
            } else {
              temp.push({
                name: option_name,
                price: option_price,
              });
            }
          }
          formik.setFieldValue('customize_group_settings', temp);

          formik.handleSubmit(e);
          document.activeElement.blur();
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Grid container>
              {/* 商品名 */}
              <Grid item xs={12} lg={8}>
                <Box className={classes.TextInputGroup}>
                  <Box className={classes.TextInputTitle}>
                    {t('COMMON_ITEM_NAME')}
                  </Box>
                  <Box className={classes.TextInputWrapper}>
                    <TextField
                      id="name"
                      {...formik.getFieldProps('name')}
                      className={classes.TextInput}
                      fullWidth
                      placeholder={t('COMMON_ITEM_NAME')}
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ style: { padding: '1rem' } }}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <Box
                        style={{
                          color: LYNKED_RED,
                          display: 'flex',
                          flexFlow: 'row',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          paddingLeft: '0.25rem',
                          paddingTop: '0.25rem',
                          paddingBottom: '0.25rem',
                        }}
                      >
                        <Box pr="1rem">
                          <WarningIcon fontSize="small" />
                        </Box>
                        <Box>{formik.errors.name}</Box>
                      </Box>
                    ) : null}
                  </Box>
                </Box>
              </Grid>

              {/* 値段 */}
              <Grid item xs={12} lg={4}>
                <Box className={classes.TextInputGroup}>
                  <Box className={classes.TextInputTitle}>
                    {t('COMMON_PRICE')}
                  </Box>
                  <Box className={classes.TextInputWrapper}>
                    <TextField
                      id="unit_price"
                      // MENY-171, update to handle unit_price checking
                      onChange={(e) => handleUnitPriceOnChange(e)}
                      onBlur={(e) => handleUnitPriceFieldOnBlur(e)}
                      value={formik.values.unit_price}
                      className={classes.TextInput}
                      fullWidth
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ style: { padding: '1rem' } }}
                    />
                    {formik.touched.unit_price && formik.errors.unit_price ? (
                      <Box
                        style={{
                          color: LYNKED_RED,
                          display: 'flex',
                          flexFlow: 'row',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          paddingLeft: '0.25rem',
                          paddingTop: '0.25rem',
                          paddingBottom: '0.25rem',
                        }}
                      >
                        <Box pr="1rem">
                          <WarningIcon fontSize="small" />
                        </Box>
                        <Box>{formik.errors.unit_price.message}</Box>
                      </Box>
                    ) : null}
                  </Box>
                </Box>
              </Grid>
            </Grid>

            {/* category */}
            <Grid item xs={12} lg={12}>
              <div className={classes.TextInputGroup}>
                <div className={classes.TextInputTitle}>
                  {/* カテゴリー */}
                  {t('COMMON_CATEGORY')}
                </div>

                <div className={classes.TextInputWrapper} mt="0.5rem">
                  <Autocomplete
                    id="edit_food_category_selector"
                    fullWidth
                    options={columns_available}
                    getOptionLabel={(option) => option.title}
                    value={current_food_categories}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        variant="standard"
                        placeholder={t('COMMON_CATEGORY')}
                      />
                    )}
                    onChange={(_event, newValue) =>
                      updateFoodCategories(newValue)
                    }
                    // defaultValue={pre_select_category}
                    // MENY-240, align beheaviour to ad edit page
                    disableClearable
                  />
                </div>
                {category_error ? (
                  <Box
                    style={{
                      color: LYNKED_RED,
                      display: 'flex',
                      flexFlow: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      paddingLeft: '0.25rem',
                      paddingTop: '0.25rem',
                      paddingBottom: '0.25rem',
                    }}
                  >
                    <Box pr="1rem">
                      <WarningIcon fontSize="small" />
                    </Box>
                    <Box>{category_error}</Box>
                  </Box>
                ) : null}
              </div>
            </Grid>

            {/* 販売状況 */}
            <Grid item xs={12}>
              <Box className={classes.TextInputGroup} mt="0.5rem">
                <FormControl fullWidth>
                  <Box className={classes.TextInputTitle}>
                    {t('MENU_SALES_STATUS')}
                  </Box>
                  <Select
                    id="sold_out"
                    {...formik.getFieldProps('sold_out')}
                    classes={{
                      root: classes.food_availability,
                      icon: classes.icon,
                    }}
                    input={<FoodStatusInput />}
                    IconComponent={KeyboardArrowDownIcon}
                    style={{ paddingTop: '0.5rem' }}
                    value={formik.values['sold_out'] || STATUS_IN_STOCK}
                    onChange={handleChangeFoodStatus}
                    name="sold_out"
                  >
                    <MenuItem value={STATUS_IN_STOCK}>{t('MENU_ON_SALE')}</MenuItem>
                    <MenuItem value={STATUS_SOLD_OUT}>{t('MENU_OUT_TO_STOCK')}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            {restaurant_config?.meny_service_class?.name === 'meny(32)' && (
              <Grid item xs={12}>
                <Box className={classes.TextInputGroup} mt="0.5rem">
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={formik.values.sold_out === STATUS_SOLD_OUT}
                        checked={formik.values.isEnableStockCount}
                        name="isEnableStockCount"
                        onChange={handleChangeChecked}
                        color="primary"
                      />
                    }
                    label={t('MENU_STOCK_MANAGE')}
                  />
                  <Box className={classes.FoodStockCountWrapper}>
                    <Box className={classes.TextInputTitle}>{t('MENU_QUANTITY')}</Box>
                    <Box className={classes.TextInputWrapper}>
                      <TextField
                        name="foodStockCount"
                        {...formik.getFieldProps('foodStockCount')}
                        onChange={(e) => handleUnitFoodStockOnChange(e)}
                        onBlur={(e) => handleUnitFoodStockFieldOnBlur(e)}
                        value={formik.values.foodStockCount}
                        className={
                          formik.values.isEnableStockCount
                            ? classes.TextInput
                            : classes.TextInputDisabled
                        }
                        InputProps={{ disableUnderline: true }}
                        inputProps={{ style: { padding: '0.5rem 1rem' } }}
                        disabled={
                          !formik.values.isEnableStockCount ||
                          formik.values.sold_out === STATUS_SOLD_OUT
                        }
                        autoComplete={false}
                        placeholder={t('MENU_EMPTY_ERROR')}
                      />
                      {formik.values.isEnableStockCount &&
                      formik.touched.foodStockCount &&
                      formik.errors.foodStockCount ? (
                        <Box
                          style={{
                            color: LYNKED_RED,
                            display: 'flex',
                            flexFlow: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            paddingLeft: '0.25rem',
                            paddingTop: '0.25rem',
                            paddingBottom: '0.25rem',
                          }}
                        >
                          <Box pr="1rem">
                            <WarningIcon fontSize="small" />
                          </Box>
                          <Box>{formik.errors.foodStockCount}</Box>
                        </Box>
                      ) : null}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            )}

            {/* 商品の詳細 */}
            <Grid item xs={12} lg={12}>
              <div className={classes.TextInputGroup}>
                <div className={classes.TextInputTitle}>
                  {t('MENU_PRODUCT_DESCRIPTION')}
                </div>
                <div className={classes.TextInputWrapper} mt="0.5rem">
                  <TextField
                    id="description"
                    {...formik.getFieldProps('description')}
                    className={classes.TextInput}
                    fullWidth
                    multiline
                    rows={4}
                    InputProps={{ disableUnderline: true }}
                    inputProps={{ style: { padding: '1rem' } }}
                  />
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            lg={6}
            style={{
              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'center',
            }}
          >
            <ImageHandler
              id="image_url"
              {...formik.getFieldProps('image_url')}
              onChange={formik.handleChange}
              image_url={formik.values.image_url}
              edit_show_detail={formik.values}
              setEditShowDetail={`setEditShowDetail`}
            />
          </Grid>

          {/* オプション */}
          <Grid item xs={12}>
            <Box className={classes.TextInputGroup} mt="0.5rem">
              <FormControl className={classes.margin} style={{ width: '100%' }}>
                <div className={classes.TextInputTitle}>
                  {t('MENU_OPTION')}
                </div>
                <div style={{ paddingTop: '0.5rem' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexFlow: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    {show_add_new_customize_group ? (
                      <AddNewCustomizeGroup
                        id="customize_group_settings"
                        {...formik.getFieldProps('customize_group_settings')}
                        onChange={formik.handleChange}
                        value={formik.values.customize_group_settings}
                        // handleAddNewCustomizeSetting={
                        //   handleAddNewCustomizeSetting
                        // }
                      />
                    ) : (
                      <ShowCustomizeGroup
                        // customize_group_settings={`customize_group_settings.a`}
                        handleRemoveCustomizeSetting={`handleRemoveCustomizeSetting`}
                        handleAddNewCustomizeSetting={`handleAddNewCustomizeSetting`}
                        handleUpdateOptionPrice={`handleUpdateOptionPrice`}
                        handleUpdateOptionName={`handleUpdateOptionName`}
                        id="customize_group_settings"
                        {...formik.getFieldProps('customize_group_settings')}
                        onChange={formik.handleChange}
                        value={formik.values.customize_group_settings}
                        customize_group_settings={
                          formik.values.customize_group_settings
                        }
                        // touched_objs={
                        //   formik.touched.customize_group_settings
                        // }
                        formik={formik}
                        error_objs={formik.errors.customize_group_settings}
                      />
                    )}
                  </div>
                </div>
              </FormControl>
              {/* MENY-170, Duplicate error message when delete price at food price */}
            </Box>
          </Grid>

          <Grid container>
            <Grid xs={2}></Grid>

            <Grid item xs={8} zeroMinWidth>
              <ShowFoodSelect formik={formik} />
            </Grid>

            <Grid xs={2}></Grid>
          </Grid>

          {/* bottom buttons */}
          <Grid item xs={12} lg={12}>
            <Grid container justify="center">
              <Grid item xs={6} lg={6}>
                <div className={classes.DialogBottomButtons}>
                  <Button
                    className={`${classes.Buttons} ${classes.ButtonCancel}`}
                    onClick={() => {
                      formik.resetForm();
                      handleClose();
                    }}
                    type="button"
                  >
                    {t('COMMON_CANCEL')}
                  </Button>
                  <Button
                    disabled={formik.isSubmitting}
                    type="submit"
                    className={`${classes.Buttons} ${classes.ButtonOK}`}
                  >
                    {t('MENU_REGISTER')}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
