import React from 'react';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import NavButton from 'src/components/NavButton';
import active_lang from 'src/langs/jp_en';
import { useTranslation } from 'react-i18next';

export default function NavMenuOverview({ handleCloseNavBar }) {
  const { t } = useTranslation();

  return (
    <>
      <NavButton
        text={t('SIDEMENU_DASHBOARD')}
        href={`/app`}
        icon={<DashboardOutlinedIcon />}
        handleCloseNavBar={handleCloseNavBar}
        id="nav-overview"
      />
    </>
  );
}
