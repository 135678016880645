import React from 'react';
import moment from 'moment-timezone';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
import Typography from '@material-ui/core/Typography';

import ShowDebug from 'src/components/ShowDebug';

import { GlobalContext } from 'src/contexts/Global';
import { MonitorOrderContext } from 'src/contexts/MonitorOrder';

import { getOrdersByOrderId, updateOrderStatus } from 'src/modals/orders';

import Popover from '@material-ui/core/Popover';

import {
  LYNKED_RED,
  LYNKED_RED_HOVER,
  LYNKED_WHITE,
  LYNKED_GREEN,
  LYNKED_GREEN_HOVER,
  LYNKED_LIGHT_GREY,
  LYNKED_LIGHT_GREY_HOVER,
  LYNKED_DEEP_GREY,
  LYNKED_DEEP_GREY_HOVER,
  LYNKED_BLACK,
} from 'src/consts/colors';

import active_lang from 'src/langs/jp_en';

import ShowOrderTable from './ShowOrderTable';

import {
  STATUS_DELIVERED,
  STATUS_PREPARING,
  STATUS_CANCELLED,
} from 'src/consts/ORDER_STATUS';
import { AuthContext } from 'src/Firebase/context';
import { useTranslation } from 'react-i18next';

// TODO: update this

const useStyles = makeStyles({
  line1: {},
  line2: { font: "normal normal bold 12px/16px 'Noto Sans JP'" },
  table: {
    minWidth: 650,
    font: "normal normal bold 12px/16px 'Noto Sans JP'",
    '& thead': {
      background: '#E0E0E0 0% 0% no-repeat padding-box',
      textTransform: 'uppercase',
    },
    '& tbody': {
      '& th': {},
      '& td': { padding: '8px' },
    },
  },
  Buttons: {
    width: '120px',
    height: '30px',
    color: 'white',
    letterSpacing: '0.12px',
    borderRadius: '1px',
    backgroundColor: LYNKED_GREEN,
    font: "normal normal bold 10px/14px 'Noto Sans JP'",
    '&:hover': {
      backgroundColor: LYNKED_GREEN_HOVER,
    },
  },
  tableHeadText: {
    color: LYNKED_BLACK,
    font: 'normal normal bold 12px/16px Roboto',
  },
  tableBodyText: {
    color: LYNKED_LIGHT_GREY,
    font: "normal normal bold 12px/16px 'Noto Sans JP'",
  },
  deliveredBtn: {
    backgroundColor: LYNKED_GREEN,
    '&:hover': {
      backgroundColor: LYNKED_GREEN_HOVER,
    },
    color: LYNKED_WHITE,
    width: '120px',
    height: '20px',
    font: 'normal normal normal 11px/15px Hiragino Sans',
  },
});

export default function DeliveredButton({
  button_id,
  status,
  order_id,
  order_idx,
}) {
  const classes = useStyles();
  let { rest_id } = React.useContext(GlobalContext);
  const { setIsAdminUpdating } = React.useContext(AuthContext);
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleUpdateOrderStatus = async (
    rest_id,
    order_id,
    order_idx,
    status
  ) => {
    let u = await getOrdersByOrderId(rest_id, order_id)
      .then((doc) => {
        // console.log(doc.data());
        return doc.data();
      })
      .then((doc_data) => {
        doc_data.orders[order_idx].status = status;
        updateOrderStatus(rest_id, order_id, doc_data).then(() => {
          handleClose();
          setIsAdminUpdating(true);
        });
      });

    return u;
  };

  return (
    <>
      {/* <Button className={classes.Buttons}>{status}</Button> */}
      <Button
        id={button_id}
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        className={classes.deliveredBtn}
      >
        {t('ORDER_PROVIDED')}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box style={{ display: 'flex', flexFlow: 'column' }}>
          <Button
            id={`${button_id}-preparing`}
            style={{ width: '200px', height: '40px' }}
            onClick={(e) =>
              handleUpdateOrderStatus(
                rest_id,
                order_id,
                order_idx,
                STATUS_PREPARING
              )
            }
          >
            <Box
              style={{
                display: 'flex',
                flexFlow: 'row',
                justifyContent: 'flex-start',
                width: '100%',
              }}
            >
              <Box
                style={{
                  width: '20px',
                  height: '20px',
                  marginLeft: '1rem',
                  backgroundColor: LYNKED_RED,
                }}
              ></Box>
              <Box
                style={{
                  marginLeft: '1rem',
                  font: 'normal normal normal 14px/19px Hiragino Sans',
                }}
              >
                {t('OPERATING_PREPARING')}
              </Box>
            </Box>
          </Button>
          <Button
            id={`${button_id}-cancelled`}
            style={{ width: '200px', height: '40px' }}
            onClick={(e) =>
              handleUpdateOrderStatus(
                rest_id,
                order_id,
                order_idx,
                STATUS_CANCELLED
              )
            }
          >
            <Box
              style={{
                display: 'flex',
                flexFlow: 'row',
                justifyContent: 'flex-start',
                width: '100%',
              }}
            >
              <Box
                style={{
                  width: '20px',
                  height: '20px',
                  marginLeft: '1rem',
                  backgroundColor: LYNKED_BLACK,
                }}
              ></Box>
              <Box
                style={{
                  marginLeft: '1rem',
                  font: 'normal normal normal 14px/19px Hiragino Sans',
                }}
              >
                {t('COMMON_CANCEL')}
              </Box>
            </Box>
          </Button>
        </Box>
      </Popover>
    </>
  );
}
