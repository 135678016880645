import React from 'react';
import { useSnackbar } from 'notistack';

import { GlobalContext } from 'src/contexts/Global';

import useMutateRunRestaurantOpenProcedure from 'src/hooks/useMutateRunRestaurantOpenProcedure';
import useMutateRunRestaurantClosedProcedure from 'src/hooks/useMutateRunRestaurantClosedProcedure';

import active_lang from 'src/langs/jp_en';
import { monitorRestaurantConfig } from 'src/modals/restaurant_config';
import { MonitorLineupContext } from '../MonitorLineup';

import {
  RESTAURANT_OPEN_STATE_LOADING,
  RESTAURANT_OPEN_STATE_OPENED,
  RESTAURANT_OPEN_STATE_PREPARING,
} from 'src/consts/RESTAURANT_OPEN_STATE';

import {
  ENQUEUE_SUCCESS_CONFIG,
  ENQUEUE_WARNING_CONFIG,
} from 'src/consts/ENQUEUE_CONFIG';
import ShowDebug from 'src/components/ShowDebug';
import { useTranslation } from 'react-i18next';

let local_restaurant_open_state = -1;

export const MonitorRestaurantConfigContext = React.createContext();

export function MonitorRestaurantConfigContextProvider({ children }) {
  const { t } = useTranslation();

  let user_first_enter_overview = true;
  const { enqueueSnackbar } = useSnackbar();

  const { rest_id } = React.useContext(GlobalContext);
  const { resetLineupList } = React.useContext(MonitorLineupContext);

  let [restaurant_open_state, setRestaurantOpenState] = React.useState(
    RESTAURANT_OPEN_STATE_LOADING
  );
  let { restaurant_config, setRestaurantConfig } =
    React.useContext(GlobalContext);

  let mutation_run_restaurant_open_procedure =
    useMutateRunRestaurantOpenProcedure();

  let mutate_run_restaurant_closed_procedure =
    useMutateRunRestaurantClosedProcedure();

  let handleChangeRestaurantOpenState = (target_state) => {
    setRestaurantOpenState(RESTAURANT_OPEN_STATE_LOADING);

    switch (target_state) {
      // preparing -> open
      case RESTAURANT_OPEN_STATE_OPENED:
        return mutation_run_restaurant_open_procedure
          .mutateAsync(rest_id)
          .catch((err) => {
            // alert('mutation_run_restaurant_open_procedure error');
            console.error('mutation_run_restaurant_open_procedure', err);
          });

      // open -> preparing
      case RESTAURANT_OPEN_STATE_PREPARING:
        return mutate_run_restaurant_closed_procedure
          .mutateAsync(rest_id)
          .then(() => resetLineupList())
          .catch((err) => {
            // alert('mutate_run_restaurant_closed_procedure error');
            console.error('mutate_run_restaurant_closed_procedure', err);
          });

      default:
        break;
    }
  };

  React.useEffect(() => {
    let unsubMonitor;

    if (rest_id) {
      unsubMonitor = monitorRestaurantConfig(rest_id, (ss) => {
        let updated_restaurant_config = ss.data();
        console.log('updated_restaurant_config', updated_restaurant_config);

        if (updated_restaurant_config) {
          // setRestaurantConfig(updated_restaurant_config);

          if (user_first_enter_overview) {
            user_first_enter_overview = false;
            local_restaurant_open_state =
              updated_restaurant_config.restaurant_open_state;
          } else {
            if (
              updated_restaurant_config.restaurant_open_state !==
              local_restaurant_open_state
            ) {
              switch (updated_restaurant_config.restaurant_open_state) {
                case RESTAURANT_OPEN_STATE_OPENED:
                  enqueueSnackbar(
                    t('COMMON_UPDATED'),
                    ENQUEUE_SUCCESS_CONFIG
                  );
                  break;

                case RESTAURANT_OPEN_STATE_PREPARING:
                  enqueueSnackbar(
                    t('COMMON_UPDATED'),
                    ENQUEUE_WARNING_CONFIG
                  );
                  break;

                default:
                  break;
              }
              local_restaurant_open_state =
                updated_restaurant_config.restaurant_open_state;
            } else {
              // change occur on other field, skipping
            }
          }
          setRestaurantOpenState(
            updated_restaurant_config.restaurant_open_state
          );
        }
      });
    }

    function resetMonitor() {
      if (unsubMonitor) unsubMonitor();
    }

    return resetMonitor;
  }, [rest_id]);

  return (
    <>
      <MonitorRestaurantConfigContext.Provider
        value={{
          hello: 'world',
          restaurant_open_state,
          handleChangeRestaurantOpenState,
          restaurant_config,
        }}
      >
        <ShowDebug>
          {JSON.stringify(restaurant_config?.restaurant_open_id)}
        </ShowDebug>
        {children}
      </MonitorRestaurantConfigContext.Provider>
    </>
  );
}
