import React from 'react';
import { Box } from '@material-ui/core';

import ShowDebug from 'src/components/ShowDebug';

import { LYNKED_DEEP_GREY } from 'src/consts/colors';
import active_lang from 'src/langs/jp_en';
import { useTranslation } from 'react-i18next';

export default function NoLineupPeopleWaiting() {
  const { t } = useTranslation();

  return (
    <>
      <Box
        style={{
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '300px',
          margin: '1rem',
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexFlow: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            marginBottom: '0.5rem',
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              color: LYNKED_DEEP_GREY,
            }}
          >
            <div>{t('RECEPTION_NO_RECEIVED_MSG')}</div>
            <ShowDebug>NO_LINE_UP_PEOPLE_WAITING</ShowDebug>
          </Box>
        </Box>
      </Box>
    </>
  );
}
