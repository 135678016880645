import React from 'react';

import { Box } from '@material-ui/core';
import { LYNKED_DEEP_GREY } from 'src/consts/colors';

import active_lang from 'src/langs/jp_en';

export default function SettingsLoading() {
  return (
    <>
      <Box
        style={{
          width: '100%',
          margin: '2rem',
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: LYNKED_DEEP_GREY,
        }}
      >
        {active_lang.SETTINGS_LOADING}
      </Box>
    </>
  );
}
