import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LANG_DISPLAY_KEY, SITES } from '../consts/language';
import { I18nextProvider } from 'react-i18next';
import useGetSourceLang from 'src/hooks/useGetSourceLang';
import { useEffect } from 'react';

i18n.use(initReactI18next).init({
  resources: {},
  lng: localStorage.getItem(LANG_DISPLAY_KEY) || 'ja',
  fallbackLng: 'ja',
});

export function I18nProvider({ children }) {
  const { data } = useGetSourceLang(SITES.CMS);

  useEffect(() => {
    if (data) {
      for (var key in data) {
        i18n.addResourceBundle(key, 'translation', data[key]);
      }
    }
  }, [data]);

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
}

export default i18n;
