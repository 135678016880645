import React from 'react';
import { Box } from '@material-ui/core';

import ProfilePic from 'src/components/ProfilePic';
import RestaurantNavButtonColumn from 'src/components/RestaurantNavButtonColumn';
import SystemNavButtonColumn from 'src/components/SystemNavButtonColumn';

import { checkLgUp } from 'src/utils/checkClientWidth';

import { useStyles } from './styles';

export default function NavBar({ open, setOpen }) {
  const classes = useStyles();
  const nav_bar_ref = React.useRef();

  const handleCloseNavBar = () => {
    nav_bar_ref.current.style.display = 'none';
  };
  const handleOpenNavBar = () => {
    nav_bar_ref.current.style.display = 'block';
  };

  React.useEffect(() => {
    if (open) {
      // alert('open nav bar');
      handleOpenNavBar();
    } else {
      // alert(JSON.stringify(open));
      handleCloseNavBar();
    }
  }, [open]);

  function handleResize() {
    if (checkLgUp()) {
      setOpen(true);
    } else {
      // alert('setOpenfalse');
      setOpen(false);
    }
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleCalcNavHeight = () => {
    setNavHeight(window.innerHeight * 0.93);
  };
  let [nav_height, setNavHeight] = React.useState(window.innerHeight * 0.93);
  React.useEffect(() => {
    window.addEventListener('resize', handleCalcNavHeight);
    return () => {
      window.removeEventListener('resize', handleCalcNavHeight);
    };
  }, []);

  return (
    <>
      <Box
        ref={nav_bar_ref}
        className={classes.NavbarContainer}
        style={{ display: open ? 'block' : 'none' }}
      >
        <Box
          style={{
            width: '100%',
            height: `${nav_height}px`,
            display: 'flex',
            flexFlow: 'row',
          }}
        >
          <Box
            className={classes.NavBarCloseArea}
            onClick={() => {
              setOpen(false);
            }}
          ></Box>
          <Box className={classes.NavbarBody}>
            <Box className={classes.NavbarTopPart}>
              <ProfilePic />
              <RestaurantNavButtonColumn
                handleCloseNavBar={() => {
                  if (checkLgUp()) {
                    setOpen(true);
                  } else {
                    setOpen(false);
                  }
                }}
              />
            </Box>
            <Box className={classes.NavbarBottomPart}>
              <SystemNavButtonColumn
                handleCloseNavBar={() => {
                  if (checkLgUp()) {
                    setOpen(true);
                  } else {
                    setOpen(false);
                  }
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {open ? (
        <>
          <Box id={'nav-bar-container'}></Box>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
