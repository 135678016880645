import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { isNull } from 'lodash';

import ShowDebug from 'src/components/ShowDebug';

import { MonitorLineupContext } from 'src/contexts/MonitorLineup';

import { LYNKED_RED } from 'src/consts/colors';

import active_lang from 'src/langs/jp_en';

import NoLineupPeopleWaiting from './NoLineupPeopleWaiting';
import ShowReceptionList from './ShowReceptionList';
import { useTranslation } from 'react-i18next';
function LineupListBody() {
  let { line_up_list } = React.useContext(MonitorLineupContext);

  if (line_up_list && line_up_list.length > 0) return <ShowReceptionList />;

  return <NoLineupPeopleWaiting />;
}

export default function ReceptionList() {
  let { line_up_list } = React.useContext(MonitorLineupContext);
  const { t } = useTranslation();

  return (
    <>
      <Box
        style={{
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          minHeight: '100px',
          margin: '1rem',
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexFlow: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            marginBottom: '0.5rem',
            borderBottom: '1px solid #E0E0E0',
          }}
        >
          <Box
            style={{
              paddingLeft: '1rem',
              paddingBottom: '2rem',
              font: 'normal normal bold 20px/26px Roboto',
            }}
          >
            {t('COMMON_RECEPTION_LIST')}
            <ShowDebug>RECEPTION_LIST</ShowDebug>
          </Box>
          <Box
            style={{
              paddingRight: '1rem',
              paddingBottom: '2rem',
            }}
          >
            <Box style={{ border: '1px solid #E0E0E0', padding: '1rem' }}>
              <span style={{ font: 'normal normal bold 15px/20px Roboto' }}>
                {t('COMMON_TOTAL')}
              </span>{' '}
              <span
                style={{
                  font: 'normal normal bold 15px/20px Roboto',
                  color: LYNKED_RED,
                }}
              >
                {(line_up_list && line_up_list.length) || 0}{t('RECEPTION_ITEMS')}
              </span>
            </Box>
          </Box>
        </Box>
      </Box>

      <Grid container style={{ paddingBottom: '3rem' }}>
        <Grid item xs={12}>
          <LineupListBody />
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </>
  );
}
