import { makeStyles } from '@material-ui/core';

import {
  LYNKED_WHITE,
  LYNKED_BLUE,
  LYNKED_BLUE_HOVER,
  LYNKED_LIGHT_GREY,
} from 'src/consts/colors';

const useStyles = makeStyles(() => ({
  helloworld: {},
  SearchInput: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0px 1px 2px #00000029',
    width: '100%',
    height: '45px',
  },
  TextInput: {
    width: '100%',
    height: '100%',
    padding: '10px',
    font: 'normal normal bold 12px/16px Roboto',
    '& ::placeholder': {
      color: LYNKED_LIGHT_GREY,
    },
  },

  SearchButton: {
    height: '100%',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    font: 'normal normal bold 12px/16px Roboto',
    borderRadius: '1px',
    width: '100px',

    color: LYNKED_WHITE,
    backgroundColor: LYNKED_BLUE,
    '&:hover': {
      backgroundColor: LYNKED_BLUE_HOVER,
    },
  },
}));

export { useStyles };
