import { makeStyles } from '@material-ui/core';

import { LYNKED_WHITE } from 'src/consts/colors';

const useStyles = makeStyles((theme) => ({
  NavbarContainer: {
    position: 'fixed',
    height: '100%',
    [theme.breakpoints.up('xs')]: {
      width: '100%',
      zIndex: 5,
      // borderRight: '1px solid #E0E0E0',
    },
    [theme.breakpoints.up(1281)]: {
      // width: '16%',
      width: document.documentElement.clientWidth * 0.16,
      // backgroundColor: 'yellow',
      position: 'unset',
    },
  },
  NavBarCloseArea: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    // [theme.breakpoints.up('lg')]: {
    //   display: 'none',
    // },
    [theme.breakpoints.up(1281)]: {
      display: 'none',
    },
  },

  // no eye see
  NavbarBody: (props) => {
    return {
      backgroundColor: LYNKED_WHITE,
      [theme.breakpoints.up('xs')]: {
        // backgroundColor: 'green',
        minWidth: '250px',
        maxWidth: '240px',

        height: `${props.current_client_height * 0.93}px`,
        minHeight: `${props.current_client_height * 0.93}px`,

        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'space-between',

        zIndex: 5,
      },
      // [theme.breakpoints.up('lg')]: {
      //   // backgroundColor: 'tomato',
      //   minWidth: 'unset',
      //   maxWidth: '100%',
      //   minHeight: `${props.current_client_height * 0.93}`,

      //   display: 'flex',
      //   flexFlow: 'column',
      //   justifyContent: 'space-between',
      // },
      [theme.breakpoints.up(1281)]: {
        // backgroundColor: 'tomato',
        minWidth: 'unset',
        maxWidth: '100%',
        minHeight: `${props.current_client_height * 0.93}`,

        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'space-between',
      },
    };
  },
  NavbarTopPart: { backgroundColor: 'white' },
}));

export { useStyles };
