// opening hours control
let OPENING_HOURS_SETTING_TITLE = '営業時間設定';

let OPENING_HOURS_MON = '月';
let OPENING_HOURS_TUE = '火';
let OPENING_HOURS_WED = '水';
let OPENING_HOURS_THU = '木';
let OPENING_HOURS_FRI = '金';
let OPENING_HOURS_SAT = '土';
let OPENING_HOURS_SUN = '日';

let OPENING_HOURS_START = '営業開始：';
let OPENING_HOURS_END = '営業終了：';

// status control on top bar
let OPENING_HOURS_STATE_LOADING = '読み込み中';
let OPENING_HOURS_STATE_SHOP_PREPARING = '準備中';
let OPENING_HOURS_STATE_SHOP_OPENED = '營業中';

let OPENING_HOURS_OPEN_TO_PREPARING_TITLE = '注意事項';
let OPENING_HOURS_OPEN_TO_PREPARING_SUBTITLE = 'お客様は「準備中」の間はご注文頂けません。';
let OPENING_HOURS_OPEN_TO_PREPARING_MESSAGE = '※次の指定した営業開始時間から注文することができます。';
let OPENING_HOURS_OPEN_TO_PREPARING_OK = 'OK';
let OPENING_HOURS_OPEN_TO_PREPARING_CANCEL = 'キャンセル';

let OPENING_HOURS_PREPARING_TO_OPEN_TITLE = '注意事項';
let OPENING_HOURS_PREPARING_TO_OPEN_SUBTITLE = 'お客様は「営業中」の間は注文することができます。';
let OPENING_HOURS_PREPARING_TO_OPEN_MESSAGE = '';
let OPENING_HOURS_PREPARING_TO_OPEN_OK = 'OK';
let OPENING_HOURS_PREPARING_TO_OPEN_CANCEL = 'キャンセル';

// settings for table reservation page
let LINEUP_PAGE_SETTINGS_TITLE = 'meny受付ページの設定';
let CLIENT_NAME_PLACEHOLDER = 'テキストヒント：';
let CLIENT_REMARKS_TITLE = '備考欄：';

let CLIENT_REMARKS_PLACEHOLDER = '備考';

let MAX_NUMBER_OF_CHILDREN = '子供の最大人数：';
let MAX_NUMBER_OF_ADULT = '大人の最大人数：';

// snackbars
let SAVE_RESTAURANT_CONFIG_START = 'SAVE_RESTAURANT_CONFIG_START';
let SAVE_RESTAURANT_CONFIG_IN_PROGRESS = '更新中';
let SAVE_RESTAURANT_CONFIG_DONE = '更新完了';
let PLEASE_APPLY_CHANGES_AND_PRESS_SAVE = 'PLEASE_APPLY_CHANGES_AND_PRESS_SAVE';

let OPENING_HOURS_STATE_CHANGED_TO_PREPARING = '更新完了';
let OPENING_HOURS_STATE_CHANGED_TO_OPENED = '更新完了';

// page saving, loading
let SETTINGS_LOADING = '設定を読み込み中';
let SAVE_SETTINGS = '保存する';

let ERROR_FAILED_TIME_DIFFERENCE = '営業開始時間は終了時間より前に設定してください';

export default {
  ERROR_FAILED_TIME_DIFFERENCE,
  PLEASE_APPLY_CHANGES_AND_PRESS_SAVE,
  OPENING_HOURS_STATE_LOADING,
  LINEUP_PAGE_SETTINGS_TITLE,
  SAVE_SETTINGS,
  SAVE_RESTAURANT_CONFIG_START,
  SAVE_RESTAURANT_CONFIG_IN_PROGRESS,
  SAVE_RESTAURANT_CONFIG_DONE,
  MAX_NUMBER_OF_ADULT,
  MAX_NUMBER_OF_CHILDREN,
  CLIENT_NAME_PLACEHOLDER,
  CLIENT_REMARKS_TITLE,
  CLIENT_REMARKS_PLACEHOLDER,
  OPENING_HOURS_SETTING_TITLE,
  SETTINGS_LOADING,
  OPENING_HOURS_MON,
  OPENING_HOURS_TUE,
  OPENING_HOURS_WED,
  OPENING_HOURS_THU,
  OPENING_HOURS_FRI,
  OPENING_HOURS_SAT,
  OPENING_HOURS_SUN,

  OPENING_HOURS_START,
  OPENING_HOURS_END,
  OPENING_HOURS_STATE_SHOP_PREPARING,
  OPENING_HOURS_STATE_SHOP_OPENED,

  OPENING_HOURS_OPEN_TO_PREPARING_TITLE,
  OPENING_HOURS_OPEN_TO_PREPARING_MESSAGE,
  OPENING_HOURS_OPEN_TO_PREPARING_SUBTITLE,
  OPENING_HOURS_OPEN_TO_PREPARING_OK,
  OPENING_HOURS_OPEN_TO_PREPARING_CANCEL,

  OPENING_HOURS_PREPARING_TO_OPEN_TITLE,
  OPENING_HOURS_PREPARING_TO_OPEN_MESSAGE,
  OPENING_HOURS_PREPARING_TO_OPEN_SUBTITLE,
  OPENING_HOURS_PREPARING_TO_OPEN_OK,
  OPENING_HOURS_PREPARING_TO_OPEN_CANCEL,

  OPENING_HOURS_STATE_CHANGED_TO_PREPARING,
  OPENING_HOURS_STATE_CHANGED_TO_OPENED,
};
