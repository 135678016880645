import React from 'react';

import {
  RESTAURANT_OPEN_STATE_OPENED,
  RESTAURANT_OPEN_STATE_PREPARING,
  RESTAURANT_OPEN_STATE_LOADING,
} from 'src/consts/RESTAURANT_OPEN_STATE';

import { MonitorRestaurantConfigContext } from 'src/contexts/MonitorRestaurantConfig';

import RestaurantButtonLoading from './RestaurantButtonLoading';
import RestaurantOpenButton from './RestaurantOpenButton';
import RestaurantPreparingButton from './RestaurantPreparingButton';

export default function OpeningHourToggleButton() {
  // let [restaurant_open_state, setRestaurantOpenState] = React.useState(
  //   RESTAURANT_OPEN_STATE_OPENED
  // );

  let { restaurant_open_state, handleChangeRestaurantOpenState } =
    React.useContext(MonitorRestaurantConfigContext);

  switch (restaurant_open_state) {
    case RESTAURANT_OPEN_STATE_OPENED:
      return (
        <>
          <RestaurantOpenButton
            handleChangeRestaurantOpenState={handleChangeRestaurantOpenState}
          />
        </>
      );
    case RESTAURANT_OPEN_STATE_PREPARING:
      return (
        <>
          <RestaurantPreparingButton
            handleChangeRestaurantOpenState={handleChangeRestaurantOpenState}
          />
        </>
      );
    case RESTAURANT_OPEN_STATE_LOADING:
      return (
        <>
          <RestaurantButtonLoading />
        </>
      );
    default:
      return (
        <>
          <RestaurantOpenButton
            handleChangeRestaurantOpenState={handleChangeRestaurantOpenState}
          />
        </>
      );
  }
}
