import React from 'react';
import { Box } from '@material-ui/core';

import {
  LYNKED_WHITE,
  LYNKED_DEEP_GREY,
  LYNKED_DEEP_GREY_HOVER,
  LYNKED_RED,
} from 'src/consts/colors';
import ShowDebug from 'src/components/ShowDebug';

import active_lang from 'src/langs/jp_en';

import OpeningHourEnableButton from './OpeningHourEnableButton';

import { useStyles } from './styles';

import './rc-time-picker.css';

import {
  DOW_SUN,
  DOW_MON,
  DOW_TUE,
  DOW_WED,
  DOW_THU,
  DOW_FRI,
  DOW_SAT,
} from 'src/consts/WEEK';

import WarningIcon from '@material-ui/icons/Warning';
import StartTimePicker from './StartTimePicker';
import EndTimePicker from './EndTimePicker';
import { isUndefined } from 'lodash';
import { useTranslation } from 'react-i18next';

let dict_day_of_week_text = [
  [DOW_SUN, "SETTINGS_SUNDAY"],
  [DOW_MON, "SETTINGS_MONDAY"],
  [DOW_TUE, "SETTINGS_TUESDAY"],
  [DOW_WED, "SETTINGS_WEDNESDAY"],
  [DOW_THU, "SETTINGS_THURSDAY"],
  [DOW_FRI, "SETTINGS_FRIDAY"],
  [DOW_SAT, "SETTINGS_SATURDAY"],
];

const LOG_PREFIX = 'DayCard';

function lookupDayOfWeekText(day_of_week) {
  return dict_day_of_week_text.filter((test) => test[0] === day_of_week)[0];
}

export default function DayCard({ formik, day_of_week }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleStartTimeEndTimeEnableToggle = () => {
    console.log(
      LOG_PREFIX,
      formik.values['opening_schedule'][day_of_week].enabled
    );
    // setStartTimeEndTimeEnable(!start_time_end_time_enable);
    if (formik.values['opening_schedule'][day_of_week].enabled) {
    } else {
      if (
        isUndefined(formik.errors) ||
        isUndefined(formik.errors.opening_schedule)
      ) {
      } else {
        delete formik.errors.opening_schedule[day_of_week];
      }
    }

    formik.setFieldValue(
      `opening_schedule.${day_of_week}.enabled`,
      !formik.values['opening_schedule'][day_of_week].enabled
    );

    formik.handleBlur({
      target: { name: `opening_schedule.${day_of_week}.enabled` },
    });
  };

  return (
    <>
      <Box className={classes.day_card}>
        <Box
          style={{
            display: 'flex',
            flexFlow: 'row',
            justifyContent: 'center',
            alignItems: 'center',

            padding: '1rem',
          }}
        >
          <Box
            style={{
              background: LYNKED_WHITE,
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: formik.values['opening_schedule'][day_of_week]
                .enabled
                ? LYNKED_DEEP_GREY
                : LYNKED_DEEP_GREY_HOVER,

              boxSizing: 'border-box',
              borderRadius: '5px',

              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'center',
              alignItems: 'center',

              padding: '0.5rem',
            }}
          >
            <ShowDebug>{day_of_week}</ShowDebug>
            <Box style={{ marginTop: '1rem', marginBottom: '1rem' }}>
              <OpeningHourEnableButton
                value={formik.values['opening_schedule'][day_of_week].enabled}
                handleOnClick={handleStartTimeEndTimeEnableToggle}
                button_text={t(lookupDayOfWeekText(day_of_week)[1])}
              />
            </Box>
            <StartTimePicker formik={formik} day_of_week={day_of_week} />
            <EndTimePicker formik={formik} day_of_week={day_of_week} />

            <Box>
              {formik.touched &&
              formik.touched.opening_schedule &&
              formik.touched.opening_schedule[day_of_week] &&
              formik.errors &&
              formik.errors.opening_schedule &&
              formik.errors.opening_schedule[day_of_week] ? (
                <Box
                  style={{
                    color: LYNKED_RED,
                    display: 'flex',
                    flexFlow: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    paddingLeft: '0.25rem',
                    paddingTop: '0.25rem',
                    paddingBottom: '0.25rem',
                  }}
                >
                  <Box pr="1rem">
                    <WarningIcon fontSize="small" />
                  </Box>
                  <Box>
                    {t(formik.errors.opening_schedule[day_of_week][0])}
                  </Box>
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
