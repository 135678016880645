import React from 'react';
import moment from 'moment-timezone';

import { Box, Button, Grid } from '@material-ui/core';
import { UtilContext } from 'src/contexts/Util';

import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';

export default function HelpRequestCard({
  service_request_info,
  handleAcknowledge,
  idx,
}) {
  const classes = useStyles();
  let { ios_device } = React.useContext(UtilContext);
  const { t } = useTranslation();

  return (
    <>
      <Box
        className={classes.boxWrapper}
        style={{ width: ios_device ? '210px' : '250px' }}
      >
        <Grid container>
          <Grid
            item
            xs={9}
            style={{
              paddingRight: '0.5em',
              paddingLeft: '0.5em',
              paddingTop: '0.5em',
            }}
          >
            <Box
              style={{
                width: '100%',
                height: '33%',

                paddingLeft: '10px',
                fontSize: '8.5px',
                lineHeight: '24px',

                borderBottom: '1px solid #E0E0E0',
              }}
            >
              {process.env.NODE_ENV === 'test'
                ? t('COMMON_TIME_FORMAT')
                : moment
                    .unix(service_request_info.update_time / 1000)
                    .format(t('COMMON_TIME_FORMAT'))}
            </Box>
            <Box
              style={{
                width: '100%',
                height: '67%',

                display: 'inline-flex',
                alignItems: 'center',
                paddingLeft: '10px',

                fontSize: '0.8rem',
                lineHeight: '18px',
                fontWeight: 'bold'
              }}
            >{`${t('COMMON_TABLE')} ${service_request_info.table_name}`}</Box>
          </Grid>
          <Grid item xs={3}>
            <Button
              id={`ack-service-request-${idx}`}
              onClick={(e) => {
                handleAcknowledge(e, service_request_info.id);
              }}
              className={classes.FinishButton}
            >
              { t('DASHBOARD_COMPLETE') }
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
