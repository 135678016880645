import React from 'react';
import { Grid } from '@material-ui/core';
import CustomizeGroupElement from './CustomizeGroupElement';
import AddNewCustomizeGroup from './AddNewCustomizeGroup';

export default function ShowCustomizeGroup({
  handleAddNewCustomizeSetting,
  handleUpdateOptionPrice,
  handleUpdateOptionName,
  onChange,
  value,
  customize_group_settings,
  // touched_objs,
  formik,
  error_objs,
}) {
  let [show_tailing_add_button, setShowTailingAddButton] = React.useState(true);

  React.useEffect(() => {
    if (
      customize_group_settings &&
      customize_group_settings.length != 'undefined'
    ) {
      if (customize_group_settings.length >= 6) {
        setShowTailingAddButton(false);
      } else {
        setShowTailingAddButton(true);
      }
    } else {
      setShowTailingAddButton(true);
    }
    console.log(
      'ShowCustomizeGroup',
      'customize_group_settings',
      customize_group_settings
    );
  }, [customize_group_settings]);

  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          {customize_group_settings &&
            customize_group_settings.map((customize_group_setting, idx) => {
              let show_add_button = false;
              let settings_length = customize_group_settings.length;
              let last_one = settings_length - 1 == idx;
              if (settings_length < 6 && last_one) {
                show_add_button = true;
              }
              return (
                <Grid item xs={2} key={idx}>
                  <CustomizeGroupElement
                    customize_group_setting={customize_group_setting}
                    show_add_button={show_add_button}
                    // handleRemoveCustomizeSetting={handleRemoveCustomizeSetting}
                    idx={idx}
                    onChange={onChange}
                    value={value}
                    handleUpdateOptionName={handleUpdateOptionName}
                    handleUpdateOptionPrice={handleUpdateOptionPrice}
                    // touched_objs={touched_objs}
                    error_objs={error_objs}
                    formik={formik}
                  />
                </Grid>
              );
            })}
          {show_tailing_add_button ? (
            <>
              <AddNewCustomizeGroup
                id="customize_group_settings"
                onChange={onChange}
                value={value}
                // handleAddNewCustomizeSetting={
                //   handleAddNewCustomizeSetting
                // }
              />
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </>
  );
}
