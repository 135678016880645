import moment from 'moment-timezone';

function getNearestCutOffTime() {
  if (moment(moment.tz('JAPAN')).hours() > 6) {
    return moment(moment.tz('JAPAN')).hours(6).minute(0).second(0);
  } else {
    return moment(moment.tz('JAPAN'))
      .subtract(1, 'days')
      .hours(6)
      .minute(0)
      .second(0);
  }
}

export { getNearestCutOffTime };
