import React, { useRef } from 'react';
import { Box, makeStyles } from '@material-ui/core';

import ReceptionListCard from 'src/components/ReceptionListCard';

import { MonitorLineupContext } from 'src/contexts/MonitorLineup';
import { ReactComponent as ChevronDownButton } from 'src/assets/icons/chevron-down-btn.svg'
import { divide } from 'lodash';
import useOnScreen from './useOnScreen';

const useStyles = makeStyles((theme) => ({
  TableContainer: { marginBottom: '3rem' },
}));

export default function ShowReceptionList() {
  const classes = useStyles();
  let { line_up_list } = React.useContext(MonitorLineupContext);
  const lastReceptionCard = useRef(null);
  const isOnScreen = useOnScreen(lastReceptionCard);
  const handleScroll = () => {
    lastReceptionCard.current?.scrollIntoView();
  }

  return (
    <>
      <Box
        className={classes.TableContainer}
        style={{ font: 'normal normal bold 20px/26px Noto Sans JP' }}
      >
        <Box
          style={{
            paddingTop: '1rem',
            paddingBottom: '3rem',
            paddingRight: '1rem',
            position: 'relative'
          }}
        >
          {line_up_list.map((x, idx) => {
            return (
              <>
                {idx === line_up_list.length - 1 && <div ref={lastReceptionCard}></div>}
                <ReceptionListCard
                  line_up_info={x}
                  idx={idx}
                  key={`reception_list_card_${idx}`}
                />
              </>
            )
          })}

          {!isOnScreen && <ChevronDownButton style={{ position: 'fixed', bottom: 20, right: 30, cursor: 'pointer' }}
            onClick={handleScroll} ></ChevronDownButton>}
        </Box>
      </Box>
    </>
  );
}
